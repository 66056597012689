import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd-mobile'
import cls from 'classnames'

import { storeDetailsRequest, storeClassifyRequest, storeMedPage } from '../../api/request/store'
import { cartlistRequest } from "../../api/request/cart"
import { fllowRequest } from '../../api/request/fllow'
import RecommmendMed from '../../component/Goods/rmd-med'
import { getonemedAll } from "../../api/request/med"
import './style.less'

import IMG from '../../static/img/store/backimg.png'

export default function Shop(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const pharmacyId = props.match.params.pharmacyId
    const pharmacyId2 = localStorage.type == "1" ? 1 : localStorage.type == "2" ? 1 : 3
    const type = localStorage.type
    const [load, setLoad] = useState(true)
    const [page, setPage] = useState(1)
    const [size] = useState(20)
    const [details, setDetails] = useState(null)
    const [medList, setMedList] = useState([])
    const [isFollow, setIsFollow] = useState(false)
    const [footindex, setfootindex] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const [category, setCategory] = useState([])
    const [classify, setClassify] = useState([])
    const [showClassify, setShowClassify] = useState([])
    const [selectMenuId, setSelectMenuId] = useState(null)
    const [searchVal, setSearchVal] = useState('')
    const [cartnum, setCartnum] = useState(null)
    const [medNum, setmedNum] = useState(0)
    //const tabs = [{ title: '精选' }, { title: '商品' }, { title: '活动' }, { title: '上新' }, { title: '动态' }]
    const tabs = [{ title: '商品' }, { title: '活动' }, { title: '上新' }]
    useEffect(() => {
        storeDetailsRequest(pharmacyId, userId).then(res => {
            if (res.code === 1 && res.data[0].length > 0) {
                const data = res.data
                setDetails(data[0][0])
                setIsFollow(data[1].length > 0)
                setMedList(data[2] && data[2].length > 0 ? data[2] : [])
                document.title = data[0][0].pharmacyName
            }
        })
    }, [pharmacyId, userId])

    useEffect(() => {
        refresh && setTimeout(() => setRefresh(false))
    }, [refresh])

    //获取购物车信息
    useEffect(() => {
        getcartnum()
        getallnum()
    }, [])
    //获取商家所有商品数
    const getallnum = async () => {
        const res = await getonemedAll({
            pharmacyId: pharmacyId
        })
        setmedNum(res.data[0][0].num)
    }
    const getcartnum = async () => {
        const pharmacyId = pharmacyId2
        const res = await cartlistRequest(userId, pharmacyId)
        setCartnum(res.data[0].length)
        // console.log(res)
    }
    const onscroll = e => {
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10) {
            pageMedlist()
        }
    }

    const pageMedlist = () => {
        if (load) {
            const params = {
                pharmacyId: pharmacyId,
                page: page + 1,
                size: size
            }
            storeMedPage(params).then(res => {
                if (res.code === 1) {
                    let data = res.data
                    if (data[0].length > 0) {
                        setMedList([...medList, ...data[0]])
                        setPage(params.page)
                        if (data[0].length < size) {
                            setLoad(false)
                        }
                    } else {
                        if (data[0].length < size) {
                            setLoad(false)
                        }
                    }
                }
            })
            setRefresh(true)
        }
    }
    const renderContent = (tab, index) => {
        return (
            <>
                <div className='shop-contain-item' onScroll={onscroll}>
                    <RecommmendMed recommand={medList} getcartnum={getcartnum} />
                </div>
            </>
        )
    }
    const backPage = () => {
        window.history.back()
    }

    const follow = () => {
        const params = {
            type: 2,
            userId: userId,
            pharmacyId: pharmacyId
        }
        if (!isFollow && userId) {
            fllowRequest(params).then(res => {
                if (res.code === 1) {
                    setIsFollow(true)
                }
            })
            setRefresh(true)
        }
    }

    const storefootnav = (index) => {
        setfootindex(index)
        switch (index) {
            case 0:
                storeDetailsRequest(pharmacyId, userId).then(res => {
                    if (res.code === 1 && res.data[0].length > 0) {
                        const data = res.data
                        setDetails(data[0][0])
                        setIsFollow(data[1].length > 0)
                        setMedList(data[2] && data[2].length > 0 ? data[2] : [])
                        document.title = data[0][0].pharmacyName
                    }
                })
                break;
            case 1:
                props.history.push("/shopcart")
                break;
            case 2:
                storeClassifyRequest(pharmacyId).then(res => {
                    if (res.code === 1 && res.data[0].length > 0) {
                        const data = res.data[0]
                        let newcategory = []
                        let newclassify = []
                        let classifyArr = []
                        data.forEach((item, index) => {
                            if (item.parentCode === '0') {
                                newcategory.push({
                                    categoryId: item.classifyId,
                                    categoryCode: item.classifyCode,
                                    categoryName: item.classifyName
                                })
                            } else {
                                newclassify.push({
                                    categoryId: item.categoryId,
                                    ...item
                                })
                                if (item.parentCode === data[0].classifyCode) {
                                    classifyArr.push({
                                        categoryId: item.categoryId,
                                        ...item
                                    })
                                }
                            }
                        })
                        setCategory(newcategory)
                        setClassify(newclassify)
                        setSelectMenuId(data[0].classifyId)
                        setShowClassify(classifyArr)
                    }
                })
                break;

            default:
                break;
        }
    }

    const menuClick = (v) => {
        //console.log(111);
        let classifyArr = []
        classify.forEach((item, index) => {
            if (item.parentCode === v.categoryCode) {
                classifyArr.push({
                    categoryId: v.categoryId,
                    ...item
                })
            }
        })
        setSelectMenuId(v.categoryId)
        setShowClassify(classifyArr)
    }

    const menuModalItemStyle = (categoryId, compareArr, compareId) => {
        if (selectMenuId === null) {
            setSelectMenuId(compareArr.categoryId)
            const cid = compareArr[0].categoryId
            return cls('item', cid === categoryId ? 'active' : null)
        }
        return cls('item', categoryId === compareId ? 'active' : null)
    }

    const subItemClick = (v) => {
        props.history.push(`/medlist/${selectMenuId}/${v.classifyId}/${v.classifyName}?pharmacyId=${pharmacyId}`)
    }

    const shopSearch = (val) => {
        setSearchVal(val)
    }

    const toSearch = () => {
        props.history.push(`/medlist/0/0/${searchVal}?shopId=${pharmacyId}`)
    }
    //跳转到店铺详情
    const toshopinfo = () => {
        props.history.push({
            pathname: "/shopinfo",
            params: {
                pharmacyId
            }
        })
    }

    return (
        <div>
            <div className='shop-backimg' style={{ backgroundImage: `url(${IMG})` }}></div>
            <div className='backimg-mask' />
            <div className='shop-content'>
                <div className='shop-header'>
                    <div className='shop-header-zhanwei flex-row' onClick={backPage}>
                        <i className='iconfont icon-xiangzuo'></i>
                    </div>
                    <div className='shop-search flexr'
                        style={{ border: '1px solid #414141' }}>
                        <i className='iconfont icon-suosou'></i>
                        <input type="text" placeholder='999感冒灵'
                            onChange={({ target: { value } }) => shopSearch(value)} />
                        {searchVal ? <div className='shop-search-btn' onClick={toSearch}>搜索</div> : null}
                    </div>
                    <div className='shop-header-zhanwei'></div>
                </div>
                {details === null ? null :
                    <div className='shop-introduce'>
                        <div className='shop-brank flexr'>
                            <div className='shop-logo flexr'>
                                <img src={details.pharmacyPhoto} alt='' onClick={toshopinfo} />
                            </div>
                            <div className='shop-title flexc'>
                                <p>{details.pharmacyName}</p>
                                {type == "2" ? <span>{details.followNum > 9999 ? `${(details.followNum / 10000).toFixed(1)}万` : details.followNum}人关注   共 <span style={{ color: "#FF5555" }}>({medNum})</span> 种商品</span> :
                                    <span>{details.followNum > 9999 ? `${(details.followNum / 10000).toFixed(1)}万` : details.followNum}人关注</span>
                                }

                            </div>
                            <div className='shop-fllow flex-row'
                                onClick={follow}
                                style={{ backgroundColor: type == "2" ? "#FF5555" : "#00B7F5" }}
                            >{isFollow ? '已关注' : '关注'}</div>
                        </div>
                    </div>
                }
            </div>
            <div className='shop-tab-contain'>
                {footindex === 0 ?
                    <Tabs
                        tabs={tabs}
                        tabBarTextStyle={{ fontSize: '14px', padding: '0', height: '62px' }}
                        tabBarActiveTextColor="#FFFFFF"
                        tabBarInactiveTextColor="#FFFFFF"
                        tabBarBackgroundColor='none'
                        tabBarUnderlineStyle={{ border: '2px #FFFFFF solid', transform: 'scaleX(0.4)', bottom: '16px' }}
                        renderTabBar={props => <Tabs.DefaultTabBar {...props} page={6} />}>
                        {renderContent}
                    </Tabs>
                    : null}
                {footindex === 1 ?
                    <>
                        <div className='shop-sort flex-apart-row'>
                            <div className='shop-sort-item shopSortActive'>推荐</div>
                            <div className='shop-sort-item'>销量</div>
                            <div className='shop-sort-item'>价格</div>
                        </div>
                        <div className='shop-contain-item'></div>
                    </>
                    : null}
                {footindex === 2 ?
                    <div className='shop-classify-body'>
                        <div className='menu'>
                            <div className='menu-scroll'>
                                {
                                    category.map((v, i) => (
                                        <div key={i}
                                            className={menuModalItemStyle(v.categoryId, category, selectMenuId)}
                                            onClick={() => menuClick(v)}>
                                            <span>{v.categoryName}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='detail'>
                            <div>
                                {
                                    showClassify.map(v => (
                                        <div
                                            key={v.classifyId}
                                            onClick={() => subItemClick(v)}
                                            className='ill-item flex-col'
                                        >
                                            <div className='img'>
                                                <img src={v.classifyPng} alt='' />
                                            </div>
                                            <span className='title'>{v.classifyName}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div> : null
                }
            </div>
            <div className='shop-footer'>
                <ul className="clear-fix">
                    <li>
                        <div onClick={() => storefootnav(0)} className={footindex === 0 ? 'shopFootActive' : ''}>
                            <i className="iconfont icon-shouye"></i>
                            首页
                        </div>
                    </li>

                    <li>
                        <div onClick={() => storefootnav(2)} className={footindex === 2 ? 'shopFootActive' : ''}>
                            <i className="iconfont icon-fenlei"></i>
                            分类
                        </div>
                    </li>
                    <li>
                        <div onClick={() => storefootnav(1)} className={footindex === 1 ? 'shopFootActive' : ''}>
                            <p className="p-move">{cartnum}</p>
                            <i className="iconfont icon-tubiaozhizuomoban"></i>
                            购物车
                        </div>
                    </li>
                    <li>
                        {/* <div onClick={()=>setfootindex(3)} className={footindex===3?'shopFootActive':''}>
                            <i className="iconfont icon-tubiao210"></i>
                            联系客服
                        </div> */}
                        {details === null ? null :
                            <a className='shop-telphone' href={`tel:${details.servicePhone}`}>
                                <i className="iconfont icon-tubiao210"></i>
                                联系客服
                            </a>
                        }
                    </li>
                </ul>
            </div>
        </div>

    )
}