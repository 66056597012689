import React, { Component } from 'react'
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/yy/moren.png"
import icon3 from "../../static/img/yy/right.png"
import icon4 from "../../static/img/yy/touxiang.png"
import icon5 from "../../static/img/yy/top.png"
import "./style.less"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            code: false
        }
    }
    gobank = () => {
        window.history.back()
    }
    render() {
        const { code } = this.state
        return (
            <div className='storebox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>选择预约药店</h1>
                </div>
                <div className='box-title'>
                    我们将根据地址为您推荐附近最优的药房服务
                </div>
                <div className='box-storetext'>
                    <p>
                        <img src={icon2} alt="" /><span>四川省成都市新都区</span>
                    </p>
                    <p className='store-name'>
                        福瑞民心堂大药房3楼302
                    </p>
                    <p>
                        <span>客服小吴</span><span>19048012565</span>
                    </p>
                    <img src={icon3} alt="" className='text-right' />
                </div>
                <div className='box-content'>
                    <div className='content-top'>
                        <img src={icon4} alt="" />
                        <div>
                            <p>福瑞民心堂（云药购店）</p>
                            <p className='top-time'>预计30分钟送达</p>
                            <p>￥68.70</p>
                        </div>
                        <button onClick={()=>this.props.history.push("/submit")}>去预约</button>
                    </div>
                    {
                        code ? <>
                            <div className='content-text'>
                                <p>
                                    <span>药品名字</span><span>规格</span><span>适应症状适应症状 ...</span>
                                </p>
                                <p>x2</p>
                                <p>￥单价</p>
                            </div>
                            <div className='content-text'>
                                <p>
                                    <span>药品名字</span><span>规格</span><span>适应症状适应症状 ...</span>
                                </p>
                                <p>x2</p>
                                <p>￥单价</p>
                            </div>
                            <div className='content-text'>
                                <p>
                                    <span>药品名字</span><span>规格</span><span>适应症状适应症状 ...</span>
                                </p>
                                <p>x2</p>
                                <p>￥单价</p>
                            </div>
                        </> : <div className='content-text'>
                            <p>
                                <span>药品名字</span><span>规格</span><span>适应症状适应症状 ...</span>
                            </p>
                            <p>x2</p>
                            <p>￥单价</p>
                        </div>
                    }
                    {code ? <div className='content-shou' onClick={()=>{
                        this.setState({
                            code:false
                        })
                    }}>
                        <span>收起</span><img src={icon5} alt="" />
                    </div> : <div className='content-btn'>
                        <button onClick={()=>{
                        this.setState({
                            code:true
                        })
                    }}>查看全部</button>
                    </div>}


                </div>
            </div>
        )
    }
}
