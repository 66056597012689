import React, { Component } from 'react';
import "./style.less"
import { Toast, ImagePicker,Modal } from "antd-mobile"
import { Divider, Dialog, ImageViewer } from 'antd-mobile-v5'
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/yy/touxiang.png"
import icon3 from "../../static/img/doctor/tui.png"
import icon4 from "../../static/img/doctor/jie.png"
import icon5 from "../../static/img/sure1.png"
import icon7 from "../../static/img/sure2.png"
import icon6 from "../../static/img/doctor/img2.png"
import {
  getonenews,
  getstatus,
  changestatus,
  addnews,
  docAvater,
  prescribingDetail,
  updateDoctype,
  getStatus,
  refundApply,
  getIcdCode,
  saveIcd,
  allIcd, getnewslist
} from "../../api/hospitalapi/news"
import { zipImg } from '../../component/zipImage'
import { uploadpic,getToken,addToken,tokenSupervision,uploadFurtherConsult,updateConsultation } from "../../api/hospitalapi/upload"
import { getBase64 ,supervise_data,supervise_url} from "../../api/config"
import { parseAsync } from '@babel/core';
import moment from 'moment'
import {axiosInstance} from "../../api/config";
import {logDOM} from "@testing-library/react";

import {postSupervise} from '../../api/config3'
import Nodata from "../../component/Nodata";

const alert = Modal.alert
export default class index extends Component {
  constructor() {
    super()
    this.state = {
      bottomcode: false,
      bottomcode1: 1,
      visible: false,
      overcode: false,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      icdList: [],
      list: [],
      list1: [],
      list2: [],
      item: JSON.parse(localStorage.getItem("item")),
      reason: "",
      docterId: localStorage.getItem("docterId"),
      consultationId: "2",
      userId: "1",
      userpic: "http://images.yyj-health.com/202202/1644215398065.gif",
      detailContent: "",
      fileList: [],
      patientName: '',
      patientSex: '',
      patientBirthday: '',
      consultationDetail: '',
      timer: 0,
      docterAvatar: '',
      memberId: '',
      details: [],
      b: [],
      clickFd: 0,
      index1: -1,
      chatLength: 0,
      tip: false,
      patientDetails: '',
      consultationFiles: '',
      image: {
        url: '',
        visible: false
      },
      page: 1,
      size: 20,
      icdone: '',
      icdlists: [],
      token: '',
      formData: [],
      upload: []
    }
  }

  componentDidMount = async () => {
    //默认信息触底
    const {item, docterAvatar, docterId} = this.state

    let res = await docAvater({docterId})
    if (res && res.data && res.data[0]) {
      this.setState({
        docterAvatar: res.data[0][0].docterAvatar
      })
    }
    // console.log(item);
    if (item != null) {
      this.setState({
        consultationId: item.consultation_id,
        userpic: item.patient_avatar ? item.patient_avatar : "http://images.yyj-health.com/202202/1644215398065.gif",
        username: item.patientName,
        userId: item.user_id,
      }, () => {

        this.getnewsstatus()
        this.getnews()
        const newTimer = window.setTimeout(() => {
          this.scrollToBottom();
          window.clearTimeout(newTimer)
        }, 50);
      })
    }
    // let res=await allMedicinal({prescribingId:this.props.location.params.detailContent})
    // console.log(res);
    // if (res.code==1&&res.data[0]) {
    //     let res1=await prescribingDetail({prescribingId:res.data[0][0].prescribing_id})
    //     console.log(res1);
    //     this.setState({
    //         list:res.data[0],
    //         detail:res1.data[0][0]
    //     })
    // }
  }
  // 获取聊天信息
  getnews = async () => {
    const {timer, item, consultationId, details, b, chatLength} = this.state
    window.clearTimeout(timer)
    if (consultationId) {
      const res = await getonenews({
        consultationId
      })
      // console.log(res);
      // console.log(res.data[2].length);
      if (res && res.data && res.data[2]) {
        this.setState({
          chatLength: res.data[2].length
        })
        // console.log(chatLength,res.data[2].length);
        if (chatLength != 0 && chatLength < res.data[2].length && res.data[2][res.data[2].length - 1].Identity == '1') {
          // this.setState({
          //   tip:true
          // })
          Toast.info('您有新消息')
        } else {
          // this.setState({
          //   tip:false
          // })
        }
      }
      if (res && res.data && res.data[0] && res.data[0].length) {
        if (item.consultation_stu == '-1' || item.consultation_stu == '0') {
          const newTimer = window.setTimeout(() => {
            this.getnews()
          }, 5000);
          // localStorage.setItem('timer',newTimer)
          this.setState({
            timer: newTimer
          })

        }
        localStorage.setItem('memberId', res.data[0][0].memberId)
        let a = []
        res.data[2].map((item, index) => {
          if (item.detailType == '3') {
            a.push(item)
            // prescribingDetail({prescribingId:item.detailContent}).then(res1=>{
            //   // console.log(res1);
            //   a.push(res1.data[0][0])
            //   // console.log(a);
            //   if (a!=details) {
            //     this.setState({
            //        details:a
            //     })
            //   }
            // })
          }
        })
        // console.log(a);
        this.setState({
          b: a,
          patientDetails: res.data[1][0],//患者详情
          consultationDetail: res.data[0][0].consultationDetail,  //病情描述
          consultationFiles: res.data[0][0].consultationFiles,
          patientName: res.data[1][0].patient_name,  //患者问诊信息
          patientSex: res.data[1][0].patient_sex,
          patientBirthday: (new Date().getFullYear()) - (new Date(res.data[1][0].patient_birthday).getFullYear()),
          memberId: res.data[0][0].memberId,
          list: res.data[2]  //聊天内容,
        }, () => {
          // this.scrollToBottom();
        })
        let c = []
        if (a.length != b.length) {
          a.map(item => {
            prescribingDetail({prescribingId: item.detailContent}).then(res1 => {
              // console.log(res1);
              if (res1 && res1.data && res1.data[0]) {
                c.push(res1.data[0][0])
                // console.log(a);
                this.setState({
                  details: c
                })
              }
            })
          })

        }

      }
    }

  }
  // 获取当前里聊天状态
  getnewsstatus = () => {
    const {docterId, userId, item} = this.state
    // console.log(item);
    getStatus({
      docterId: parseInt(docterId),
      userId,
      consultationId: item.consultation_id
      // orderNumber:item.order_number
    }).then(res => {
      // console.log(docterId, userId);
      // console.log(res);
      this.setState({
        bottomcode1: 2,
        bottomcode: res.data[0].length && res.data[0][0].consultationStu == "0" ? true : false
      })
    })

  }

  //聊天界面默认显示到最底部
  scrollToBottom = () => {
    // console.log(111);
    if (this.messagesEnd) {
      const scrollHeight = this.messagesEnd.scrollHeight;//里面div的实际高度  
      const height = this.messagesEnd.clientHeight;  //网页可见高度  
      const maxScrollTop = scrollHeight - height;
      this.messagesEnd.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
      // console.log(maxScrollTop,scrollHeight , height)
      //如果实际高度大于可见高度，说明是有滚动条的，则直接把网页被卷去的高度设置为两个div的高度差，实际效果就是滚动到底部了。
    }
  }
  closebox = async (code) => {
    const {consultationId, item, docterId} = this.state
    if (code == 2) {
      const res = await changestatus({
        consultationStu: 1,
        consultationId
      })
      if (res.code == 1 && item.order_number) {
        updateDoctype({docterId, docterType: 1}).then(res0 => {
          // console.log(res0);
        })
      }
      let a = item
      a.consultation_stu = '1'
      localStorage.setItem("item", JSON.stringify(a))
      this.getnewsstatus()
      //上传监管平台
      this.getToken()
      this.setState({
        visible: false,
        overcode: true
      })

    } else if (code == 1) {
      this.setState({
        visible: false
      })
    } else if (code == 3) {
      this.setState({
        visible3: false
      })
    } else if (code == 4) {
      this.setState({
        visible3: false
      })
      const {consultationId, item} = this.state
      if (item.order_number) {
        updateDoctype({docterId, docterType: 2}).then(res0 => {
          // console.log(res0);
        })
      }
      const res = await changestatus({
        consultationStu: 0,
        consultationId
      })
      // console.log(res);
      let a = item
      a.consultation_stu = '0'
      localStorage.setItem("item", JSON.stringify(a))
      this.setState({
        bottomcode1: 2,
        bottomcode: true
      })
      // console.log(res);
    }

  }
  //获取监管平台token
  getToken = async () => {
    // this.uploadData()
    this.storageToken();
  }
  /**
   * @description 获取token
   */
  storageToken = () => {
    const {token, upload, item} = this.state
    tokenSupervision({})
        .then((data) => {
          if (data.data[0][0]) {
            // console.log(data)
            this.setState({
              token: data.data[0][0].token
            }, () => {
              this.getImage()
            })
          } else {
            getToken()
                .then((data) => {
                  let value = JSON.parse(data);
                  addToken({token: value.accessToken, expiresIn: value.expiresIn}).then((data) => {
                    this.setState({
                      token: value.accessToken
                    }, () => {
                      this.getImage()
                    })
                    Toast.success('成功');
                  });
                })
                .catch((e) => console.log(e?.message || '失败'));
          }
        })
        .catch((e) => Toast.fail('请求失败'));
  };
  /**
   * @description 获取图片64
   * @params item
   */
  getImage = async () => {
    const {token, upload, item} = this.state
    // console.log(item)
    let res = await getnewslist({
      consultationId: item.consultation_id
    })
    // console.log(res);
    if (res && res.data && res.data[0]) {
      getBase64(item.consultation_files, callback);

      let type = item.consultation_files?.split('.').pop().toLowerCase();

      let furtherConsultDiagnosis = [];
      let furtherConsultDiagnosisNo = [];
      JSON.parse(res.data[0][0].electronic_icd).forEach((item) => {
        furtherConsultDiagnosisNo.push(item.icdValue);
        furtherConsultDiagnosis.push(item.icdName);
      });

      function callback(value) {
        upload.push({
          accessToken: token,
          clientId: supervise_data.clientId,
          thirdUniqueid: item.consultation_id,
          orgName: supervise_data.orgName,
          orgCode: supervise_data.orgCode,
          channelName: supervise_data.channelName,
          section: item.dep_name,
          sectionCode: item.dep_num,
          docName: item.docter_name,
          certificateNum: item.certificateNum,
          patientName: item.patientName,
          patientAge: item.patient_age,
          patientSex: item.patientSex,
          patientIdcardType: 1,
          patientIdcardNum: item.patientIdcardNum,
          furtherConsultNo: item.consultation_number,
          furtherConsulType: parseInt(item.consultation_type),
          medicalHistory: item.consultation_detail,
          consultDiagnosisType: type === 'jpg' ? 2 : type === 'png' ? 3 : 4,
          consultDiagnosis: value,
          consultOrg: item.consultOrg,
          furtherConsultApplyTime: moment(item.pay_time).format('YYYY-MM-DD HH:mm:ss'),

          furtherConsulStartTime: moment(item.reception_time).format('YYYY-MM-DD HH:mm:ss'),
          furtherConsulEndTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          furtherConsulIsReply: 1,
          feeType: 1,
          furtherConsultDiagnosis: furtherConsultDiagnosis.toString().replace(',', '|'),
          furtherConsultDiagnosisNo: furtherConsultDiagnosisNo.toString().replace(',', '|'),
          furtherConsultPrice: item.consultation_amount / 100,
          patientEvaluate: 1,
          isPrescription: 1,
          uploadTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          cityId: item.city_id,
          isMark: 1,
          isRiskWarn: 1,
          isPatientSign: 1,
          disposeResult:'无',
          complainInfo:'无',
          consultTime:moment(item.consultTime).format('YYYY-MM-DD HH:mm:ss'),

        });

        if (item.patient_age < 6) {
          upload.guardianName = item.guardianName;
          upload.guardianIdcardNum = item.guardianIdcardNum;
        }
        // console.log(upload)
        postSupervise('/wjw/upload/uploadFurtherConsult', upload).then(data => {
          // console.log(data)
          // Toast.success('上传监管平台成功')
          if (data.data.status!=-1){
            updateConsultation({id:item.consultation_id}).then(data1=>{
              // console.log(data1)
            })
          }
        }).catch(e => {
          // console.log(e)
          // Toast.fail('上传监管平台失败')
        })
      }
    }
  };

  gobank = () => {
    // window.history.back()
    const {timer} = this.state
    window.clearTimeout(timer)
    this.props.history.push("/news")
  }
  getreason = (e) => {
    this.setState({
      reason: e.target.value
    })
  }
  // 确认退诊
  cancelchat = async () => {
    const {reason, consultationId, item, docterId} = this.state
    if (reason) {
      const res = await changestatus({
        consultationStu: 3,
        consultationId,
        consultationReason: reason
      })
      if (res.code == 1 && item.order_number) {
        updateDoctype({docterId, docterType: 1}).then(res0 => {
          // console.log(res0);
        })
      }
      if (res && res.code == 1) {
        let params = {
          client_id: 5000,
          client_trade_no: item.consultation_number,
          total_fee: item.consultation_amount,
          timestamp: moment().format('YYYYMMDDHHmmss'),
        }
        refundApply(params).then(res1 => {
          // console.log(res1)
          if (res1 && res1.code == 1) {
            Toast.info('退款成功')
          }
        })
      }
      let a = item
      a.consultation_stu = '3'
      localStorage.setItem("item", JSON.stringify(a))
      this.getnewsstatus()
      this.setState({
        visible2: false
      })
    } else {
      Toast.fail("请选择缘由", 1)
    }
  }
  changevisible2 = () => {
    this.setState({
      visible2: true
    })
  }
  //更改聊天状态
  changestatus = async () => {
    // const { consultationId,item } = this.state
    this.setState({
      visible3: true
    })
    // const res = await changestatus({
    //   consultationStu: 0,
    //   consultationId
    // })
    // console.log(res);
    // let a=item
    // a.consultation_stu='0'
    // localStorage.setItem("item", JSON.stringify(a))
    // this.getnewsstatus()
    // console.log(res);
  }
  //获取聊天框内容
  changevalue = (e) => {
    this.setState({
      detailContent: e.target.value
    })
  }
  //发送聊天信息
  sendout = async () => {
    const {consultationId, detailContent} = this.state
    // console.log(detailContent);
    if (detailContent) {
      const res = await addnews({
        consultationId,
        Identity: 2,
        detailType: 1,
        detailContent
      })
      this.getnews()
      const newTimer = window.setTimeout(() => {
        this.scrollToBottom();
        window.clearTimeout(newTimer)
      }, 50);
      this.setState({
        detailContent: ""
      })
    } else {
      Toast.fail("请先输入内容", 1)
    }
  }
  //图片上传发送
  imgChange = async (files, type, index) => {
    const {consultationId} = this.state
    if (type == "add") {
      let file = files[files.length - 1].file
      if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/jpg") {
        if (file.size < 1024 * 1024) {
          let formData = new FormData()
          const res = await zipImg(file)
          // console.log(res);
          formData.append('file', res)
          const res2 = await uploadpic(formData)
          //  console.log(res2);
          // this.setState({
          //     fileList: [...files]
          // })
          // console.log(res2);
          const res3 = await addnews({
            consultationId: consultationId,
            Identity: 2,
            detailType: 2,
            detailContent: res2.data
          })
          this.getnews()
          this.setState({
            detailContent: ""
          })
        } else {
          Toast.show('请上传小于1M的图片', 1)
        }
      } else {
        Toast.show('请上传png/jpg格式的图片', 1)
      }
    } else {
      this.setState({
        fileList: []
      })
    }
  }
  // 查看详情
  toinfo = (detailContent) => {
    // console.log(detailContent)
    const {consultationDetail, patientName, patientSex, patientBirthday} = this.state
    const {timer} = this.state
    window.clearTimeout(timer)
    this.props.history.push({
      pathname: "/doctorinfo/chat/chatinfo",
      params: {
        detailContent: detailContent,
        consultationDetail: consultationDetail,  //病情描述
        patientName: patientName,  //患者问诊信息
        patientSex: patientSex,
        patientBirthday: patientBirthday
      }
    })
  }
  // 开处方
  prescribingCf = () => {
    const {timer, memberId} = this.state
    alert('确认开处方', '处方提交后不能修改病历诊断', [
      {
        text: '取消', onPress: () => {
          console.log('取消')
        }
      },
      {
        text: '确认', onPress: () => {
          window.clearTimeout(timer)
          this.props.history.push({
            pathname: "/prescribing",
            // params:{
            //   memberId
            // }
          })
        }
      },
    ])

  }

  componentWillUnmount = () => {
    const {timer} = this.state
    window.clearInterval(timer)
  }
  imgFd = (index) => {
    const {clickFd} = this.state
    if (clickFd == 0) {
      this.setState({
        clickFd: 1,
        index1: index
      })
    } else {
      this.setState({
        clickFd: 0,
        index1: -1
      })
    }
  }
  sxImg = () => {
    const {clickFd} = this.state
    if (clickFd == 1) {
      this.setState({
        clickFd: 0,
        index1: -1
      })
    }
  }
  // 结束问诊
  overwz = () => {
    this.setState({
      visible: true
    })
  }
  //输入病历，模糊查询
  changeuserinfo = (e) => {
    this.setState({
      icdone: e.target.value
    })
  }
  //搜索病历
  onSearch = async () => {
    const {page, size, icdone, icdList, icdlists} = this.state
    const params = {
      content: icdone,
      page: 1,
      size: size,
    }
    let res = await getIcdCode(params)
    // console.log(res)
    if (res && res.data && res.data[0]) {
      res.data[0].forEach(i => {
        icdlists.forEach(j => {
          if (i.id == j.id) {
            i.state = 1
          }
        })
      })
      this.setState({
        icdList: res.data[0],
        page: 1,
        visible5: true
      })

    }
  }
  // 开电子病历
  prescribingBl = async () => {
    // let res = await getIcdCode({})
    // console.log(res)
    // if (res && res.data && res.data[0]) {
    //   this.setState({
    //     icdList: res.data[0]
    //   })
    // }
    this.setState({
      visible4: true
    })
  }
  //分页搜索病历
  pageMedlist = async () => {
    const {page, size, icdList, icdone} = this.state
    const params = {
      content: icdone,
      page: page + 1,
      size: size,
    }
    let res = await getIcdCode(params)
    console.log(res)
    if (res && res.data && res.data[0]) {
      this.setState({
        icdList: [...icdList, ...res.data[0]],
        page: page + 1
      })
    }
  }
  onscroll = e => {
    // console.log(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight);
    if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10) {
      this.pageMedlist()
    }
  }
  //选择或取消某个病历
  setIcd = (e, item) => {
    const {page, size, icdList, icdone, icdlists} = this.state
    e.stopPropagation()
    let arr = []
    let arr1 = []
    let arr2 = []
    // console.log(item)
    if (item.state) {
      item.state = 0
      icdlists.map(j => {
        if (j.id != item.id) {
          arr2.push(j)
        }
      })
    } else {
      item.state = 1
      arr2 = icdlists
    }
    this.setState({
      icdList: [...icdList],
    }, () => {
      icdList.map(i => {
        if (i.state) {
          arr.push(i)
        }
      })
      arr2.map(j => {
        if (j.state) {
          arr1.push(j)
        }
      })
      let nArr = [...arr1, ...arr].filter((currentValue, currentIndex, selfArr) => {
        return selfArr.findIndex(x => x.id === currentValue.id) === currentIndex
      });
      this.setState({
        icdlists: nArr
      })
    })
  }
  //确认病历
  onSure = async () => {
    const {icdlists, consultationId, detailContent} = this.state
    let arr = []
    if (icdlists.length) {
      icdlists.map(item => {
        arr.push(item.icdName)
      })
      let a = arr.join(',')
      alert('确认选择病历', a, [
        {
          text: '取消', onPress: () => {
            console.log('取消')
          }
        },
        {
          text: '确认', onPress: () => {
            addnews({
              consultationId,
              Identity: 2,
              detailType: 1,
              detailContent: '根据您的病情描述诊断为：' + a
            }).then(res => {
              this.getnews()
              this.saveIcd()
              const newTimer = window.setTimeout(() => {
                this.scrollToBottom();
                window.clearTimeout(newTimer)
              }, 50);
              this.setState({
                detailContent: "",
                visible4: false,
                visible5: false,
                icdlists: [],
              })
            })
          }
        },
      ])
    } else {
      Toast.show('请先选择患者病历')
    }
  }
  onSure1 = async (e) => {
    e.stopPropagation()
    Toast.show('请先选择患者病历')
  }
  //上传保存电子病历
  saveIcd = async () => {
    const {icdlists, consultationId, detailContent} = this.state
    let res0 = await allIcd(consultationId)
    // console.log(res0)
    if (res0 && res0.data && res0.data[0] && res0.data[0].length) {
      let res = await saveIcd({consultationId, electronicIcd: JSON.stringify(icdlists), type: 2})
      // console.log(res)
    } else {
      let res = await saveIcd({consultationId, electronicIcd: JSON.stringify(icdlists), type: 1})
      // console.log(res)
    }
  }

  render() {
    const {
      patientDetails,
      consultationFiles,
      image,
      userpic,
      details,
      index1,
      consultationId,
      docterAvatar,
      clickFd,
      item,
      patientSex,
      patientBirthday,
      bottomcode1,
      bottomcode,
      visible,
      visible3,
      overcode,
      list,
      patientName,
      consultationDetail,
      visible2,
      visible4,
      visible5,
      icdList,
      username,
      detailContent,
      fileList,
      icdlists
    } = this.state
    // console.log(icdlists);
    return (
        <>
          {item ? <div className='chatdoctorbox' onClick={this.sxImg}>
            <div className='box-top'>
              <img src={icon1} alt="" onClick={this.gobank}/><h1>{username}</h1>{bottomcode ?
                <p style={{marginRight: '10px'}} onClick={() => this.overwz()}>结束问诊</p> : null}
            </div>
            {consultationId && bottomcode1 == 2 ?
                <>
                  {item.consultation_stu == '-1' ? <div className='box-status'>
                        待接诊<span>{item.order_number ? '零售处方单' : '医院订单'}</span>
                      </div> :
                      item.consultation_stu == '1' || (item.consultation_stu == '0' && !bottomcode) || (item.consultation_stu == '-1' && !bottomcode) ?
                          <div className='box-status'>
                            接诊已结束
                          </div> :
                          item.consultation_stu == '3' ? <div className='box-status'>
                            医生已退诊
                          </div> : null}
                </> : null}
            <div className={item && item.consultation_stu == '0' ? 'box-container' : 'box-container box-container1'}
                 id='box-container' ref={(el) => {
              this.messagesEnd = el;
            }}>
              <>
                <div className='container-dan'>
                  <img src={userpic} alt=""/>
                  <div className='dan-left'>
                    <div className='left-top'>
                      <p>图文问诊</p>
                      {item.order_number ? <p></p> : <p>￥{item && (item.consultation_amount / 100).toFixed(2)}</p>}
                    </div>
                    <div className='itembox' style={{backgroundColor: "white"}}>
                      <div className='left-item'>
                        <p>患者</p>
                        <p style={{wordWrap: 'break-word'}}>{item?.patientName}</p>
                      </div>
                      <div className='left-item'>
                        <p>确诊疾病</p>
                        <p style={{wordWrap: 'break-word'}}>{item?.consultation_detail}</p>
                      </div>
                      <div className='left-item'>
                        <p>既往史</p>
                        <p style={{wordWrap: 'break-word'}}>{item?.past_history_note}</p>
                      </div>
                      <div className='left-item'>
                        <p>过敏史</p>
                        <p style={{wordWrap: 'break-word'}}>{item?.allergic_history_note}</p>
                      </div>
                      <div className='left-item'>
                        <p>家族遗传史</p>
                        <p style={{wordWrap: 'break-word'}}>{item?.family_genetic_history_note}</p>
                      </div>
                      <div className='left-item'>
                        <p>肝功能异常</p>
                        <p>{item?.abnormal_liver_function == 0 ? '无' : '异常'}</p>
                      </div>
                      <div className='left-item'>
                        <p>肾功能异常</p>
                        <p>{item?.abnormal_renal_function == 0 ? '无' : '异常'}</p>
                      </div>
                      {item?.consultation_files ? <div className='left-item'>
                        <p className='left-item-one'>电子病历</p>
                        {item.consultation_files.split(',').map(item0=>(<p  className='left-item-two'>
                          <img src={item0} alt="" onClick={() => {
                            this.setState({
                              image: {
                                url: item0,
                                visible: true
                              }
                            })
                          }}/>
                        </p>))}
                      </div> : null}
                    </div>

                  </div>
                </div>
              </>
              {list.length ? list.map((item, index) => {
                return (
                    <div className={item.Identity == "1" ? "container-left" : "container-right"} key={index}>
                      {item.Identity == "1" ? <img className='imgpic' src={userpic} alt=""/> :
                          <img className='imgpic' src={docterAvatar ? docterAvatar : icon2} alt=""/>}
                      {item.detailType == "1" ? <div className='left-text' style={{wordWrap: 'break-word'}}>
                        {item.detailContent}
                      </div> : item.detailType == "2" ? <div className='left-text'>
                        <img src={item.detailContent} onClick={() => this.imgFd(index)} alt=""
                             className={clickFd == 1 && index1 == index ? 'fd-img' : 'right-img'}/>
                      </div> : item.detailType == "3" ? <div className='container-cfd'>
                        {/* <img src={icon2} alt="" /> */}
                        <div className='dan-left'>
                          <div className='left-top'>
                            <p>处方单</p>
                          </div>
                          <div className='itembox' style={{backgroundColor: "white"}}>
                            <div className='left-item'>
                              <p>患者</p>
                              <p>
                                <span>{patientName}</span><span>{patientSex}</span><span>{patientBirthday}岁</span>
                              </p>
                            </div>
                            <div className='left-item left-item1'>
                              <span style={{marginRight: '18px', width: '40px'}}>诊断</span>
                              {details && details.length ? details.map(i => (
                                  <span>{item.detailContent == i.prescribing_id ? i.docter_diagnosis : ''}</span>
                              )) : null}
                            </div>
                            <div className='left-title'>
                              <button onClick={() => this.toinfo(item.detailContent)}>查看详情</button>
                            </div>
                          </div>
                        </div>
                      </div> : null}
                    </div>
                )
              }) : null}
              {item.consultation_stu == '-1' ? <div className='box-tips'>
                <Divider style={{
                  borderColor: '#C7C7C7'
                }}>请在30分钟内接诊，超时将自动退款</Divider>
              </div> : null}
              {(item.consultation_stu == '0' && !bottomcode) || item.consultation_stu == '1' ?
                  <div className='box-over'>
                    问诊结束
                  </div> : null}
              {item.consultation_stu == '3' ? <div className='box-over'>
                医生已退诊
              </div> : null}
            </div>

            {item.consultation_stu == '-1' ?
                <div className='box-bottom1'>
                  <div>
                    <img src={icon3} alt="" onClick={this.changevisible2}/>
                    <p style={{color: "#FF6363"}}>退诊</p>
                  </div>
                  <div>
                    <img src={icon4} alt="" onClick={this.changestatus}/>
                    <p style={{color: "#336FF6"}}>接诊</p>
                  </div>
                </div> : null}
            {bottomcode && !overcode ? <div className='box-bottom2'>
              <div className='bottom2-top'>
                <input type="text" placeholder='请回复患者' value={detailContent} onChange={(e) => this.changevalue(e)}/>
                <button onClick={this.sendout}>发送</button>
              </div>
              <div className='bottom2-bottom'>
                {/* <img src={icon5} alt="" className='bottom-img1' /> */}
                <ImagePicker
                    files={fileList}
                    length={1}
                    style={{width: "50px", display: "inline-block", verticalAlign: "middle"}}
                    onChange={this.imgChange}
                    selectable={fileList.length < 1}
                    accept='image/gif,image/jpeg,image/jpg,image/png'/>
                <p onClick={this.prescribingCf}>
                  <img src={icon6} alt="" className='bottom-img2'/> <span>开处方</span>
                </p>
                <p onClick={this.prescribingBl}>
                  <img src={icon6} alt="" className='bottom-img2'/> <span>电子病历</span>
                </p>
              </div>
            </div> : null}
            {visible4 ? <div className='add-icd' onScroll={this.onscroll} onClick={(e) => {
              e.stopPropagation()
              this.setState({
                visible5: false
              })
            }}>
              <div className='icd-top' onClick={(e) => {
                e.stopPropagation()
              }}>
                <img src={icon1} onClick={() => {
                  this.setState({
                    visible5: false,
                    visible4: false,
                    icdlists: []
                  })
                }}/>
                <span>电子病历单</span>
                <span></span>
              </div>
              <div className='register-head' onClick={(e) => {
                e.stopPropagation()
              }}>
                <i className='iconfont icon-suosou'></i>
                <input type="text" placeholder='搜索icd编码、icd名称' onChange={(e) => this.changeuserinfo(e, 4)}/>
                <span onClick={() => this.onSearch()}>搜索</span>
              </div>
              {visible5 ? <ul className='icd-details'>
                {icdList.length ? icdList.map(item => (
                    // <p className={item.state ? 'icd-details-one1' : 'icd-details-one0'}
                    //    style={{height: '26px', lineHeight: '26px'}} onClick={(e) => this.setIcd(e, item)}>
                    //   <span style={{marginRight: '20px'}}>{item.icdValue}</span>
                    //   <span>{item.icdName}</span>
                    // </p>
                    <li className='icd-details-one' onClick={(e) => {
                      e.stopPropagation()
                    }}>
                      <span>{item.icdValue} &nbsp; {item.icdName}</span><input onClick={(e) => this.setIcd(e, item)}
                                                                               type='checkbox' checked={item.state}
                                                                               name='icd'/><br/>
                    </li>
                )) :  <Nodata type='病历'/>}
              </ul> : null}
              <div className='select-all'>
                {icdlists.length ? icdlists.map(i => (
                    <p>
                      <span>{i.icdName}</span><span onClick={(e) => this.setIcd(e, i)}>删除</span>
                    </p>)) : <Nodata type='病历'/>}
              </div>
              <div className='icd-button'>
                {icdlists.length ? <img src={icon5} onClick={() => this.onSure()}/> :
                    <img src={icon7} onClick={(e) => this.onSure1(e)}/>}
              </div>
            </div> : null}
            {visible2 ? <div className='box-tipsbox'>
              <div className='tipsbox-text'>
                <h1>请选择退诊原因</h1>
                <div>
                  <p>咨询不对症</p> <input type="radio" value="咨询不对症" name='reason' onChange={(e) => this.getreason(e)}/>
                </div>
                <div>
                  <p>患者资料不全</p> <input type="radio" value="患者资料不全" name='reason' onChange={(e) => this.getreason(e)}/>
                </div>
                <div>
                  <p>患者病情复杂</p> <input type="radio" value="患者病情复杂" name='reason' onChange={(e) => this.getreason(e)}/>
                </div>
                <button onClick={() => this.setState({
                  visible2: false
                })}>取消
                </button>
                <button className='text-btn2' onClick={this.cancelchat}>确定</button>
              </div>
            </div> : null}


          </div> : null}
          <Dialog
              visible={visible}
              content='上传电子病历后问诊单才生效，是否确认结束？'
              closeOnAction
              // onClose={(v)=>this.closebox(v)}
              actions={[
                [
                  {
                    key: 'cancel',
                    text: '取消',
                    onClick: () => this.closebox(1)
                  },
                  {
                    key: 'delete',
                    text: '确定',
                    bold: true,
                    danger: true,
                    onClick: () => this.closebox(2)
                  },
                ],
              ]}
          />
          <Dialog
              visible={visible3}
              content='确认接受此问诊吗？'
              closeOnAction
              // onClose={(v)=>this.closebox(v)}
              actions={[
                [
                  {
                    key: 'cancel',
                    text: '取消',
                    onClick: () => this.closebox(3)
                  },
                  {
                    key: 'delete',
                    text: '确定',
                    bold: true,
                    danger: true,
                    onClick: () => this.closebox(4)
                  },
                ],
              ]}
          />
          <ImageViewer
              image={image.url}
              visible={image.visible}
              onClose={() => {
                this.setState({
                  image: {
                    url: '',
                    visible: false
                  }
                })
              }}
          />
        </>
    )
  }

}
