import {axiosInstance, get, post,supervise_data,getvideoSetting,postSupervise,postvalidationSetting,getvideoRecording} from '../config'

//上传医生资质
export const uploaddoctor = (data) => {
    return axiosInstance.post('/api/hosp/doctor_manage/qualifications', data)
}
//图片上传
export const uploadpic = (data) => {
    return axiosInstance.post('/api/hosp/upload/picture', data)
}
//ca认证
export const caAuthentication = (data) => {
    return axiosInstance.post('/faas/hosp/scca/authentication', data)
}
// 云证书查询	/faas/hosp/scca/testca
export const testca = (data) => {
    return axiosInstance.post('/faas/hosp/scca/testca', data)
}
//请求回调接口
export const cacallback = (data) => {
    return axiosInstance.post('/faas/hosp/scca/callback', data)
}
//查询ca通过时间
export const caPasstime = (docterId) => {
    return axiosInstance.get(`/api/hosp/scca/ca-pass-time?docterId=${docterId}`)
}
export const getToken = () => postSupervise('/wjw/third/oauth/getAccessToken', {clientId:supervise_data.clientId,appSecret:supervise_data.appSecret,});
export const addToken = (params) => post('/api/hosp/supervision-platform/add-token', params);
export const tokenSupervision = (params) => get('/api/hosp/supervision-platform/get-token', params);
//网络诊疗服务数据上传
export const uploadFurtherConsult = (params) => postSupervise('https://202.61.88.184:19200/wjw/upload/uploadFurtherConsult', params);
//更新问诊是否上传状态
export const updateConsultation = (data) => {
    return axiosInstance.post('/api/hosp/supervision-platform/update-consultation', data)
}

//3.2.1.获取视频配置  https://ep-endpoint.cecdat.com
// export const videoSetting = (params,token) => getvideoSetting('https://endpoint.pre.cecdat.com/ep/third-party/video/setting', params,token);
// export const videoSetting = (params,token) => getvideoSetting('https://ep-endpoint.cecdat.com/ep/third-party/video/setting', params,token);
export const videoSetting = (docterId) => getvideoSetting(`https://egcare-endpoint.yyj-health.com//sec/third-party/video/setting?institutionCode=ZJYYJ&userId=${docterId}`);
//3.2.1.获取视频配置
// export const validationSetting = (params,token) => postvalidationSetting('https://endpoint.pre.cecdat.com/ep-validation/api/validation', params,token);
// export const validationSetting = (params,token) => postvalidationSetting('https://ep-endpoint.cecdat.com/ep-validation/api/validation', params,token);
export const validationSetting = (docterId) => postvalidationSetting(`https://egcare-endpoint.yyj-health.com//sec/third-party/video/setting?institutionCode=ZJYYJ&userId=${docterId}`);

// 视频开始录制 192.168.10.31:8080
export const videoRecording = (params) =>
    getvideoRecording(`https://gateway.yyj-health.com/zy/trtc/createCloudRecording`,params)  //正式环境
    // getvideoRecording(`https://c-gateway.yyj-health.com/zy/trtc/createCloudRecording`,params)  //测试环境

// 视频结束录制 endVedioRecording
export const endVedioRecording = (params) =>
    getvideoRecording(`https://gateway.yyj-health.com/zy/trtc/deleteCloudRecording`,params)  //正式环境
    // getvideoRecording(`https://c-gateway.yyj-health.com/zy/trtc/deleteCloudRecording`,params)  //测试环境