import React, { Component } from 'react';
import "./style.less"
import icon1 from "../../../../static/img/yy/fanhuihei.png"
import { ImagePicker, Toast } from "antd-mobile"
import { zipImg } from '../../../../component/zipImage'
import { uploadpic } from "../../../../api/hospitalapi/upload"
import { changedoctor } from "../../../../api/hospitalapi/mine"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            fileList: []
        }
    }
    imgChange = async (files, type, index) => {
        if (type == "add") {
            let file = files[files.length - 1].file
            if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                if (file.size < 1024 * 1024) {
                    let formData = new FormData()
                    const res = await zipImg(file)
                    // console.log(res);
                    formData.append('file', res)
                    const res2 = await uploadpic(formData)
                    //  console.log(res2);
                    this.setState({
                        fileList: [...files]
                    })
                    const res3 = await changedoctor({
                        docterId: localStorage.getItem("docterId"),
                        docterAvatar: res2.data
                    })
                    if (res3.code == 1) {
                        localStorage.setItem("docterAvatar",res2.data)
                        Toast.success("修改成功", 1)
                    }
                }else{
                    Toast.show('请上传小于1M的图片', 1)
                }
            }else{
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            this.setState({
                fileList: []
            })
        }
    }
    gobank = () => {
        window.history.back()
    }
    render() {
        const { fileList, defaultfileList } = this.state
        return (
            <div className='docpicbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>更换头像</h1>
                </div>
                <div className='box-pic'>
                    <ImagePicker
                        files={fileList}
                        length={1}
                        style={{ width: "100px", height: "100px", display: "inline-block", verticalAlign: "top" }}
                        onChange={this.imgChange}
                        selectable={fileList.length < 1}
                        accept='image/gif,image/jpeg,image/jpg,image/png' />
                    <h1>上传头像</h1>
                    <p>建议您上传职业正装照</p>
                </div>
            </div>
        )
    }
}
