import React, { Component } from 'react'
import "./style.less"
import {Toast,} from "antd-mobile"
// import { TextArea } from "antd-mobile-v5"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/head.png"
import icon3 from "../../../static/img/doctor/right.png"
import { getuserinfo } from "../../../api/hospitalapi/login"
import {changedoctor} from "../../../api/hospitalapi/mine"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            doctor: {},
            docterProfile:"",
            docterExpert:"",
            docterId:localStorage.getItem("docterId"),
            docterAvatar:localStorage.getItem("docterAvatar")
        }
    }
    componentDidMount = async () => {
        //获取医生信息
        const res = await getuserinfo({
            docterPhone: localStorage.getItem("docterPhone")
        })

        if (res.data[0].length) {
            this.setState({
                doctor: res.data[0][0],
                docterProfile: res.data[0][0].docter_profile,
                docterExpert: res.data[0][0].docter_expert
            })
        }

    }
    gobank = () => {
        window.history.back()
    }
    changevalue=(code,val)=>{
       if(code==1){
           this.setState({
            docterProfile:val.target.value
           })
       }else{
        this.setState({
            docterExpert:val.target.value
           })
       }
    }
    // 修改资料
    upload=async()=>{
        const {docterProfile,docterExpert,docterId} =this.state
        const res=await changedoctor({
            docterProfile,
            docterExpert,
            docterId
        })
        if(res&&res.code==1){
            Toast.success("修改成功",1)
            this.props.history.push('/mine')
        }
    }
    render() {
        const { doctor,docterProfile,docterExpert,docterAvatar } = this.state
        return (
            <div className='userinfobox'>
                {/*<div className='box-top'>*/}
                {/*    <img src={icon1} alt="" onClick={this.gobank} /><h1>个人信息</h1>*/}
                {/*</div>*/}
                <div className='doc-jianjie'>
                    <span className='doc-jianjie-left'>
                        <span></span>
                        <span>医生简介</span>
                    </span>
                    <span className='doc-jianjie-right'>简介会展示在用户端，请医生仔细填写</span>
                </div>
                <div className='box-item'>
                    <div>头像</div>
                    <div onClick={()=>this.props.history.push("/mine/userinfo/docterpic")}>
                        {/* <img src={!doctor ? icon2 : doctor.docter_avatar ? doctor.docter_avatar : icon2} alt="" /> */}
                        <img src={docterAvatar!='null'?docterAvatar:icon2} alt="" />
                        <img src={icon3} alt="" />
                    </div>
                </div>
                <div className='box-item'>
                    <div>姓名</div>
                    <div>
                        {!doctor ? "李亚琴" : doctor.docter_name}
                    </div>
                </div>
                <div className='box-item'>
                    <div>医院</div>
                    <div>
                        {!doctor ? "成都市第三人民医院" : doctor.docter_hospital}
                    </div>
                </div>
                <div className='box-item'>
                    <div>科室</div>
                    <div>
                        {!doctor ? "呼吸内科" : doctor.docter_department}
                    </div>
                </div>
                <div className='box-item'>
                    <div>职称</div>
                    <div>
                    {!doctor ? "主治医师" : doctor.docter_title}
                        
                    </div>
                </div>
                <div className='box-content'>
                    <h1>个人简介</h1>
                    <textarea
                        // value={docterProfile}
                        // showCount
                        defaultValue={docterProfile}
                        maxLength={500}
                        onChange={(val)=>this.changevalue(1,val)}
                        style={{
                            backgroundColor: "#F8F8F8",
                            height: "90px",
                            width:'345px',
                            marginBottom: "20px",
                            "--font-size": "10px",
                            "--placeholder-color": "#D0D0D0"
                        }}
                    />
                    <h1>擅长领域</h1>
                    <textarea
                        showCount
                        value={docterExpert}
                        onChange={(val)=>this.changevalue(2,val)}
                        maxLength={500}
                        style={{
                            backgroundColor: "#F8F8F8",
                            height: "90px",
                            width:'345px',
                            marginBottom: "30px",
                            "--font-size": "10px",
                            "--placeholder-color": "#D0D0D0"
                        }}
                    />
                    <button className='content-btn' onClick={this.upload}>保存</button>
                </div>
            </div>
        )
    }
}
