import React, { Component } from 'react'
import { Popup } from "antd-mobile-v5"
import icon1 from "../../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../../static/img/yy/three.png"
import icon4 from "../../../../static/img/bhimg.png"
import icon5 from "../../../../static/img/successimg.png"
import "./style.less"
import {allMedicinal,prescribingDetail,caWrite} from '../../../../api/hospitalapi/news'
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            visible1: false,
            list: [],
            detail: {},
            ill: {},
            gqTime: '',
            docterAutograph: '',
            docterAutograph1: '',
            videodiagnostics:''
        }
    }

    componentDidMount = async () => {
        // console.log(this.props.location.params);
        if (this.props.location.params) {
            let res = await allMedicinal({prescribingId: this.props.location.params.detailContent})
            // console.log(res);
            if (res.code == 1 && res.data[0]) {
                let res1 = await prescribingDetail({prescribingId: res.data[0][0].prescribing_id})
                // console.log(res1);
                let time1 = new Date(res1.data[0][0].create_time).getTime()
                // console.log(time1);
                // console.log(new Date(res1.data[0][0].create_time));
                // console.log(new Date(1649491606000).toLocaleString());
                this.setState({
                    list: res.data[0],
                    detail: res1.data[0][0],
                    gqTime: new Date(time1 + 259200000).toLocaleString()
                })
                if (res1.data[2]) {
                    this.setState({
                        docterAutograph: res1.data[2][0].docterAutograph
                    })
                }
                if (res1.data[0][0].pharmacist_id) {
                    caWrite({docterId: res1.data[0][0].pharmacist_id}).then(res2 => {
                        if (res2 && res2.data && res2.data[0]) {
                            this.setState({
                                docterAutograph1: res2.data[0][0].docterAutograph
                            })
                        }
                    })
                }
            }
            if (this.props.location.params.videodiagnostics){
                this.setState({
                    videodiagnostics:this.props.location.params.videodiagnostics
                })
            }
        }
    }
    openbox = (item) => {
        this.setState({
            visible1: true,
            ill: item
        })
    }
     buysome = () => {
        const { detail,videodiagnostics } = this.state
        if (videodiagnostics){
            this.props.history.replace(`/videodiagnostics`)
        }else{
            this.props.history.replace(`/chatdoctor`)
        }
        this.props.history.push(`/prescribing/prescription?prescribingId=${detail.prescribing_id}`)
    }
    goback = () => {
        this.props.history.replace('/news')
        // this.props.history.push({
        //     pathname: "/chatdoctor",
        // })
        window.history.back()
    }

    render() {
        const {visible1, docterAutograph1, list, detail, ill, gqTime} = this.state
        return (
            <>
                <div className='chatinfobox'>
                    <div className='box-top'>
                        <img src={icon1} alt="" onClick={this.goback}/><h1>处方详情</h1>
                    </div>
                    <div className='box-id'>
                        <p>处方编号：{detail.prescribing_number}</p>
                        <p>处方未使用（{gqTime} 过期）</p>
                    </div>
                    <div className='box-title'>
                        云益健互联网医院<br/>
                        处方笺
                    </div>
                    <div className='box-info'>
                        <p>姓名：{detail.patient_name}</p>
                        <p>性别：{detail.patient_sex}</p>
                        <p>年龄：{detail.patient_age}岁</p>
                        <p>科室：{detail.docter_department}</p>
                        <p style={{width: '200px'}}>日期：{detail.create_time}</p>
                    </div>
                    <div className='box-explain'>
                        诊断
                    </div>
                    <div className='box-explain' style={{wordWrap: 'break-word'}}>
                        {detail.docter_diagnosis}
                    </div>
                    <div className='box-listbox'>
                        <div className='listbox-top'>
                            处方清单
                        </div>
                        {list && list.map(item => (<div className='listbox-item'>
                            <p>{item.medicinal_name} x{item.medicinal_quantity}</p>
                            <p>规格： {item.medicinal_spec}</p>
                            <p style={{wordWrap: 'break-word'}}>{item.medicinal_frequency},每次{item.medicinal_usage},{item.medicinal_day}天的量, {item.medicinal_method}</p>
                            <img src={icon2} alt="" onClick={() => this.openbox(item)}/>
                            {item.medicinal_supplementary ?
                                <p style={{wordWrap: 'break-word'}}>补充说明：{item.medicinal_supplementary}</p> : null}
                        </div>))}

                    </div>
                    <div className='box-sm'>
                        <div className='sm-top'>
                            补充说明
                        </div>
                        <div className='sm-text' style={{wordWrap: 'break-word'}}>
                            医生建议：{detail.docter_proposal}
                        </div>
                    </div>
                    {/*<div className='box-gz'>*/}
                    {/*    <div className='gz-top'>*/}
                    {/*        签字盖章*/}
                    {/*    </div>*/}
                    {/*    <div className='gz-text'>*/}
                    {/*        <p>医师：{detail.docter_name}</p>*/}
                    {/*        <p>药师：{detail.pharmacist_name}</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className='ispass-img'>
                        {docterAutograph1 && detail?.prescribing_state == 1 ? <img src={icon5}/> : null}
                        {detail?.prescribing_state == -1 ? <img src={icon4}/> : null}
                    </div>
                </div>
                <Popup
                    visible={visible1}
                    onMaskClick={() => {
                        this.setState({
                            visible1: false
                        })
                    }}
                    bodyStyle={{minHeight: '48vh'}}
                >
                    <div className='chatinfo-popup'>
                        <h1 className='title'>药品详情</h1>
                        <span onClick={() => {
                            this.setState({
                                visible1: false
                            })
                        }} style={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                            fontSize: '18px',
                            color: '#999'
                        }}>x</span>
                        <div className='popup-text'>
                            <p>通用名</p>
                            <p>{ill.medicinal_name}</p>
                        </div>
                        <div className='popup-text'>
                            <p>规格</p>
                            <p>{ill.medicinal_spec}</p>
                        </div>
                        <div className='popup-text'>
                            <p>剂型</p>
                            <p>{ill.medicinal_dosage_form}</p>
                        </div>
                        <div className='popup-text'>
                            <p>类别</p>
                            <p>{ill.medicinal_type}</p>
                        </div>
                        <div className='popup-text'>
                            <p>适应症状</p>
                            <p>{ill.medicinal_apply_symptoms}</p>
                        </div>
                    </div>

                </Popup>
                <div className='popup-btn'>
                    {detail.original_prescribing?null:<button onClick={this.buysome}>重新上传</button>}
                </div>
            </>

        )
    }
}
