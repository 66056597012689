import React, { Component } from 'react'
import "./style.less"
import {  DatePicker,Popup} from "antd-mobile-v5"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/pt.png"
import icon3 from "../../../static/img/doctor/yang.png"
import icon4 from "../../../static/img/doctor/tixian.png"
import Nodata from '../../../component/Nodata'
import {statisticsAll,offdocAllnum,selectFiltering} from '../../../api/hospitalapi/offline'
import moment from 'moment'
import getUrl from "../../../component/GetUrlParam";
import {
    allMedicinal,
    prescribingDetail,
    caWrite,
    isChineseMeds,
    chineseMethod,
    belongStore,
    selectPharmaIdStaff,
    selectPharmaIdPha,
    selectSignaturePic,
    selectPrescPhaSign
} from "../../../api/hospitalapi/news";
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            docterId: localStorage.getItem("docterId"),
            code: 1,
            dateList: '',
            browseList: '',
            page: 1,
            size: 20,
            countallNum: 0,
            countNum: 0,
            createTime: '',
            prescribingState: '',
            visible: false,
            visible1: false,
            flag: false,
            homeaccount: 0,
            list: "",
            medsNews: '',
            dutycode: '',
            supervisionType: '',
            prescribingId: '',
            list3: [],
            countall:0,
            ischinese:[],
            chinesemethod:[],//用法用量
            staff_img:'',
            pharmacist_img:'',
            pharmacist_img1:'',
            filteringArr:[],
            prescringPhaState:'',
            isPhaExamine: false, //是否需要药店药师审核
            phaStaffIdStoreImg:'', //核对签名
            phaStaffIdStore:'',//如果为null,那么就是自动，存在就是手动
            
        }
    }

    componentDidMount = async () => {
        const {size, dateList, docterId,isPhaExamine} = this.state

        let res = await offdocAllnum(docterId)
        if (res && res.data && res.data[0] && res.data[1]) {
            this.setState({
                countallNum: res.data[0][0].countallNum,
                countNum: res.data[1][0].countNum
            })
        }
        if (getUrl('homeaccount', this.props.location.search)) {
            this.setState({
                homeaccount: getUrl('homeaccount', this.props.location.search)
            })
        }
        this.getAllList()
    }
    resetBrowseList = (data, type) => {
        const {page, dateList, browseList, code} = this.state
        //获取日期
        const year = moment().year() + ''
        const month = moment().month() + 1 < 10 ? '0' + (moment().month() + 1) : '' + (moment().month() + 1)
        const browseDate = year + month
        let newData = data
        let newDateList = [...dateList]
        newData.forEach(item => {
            item.checked = false
            item.createTime0 = item.createTime.slice(0, 4) + '年' + item.createTime.slice(5, 7) + '月'
        })
        for (let i = 0; i < newData.length; i++) {
            for (let j = i + 1; j < newData.length; j++) {
                if (newData[i].createTime === newData[j].createTime) {
                    ++i
                }
            }
            newDateList.push({
                createTime: newData[i].createTime0,
                allCount: newData[i].allCount,
                successCount: newData[i].successCount
            })
        }
        let obj = {}
        // newDateList = [...dateList, ...newDateList]
        newDateList = [...newDateList]
        newDateList = newDateList.filter((x, index, self) => {
            var arrids = []
            newDateList.forEach((item, i) => {
                arrids.push(item.createTime)
            })
            return arrids.indexOf(x.createTime) === index
        })
        // setDateList(newDateList)
        // setDateList([...newDateList])
        // setBrowseList(type === 'get' ? newData : [...browseList, ...newData])
        this.setState({
            dateList: [...newDateList],
            browseList: type === 'get' ? newData : [...browseList, ...newData],
            flag: false
        })
    }
    getAllList = async () => {
        const {size, page, docterId, prescribingState, createTime,prescringPhaState} = this.state
        const params = {
            docterId,
            page: 1,
            size: size,
            prescribingState: prescribingState ? prescribingState : '',
            createTime: createTime ? createTime : '',
            prescringPhaState: prescringPhaState ? prescringPhaState : '',
        }
        let res = await statisticsAll(params)
        if (res && res.data && res.data[0] && res.data[0].length) {
            let data = res.data[0]
            this.resetBrowseList(data, 'get')
        } else {
            this.setState({
                dateList: [],
                browseList: [],
            })
        }
    }
    // 触底更新数据
    onscroll = e => {
        const {flag,} = this.state
        e.preventDefault()
        e.stopPropagation()
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10) {
            if (!flag) {
                this.pageBrowselist()
            }
        }
    }
    // 触底时换页
    pageBrowselist = () => {
        const {size, page, docterId, prescribingState, createTime,prescringPhaState} = this.state
        this.setState({
            flag: true
        }, () => {
            const params = {
                docterId,
                size: size,
                page: page + 1,
                prescribingState: prescribingState ? prescribingState : '',
                createTime: createTime ? createTime : '',
                prescringPhaState:prescringPhaState ? prescringPhaState : ''
            }
            statisticsAll(params).then(res => {
                if (res.code === 1) {
                    if (res.data && res.data[0] && res.data[0].length > 0) {
                        const data = res.data[0]
                        this.resetBrowseList(data, 'limit')
                        this.setState({
                            page: params.page
                        })
                    }
                }
            })
        })
    }
    gobank = () => {
        window.history.back()
    }
    selectOne = (v) => {
        const {createTime} = this.state
        if (v == 'all') {
            this.setState({
                page: 1,
                code: 1,
                dateList: [],
                prescribingState: '',
                prescringPhaState:'',
                createTime: ''
            }, () => {
                this.getAllList()
            })
        }
        if (v == 'success') {
            this.setState({
                page: 1,
                code: 2,
                dateList: [],
                createTime: '',
                prescribingState: 1,
                prescringPhaState: 1,
            }, () => {
                this.getAllList()
            })
        }
    }
    // 查看详情
    toinfo = async (prescribingId) => {
        // const {prescribingId} = this.state
        if (prescribingId!='null'){
            let res = await allMedicinal({prescribingId:prescribingId})
            if (res.code == 1 && res.data[0]) {
                let res1 = await prescribingDetail({prescribingId: prescribingId})
                let res04 = await isChineseMeds({consultation_id: res1.data[0][0].consultation_id})
                let res05 = await chineseMethod({consultation_id: res1.data[0][0].consultation_id})
                this.setState({
                    ischinese:res04.data[0],
                    chinesemethod:res05.data[0]
                })
                let time1 = new Date(res1.data[0][0].create_time).getTime()
                if (res1 && res1.data && res1.data[0]) {
                    this.setState({
                        list3: res.data[0],
                        detail: res1.data[0][0],
                        originalPrescribing: res1.data[0][0].original_prescribing ? res1.data[0][0].original_prescribing : '',
                        year: res1.data[0][0].year,
                        month: res1.data[0][0].month,
                        day: res1.data[0][0].day,
                        visible1: true
                    })
                    if (res1.data[2]) {
                        this.setState({
                            docterAutograph: res1.data[2][0].docterAutograph
                        })
                    }
                    if (res1.data[0][0].pharmacist_id) {
                        caWrite({docterId: res1.data[0][0].pharmacist_id}).then(res2 => {
                            if (res2 && res2.data && res2.data[0]) {
                                this.setState({
                                    docterAutograph1: res2.data[0][0].docterAutograph
                                })
                            }
                        })
                    }

                    if(res1.data[0][0].pha_pharmacist_id_store){
                        // 查询药店药师对应
                        selectPrescPhaSign({ 
                            docter_id:res1.data[0][0].pha_pharmacist_id_store
                        }).then((resPhas)=>{
                            if(resPhas && resPhas.data && resPhas.data[0] && resPhas.data[0][0].docterAutograph3){
                                this.setState({
                                    docterAutograph3:resPhas.data[0][0].docterAutograph3
                                })
                            }
                        })
                    }

                }

            }

            let res06 = await belongStore({prescribingId:res.data[0][0].prescribing_id})
            if(res06.data[0][0].pharmacy_id != null){
                const res12 = await selectFiltering({pharmacyId:res06.data[0][0].pharmacy_id})
                if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
                    this.setState({
                        filteringArr:res12.data[0]
                    })
                }
                if(this.state.filteringArr.some(item => item.label === '药店药师审核' && item.label_type == 4)){
                    this.setState({
                        isPhaExamine: true
                    })
                }

                // 查询处方单店员、药店药师签名图
                let res88 = await selectSignaturePic({prescribingId:res.data[0][0].prescribing_id})

                if(res88.data && res88.data[0] && res88.data[0][0].phaStaffIdStore){
                    this.setState({
                        phaStaffIdStoreImg:res88.data[1][0].phaStaffIdStoreImg,
                        phaStaffIdStore:res88.data[0][0].phaStaffIdStore
                    })
                }else{
                    this.setState({
                        phaStaffIdStoreImg:''
                    })
                }

                if (this.state.filteringArr.some(item => item.label === "核对/发药")) {
                    if(res88.data[0][0].phyStaffImg){
                        this.setState({
                            staff_img:res88.data[0][0].phyStaffImg
                        })
                    } 
                }else{
                    this.setState({
                        staff_img:'',
                    })
                }

                if (this.state.filteringArr.some(item => item.label === "药店药师审核" && item.label_type == 3)) {
                    if(res88.data[0][0].phyPharmacistImg){
                        this.setState({
                            pharmacist_img:res88.data[0][0].phyPharmacistImg
                        })
                    }
                }else{
                    this.setState({
                        pharmacist_img:"",
                    })
                }
                
                if (this.state.filteringArr.some(item => item.label === "调配")) {
                    if(res88.data[0][0].phyPharmacistsImg){
                        this.setState({
                            pharmacist_img1:res88.data[0][0].phyPharmacistsImg
                        })
                    }
                }else{
                    this.setState({
                        pharmacist_img1:"",
                    })
                }
            }    

        }
    }
    render() {
        const {
            code, dateList, browseList, countallNum, visible,visible1, createTime, homeaccount,
            detail, docterAutograph1, docterAutograph, list3,
            year, month, day,countNum,ischinese,chinesemethod,pharmacist_img,pharmacist_img1,
            staff_img,docterAutograph3,isPhaExamine,phaStaffIdStoreImg,
            phaStaffIdStore
        } = this.state

        const now = new Date()
        const now1 = new Date('2022-01')
        // const now2 = new Date('2200-01-01')
        return (
            <>
                {dateList ? <div className='accountbox' onScroll={(e) => this.onscroll(e)}>
                    <div className='box-top'>
                        <img src={icon1} alt="" onClick={this.gobank}/><h1>单量统计</h1>
                    </div>
                    <div className='statistics-content'>
                        <div className='statistics-content-top'>
                            <div className='statistics-top-count'>
                                <p>{countNum}</p>
                                <p>成功数量</p>
                            </div>
                            <p className='statistics-top-line'></p>
                            <div className='statistics-top-count'>
                                <p>{countallNum}</p>
                                <p>开方总数</p>
                            </div>
                        </div>
                        <p className='statistics-content-bottom'>
                            <p className='statistics-content-bottom-left'>
                                <span className={code == 1 ? 'selected' : 'unselect'}
                                      onClick={() => this.selectOne('all')}>全部</span>
                                <span className={code == 2 ? 'selected' : 'unselect'}
                                      onClick={() => this.selectOne('success')}>开方成功</span>
                            </p>
                            <p className='statistics-content-bottom-right' onClick={() => this.setState({
                                visible: true
                            })}
                            >
                                <span>{createTime ? createTime : '日期选择'}</span>
                                <i className='iconfont icon-xiala'/>
                            </p>
                        </p>
                    </div>
                    {dateList.length > 0 ?
                        <div className='statistics-all-outer'>
                            {dateList.map((item, index) => {
                                return (
                                    <div key={index} className='statistics-all-details'>
                                        <div className='statistics-all-details-date'>
                                            <p>{item.createTime}</p>
                                            <p>{code == 1 ? `(成功数量:${item.successCount},开方总数:${item.allCount})` : `(开方成功数量:${item.successCount})`}</p>
                                        </div>
                                        {browseList.map((v, i) => {
                                            return (item.createTime === v.createTime0 ?
                                                    <div key={i}>
                                                        <div className='statistics-all-details-news'>
                                                            <p className='name-count'>
                                                                <span>{v.patientName}</span>
                                                                <span
                                                                    className={v.prescribingState == 1 ? 'add' : ''}>{v.prescribingState == 1&&!homeaccount ? '+1' : ''}</span>
                                                            </p>
                                                            <p className='pass-date'>
                                                                {(v.prescribingState == 1 && v.prescringPhaState == 1) ?
                                                                    <span className='pass'>【处方审核通过】</span> :
                                                                    (v.prescribingState == 0 || (v.prescringPhaState == 0 && v.prescribingState == 1)) ?
                                                                        <span className='await'>【处方待审核】</span> :
                                                                        (v.prescribingState == -1 || v.prescringPhaState == 2) ? <span
                                                                            className='nopass'>【处方审核失败】</span> : null
                                                                }
                                                                {homeaccount ? null :
                                                                    <span>{v.createTime}</span>}
                                                            </p>
                                                            {homeaccount ? <p className='time-details'>
                                                                <span>开方时间：{v.createTimestart}</span>
                                                                <span onClick={() => this.toinfo(v.prescribingId)
                                                                }>查看详情</span>
                                                            </p> : null}
                                                            {/* <p className='time-details'>
                                                                <span>开方时间：{v.createTimestart}</span>
                                                                <span onClick={() => this.toinfo(v.prescribingId)
                                                                }>查看详情</span>
                                                            </p> */}
                                                        </div>
                                                    </div> : null
                                            )
                                        })}
                                    </div>
                                )
                            })
                            }
                        </div> : <Nodata type='单量统计'/>
                    }
                    <Popup
                        visible={visible1}
                        onMaskClick={() => {
                            this.setState({
                                visible1: false
                            })
                        }}
                        bodyStyle={{maxHeight: '80vh', borderTopRightRadius: '14px', borderTopLeftRadius: '14px'}}
                    >
                        <div className='offline-popup'>
                            <h1 className='title'>药品详情</h1>
                            <span onClick={() => {
                                this.setState({
                                    visible1: false
                                })
                            }} style={{
                                position: 'absolute',
                                right: '12px',
                                top: '12px',
                                fontSize: '16px',
                                color: '#177FFF'
                            }}>确定</span>
                        </div>
                        {detail ?
                            <div className='offline-body11'>
                                <div className='offprescription-outer11'>
                                    <h1 className='title'>中江云益健互联网医院</h1>
                                    <p className='offprescription-jian'>
                                        <h1>处方笺</h1>
                                        <img src={icon2}/>
                                    </p>
                                    <p className='offprescription-number'>处方编号：{detail.prescribing_number}</p>
                                    {/*<p className='offprescription-line'></p>*/}
                                    <p className='offprescription-one'>
                                        <p className='offprescription-one-left'>
                                            <span>科室:</span>
                                            <p>{detail.docter_department}</p>
                                        </p>
                                        <p className='offprescription-one-right'>
                                            <span>开具日期:</span>
                                            <p>{year}</p>
                                            <span>年</span>
                                            <p>{month}</p>
                                            <span>月</span>
                                            <p>{day}</p>
                                            <span>日</span>
                                        </p>
                                    </p>
                                    <p className='offprescription-one'>
                                        <p className='offprescription-one-left'>
                                            <span>姓名:</span>
                                            <p>{detail.patient_name}</p>
                                        </p>
                                        <p className='offprescription-one-right2'>
                                            <span>性别:</span>
                                            <p>{detail.patient_sex == '1' ? '男' : '女'}</p>
                                            <span>年龄:</span>
                                            <p>{detail.patient_age} 岁</p>
                                        </p>
                                    </p>
                                    <p className='offprescription-one2'>
                                        <span>初步诊断：</span>
                                        <p>{detail.docter_diagnosis}</p>
                                    </p>
                                    {/*<img className='zj-zhang1' src={icon13}/>*/}
                                    {/*<p className='offprescription-line'></p>*/}
                                    <p className='offprescription-RP'>RP</p>
                                    {ischinese.length ? (
                                        <>
                                            <p className='all-meds'>
                                                <p className='chinese-list'>
                                                    {list3 ? list3.map((item, index) => (
                                                        <p className='offprescription-chameds-news'>
                                                            <p className='offprescription-chameds-left'>
                                                                <span>{item.med_com_name ? item.med_com_name : item.medicinal_name}</span>
                                                                <span>{item.medicinal_quantity}g</span>
                                                            </p>
                                                        </p>
                                                    )) : null}
                                                </p>
                                                <p className="cha-methods">
                                                    <p className="mtthod-tit">用法：</p>
                                                    <p className="mtthod-con">
                                                        {chinesemethod.filter(i => i.consultation_id == detail.consultation_id).map(i=>(
                                                            <span>
                                                                共{i.co_demand}剂，{i.meiday}{i.daily}剂，每剂分{i.each_dose}次使用，
                                                                每次{i.at_time}{i.unit}，{i.med_method}，
                                                                {i.med_time == 1
                                                                ? "饭前用药"
                                                                : i.med_time == 2
                                                                ? "饭后用药"
                                                                : i.med_time == 3
                                                                ? "睡前用药"
                                                                : i.med_time == 4
                                                                ? "晨起用药"
                                                                : ""}
                                                            </span>
                                                        ))} 
                                                    </p>
                                                </p>
                                                {list3.length<39 && <p className="kongbailine">(以下空白)</p>} 
                                            </p>
                                            
                                        </>
                                    ) : (
                                        <>
                                            <p className='all-meds'>
                                                {list3 ? list3.map((item, index) => (<p className='offprescription-meds'>
                                                    <p className='offprescription-meds-news'>
                                                        <p className='offprescription-meds-left'>
                                                            <span>{index + 1}、{item.med_com_name ? item.med_com_name : item.medicinal_name}</span>
                                                            <span>{item.medicinal_spec} x{item.medicinal_quantity}{item.packing_unit && item.packing_unit != "null" ? item.packing_unit : ''}</span>
                                                        </p>
                                                    </p>
                                                    <p className='offprescription-meds-details'>用法用量：{item.medicinal_frequency},每次{item.medicinal_usage},{item.medicinal_day}天的量, {item.medicinal_method}</p>
                                                    {item.medicinal_supplementary ?
                                                        <p className='offprescription-meds-details'>补充说明：{item.medicinal_supplementary}</p> : null}
                                                    {index + 1 == list3.length && index < 4 ? <p className='xu-line'></p> : null}
                                                    {index + 1 == list3.length && index < 4 ?
                                                        <p className='kongbai'>(以下空白)</p> : null}
                                                </p>)) : null}
                                            </p>
                                        </>
                                    )}
                                    
                                    {/*<p className='offprescription-line'></p>*/}
                                    <p className='offprescription-doctor'>
                                        <p className='offprescription-doctor10'>
                                            <span>医师:</span>
                                            <p>
                                                <img src={docterAutograph}/>
                                                <span className='xia-line00'></span>
                                            </p>
                                        </p>
                                        <p className='offprescription-doctor100'>
                                            <span>医院药师:</span>
                                            <p>
                                                {detail.prescribing_state == 1 ?
                                                    <img src={docterAutograph1}/> :
                                                    <span className='no-line'></span>}
                                                <span className='xia-line00'></span>
                                            </p>
                                        </p>
                                        <p className="offprescription-doctor12">
                                            <span>药店药师审核:</span>
                                            <p>    
                                                {docterAutograph3 && detail.prescring_pha_state == 1 ? 
                                                    <img className="ys-img" src={docterAutograph3} />
                                                    :  detail.prescring_pha_state == 1 && pharmacist_img ? 
                                                        <img className="ys-img" src={pharmacist_img} /> : 
                                                        <span className="no-line"></span> }

                                                {/* {detail.prescribing_state == 1 ?
                                                    <img src={pharmacist_img}/> :
                                                    <span className='no-line'></span>} */}
                                                <span className='xia-line00'></span>
                                            </p>
                                        </p>
                                    </p>
                                    <p className='offprescription-doctor2'>
                                        <p className='offprescription-doctor3'>
                                            <span className='customstyle customstyle'>调配:</span>
                                            <p>
                                            {docterAutograph3 && detail.prescring_pha_state == 1 ? 
                                                <img className="ys-img" src={docterAutograph3} />
                                                :  detail.prescring_pha_state == 1 && pharmacist_img1 ? 
                                                    <img className="ys-img" src={pharmacist_img1} /> : 
                                                    <span className="no-line"></span> }

                                                {/* {detail.prescribing_state == 1 ?
                                                    <img src={pharmacist_img1}/> :
                                                    <span className='no-line'></span>} */}
                                                <span className='xia-line00'></span>
                                            </p>
                                        </p>
                                        <p className='offprescription-doctor333'>
                                            <span className='customstyle customstyle'>核对/发药:</span>
                                            <p>
                                                {/* {detail.prescring_pha_state == 1 ? <img src={staff_img}/> : <span className='no-line'></span>} */}
                                                {detail.prescring_pha_state == 1 && phaStaffIdStore && phaStaffIdStoreImg ? <img src={phaStaffIdStoreImg}/> : 
                                                    detail.prescring_pha_state == 1 && staff_img ? <img src={staff_img}/> : <span className='no-line'></span>}
                                                <span className='xia-line00'></span>
                                            </p>
                                        </p>
                                        <p className='offprescription-doctor4'>
                                            <span>金额:</span>
                                            <p></p>
                                        </p>
                                    </p>


                                    <p className='offprescription-tips'>
                                        <span>备注：</span>
                                        <p>{detail.docter_proposal}</p>
                                    </p>
                                    <p className='offprescription-tips1'>1、医师处方仅开具当日有效（医生注明除外）。</p>
                                    <p className='offprescription-tips1'>2、处方超过7日用量为病情需要。</p>

                                </div>
                            </div>
                            : null}
                    </Popup>
                    <DatePicker
                        // title='时间选择'
                        visible={visible}
                        onClose={() => {
                            this.setState({
                                visible: false,
                            })
                        }}
                        defaultValue={now}
                        max={now}
                        min={now1}
                        precision='month'
                        onConfirm={value => {
                            const num2 = new Date(now).getFullYear() + '-' + ((new Date(now).getMonth() + 1) > 9 ? (new Date(now).getMonth() + 1) : '0' + (new Date(now).getMonth() + 1))
                            // if (time20) {
                            const num = new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                            this.setState({
                                page: 1,
                                dateList: [],
                                createTime: new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                            }, () => {
                                this.getAllList()
                            })
                        }}
                    />
                </div> : null}
            </>

        )
    }
}
