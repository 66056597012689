import React from "react";

import "./style.less";

import img1 from "../../static/img/nodata/1.png";
import xiaoxi from "../../static/img/nodata/xiaoxi.png";
import dingdan from "../../static/img/nodata/dingdan.png";
import xinxi from "../../static/img/nodata/xinxi.png";
import huanzhe from "../../static/img/nodata/huanzhe.png";
import ill from "../../static/img/chat/ill.png";

const Nodata = ({ type }) => (
  <>
    {(() => {
      switch (type) {
        case "收藏":
          return (
            <div className="no-data">
              <img src={img1} alt="" />
              <p>暂时没有收藏</p>
            </div>
          );
        case "网络":
          return <img src={img1} alt="" />;
        case "购物车":
          return (
            <div className="no-data">
              <img src={img1} alt="" />
              <p>您的购物车太干净了~</p>
              <div className="flex-row">去逛逛</div>
            </div>
          );
        case "接诊":
          return (
            <div className="no-data" style={{ paddingTop: 100 }}>
              <img src={dingdan} alt="" />
              <p>亲，还没接诊记录呢~</p>
            </div>
          );
        case "处方":
          return (
            <div className="no-data" style={{ paddingTop: 100 }}>
              <img src={dingdan} alt="" />
              <p>亲，还没处方记录呢~</p>
            </div>
          );
        case "病历":
          return (
            <div className="no-data">
              <img src={ill} alt="" style={{ marginTop: "-50px" }} />
              <p>搜索添加患者病情</p>
            </div>
          );
        case "视频开方":
          return (
            <div className="no-data" style={{ paddingTop: 100 }}>
              <img src={dingdan} alt="" />
              <p>亲，还没相关订单呢~</p>
            </div>
          );
        case "消息":
          return (
            <div className="no-data" style={{ marginTop: "100px" }}>
              <img src={xiaoxi} alt="" />
              <p>亲，还没消息呢~</p>
            </div>
          );
        case "药品":
          return (
            <div className="no-data" style={{ marginTop: "-80px" }}>
              <img src={dingdan} alt="" />
              <p>亲，还没相关药品呢~</p>
            </div>
          );
        case "信息":
          return (
            <div className="no-data">
              <img src={xinxi} alt="" />
              <p>抱歉，暂无相关信息~</p>
            </div>
          );
        case "患者":
          return (
            <div className="no-data" style={{ marginTop: "50px" }}>
              <img src={huanzhe} alt="" />
              <p>暂无患者问诊信息</p>
              <p style={{ marginTop: "-5px" }}>请刷新查看</p>
            </div>
          );
        case "驳回处方":
          return (
            <div className="no-data" style={{ marginTop: "50px" }}>
              <img src={huanzhe} alt="" />
              <p>暂无驳回处方信息</p>
              <p style={{ marginTop: "-5px" }}>请刷新查看</p>
            </div>
          );
        case "未开处方":
          return (
            <div className="no-data" style={{ marginTop: "50px" }}>
              <img src={huanzhe} alt="" />
              <p>暂无未开处方信息</p>
              <p style={{ marginTop: "-5px" }}>请刷新查看</p>
            </div>
          );
        case "单量统计":
          return (
            <div className="no-data" style={{ marginTop: "-100px" }}>
              <img src={dingdan} alt="" />
              <p>亲，还没相关统计信息呢~</p>
            </div>
          );
        default:
          return null;
      }
    })()}
  </>
);

export default Nodata;
