import React, { Component } from 'react';
import "./style.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
export default class index extends Component {
    gobank=()=>{
        window.history.back()
    }
    render() {
        return (
            <div className='historycfbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>历史处方详情</h1>
                </div>
                <div className='box-content1'>
                    <div className='content1-one'>
                        <p>处方编号：20220108121325</p>
                        <p>2022-01-08</p>
                    </div>
                    <div>
                        <div>患者姓名：</div>
                        <div>
                            ddddd
                        </div>
                    </div>
                    <div>
                        <div>年龄：</div>
                        <div>
                           <span style={{marginRight:"20px"}}>15</span> 岁
                        </div>
                    </div>
                    <div>
                        <div>性别：</div>
                        <div>
                           男
                        </div>
                    </div>
                </div>
                <div className='box-content2'>
                    <p className='content2-p1'>初步诊断</p>
                    <p className='content2-p2'>高血压</p>
                    <p className='content2-p1'>处理意见</p>
                    <p className='content2-p1'>监测血压，xxxxxxx</p>
                </div>
                <div className='box-content3'>
                    <h1>处方药清单</h1>
                    <div>
                        <p>【品牌】阿司匹林胶囊</p>
                        <p>x1</p>
                    </div>
                    <h1>规格：80mg*7粒/盒</h1>
                    <h1>口服：成人一次xxxxx每天xxxx</h1>
                </div>
                <div className='box-btn'>
                    <button>使用该处方</button>
                </div>
            </div>
        )
    }
}
