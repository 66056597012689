import React, { Component } from 'react'
import { Modal,  } from "antd-mobile-v5"
import { Toast } from "antd-mobile"
import "./style.less"
import icon1 from "../../static/img/fanhuihei.png"

export default class index extends Component {
    constructor() {
        super()
        this.state = {

        }
    }
    goBack = () => {
        window.history.back()
    }

    render() {
        return (
            <div className='setbox' style={{backgroundColor:'#fff'}}>
                <div className='box-top box-top1'>
                    <img src={icon1} alt="" onClick={() => this.goBack()} />   医生端隐私权政策
                </div>
                {/*<h2 className='risk-title'>平台隐私政策</h2>*/}
                <p className='risk-body'>
                    <p>【引言】</p>
                    <p>欢迎您使用我们的产品和服务！我们深知个人信息对您的重要性，您的信赖对我们非常重要，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于保护您的个人信息安全可控。基于此，医生端服务提供者（或简称“我们”）制定本《医生端隐私政策》（下称“本政策”/“本隐私政策”），帮助您充分了解在您使用我们产品和服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。</p>
                    <p>在您开始使用我们的产品和服务前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以粗体/粗体下划线标识的条款，确保您充分理解和同意之后再开始使用。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。如对本政策内容有任何疑问、意见或建议，您可通过本政策文末的联系方式与我们联系。</p>
                    <p>您同意隐私政策表示您已了解应用提供的功能，以及功能运行所需的必要个人信息，并给予相应的收集使用授权，但并不代表您已单独同意开启附加功能、处理非必要个人信息，相关附加功能的开启、处理非必要个人信息以及处理敏感个人信息，我们会根据您的实际使用情况单独征求您的同意。</p>
                    <p>本隐私政策将帮助您了解以下内容：</p>
                    <p>一、适用范围</p>
                    <p>二、信息收集及使用</p>
                    <p>三、对外提供信息</p>
                    <p>四、您的权利</p>
                    <p>五、信息的存储</p>
                    <p>六、政策的更新</p>
                    <p>七、联系我们</p>
                    <p>附录：相关定义</p>
                    <p>一、适用范围</p>
                    <p>本政策适用于医生端服务提供者以网站、移动客户端、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。我们及关联公司就其向您提供的产品或服务单独设有隐私政策的，则相应产品或服务适用相应隐私政策。</p>
                    <p>除本政策说明的相关信息收集使用活动外，本政策不适用于平台商家及其他第三方向您提供的其他服务，其他服务适用其向您另行说明的个人信息收集使用规则。</p>
                    <p>二、信息收集及使用</p>
                    <p>在您使用我们的产品/服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：</p>
                    <p>1、为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务；</p>
                    <p>2、为实现向您提供我们产品及/或服务的附加功能，您可选择单独同意或不同意我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，不会影响您使用我们的基本功能。</p>
                    <p>我们在此特别提醒您：我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，故基本/附加功能及对应收集使用的个人信息类型、范围会有所区别，请以具体的产品/服务功能为准。</p>
                    <p>我们会为您提供的各项具体功能场景包括：</p>
                    <p>（一）帮助您成为我们的会员及账户管理</p>
                    <p>1、为向您提供账户注册、登录功能及服务，在您通过以下方式注册或登录医生端时，您需要提供如下账户注册信息：</p>
                    <p>手机登录：当您选择使用手机号码登录方式登录时，您需要输入手机号码及对应验证码，经验证通过后方可登录。我们会收集您所填写的手机号码，用于账户管理及登录验证。</p>
                    <p>2、如果您已拥有医生端账户，我们可能会在医生端中显示您的上述个人信息，以及您在医生端中的相关设置，包括通过医生端账户展示您的头像、个人资料等。</p>
                    <p>3、您需要提供您的真实姓名、第一执业点医院名称及科室、职称等个人信息，您的前述基础个人信息会将在医生端公开展示并在互联网诊疗或医学咨询过程中展现，以便于患者进行检索、对症咨询。您可以补充完善您的真实头像、性别、擅长疾病及个人简介，用于您个人主页的展示，以便获取精准患者。</p>
                    <p>4、根据《互联网诊疗管理办法(试行)》、《互联网医院管理办法》及监管部门要求，您可能需要根据页面提示向我们提供相应的身份信息(身份证正反面、医师执业证、医师资格证、专业技术资格证)、生物识别特征(静态或动态的面部特征)，以便于我们进行实名(实人)认证及资质审核，并在认证/审核通过后将您备案到相关互联网医院主体（注册地址：<span className='blod'>四川省德阳市中江县南华镇狮子山大道106号附3号</span>）。如果您不提供这些信息，可能会影响您对医生端部分核心业务功能的正常使用，如在问诊开方、复诊开方等。</p>
                    <p>（二）向您展示商品或服务信息</p>
                    <p>1、为您提供开展互联网诊疗服务或医学咨询服务，我们可能会申请开启您的相机/摄像头、麦克风(语音)、通讯录以及存储的相关权限，以便于您开展互联网诊疗服务或医学咨询服务，例如在图文、语音、视频/直播问诊咨询中发送语音、图片消息，接听来自患者的视频会话。如您拒绝授权上述权限将导致无法使用对应功能，但不影响您进行基本的浏览。</p>
                    <p>2、为保护您的个人信息安全，防止您的联系方式泄露，我们会使用双向电话呼叫方式连通医患双方进行咨询、问诊，也请您尽可能避免在问诊、咨询中发送个人联系方式等敏感信息。</p>
                    <p>3、请您知晓并同意，您的真实姓名、第一执业点医院名称及科室、职称，以及您补充的个人主页上的信息(如您的真实头像、性别、擅长疾病及个人简介)会在诊疗或咨询服务过程中展现。同时，出于患教以及帮助其他患者的目的，您与患者的交流信息将有可能作为优质内容在对患者进行匿名化和/或去标识化处理后公开展示，您的真实姓名、真实头像(如有)、职称、主要执业点医院以及科室也将一并展示。</p>
                    <p>4、如果您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方式进行退订或关闭。此外，我们也会为了不断改进和优化上述的功能来使用您的上述信息。</p>
                    <p>5、在您使用我们服务过程中，为保障您的账号安全、识别账号异常状态、了解产品适配性、向您提供更契合您需求的页面浏览和搜索结果，我们可能会自动收集您的使用情况并存储为网络日志信息，包括：</p>
                    <p>1）设备信息：我们会根据您在软件安装及/或使用中的具体操作，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设备设置、MAC地址及IMEI、IDFA、OAID等设备标识符、网卡地址、移动应用列表等软硬件及设备环境信息、设备WLAN接入点、蓝牙和基站等传感器信息）。</p>
                    <p>2）服务日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况（包括但不限于您浏览的医疗服务内容及进程、患者评价、医学科普类信息资源等），作为服务日志保存，包括浏览、点击查看、搜索查询、收藏、添加至购物车、交易、售后、关注分享信息、发布信息，以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间。</p>
                    <p>3）读取设备通话状态和识别码，识别手机设备ID，保证运营商网络免流服务，用于完成信息展示、账号登录、安全保障等主要功能。请您放心该权限无法监听、获取您的任何通话内容与信息。</p>
                    <p>6、我们通过以下措施努力保障您的隐私体验：</p>
                    <p>1）如您不想接受我们给您发送的短信，您可通过短信提示回复退订或我们提供的其他方式进行退订或关闭；</p>
                    <p>（三）为您提供的服务</p>
                    <p>1、为了保障您在线上为患者提供医疗类服务，您可以享受平台提供的技术支持服务，比如如下平台功能（具体的服务内容及服务主体以产品页面的展示为准）：问诊服务功能（图文问诊、视频问诊）、图文问诊功能、视频/直播问诊功能、复诊开方（若有）功能、健康管理功能等。您可通过平台提供的上述功能为患者开展相应的线上医疗服务。在您接受平台服务的过程中，可能需要您提供姓名、身份证件号码、医师执业证、医师资格证、技术职称证书等信息，并可能需要将上述信息提供给到您实际提供服务的患者。若您在电话问诊（含语音问诊）/视频问诊中，我们将对您的电话或视频/直播问诊过程的视听信息予以录制，并可能需要将录音/视频信息提供给到您实际提供服务的患者。</p>
                    <p>（四）客服及争议处理</p>
                    <p>当您与我们联系或提出争议纠纷处理申请时，为了保障您的账户及系统安全，我们需要您提供必要的个人信息以核验您的会员身份。</p>
                    <p>为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的沟通、通信/通话记录及相关内容（包括账户信息、医患沟通信息、病历资料、您为了证明相关事实提供的其他信息，或您留下的联系方式信息）。如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账户信息和订单信息。</p>
                    <p>为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。</p>
                    <p>（五）为您提供信息公开发布功能</p>
                    <p>1、您在使用我们的信息公开发布功能时，您的真实姓名、主要执业点医院名称及科室、职称，以及您补充的个人主页上的信息(如您的真实头像、性别、擅长疾病及个人简介)会在您发布信息的内容分发页面展示，包括页面。请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息；如您上传包含个人信息的图片时，请您更加谨慎地考虑是否在使用医生端的服务时共享甚至公开分享相关信息。若您公开发布的信息中涉及儿童或他人个人信息的，您需在发布前征得对应儿童监护人或他人的同意。</p>
                    <p>2、请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息。如您在评价时选择上传包含个人信息的图片，请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。您公开发布的信息中涉及儿童个人信息的，需在发布前取得所涉及儿童的监护人授权同意。前述情形下监护人有权通过本政策第九条的途径联系我们，要求更正或删除涉及儿童个人信息的内容。</p>
                    <p>（六）为您提供安全保障</p>
                    <p>为履行保障电子商务交易安全的法定义务，提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入风险，更准确地识别违反法律法规或【医技护人员】相关协议、规则的情况，我们在应用程序中嵌入我们开发的应用安全SDK收集您的设备信息、服务日志信息，并可能使用或整合您的会员信息、交易信息、设备信息、服务日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息（其中，我们可能会在应用后台运行期间收集MAC地址、IMEI及其他设备标识符进行风险校验），来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</p>
                    <p>（七）为您提供的其他附加服务</p>
                    <p>1、基于系统权限的附加服务</p>
                    <p>为向您提供更便捷、更优质的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加服务中可能会通过开启系统权限以收集和使用您的个人信息。如果您不同意开启相关权限，不会影响您使用医生端的基本服务，但您可能无法获得这些附加服务给您带来的用户体验。</p>
                    <p>您可自行决定这些权限随时的开启或关闭。</p>
                    <p>请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法继续为您提供该权限所对应的服务。您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</p>
                    <p>您可点击此处查看应用权限申请与使用情况说明。</p>
                    <p>2、Cookie和同类技术的服务</p>
                    <p>Cookie和同类设备信息标识技术是互联网中普遍使用的技术。当您使用我们的服务时，我们可能会使用相关技术向您的设备发送一个或多个 Cookie 或匿名标识符（以下简称“Cookie”），以收集、标识和存储您访问、使用本产品时的信息。我们承诺，不会将 Cookie 用于本隐私政策所述目的之外的任何其他用途。我们使用 Cookie 主要为了保障产品与服务的安全、高效运转，可以使我们确认您账户与交易的安全状态，排查崩溃、延迟的相关异常情况，帮助您省去重复您填写表单、输入搜索内容的步骤和流程。</p>
                    <p>大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以进行相应的数据清除操作，或可修改对Cookie的接受程度或拒绝我们的Cookie。您可能因为这些修改，无法使用依赖于Cookie的服务或相应功能。</p>
                    <p>三、对外提供信息</p>
                    <p>（一）共享</p>
                    <p>我们不会与公司以外的公司、组织和个人共享您的个人信息，但以下情况除外：</p>
                    <p>1、履行法定义务所必需的共享：我们可能会根据法律法规规定、诉讼、争议解决的必要，或按行政、司法机关依法提出的要求，以及其他法定义务履行的必需，共享您的个人信息；例如向卫健委提供您诊疗的相关信息。</p>
                    <p>2、取得您同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
                    <p>3、为订立、履行您作为一方当事人的合同所必需的情况下的共享：【您通过医生端使用的服务，我们会根据您的选择，将您的服务有关的必要信息共享给相关商品或服务的提供者，以实现您的服务的相关需求。】</p>
                    <p>4、与关联公司间共享：为便于我们与关联公司共同向您提供部分服务，【基于医生端账户向您提供产品和服务，识别会员账户异常，保护医生端服务提供者关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司和/或其指定的服务提供商共享。】我们只会共享必要的个人信息，且受本政策中所声明目的的约束，如果我们共享您的敏感个人信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。</p>
                    <p>请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息。请您更加谨慎地考虑并做出决定。</p>
                    <p>（二）委托处理</p>
                    <p>我们可能委托授权合作伙伴处理您的个人信息，以便授权合作伙伴代表我们为您提供某些服务或履行职能。我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的委托其处理您的信息，授权合作伙伴只能接触到其履行职责所需信息，且我们将会通过协议要求其不得将此信息用于其他任何超出委托范围的目的。如果授权合作伙伴将您的信息用于我们未委托的用途，其将单独征得您的同意。</p>
                    <p>目前，我们委托的授权合作伙伴包括以下类型：</p>
                    <p>1、广告、分析服务类的授权合作伙伴。我们会在采用行业通用的安全技术前提下，委托这些合作伙伴处理与广告投放、覆盖面和有效性相关的信息。我们不会将您的个人身份信息委托合作伙伴处理。</p>
                    <p>2、供应商、服务提供商和其他合作伙伴。我们将信息委托给支持我们业务的供应商、服务提供商和其他合作伙伴处理，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、提供客户服务、支付便利或进行学术研究和调查。</p>
                    <p>为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。授权合作伙伴单独取得您同意的除外。</p>
                    <p>您可以点击此处查看我们接入的SDK详情。</p>
                    <p>（三）转让</p>
                    <p>如果我们因合并、分立、解散、被宣告破产的原因需要转移个人信息的，我们会向您告知接收方的名称或者姓名和联系方式。接收方将继续履行本政策及其他法定义务。接收方变更原先的处理目的、处理方式的，会重新取得您的同意。</p>
                    <p>（四）公开披露</p>
                    <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
                    <p>1、基于您的主动选择及其他单独同意的情况下，我们可能会公开披露您的个人信息；</p>
                    <p>2、如果我们确定您出现违反法律法规或严重违反平台相关协议及规则的情况，或为保护用户或公众的人身财产安全免遭侵害，我们可能披露关于您的个人信息，包括相关违规行为以及平台已对您采取的措施。</p>
                    <p>四、您的权利</p>
                    <p>您可以通过以下方式查阅和管理您的信息，我们会在符合法律法规要求的情形下响应您的请求：</p>
                    <p>（一）查阅、复制、更正和补充</p>
                    <p>您有权通过以下方式查阅、更正和补充您的信息：</p>
                    <p>1、登录，进入“我的”页面，选择“个人信息”后，可以查询、修改头像、专业擅长、学术职称类型、执业经历、医生寄语等个人账户相关信息；</p>
                    <p>2、登录，选择“问诊服务设置”后，可以查询、开关、设置您的服务项目。</p>
                    <p>3、登录，选择“我的收入”后，可以查询您的服务收入明细。</p>
                    <p>4、登录，选择“接诊记录”后，可以查询您的患者、开方及购药情况。</p>
                    <p>5、登录，进入“我的”页面，并选择“联系客服”，协助您查询、复制、更正或补充您的信息。</p>
                    <p>（二）删除</p>
                    <p>您可以【通过“（一）查阅、更正和补充您的信息”列明的路径】删除您的部分信息，或申请注销账户删除您的全部信息。</p>
                    <p>在以下情形中，您可以通过与【客服联系】向我们提出删除个人信息的请求：</p>
                    <p>1、如果我们处理个人信息的行为违反法律法规；</p>
                    <p>2、如果我们收集、使用您的个人信息，却未征得您的明确同意；</p>
                    <p>3、如果我们处理个人信息的行为严重违反了与您的约定；</p>
                    <p>4、如果我们的处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
                    <p>5、如果我们停止提供产品或者服务，或者保存期限已届满。</p>
                    <p>若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。</p>
                    <p>当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。</p>
                    <p>（三）改变授权范围</p>
                    <p>您可以在“我的-设置-隐私-系统权限”中管理使用的系统权限授权。</p>
                    <p>对于您无法直接通过上述方式设置的授权，您可以通过【客服联系】进行修改，但请注意，对于部分类型的个人信息，例如实现基本功能所必须的信息或者我们履行法律法规规定的义务所必需的信息，我们可能无法响应您改变授权范围的请求。当您撤回授权后，我们将不再处理相应的个人信息。但您撤回授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。</p>
                    <p>（四）注销账户</p>
                    <p>您可以通过以下方式申请注销您的账户：</p>
                    <p>1、在“我的-设置-账户信息与安全-申请注销账号”提交账户注销申请；</p>
                    <p>2、通过客服联系，申请注销您的账户。</p>
                    <p>3、由于医疗服务的特殊性，您在提交账户注销申请后，我们将查询您后台是否有未完成的诊疗服务订单（患者安全性考量）、是否存在医患纠纷（纠纷处置考量）等情形，来判定是否延后您的注销申请。</p>
                    <p>在您主动注销账户之后，我们将停止为您提供产品或服务，并根据适用法律的要求删除或匿名化处理您的个人信息。</p>
                    <p>（五）约束信息系统自动决策</p>
                    <p>在某些业务功能中，我们可能仅依据算法在内的非人工自动决策机制做出决定。如果这些决定影响您的合法权益，您可以通过客服联系我们。</p>
                    <p>（六）响应您的上述请求</p>
                    <p>对于您或可能的您的监护人、近亲属及其他有权主体向我们提出上述请求，以及中华人民共和国法律与其他适用法律规定的您的相关个人信息权利，您可以通过【客服联系我们】或直接向我们的个人信息保护专职部门发起投诉。我们将在15天内做出答复。</p>
                    <p>为保障您的账户安全和您的个人信息安全，您向我们提出上述请求时，我们可能会先验证您的身份（如增加账户核验、要求您提供书面请求或其他合理方式），然后再处理您的请求。</p>
                    <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无合理理由重复申请的信息，或者需要过多技术手段（如需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。</p>
                    <p>五、信息的存储</p>
                    <p>（一）存储期限</p>
                    <p>我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求，例如履行《中华人民共和国电子商务法》中商品和服务信息、交易信息保存时间自交易完成之日起不少于三年的义务，或依据《中华人民共和国民法典》中向人民法院请求保护民事权利的诉讼时效期间为三年的要求履行义务。</p>
                    <p>我们判断个人信息的存储期限主要依据以下标准：</p>
                    <p>1、完成与您相关的交易目的、维护相应交易及业务记录，以应对您可能的查询或投诉；</p>
                    <p>2、保证我们为您提供服务的安全和质量；</p>
                    <p>3、您是否同意更长的留存期间；</p>
                    <p>4、是否存在关于保留期限的其他特别约定或法律法规规定；例如病历管理规范的相关规定。</p>
                    <p>在超出保留期间后，我们会根据适用法律的要求删除或匿名化处理您的个人信息。</p>
                    <p>（二）存储位置</p>
                    <p>我们在中华人民共和国境内运营中收集和产生的个人信息，将存储在中国境内。以下情形下，我们会在履行法律规定的义务后，向可能的境外实体提供您的个人信息：</p>
                    <p>1、适用的法律有明确规定；</p>
                    <p>2、获得您的单独同意；</p>
                    <p>3、您作为一方当事人的跨境电子商务交易及其他类型合同订立和履行所必需的。</p>
                    <p>针对以上情形，我们会按照本政策依法保护您的个人信息安全。</p>
                    <p>（三）存储安全</p>
                    <p>1、数据保护技术措施</p>
                    <p>我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，【我们会使用加密技术提高个人信息的安全性，在您的浏览器与服务器之间交换数据时受 SSL协议加密保护，我们提供HTTPS协议安全浏览方式；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息。</p>
                    <p>2、数据保护管理组织措施</p>
                    <p>我们建立了行业内先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理及产品技术的维度提升个人信息的安全性。我们已经设置了个人信息保护专职部门。我们通过培训课程和考试，不断加强员工对于保护个人信息重要性的认识。</p>
                    <p>3、个人信息安全事件的响应</p>
                    <p>如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们会及时启动应急预案，采取合理必要的措施，以尽可能降低对您个人的影响。如发生个人信息安全事件，我们还将按照法律法规的要求向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将以短信、电话、推送通知及其他合理渠道告知您，难以逐一告知的，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>
                    <p>4、账户安全风险的防范</p>
                    <p>在使用时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。此外，您也可以通过我们的服务与第三方建立沟通、交流信息或分析内容。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供，不轻信通过其他交易工具进行交易，以免信息被窃取甚至遭遇电信网络诈骗。</p>
                    <p>如您担心自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络【我们的客服】以便我们根据您的申请采取相应措施。</p>
                    <p>六、政策的更新</p>
                    <p>为给您带来更好的产品和服务体验，我们持续努力改进我们的产品、服务及技术。在新的服务及业务流程变更时，我们可能会更新我们的隐私政策以告知您具体的变化。但未经您明确同意，我们不会限制您按照本政策所享有的权利。我们会在专门页面上发布对本政策所做的任何变更。</p>
                    <p>对于重大变更，我们还会提供更为显著的通知（包括我们会通过公示的方式进行通知或向您提供弹窗提示）。</p>
                    <p>本政策所指的重大变更包括但不限于：</p>
                    <p>1、产品的业务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式；</p>
                    <p>2、业务调整、交易并购引起的隐私政策的主体变更，新主体接收方变更原先的处理目的、处理方式；</p>
                    <p>3、个人信息共享或公开披露的主要对象发生变化；</p>
                    <p>4、用户个人信息权利及其行使方式发生重大变化；</p>
                    <p>5、处理个人信息保护负责人的联络方式及投诉渠道发生变化时；</p>
                    <p>6、个人信息保护影响评估报告表明产品存在对个人权益有重大影响时。</p>
                    <p>我们还会将本政策的旧版本在专门页面存档，供您查阅。</p>
                    <p>七、联系我们</p>
                    <p>您可以通过以下方式与我们联系，我们将在15天内回复您的请求：</p>
                    <p>1、如您对本政策内容有任何疑问、意见或建议，您可通过客服与我们联系；</p>
                    <p>2、如您发现个人信息可能被泄露，您可以投诉举报；</p>
                    <p>3、您可以通过zjyyjhlwyy@163.com与我们联系，办公地址：四川省德阳市中江县南华镇狮子山大道106号附3号。需注意的是，我们可能不会回复与本政策或与个人信息权利无关的问题。</p>
                    <p>如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</p>
                </p>
            </div>
        )
    }
}