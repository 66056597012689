import React, { Component } from 'react'
import "./style.less"
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/login/login-bgc.png"
import icon3 from "../../static/img/login/login-bgc1.png"
import { Toast } from "antd-mobile-v5"
import TipAlert from "./TipAlert";
import '../../static/fonts/font_3834206_vpijbgwacs9/iconfont.css'
import {
	getlocode, getrecode, inspectonly, verificationcode, register, userlogin,
	getuserinfo, loginDuty, updatesxVerify, getLoginInfo
} from "../../api/hospitalapi/login"
import moment, { duration } from 'moment'
import localStorage from 'redux-persist/es/storage'
import getUrl from '../../component/GetUrlParam'
import { sync } from 'resolve'
import { jumpUrl } from '../../publicurl'
import { WhiteSpace, Checkbox, Modal, List } from 'antd-mobile'
import md5 from 'js-md5';
import res from 'antd-mobile-icons/es/AaOutline'
import {openidIsExist} from "../../api/request/openid";
const CheckboxItem = Checkbox.CheckboxItem
export default class login extends Component {
	constructor() {
		super()
		this.state = {
			number: "",
			status: false,
			code: "",
			time: 60,
			type: 0,
			tip: 0,
			valid: true,//获取验证码状态位
			valid1: true,//登录或注册状态位
			password: '',//密码
			selectcode: 0,
			readcode: 0,
			loginwaycode: 1,//1、验证码登录 2、密码登录
			eye: 1,//1、闭眼 2、睁眼
			showTip: false,
			// loginAttempts:0, //登录尝试次数
			// isLocked:false
			isAccountLocked: false,
			failedLoginCount: 0,
			loginAttempts: 0, //记录失败次数
			loginFailedTime: null, //记录3次登录失败后的当前时间
			disabled: false,//60s获取验证码一次
		}
	}


	componentDidMount = async () => {
		const { number, code, caPassTime, lastFailedTime, loginAttempts } = this.state
		const urlParams = new URLSearchParams(this.props.location.search);
		const isSHowToast = urlParams.get('isSHowToast');
		if (isSHowToast) {
			Toast.show({
				content: "登录已失效，请重新登录",
				duration: 2000     //这里之前是800，他们说要时间停留长点，所以我改成2000
			})
		}
		this.setState({
			number: getUrl('number', this.props.location.search) ? getUrl('number', this.props.location.search) : '',
			code: getUrl('code', this.props.location.search) ? getUrl('code', this.props.location.search) : '',
			password: getUrl('password', this.props.location.search) ? getUrl('password', this.props.location.search) : '',
			loginwaycode: getUrl('loginwaycode', this.props.location.search) ? getUrl('loginwaycode', this.props.location.search) : 1,
			tip: getUrl('number', this.props.location.search) && (getUrl('code', this.props.location.search) || getUrl('password', this.props.location.search)) ? 1 : 0
		})
	}
	getinputvalue = (e) => {
		if (e.target.value.length > 11) {
			this.setState({
				number: e.target.value.toString().substr(0, 11)
			})
		} else {
			this.setState({
				number: e.target.value
			})
		}
	}
	getpassword = (e) => {
		this.setState({
			password: e.target.value.replace(/\s*/g, ""),
			tip: 1,
			showClearIcon: !!e.target.value.replace(/\s*/g, "")
		})
	}
	setEye = () => {
		const { eye } = this.state
		let input = document.getElementById("inputId");
		if (eye == 1) {
			input.type = 'text';
			this.setState({
				eye: 2,
			})
		} else {
			input.type = 'password';
			this.setState({
				eye: 1
			})
		}
	}
	getcodenum = (e) => {
		if (e.target.value.length <= 6) {
			this.setState({
				code: e.target.value,
				tip: 1
			})
		} else {
			this.setState({
				code: e.target.value.toString().substr(0, 6),
				tip: 1
			})
		}
	}
	//点击获取验证码
	code = async () => {
		const { number, status, time } = this.state
		const reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
		// if (status) {
		// 	this.setState({
		// 		type: 1
		// 	})
		// 	Toast.show({
		// 		content: `请${time}秒后点击获取`,
		// 		duration: 1000
		// 	})
		// } else {
		// 	this.setState({
		// 		status: true,
		// 		time:60
		// 	},()=>{
		if (number) {
			if (reg_tel.test(number)) {
				if (status) {
					this.setState({
						type: 1
					})
					Toast.show({
						content: `请${time}秒后点击获取`,
						duration: 1000
					})
				} else {
					// 更新失效验证码状态
					updatesxVerify(number).then(res0 => {
						console.log(res0, '150===============');
						if (res0.code == 1) {
							//获取登录验证码
							getlocode({
								userPhone: number
							}).then(res => {
								console.log(res.code, '156=========');
								if (res.code == 1) {
									this.setState({
										status: true,
										time: 60
									}, () => {
										let code = this.state.time
										const timer = setInterval(() => {
											code -= 1
											this.setState({
												time: code,
											})
											if (code == 0) {
												clearInterval(timer)
												// updatesxVerify(number).then(res=>{
												// 	console.log(res);
												// })
												this.setState({
													status: false,
													type: 0
												})
											}
										}, 1000);
									})
								}
								else {
									this.setState({
										status: false,
										type: 0
									})
									Toast.show({
										content: "您验证码发送太频繁，请稍后再试1111",
										duration: 800
									})
								}
							})
						}
					})
				}
			} else {
				Toast.show({
					content: "请输入正确的手机号",
					duration: 800
				})
				this.setState({
					type: 0
				})
			}
			// }

		} else {
			Toast.show({
				content: "请输入手机号",
				duration: 800
			})
			this.setState({
				type: 0
			})
		}
		// 	})
		// }

	}
	// 节流，一秒内只执行一次获取验证码操作
	// getcode = () => {
	// 	const { type, time, status,valid } = this.state
	// 	if (!valid) {
	// 		return ;
	// 	}else{
	// 		if (status) {
	// 			Toast.show({
	// 				content: `请${time}秒后点击获取`,
	// 				duration: 1000
	// 			})
	// 		}else{
	// 			this.setState({
	// 				valid :false//工作期间状态码设置为false
	// 			},()=>{
	// 				this.code()
	// 				setTimeout(() => {
	// 					this.setState({
	// 						valid :true
	// 					})
	// 				}, 200)
	// 			})
	// 		}
	// 	}
	// }

	// 登录错误处理
	handleLoginError = () => {
		const { loginAttempts } = this.state;
		const attempts = loginAttempts + 1;

		this.setState({ loginAttempts: attempts });
		localStorage.setItem('loginAttempts', attempts);
		if (attempts >= 3) {
			const currentTime = new Date().getTime();
			this.setState({ lastFailedTime: currentTime });
			localStorage.setItem('lastFailedTime', currentTime);
			Toast.show('登录失败3次，账户已被锁定，请30分钟后再试');
		}
	};

	//获取验证码60s一次
	handleClick = () => {
		if (!this.state.disabled) {
			console.log('点击了获取验证码按钮');
			this.code();
			this.setState({ disabled: true });
			setTimeout(() => {
				this.setState({ disabled: false });
			}, 60000);
		}
	}

	//点击登录或注册
	login = async () => {
		const { type, number, code, loginwaycode, password, isAccountLocked, failedLoginCount, lastFailedTime } = this.state
		const currentTime = new Date().getTime();
		const isLocked = lastFailedTime && (currentTime - lastFailedTime < 30 * 60 * 1000); // 半小时的毫秒数

		console.log(encodeURIComponent('https://doc.yyj-health.com/index.html#/getUserInfo'));
		// let reg=/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{8,64}$/;
		// const arr = 'https://pay.yyj-health.com/pay/weixin/user?client_id=7000&redirect_uri=http%3A%2F%2Ft-h5.sczyzk.com%2Findex.html%2523%2FgetUserInfo'.split("&")
		// let url= arr[0].substring(57, arr[0].length)
		// console.log(url)
		// getJMURL({
		// 	url
		// }).then(res5=>{
		//
		// 	console.log(res5)
		// })

		if ((code && loginwaycode == 1) || (password && loginwaycode == 2)) {
			// debugger
			// if (type==1) {
			console.log(type);
			//查看账户是否存在
			const res0 = await inspectonly(number)
			console.log(res0);
			if (res0.code == 1) {
				// if (reg.test(password)){
				if (res0.data[0].length && res0.data[0][0].loginType == 2) {
					// 验证码登录
					if (loginwaycode == 1) {
						//验证码验证
						const res = await verificationcode(number, code)
						console.log(res);
						if (res.data[0].length && res.data[0][0].smsCode == code) {
							userlogin({ docterPhone: number, smsCode: code, }).then(res1 => {
								console.log(res1);
								if (res1.code == 1 && res1.data && res1.data[0][0].docterState == 1) {

									console.log("登录成功")
									//获取医生信息
									getuserinfo({
										docterPhone: number
									}).then(res2 => {
										console.log('药师信息：',res2);
										if (res2.code == 1) {
											localStorage.setItem("docterPhone", res2.data[0][0].docter_phone)
											localStorage.setItem("docterAvatar", res2.data[0][0].docter_avatar)
											localStorage.setItem("docterName", res2.data[0][0].docter_name)
											localStorage.setItem("docterId", res2.data[0][0].docter_id)
											if (res1.data && res1.data[0] && res1.data[0][0].caPassTime) {
												// 将字符串日期转换为 Moment 对象
												let caPassTime = moment(res1.data[0][0].caPassTime);
												// 获取未来一年后的日期
												let futureDate = caPassTime.add(1, 'year');
												// 获取未来日期的开始时间
												let futureStart = futureDate.startOf('day');
												// 获取今天的开始时间
												let todayStart = moment().startOf('day');
												// 计算未来日期与当天的日期之间的天数差
												let timeca = futureStart.diff(todayStart, 'days') - 2;
												console.log("timeca:",timeca);

												localStorage.setItem("caPassTime", timeca)
											}
											if (res2.data[0][0].docter_examine != '-2') {
												// 查询用户的手机号查询openid
												// openidIsExist(number).then(res => {
												// 	if (res.data[0][0].flag == 1) {
												// 		window.location.href = `${jumpUrl}/index.html`
												// 	} else {
												// 		window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=` + jumpUrl + `/index.html#/`
												// 	}

												// })

												window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
												localStorage.removeItem("displayCount")
											} else {
												this.props.history.push("/ ")
											}
										} else {
											Toast.show({
												content: "网络错误",
												duration: 800
											})
										}
									})
								} else {
									Toast.show({
										content: "账号异常，暂时无法登录，请联系客服：13551897103",
										duration: 800
									})
								}
							})


						} else {
							Toast.show({
								content: "验证码错误",
								duration: 800
							})
						}
					} else {
						//	密码登录
						// debugger
						getLoginInfo({ docterPhone: number },res0.data[0][0].isEnablePwd).then(res01 => {
							if (res01.data[1][0].loginState === 0) {
								Toast.show({
									content: "连续登录失败3次,请半小时后再登录",
									duration: 800
								})
								// return;
							} else {
								// debugger
								// 如果isEnablePwd:0则查询is_enable_pwd字段
								loginDuty({ phone: number, password: md5(password) },res0.data[0][0].isEnablePwd).then(res1 => {
									if (res1.code == 1 && res1.data) {
										// console.log(res1.data, '383=============')
										if (res1.data[0].length) {
											if (res1.data[0][0].docterState == 1) {
												//获取医生信息
												getuserinfo({
													docterPhone: number
												}).then(res2 => {
													if (res2.code == 1) {
														localStorage.setItem("docterPhone", res2.data[0][0].docter_phone)
														localStorage.setItem("docterAvatar", res2.data[0][0].docter_avatar)
														localStorage.setItem("docterName", res2.data[0][0].docter_name)
														localStorage.setItem("docterId", res2.data[0][0].docter_id)

														

														if (res1.data && res1.data[0] && res1.data[0][0].caPassTime) {
															// 将字符串日期转换为 Moment 对象
															let caPassTime = moment(res1.data[0][0].caPassTime);
															// 获取未来一年后的日期
															let futureDate = caPassTime.add(1, 'year');
															// 获取未来日期的开始时间
															let futureStart = futureDate.startOf('day');
															// 获取今天的开始时间
															let todayStart = moment().startOf('day');
															// 计算未来日期与当天的日期之间的天数差
															let timeca = futureStart.diff(todayStart, 'days') - 2;
															console.log("timeca:",timeca);

															// let timeca = moment(res1.data[0][0].caPassTime).add(1, 'year').diff(moment(), 'day')
															localStorage.setItem("caPassTime", timeca) 
															let loginTimeDay=res1.data[0][0].loginTimeDay
															localStorage.setItem("loginTimeDay", loginTimeDay)

														}
														// 判断是否90天未修改密码
														let updatePwdTime = res2.data[0][0].update_pwd_time;
														const ninetyDaysAgo = moment().subtract(90, 'days');
														if (moment(updatePwdTime).isBefore(ninetyDaysAgo)) {
															this.setState({ showTip: true });
															return;
														}
														// getdoctorsh(res2.data[0][0].docter_id).then(res3=>{
														// 	console.log(res3);
														if (res2.data[0][0].docter_examine != '-2') {
															// this.props.history.push("/")
															// 查询用户的手机号查询openid
															// openidIsExist(number).then(res => {
															// 	if (res.data[0][0].flag == 1) {
															// 		window.location.href = `${jumpUrl}/index.html`
															// 	} else {
															// 		window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=` + jumpUrl + `/index.html#/`
															// 	}

															// })
															window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
															localStorage.removeItem("displayCount")
														} else {
															// if (res2.data[0][0].docuser_type==2){
															// 	window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/uploaddoc`
															// }else{
															this.props.history.push("/doctype")
															// }
														}
														// this.props.history.push("/")
														// })
													} else {
														Toast.show({
															content: "网络错误",
															duration: 800
														})
													}
												})
											} else {
												Toast.show({
													content: "账号异常，暂时无法登录，请联系客服：13551897103",
													duration: 800
												})

											}

										} else {
											Toast.show({
												content: "未设置密码或密码错误",
												duration: 800
											})
										}
									} else {
										Toast.show({
											content: "账号或密码错误",
											duration: 800
										})
									}
								})
							}
						})

					}

				} else if (res0.data[0].length && res0.data[0][0].loginType != 2) {
					Toast.show({
						content: "该号码已在管理员或药师端注册，无法继续注册",
						duration: 800
					})
				} else {
					if (loginwaycode == 1) {
						const res = await verificationcode(number, code)
						// console.log(res.data[0].length,'11111');
						if (res.data[0].length) {
							const res1 = await register({
								phone: number,
								docterPwd: password
							})
							if (res1.code == 1) {
								userlogin({ docterPhone: number, smsCode: code }).then(res11 => {
									if (res11.code == 1 && res11.data && res11.data[0][0].docterState == 1) {
										getuserinfo({
											docterPhone: number
										}).then(res2 => {
											console.log(res2);
											if (res2.code == 1) {
												localStorage.setItem("docterPhone", res2.data[0][0].docter_phone)
												localStorage.setItem("docterAvatar", res2.data[0][0].docter_avatar)
												localStorage.setItem("docterName", res2.data[0][0].docter_name)
												localStorage.setItem("docterId", res2.data[0][0].docter_id)
												if (res11.data && res11.data[0] && res11.data[0][0].caPassTime) {

													// 将字符串日期转换为 Moment 对象
													let caPassTime = moment(res11.data[0][0].caPassTime);
													// 获取未来一年后的日期
													let futureDate = caPassTime.add(1, 'year');
													// 获取未来日期的开始时间
													let futureStart = futureDate.startOf('day');
													// 获取今天的开始时间
													let todayStart = moment().startOf('day');
													// 计算未来日期与当天的日期之间的天数差
													let timeca = futureStart.diff(todayStart, 'days') - 2;
													console.log("timeca:",timeca);

													// let timeca = moment(res11.data[0][0].caPassTime).add(1, 'year').diff(moment(), 'day')
													localStorage.setItem("caPassTime", timeca)
												}
												// getdoctorsh({
												// 	docterId:res2.data[0][0].docter_id
												// }).then(res3=>{
												if (res2.data[0][0].docter_examine != '-2') {
													// 查询用户的手机号查询openid
													// openidIsExist(number).then(res => {
													// 	if (res.data[0][0].flag == 1) {
													// 		window.location.href = `${jumpUrl}/index.html`
													// 	} else {
													// 		window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=` + jumpUrl + `/index.html#/`
													// 	}

													// })
													window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=` + jumpUrl + `/index.html#/`
												} else {
													// if (res2.data[0][0].docuser_type==2){
													// 	window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/uploaddoc`
													// }else{
													this.props.history.push("/doctype")
													// }
												}
												// this.props.history.push("/")
												// })
											} else {
												Toast.show({
													content: "网络错误",
													duration: 800
												})
											}

										})

									} else {
										Toast.show({
											content: "账号异常，暂时无法登录，请联系客服：13551897103",
											duration: 800
										})
									}
								})
							}
							// console.log(res1);
						} else {
							Toast.show({
								content: "验证码错误",
								duration: 800
							})
						}
					} else {
						Toast.show({
							content: "未注册医生请使用验证码登录",
							duration: 800
						})
					}
				}

			}

		} else {
			if (code && loginwaycode == 1) {
				Toast.show({
					content: "请输入验证码",
					duration: 800
				})
			} else {
				Toast.show({
					content: "请输入密码",
					duration: 800
				})
			}

		}
	}
	// 节流，一秒内只执行一次登录或注册操作
	userLogin = () => {
		const { type, number, code, valid1 } = this.state
		if (!valid1) {
			return;
		} else {
			this.setState({
				valid1: false//工作期间状态码设置为false
			})
			setTimeout(() => {
				this.login()
				this.setState({
					valid1: true
				})
			}, 500)
		}
	}
	//点击重新获取验证码
	codeagain = async () => {
		const { type, number, time } = this.state
		this.setState({
			type: 1
		})
		if (time == 0) {
			let res
			if (res == 0) {
				res = await getrecode({
					userPhone: number
				})
			} else {
				res = await getlocode({
					userPhone: number
				})
			}
			//console.log(res,number);
			if (res.code == 1) {
				Toast.show({
					content: "获取成功",
					duration: 800
				})
				let code = 60
				const timer = setInterval(() => {
					code -= 1
					this.setState({
						time: code
					})
					if (code == 0) {
						clearInterval(timer)
						this.setState({
							status: false,
							type: 0
						})
					}
				}, 1000);
			} else {
				Toast.show({
					content: "网络错误",
					duration: 800
				})
			}
		} else {
			Toast.show({
				content: `${time}秒后再次获取`,
				duration: 800
			})
		}
	}
	// 节流，一秒内只执行一次获取验证码操作
	getcodeagain = () => {
		const { type, number, code, valid } = this.state
		if (!valid) {
			return;
		} else {
			this.setState({
				valid: false//工作期间状态码设置为false
			})
			setTimeout(() => {
				this.codeagain()
				this.setState({
					valid: true
				})
			}, 500)
		}
	}
	toSelect = () => {
		this.setState({
			selectcode: this.state.selectcode == 0 ? 1 : 0,
			readcode: this.state.readcode == 0 ? 1 : 0,
		})
	}
	setforgetment = () => {
		// debugger
		const { number, code, loginwaycode, password } = this.state
		this.props.history.replace(`/login?loginwaycode=${loginwaycode}`)
		this.props.history.push(`/login/retrieve?loginwaycode=${loginwaycode}`)
	}
	rjagreement = () => {
		const { number, code, loginwaycode, password } = this.state

		this.props.history.replace(`/login?number=${number}&code=${code}&loginwaycode=${loginwaycode}&password=${password}`)
		this.props.history.push("/agrenagreement")
	}
	ysment = () => {
		const { number, code, loginwaycode, password } = this.state

		this.props.history.replace(`/login?number=${number}&code=${code}&loginwaycode=${loginwaycode}&password=${password}`)
		this.props.history.push("/privacyagreement")
	}
	// render() {
	// 	const { number, status, code, time ,tip,password,selectcode} = this.state
	// 	console.log(`https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/uploaddoc`)
	// 	return (
	// 		// <div className='loginbox'>
	// 		// 	<h1 className='box-title1'>您好，</h1>
	// 		// 	<h2 className='box-title2'>欢迎来到云益健医生端</h2>
	// 		// 	<h3 className='box-title3'>未注册手机号验证后将自动创建账号</h3>
	// 		// 	<div className='box-input'>
	// 		// 		<input type="number" value={number} onChange={(e) => this.getinputvalue(e)} placeholder='请输入手机号' />
	// 		// 		{/*<input type="pwd" value={password} onChange={(e) => this.getpassword(e)} placeholder='请输入至少8位由字母数字和特殊符号组成的密码' />*/}
	// 		// 		<input type="number" placeholder='请输入验证码' value={code} onChange={(e) => this.getcodenum(e)} />
	// 		// 		<button className={status?'getcode1':'getcode'} onClick={this.code}>{status ? `${time}秒后重新获取` : "获取验证码"}</button>
	// 		// 	</div>
	// 		// 	<div className='box-btn'>
	// 		// 		<button className={tip==1?'dianji':'weidian'} onClick={this.userLogin}>登录</button>
	// 		// 	</div>
	// 		// 	<div className='box-bottom'>
	// 		// 		登录代表你已阅读并同意 <a onClick={() => this.props.history.push("/agrenagreement")}>《软件使用许可协议》</a> 和 <a onClick={() => this.props.history.push("/privacyagreement")}>《隐私权政策》</a>
	// 		// 	</div>
	// 		// </div>
	// 		<div className='loginbox1'>
	// 			<div className='login1-bgc'>
	// 				<img src={icon2}/>
	// 			</div>
	// 			<div className='login-title-top'>
	// 				<p className='welcome-login '>欢迎登录</p>
	// 				<p className='welcome-login welcome-login1'>云益健医生端</p>
	// 				<p className='login-tips'>未注册的手机号验证后将自动创建账号</p>
	// 			</div>
	// 			<div className='login1-body'>
	// 				<p className='login1-title'>手机号登录</p>
	// 				<div className='box-input'>
	// 					<p className='login1-phone login1-phone11'>
	// 						<i className='iconfont icon-shoujihao'/>
	// 						<input type="number" value={number} onChange={(e) => this.getinputvalue(e)} placeholder='请输入手机号' />
	// 					</p>
	// 					<p className='login1-phone'>
	// 						<i className='iconfont icon-yanzhengyanzhengma'/>
	// 						<input type="number" placeholder='请输入验证码' value={code} onChange={(e) => this.getcodenum(e)} />
	// 						<button className={status?'getcode1':'getcode'} onClick={this.code}>{status ? `${time}秒后重新获取` : "获取验证码"}</button>
	// 					</p>
	// 					{/*<input type="pwd" value={password} onChange={(e) => this.getpassword(e)} placeholder='请输入至少8位由字母数字和特殊符号组成的密码' />*/}
	// 				</div>
	// 				<div className='box-btn'>
	// 					<button className={tip==1?'dianji':'weidian'} onClick={this.userLogin}>登录</button>
	// 				</div>
	// 				<div className='box-bottom'>
	// 					{/*<input type='radio' name='a' checked={selectcode==1} onClick={()=>this.toSelect()}/>*/}
	// 					{/*<CheckboxItem checked={selectcode==1} onClick={()=>this.toSelect()}  className='my-radio'/>*/}
	// 					<p>登录代表你已阅读并同意 <a onClick={() => this.rjagreement()}>《软件使用许可协议》</a>
	// 						<a onClick={() => this.ysment()}>《隐私权政策》</a></p>
	// 				</div>
	// 			</div>
	// 			{/*<div className='login1-hos'>云益健互联网医院有限公司</div>*/}
	// 		</div>
	// 	)
	// }
	render() {
		const { number, status, code, time, tip, password, selectcode, loginwaycode, eye, showTip, showClearIcon } = this.state
		console.log(`https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=` + jumpUrl + `/index.html#/uploaddoc`)
		return (
			<div className='loginbox1'>
				<div className='login1-bgc'>
					<img src={icon3} />
				</div>
				<div className='login2-body'>
					<p className='login2-title'>
						<p className={loginwaycode == 1 ? 'loginway' : 'loginway1'} onClick={() => this.setState({
							loginwaycode: 1
						})}>验证码登录</p>
						<p className={loginwaycode == 2 ? 'loginway' : 'loginway1'} onClick={() => this.setState({
							loginwaycode: 2
						})}>密码登录</p>
					</p>
					{loginwaycode == 1 ? <div className='box-input1'>
						<p className='login2-phone login2-phone11'>
							<i className='iconfont icon-shoujihao' />
							<input type="number" value={number} onChange={(e) => this.getinputvalue(e)} placeholder='请输入手机号' />
							<i className='iconfont icon-guanbi2' onClick={() => this.setState({
								number: ''
							})} />
						</p>
						<p className='login2-phone'>
							<i className='iconfont icon-yanzhengyanzhengma' />
							<input type="number" placeholder='请输入验证码' value={code} onChange={(e) => this.getcodenum(e)} />
							{/* <button onClick={()=>{
								console.log('点到获取验证码了');
								this.code();
							}}>{status ? `${time}秒后重新获取` : "获取验证码"}</button> */}
							<button disabled={this.state.disabled} onClick={this.handleClick}>
								{status ? `${time}秒后重新获取` : "获取验证码"}
							</button>
						</p>
						{/*<input type="pwd" value={password} onChange={(e) => this.getpassword(e)} placeholder='请输入至少8位由字母数字和特殊符号组成的密码' />*/}
					</div> :
						<div className='box-input1'>
							<p className='login2-phone login2-phone11'>
								<i className='iconfont icon-shoujihao' />
								<input type="number" value={number} onChange={(e) => this.getinputvalue(e)} placeholder='请输入手机号' />
								<i className='iconfont icon-guanbi2' onClick={() => this.setState({
									number: ''
								})} />
							</p>
							<p className='login2-phone login2-phone11'>
								<i className='iconfont icon-mima-01' />
								<input type="password" value={password} id='inputId' maxLength={16} onChange={(e) => this.getpassword(e)} placeholder='请输入8-16位密码' />
								<span className='lgtipicon'>
									{showClearIcon && <i className='iconfont icon-guanbi2' onClick={() => this.setState({
										password: '',
										showClearIcon: false
									})} />}
									{eye == 1 ?
										<i onClick={() => this.setEye()} style={{ fontSize: '16px' }} className='iconfont icon-yanjing_bi' /> :
										<i onClick={() => this.setEye()} style={{ fontSize: '16px' }}
											className='iconfont icon-yanjing' />}
								</span>
							</p>
							<p className='forget-pwd' onClick={() => this.props.history.push(`/login/retrieve`)}>设置密码/忘记密码</p>
							{/* <p className='forget-pwd' onClick={()=> this.setforgetment()}>设置密码/忘记密码</p> */}
						</div>}
					<div className='box-btn'>
						<button className={tip == 1 ? 'dianji' : 'weidian'} onClick={this.userLogin}>登录</button>
					</div>
					<div className='box-bottom'>
						{/*<input type='radio' name='a' checked={selectcode==1} onClick={()=>this.toSelect()}/>*/}
						{/*<CheckboxItem checked={selectcode==1} onClick={()=>this.toSelect()}  className='my-radio'/>*/}
						<p>登录代表你已阅读并同意 <a onClick={() => this.rjagreement()}>《软件使用许可协议》</a>
							<a onClick={() => this.ysment()}>《隐私权政策》</a></p>
					</div>
					<div>{showTip && <TipAlert history={this.props.history} number={number} password={password} loginwaycode={loginwaycode} />}</div>
				</div>
				{/*<div className='login1-hos'>云益健互联网医院有限公司</div>*/}
			</div>
		)
	}
}
