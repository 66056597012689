import React, { Component } from 'react'
// import icon1 from "../../../static/img/yy/fanhuihei.png"
import { Badge, SideBar } from 'antd-mobile-v5'
import "./style.less"
import SignatureCanvas from 'react-signature-canvas'
import getUrl from '../../../component/GetUrlParam'
import icon1 from '../../../static/img/home/gou.png'
import icon4 from '../../../static/img/home/jiantou.png'
import icon2 from '../../../static/img/home/ddd.png'
import icon3 from '../../../static/img/home/doc-app.png'
import icon5 from '../../../static/img/home/all-way.png'

export default class index extends Component {
    constructor() {
        super()
        this.state = {
            trimmedDataURL: null,
            docterId:localStorage.getItem("pharmacistId"),
            docterPhone : localStorage.getItem("pharmacistPhone"),
            img:'',
        }
    }
    componentDidMount = async () => {
        let { docterPhone } = this.state;

        var ua = window.navigator.userAgent.toLowerCase();
        console.log(ua.match(/MicroMessenger/i)== 'micromessenger')
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return ; // 是微信端
        } else {
            window.location.href =('https://images.yyj-health.com/apk/%E4%BA%91%E7%9B%8A%E5%81%A5%E5%8C%BB%E7%94%9F%E7%AB%AF.apk')
        }
    }
    weixinTip=(ele)=>{
        var ua = navigator.userAgent;

        var isWeixin = !!/MicroMessenger/i.test(ua);
        if(isWeixin){

            ele.onclick=function(e){

                window.event? window.event.returnValue = false : e.preventDefault();

                document.getElementById('JdownApp').style.display='block';

            }

            // document.getElementById('JdownApp').onclick=function(){
            //
            //     this.style.display='none';
            //
            // }

        }

    }

    downapp=()=>{
        var btn1 = document.getElementById('JdownApp');//下载一

        this.weixinTip(btn1);
    }

    // var btn2 = document.getElementById('JdownApp2'); //下载二
    //
    // weixinTip(btn2);
    render() {
        let { trimmedDataURL } = this.state;
        return (
            <div >
                {/*<a href="https://images.yyj-health.com/apk/%E4%BA%91%E7%9B%8A%E5%81%A5%E5%8C%BB%E7%94%9F%E7%AB%AF.apk" id="JdownApp" onClick={()=>this.downapp()}>点击下载APP</a>*/}

                <div className="wxtip" id="JweixinTip">
                    <p className='downapp-tips-top'>
                        <img src={icon1}/>
                        <span>从浏览器打开</span>
                    </p>
                    <img className='downapp-tips-jiantou' src={icon4}/>
                    <p className='downapp-tips-top2'>
                        <span>点击右上角</span>
                        <img src={icon2}/>
                        <span>，选择在<span style={{color:'#177FFF'}}>浏览器</span>中打开</span>
                    </p>
                    {/*<span className="wxtip-icon"></span>*/}

                    {/*<p className="wxtip-txt">点击右上角<br/>选择在浏览器中打开</p>*/}
                    <p className='downapp-tips-body'>
                        <a href="https://images.yyj-health.com/apk/%E4%BA%91%E7%9B%8A%E5%81%A5%E5%8C%BB%E7%94%9F%E7%AB%AF.apk" id="JdownApp" onClick={()=>this.downapp()}><img src={icon3}/></a>
                        <p className='downapp-yaoshi'>云益健医生</p>
                        <p className='downapp-tips'>下载APP，审方更方便</p>
                        <img src={icon5} className='downapp-all-way'/>
                    </p>
                </div>
            </div>
        )
    }
}
