import React, { Component } from 'react'
import { List, InputItem, Switch, Button, Toast } from 'antd-mobile'

import Head from '../../../../component/Head'
import ChooseButon from '../../../../baseui/choosebutton/index'
import './style.less'
import selectaddress from '../../../../static/img/selectaddress.png'
import selectyou from '../../../../static/img/selectyou.png'
import BaiduMap from '../../../../application/Map/BDMap'
import BDMapS from '../../../../application/Map/BDMapS'
import GDmaps from '../../../../application/Map/MapContainer'
import { addadressRequest,adressListRequest, addressDetailsRequest, updateAdressRequest, delAdressRequest, getProvince, getCity, gitDistrict } from '../../../../api/request/adress'

export default class AdressEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // 接收类型，动态展示更新和添加地址模块
            type: this.props.match.params.type,
            addressId: this.props.match.params.addressId,
            userId: localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3,
            usertype: localStorage.getItem("type"),
            user: '',
            tel: '',
            province: '四川省',
            city: '成都市',
            district: '高新区',
            business: "小区",
            adress: '',
            addressDefault: false,
            longitude: '',
            latitude: '',
            city1: "",
            district1: "",
            business1: "",
            provinceArr: [],
            cityArr: [],
            districtArr: [],
            count:1
        }
    }

    componentDidMount() {
        if (this.state.type === 'refrash') {
            this.getAddressDetails()
        }
        this.getprovinceArr()
        adressListRequest(this.state.userId).then(res => {
            if(res.code === 1 && res.data[0].length == 0) {
                this.setState({
                    count:0
                })
            }
        }) 
    }
    // 获取用户默认地址
    getAddressDetails = () => {
        addressDetailsRequest(this.state.addressId).then(res => {
            if (res.code === 1) {
                const data = res.data[0][0]
                this.setState({
                    user: data.addressee,
                    tel: data.phone,
                    province: data.province,
                    city: data.city,
                    district: data.district,
                    adress: data.address,
                    addressDefault: data.addressDefault === '1'
                })
            }
        })
    }
    //获取所有省份
    getprovinceArr = async () => {
        const res = await getProvince()
        this.setState({
            provinceArr: res.data[0]
        })
    }
    //获取对应市
    getCity=async(code)=>{
        const res=await getCity({
            parentId:code
        })
       this.setState({
        cityArr:res.data[0]
       })
    }
    //获取对应区
    getDistrict=async(code)=>{
        const res=await gitDistrict({
            parentId:code
        })
       this.setState({
        districtArr:res.data[0]
       })
    }

    handleChange = (key, val) => {
        this.setState({
            [key]: val
        })
    }
    // 提交用户信息
    saveAddress = () => {
        const {usertype}=this.state
        let params
        if (usertype=="2") {
            if (this.state.count==0) {
                params = {
                    userId: this.state.userId,
                    addressDefault: '1',
                    province: this.state.province,
                    city: this.state.city,
                    district: this.state.district,
                    address: this.state.adress,
                    addressee: this.state.user,
                    phone: this.state.tel
                }
            }else{
                params = {
                    userId: this.state.userId,
                    addressDefault: this.state.addressDefault ? '1' : '0',
                    province: this.state.province,
                    city: this.state.city,
                    district: this.state.district,
                    address: this.state.adress,
                    addressee: this.state.user,
                    phone: this.state.tel
                    //longitude: this.state.longitude,
                    //latitude: this.state.latitude,
                    // business:this.state.business
                }
            }
        }else{
            params = {
                userId: this.state.userId,
                addressDefault: this.state.addressDefault ? '1' : '0',
                province: this.state.province,
                city: this.state.city,
                district: this.state.district,
                address: this.state.business + this.state.adress,
                addressee: this.state.user,
                phone: this.state.tel,
                longitude: this.state.longitude,
                latitude: this.state.latitude,
                // business:this.state.business
            }
        }
       
        for (let item in params) {
            if (params[item] === ''&&item!="province") {
                Toast.fail("请将信息填写完整")
                return
            }
        }
        //发请求存用户地址信息
        addadressRequest(params).then(res => {
            if (res.code === 1) {
                localStorage.address = params.province + params.city + params.district + params.address
                this.props.history.go(-1)
            }
        })
    }
    //修改更新地址
    updateAddre = () => {
        const params = {
            addressId: this.state.addressId,
            addressDefault: this.state.addressDefault ? '1' : '0',
            province: this.state.province,
            city: this.state.city,
            district: this.state.district,
            address: this.state.adress,
            addressee: this.state.user,
            phone: this.state.tel,
        }
        updateAdressRequest(params).then(res => {
            if (res.code === 1) {
                this.props.history.go(-1)
            }
        })
    }
    // 删除地址
    delAddre = () => {
        delAdressRequest(this.state.addressId).then(res => {
            if (res.code === 1) {
                this.props.history.go(-1)
            }
        })
    }

    // 获取经纬度
    getCode = (longitude, latitude,province, city1, district1, business1) => {
        this.setState({
            longitude,
            latitude,
            province,
            city1,
            district1,
            business1
        }, () => {
            this.setState({
                city: this.state.city1,
                district: this.state.district1,
                business: this.state.business1,

            })
        })
    }
    //获取用户选择得地址
    changeone = async (code, e) => {
        switch (code) {
            case 1:
                const text=e.target.value.substring(6)
                const num=e.target.value.substring(0,6)
                this.getCity(num)
                this.setState({
                    province:text
                })
                break;
            case 2:
                const text2=e.target.value.substring(6)
                const num2=e.target.value.substring(0,6)
                this.getDistrict(num2)
                this.setState({
                    city: text2
                })
                break;
            case 3:
                const text3=e.target.value.substring(6)
                this.setState({
                    district: text3
                })
                break;
        }
    }
    //展示下一个子地区
    shownext=async(code)=>{

    }
    render() {
        const { type, user, tel, adress, addressDefault, province, city, district, business, usertype, provinceArr,cityArr,districtArr } = this.state

        return (
            <div>
                {type === 'refrash' ?
                    <div>
                        <Head title='更新地址' />
                        {usertype == "2" ? null : <GDmaps width='100%' height='300px' getCode={this.getCode}></GDmaps>}
                        <List className='adressinput' style={{ boxSizing: "border-box" ,paddingTop:usertype=="2"?"0px":"300px"}}>
                        {usertype=="2"? <div className="chioseaddress">
                                <span>省</span>: <select name="" id="" data-index="1" onChange={(e) => this.changeone(1, e)}>
                                    <option value="">选择省</option>
                                    {
                                        provinceArr.map(item => {
                                            return (
                                                <option value={item.id+item.name} key={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span>市</span>:<select name="" id=""  onChange={(e) => this.changeone(2, e)}>
                                    <option value="">选择市</option>
                                    {
                                        cityArr.map(item => {
                                            return (
                                                <option value={item.id+item.name} key={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span>区(县)</span>:<select name="" id="" onChange={(e) => this.changeone(3, e)}>
                                    <option value="">选择区(县)</option>
                                    {
                                        districtArr.map(item => {
                                            return (
                                                <option value={item.id+item.name} key={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>:null}
                            <InputItem value={province + city + district + business}

                            >地址</InputItem>
                            <InputItem value={user}
                                onChange={v => this.handleChange('user', v)}
                            >收货人</InputItem>
                            <InputItem value={tel}
                                onChange={v => this.handleChange('tel', v)}
                            >手机号码</InputItem>
                            <InputItem value={adress}
                                onChange={v => this.handleChange('adress', v)}
                            >门牌号</InputItem>
                            <div className='xian'></div>
                        </List>
                        {/* <List className='adressinput addr-default'>
                        <div className='addr-tip'>
                            <div>设置默认地址</div>
                            <div>提醒：每次下单会默认使用该地址</div>
                        </div>
                        <div>
                            <Switch color='#1F87FF'
                            checked={addressDefault}
                            onChange={() => {
                                this.setState({
                                    addressDefault: !addressDefault,
                                })
                            }}/>
                        </div>
                    </List> */}
                        <div className='addr-default' style={{ marginTop: "50px" }}>
                            <div className='addr-tip'>
                                <div>设置默认地址</div>
                                <div>提醒：每次下单会默认使用该地址</div>
                            </div>
                            <div>
                                <Switch color='#1F87FF'
                                    checked={addressDefault}
                                    onChange={() => {
                                        this.setState({
                                            addressDefault: !addressDefault,
                                        })
                                    }} />
                            </div>
                        </div>
                        <ChooseButon flex='col'>
                            <div onClick={() => this.updateAddre()}>保存</div>
                            <div onClick={() => this.delAddre()}>删除</div>
                        </ChooseButon>
                    </div> : null
                }
                {type === 'add' ?
                    <div>
                        <Head title='添加地址' />
                        {/* <BaiduMap width='100%' height='300px' getCode={this.getCode}></BaiduMap>  */}
                        {/* {usertype == "2" ? null : <BDMapS width='100%' height='300px' getCode={this.getCode}></BDMapS>} */}
                        {usertype == "2" ? null : <GDmaps width='100%' height='300px' getCode={this.getCode}></GDmaps>}

                        <List className='adressinput' style={{ boxSizing: "border-box" ,paddingTop:usertype=="2"?"0px":"300px"}}>
                            {usertype == "2" ? null : <div className='select-address'>
                                <img src={selectaddress} alt="" />
                                <p className='shouhuo-address'>
                                    <span>{province + city + district + business}</span>
                                </p>
                            </div>}
                           {usertype=="2"? <div className="chioseaddress">
                                <span>省</span>: <select name="" id="" data-index="1" onChange={(e) => this.changeone(1, e)}>
                                    <option value="">选择省</option>
                                    {
                                        provinceArr.map(item => {
                                            return (
                                                <option value={item.id+item.name} key={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span>市</span>:<select name="" id=""  onChange={(e) => this.changeone(2, e)}>
                                    <option value="">选择市</option>
                                    {
                                        cityArr.map(item => {
                                            return (
                                                <option value={item.id+item.name} key={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span>区(县)</span>:<select name="" id="" onChange={(e) => this.changeone(3, e)}>
                                    <option value="">选择区(县)</option>
                                    {
                                        districtArr.map(item => {
                                            return (
                                                <option value={item.id+item.name} key={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>:null}
                            <InputItem placeholder='详细地址，例1层101室'
                                onChange={v => this.handleChange('adress', v)}
                            >{usertype=="2"?"详细地址":"门牌号"}</InputItem>
                            <InputItem
                                placeholder='请填写收货人的姓名'
                                onChange={v => this.handleChange('user', v)}
                            >联系人</InputItem>
                            <span className={usertype=="2"?"sex2":"sex"}>
                                <input className='write2-sex1' type="radio" checked name='c' /><span>先生</span>
                                <input className='write2-sex2' type="radio" name='c' /><span>女士</span>
                            </span>
                            <InputItem
                                placeholder='请填写收货手机号码'
                                onChange={v => this.handleChange('tel', v)}
                            >手机号</InputItem>
                            <div className='moren'>
                                <div className='addr-tip'>
                                    <div>设置默认地址</div>
                                    <div>提醒：每次下单会默认使用该地址</div>
                                </div>
                                <div>
                                    <Switch color='#1F87FF'
                                        checked={addressDefault}
                                        onChange={() => {
                                            this.setState({
                                                addressDefault: !addressDefault,
                                            })
                                        }} />
                                </div>
                            </div>
                        </List>
                        {/* <List className='addr-default'>
                        <div className='addr-tip'>
                            <div>设置默认地址</div>
                            <div>提醒：每次下单会默认使用该地址</div>
                        </div>
                        <div>
                            <Switch color='#1F87FF'
                            checked={addressDefault}
                            onChange={() => {
                                this.setState({
                                    addressDefault: !addressDefault,
                                })
                            }}/>
                        </div>
                    </List> */}
                        <div onClick={() => this.saveAddress()} className="cun">保存地址</div>
                        {/* <ChooseButon flex='col' className='baocun'>
                       
                    </ChooseButon> */}
                    </div> : null
                }
            </div>
        )
    }

}