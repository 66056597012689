import React, { Component } from 'react';
import "./style.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/zhenduan.png"
import icon3 from "../../../static/img/downla.png"
import icon4 from "../../../static/img/upshou.png"
import { getuserinfo } from "../../../api/hospitalapi/login"
import { getlist,addPrescribing ,getPatientMember,patientxq,updateDoctype,changestatus,getLsdetails,getIcdCode,allIcd} from "../../../api/hospitalapi/news"
import { Toast } from 'antd-mobile';
import {dealParams} from "../../../api/config";
import {caAuthentication,caPasstime} from "../../../api/hospitalapi/upload";
import getUrl from '../../../component/GetUrlParam'
import {caUrl,baseUrl,jumpUrl} from '../../../publicurl'
import moment from "moment";
import icon5 from "../../../static/img/sure1.png";
import icon7 from "../../../static/img/sure2.png";
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            docterPhone: localStorage.getItem("docterPhone"),
            doctortext: {},
            usertext: {},
            item: JSON.parse(localStorage.getItem("item")),
            // username: "",
            // userage: "",
            // usersex: "",
            somegoods:localStorage.getItem("somegoods")!='undefined'? JSON.parse(localStorage.getItem("somegoods")):'',
            patientName: "",
            patientAge: "",
            patientSex: "",
            docterDiagnosis: localStorage.getItem("docterDiagnosis"),
            docterProposal: localStorage.getItem("docterProposal"),
            medicinaldays:JSON.parse(localStorage.getItem("medicinaldays"))?JSON.parse(localStorage.getItem("medicinaldays")):[],
            patientCity:"",
            patientPhone:"",
            allergicHistory:"",
            allergicHistoryNote:"",
            abnormalLiverFunction:"",
            abnormalRenalFunction:"",
            familyGeneticHistory:"",
            pastHistory:"",
            pastHistoryNote:"",
            nowarr:[],
            vibisable:'none',
            memberId:localStorage.getItem("memberId"),
            tip:true,
            refresh:true,
            medAmount:0,
            docterId: localStorage.getItem("docterId"),
            caPassTime: localStorage.getItem("caPassTime"),
            recipeType:localStorage.getItem("recipeType")?localStorage.getItem("recipeType"):2,
            visible:false,
            icdList:[],
            icdhave:0,
            page:1,
            size:20,
            icdone:'',
            icdValue:localStorage.getItem("icdValue"),
        }
    }
    // componentWillUnmount(){
    //     const {refresh,tip} = this.state
    //     tip&&refresh && setTimeout(() =>  this.setState({
    //         refresh:false,
    //     }))
    //     window.location.reload()
    // }
    componentDidMount = async () => {
        const { somegoods ,medicinaldays,item,memberId,docterId} = this.state
        // console.log(memberId,item);
        localStorage.removeItem('castate')
        if (getUrl('ca', this.props.location.search)){
            let res=await caPasstime(docterId)
            // console.log(res)
            if (res&&res.data&&res.data[0]){
                let timeca=moment(res.data[0][0].caPassTime).add(1, 'year').diff( moment(), 'day')
                localStorage.setItem("caPassTime", timeca)
                this.setState({
                    caPassTime:timeca
                })
            }
        }
        // console.log(this.props.location.params.memberId);
        if (item&&item.order_number&&medicinaldays.length==0) {
            getLsdetails(item.order_number).then(res=>{
                // console.log(res);
                if (res.code==1) {
                    if (res.data.data[1]&&res.data.data[1].length){
                        localStorage.setItem("somegoods", JSON.stringify(res.data.data[1]))
                        res.data.data[1].map(item0=>{
                            let arr1={
                                med_id:item0.med_id,
                                medicinalDay:'',
                                frequency:'',
                                company:''
                            }
                            medicinaldays.push(arr1)
                            // console.log(medicinaldays);
                            localStorage.setItem("medicinaldays", JSON.stringify(medicinaldays))
                            this.setState({
                                somegoods:res.data.data[1],
                                medicinaldays:medicinaldays
                            })
                        })
                    }
                }
            })
        }
        // localStorage.removeItem('somegoods')
        // localStorage.removeItem('medicinaldays')

        // console.log(somegoods,medicinaldays,item);
        let nowarr = []
        let sumPrice=0

        if(somegoods!=null){
            somegoods.forEach(item => {
                medicinaldays.forEach(i => {
                    if(item.med_id==i.med_id){
                        item.medicinalFrequency=(item.medicinalFrequency?item.medicinalFrequency+'次/'+i.frequency:'')
                        item.medicinalUsage=(item.medicinalUsage?item.medicinalUsage+i.company:'')
                        item.medicinalDay=i.medicinalDay
                    }
                })
            });
            somegoods.forEach(item => {
                // console.log(item);
                let obj = {}
                obj.medId=item.med_id
                obj.medQrcode=item.med_qrcode
                obj.thirdPartyId=item.third_party_id
                obj.medComName=item.med_com_name
                obj.doseUnit=item.company
                obj.medicinalName = item.med_name
                obj.medicinalSpec = item.med_spec
                obj.medicinalType = item.med_type
                obj.medicinalDay = item.medicinalDay
                obj.medicinalDosage_form = item.med_dosage_form
                obj.medicinalPacking = item.med_packing
                obj.medicinalApplySymptoms = item.med_apply_symptoms
                obj.medicinalQuantity = item.medicinalQuantity ? item.medicinalQuantity : 0
                obj.medicinalFrequency = item.medicinalFrequency ? item.medicinalFrequency : ""
                obj.medicinalUsage = item.medicinalUsage ? item.medicinalUsage : ""
                obj.medicinalMethod = item.medicinalMethod ? item.medicinalMethod : ""
                obj.medicinalSupplementary = item.medicinalSupplementary ? item.medicinalSupplementary : ""
                obj.medicinalPrice=item.med_price
                obj.medApproval=item.med_approval
                obj.medCompany=item.med_company
                sumPrice+=item.med_price*(item.medicinalQuantity?item.medicinalQuantity:0)
                nowarr.push(obj)
            });
        }
        this.setState({
            nowarr: nowarr,
            medAmount:sumPrice
        })
        // console.log(somegoods);
        this.getdoctor()
        this.getuser()
    }
    //获取医生信息
    getdoctor = async () => {
        const { docterPhone } = this.state
        const res = await getuserinfo({
            docterPhone
        })
        this.setState({
            doctortext: res.data[0][0]
        })
        // console.log(res);
    }
    //获取患者信息
    getuser = async () => {
        const {item,memberId}=this.state
        if (item){
            this.setState({
                patientName:item.patientName,
                patientAge:item.patient_age,
                patientSex:item.patientSex==1?'男':'女',
                patientCity:item.patient_city,
                patientPhone:item.patient_phone,
                allergicHistory:item.allergic_history,
                allergicHistoryNote:item.allergic_history_note,
                abnormalLiverFunction:item.abnormal_liver_function,
                abnormalRenalFunction:item.abnormal_renal_function,
                familyGeneticHistory:item.family_genetic_history,
                familyGeneticHistoryNote:item.family_genetic_history_note,
                pastHistory:item.past_history,
                pastHistoryNote:item.past_history_note,
            })
        }

    }
    //删除一个药品
    deleteone = (item) => {
        let { somegoods,nowarr } = this.state
        somegoods = somegoods.filter((sun) => {
            return sun.med_id != item.med_id
        })
        nowarr = nowarr.filter((sun) => {
            return sun.medId != item.med_id
        })
        localStorage.setItem("somegoods", JSON.stringify(somegoods))

        this.setState({
            somegoods,
            nowarr
        })
    }
    gobank = () => {
        localStorage.removeItem('somegoods')
        localStorage.removeItem('medicinaldays')
        window.history.back()
    }
    look =() => {
        this.setState({
            vibisable: 'block'
        })
    }
    look1 =() => {
        this.setState({
            vibisable: 'none'
        })
    }
    gonone = () => {
        this.setState({
            vibisable: 'none'
        })
    }
    //点击提交
    submit = async () => {
        const { caPassTime,doctortext,icdValue,recipeType,nowarr,somegoods,medAmount, item, patientName, patientAge, patientSex, docterDiagnosis, docterProposal } = this.state
        // console.log(nowarr, somegoods)
        if (!somegoods||somegoods.length==0) {
            Toast.info('请添加药品')
        }else{
            // somegoods.map(item=>{
                for(let i=0;i<somegoods.length;i++){
                    if (!somegoods[i].medicinalQuantity) {
                        Toast.info('请先编辑药品用量')
                        return;
                    }
                }
                // if (!item.medicinalQuantity) {
                //     Toast.info('请先编辑药品用量')
                //     return;
                // }
            // })
            if (!docterDiagnosis) {
                Toast.info('请填写初步诊断')
            }else{
                this.setState({
                    tip:false
                },()=>{
                    // console.log(this.state.tip);
                    const obj = {
                        docterId: doctortext.docter_id,
                        userId: item.user_id,
                        patientName,
                        patientAge:patientAge,
                        patientSex,
                        docterDepartment: doctortext.docter_department,
                        patientIllness: item.consultation_detail,
                        docterDiagnosis,
                        docterProposal,
                        docterName:doctortext.docter_name,
                        pharmacistName:'',
                        originalPrescribing:'',
                        eSeal:'',
                        consultationId:item.consultation_id,
                        array:nowarr,
                        medAmount:medAmount,
                        isRetail:item.order_number?2:1,
                        recipeType,
                        icdValue
                    }
                    // console.log(obj)
                    // if (caPassTime&&caPassTime<60){
                    //     alert('身份信息更新', '身份认证有限期即将到期，请及时更新', [
                    //         { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
                    //         {text: '忘记密码', onPress: () => {
                    //             let data0 = {
                    //                 entityId: doctortext.docter_id,
                    //                 busiType: '5',
                    //                 redirectUrl: baseUrl+'/faas/hosp/scca/callback',
                    //                 redirectPageUrl: jumpUrl+`/index.html#/prescribing/addcf?ca=${1}`,
                    //                 province: '四川省',
                    //                 locality: '德阳市',
                    //                 orgName: '云益健互联网医院'
                    //             }
                    //             let {toSign, params} = dealParams(data0)
                    //             console.log(toSign, params)
                    //             caAuthentication({url: caUrl, toSign, FormData: params}).then(data => {
                    //                 if (data&&data.data&&data.data.success==false){
                    //                     Toast.info(data.data.result_msg)
                    //                 }else if(data&&data.data){
                    //                     window.location.href=(data.data.body)
                    //                 }
                    //             });
                    //         } },
                    //         {text: '确认', onPress: async () => {
                    //             let data0 = {
                    //                 entityId: doctortext.docter_id,
                    //                 busiType: '3',
                    //                 redirectUrl: baseUrl+'/faas/hosp/scca/callback',
                    //                 redirectPageUrl: jumpUrl+`/index.html#/prescribing/addcf?ca=${1}`,
                    //                 province: '四川省',
                    //                 locality: '德阳市',
                    //                 orgName: '云益健互联网医院'
                    //             }
                    //             let {toSign, params} = dealParams(data0)
                    //             console.log(toSign, params)
                    //             caAuthentication({url: caUrl, toSign, FormData: params}).then(data => {
                    //                 if (data&&data.data&&data.data.success==false){
                    //                     Toast.info(data.data.result_msg)
                    //                 }else if(data&&data.data){
                    //                     window.location.href=(data.data.body)
                    //                 }
                    //             });
                    //         }}
                    //     ])
                    // }else{
                        addPrescribing(obj).then(res=>{
                            // console.log(res)
                            if (res&&res.code==1) {
                                localStorage.removeItem('docterDiagnosis')
                                localStorage.removeItem('icdValue')
                                localStorage.removeItem('docterProposal')
                                localStorage.removeItem('somegoods')
                                localStorage.removeItem('medicinaldays')
                                localStorage.removeItem('recipeType')

                                // this.props.history.push("/chatdoctor")
                                this.props.history.push(`/prescribing/prescription?prescribingId=${res.data[1][0].prescribingId}`)
                            }
                        })
                    // }

                })
            }
        }
    }
    //获取输入框用户信息
    changeuserinfo = (e, text) => {
        switch (text) {
            case 4:
                this.setState({
                    // docterDiagnosis: e.target.value
                    icdone:e.target.value
                })

                break;
            case 5:
                this.setState({
                    docterProposal: e.target.value
                })
                localStorage.setItem("docterProposal",e.target.value)
                break;
        }
    }
    nosubmit=()=>{
        Toast.info('请勿重复提交')
    }
    setvalue=(e,type)=>{
        const {somegoods}=this.state
        if (type=='recipeType'){
            if (somegoods&&somegoods.length){
                Toast.info('该处方类型已有药品，若要修改处方类型请先删除已选药品')
            }else{
                localStorage.setItem('recipeType',e.target.value)
                this.setState({
                    recipeType: e.target.value,
                })
            }

        }
    }

    toIcd=async ()=>{
        const {item,icdList}=this.state
        // console.log(icdList)
        // if (!icdList.length){
            let res= await allIcd(item.consultation_id)
            // console.log(res)
            if (res&&res.data&&res.data[0]) {
                if (res.data[0].length) {
                    this.setState({
                        icdList: JSON.parse(res.data[0][0].electronic_icd),
                        visible: true
                    })
                } else {
                    Toast.info('请先选择诊断结果')
                }
            }
            // this.setState({
            //     visible:true
            // })
        // }else{
        //     Toast.info('请先选择诊断结果')
        // }

    }

    setIcd=(e,item)=>{
        const {icdList}=this.state
        e.stopPropagation()
        if (item.state==1) {
            item.state = 2
        } else {
            item.state = 1
        }
        this.setState({
            icdList: [...icdList],
        })
        // this.setState({
        //     visible:false,
        //     docterDiagnosis:item.icdName,
        //     icdValue:item.icdValue,
        // })
        // localStorage.setItem("docterDiagnosis",item.icdName)
        // localStorage.setItem("icdValue",item.icdValue)
    }
    //确认病历
    onSure=()=>{
        const {icdList}=this.state
        let arr=[]
        let arr1=[]
        let arr2=[]
        icdList.map(item=>{
            if (item.state==2){
                arr.push(item)
                arr1.push(item.icdName)
                arr2.push(item.icdValue)
            }
        })
        if (arr1.length){

            this.setState({
                visible:false,
                docterDiagnosis:arr1.length?arr1.toString().replace(/\,/g,'|'):'',
                icdValue:arr2.length?arr2.toString().replace(/\,/g,'|'):'',
            })
            localStorage.setItem("docterDiagnosis",arr1.toString().replace(/\,/g,'|'))
            localStorage.setItem("icdValue",arr2.toString().replace(/\,/g,'|'))
        }else{
            Toast.info('请先选择诊断结果')
        }
    }
    //搜索病历
    onSearch = async () => {
        const {page, size, icdone, icdList, icdlists} = this.state
        const params = {
            content: icdone,
            page: 1,
            size: size,
        }
        let res = await getIcdCode(params)
        // console.log(res)
        if (res && res.data && res.data[0]) {
            res.data[0].forEach(i => {
                icdlists.forEach(j => {
                    if (i.id == j.id) {
                        i.state = 2
                    }
                })
            })
            this.setState({
                icdList: res.data[0],
                page: 1,
                visible5: true
            })

        }
    }
    render() {
        const { item,doctortext,icdList,visible, somegoods, patientName, patientAge, patientSex, docterDiagnosis, docterProposal ,
            patientCity,patientPhone,allergicHistory,allergicHistoryNote,abnormalLiverFunction,abnormalRenalFunction,recipeType,
            familyGeneticHistory,familyGeneticHistoryNote,pastHistory,pastHistoryNote,vibisable,tip } = this.state
        // console.log(somegoods)
            return (
            <div className='addcfbox' >
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>新建处方</h1><p></p>
                    {/*<p style={{marginRight:'5px'}} onClick={() => this.props.history.push("/prescribing/addcf")}>加入常用处方</p>*/}
                </div>
                <div className='chufang-tip'>
                    不得在互联网上开具麻醉药品、精神类药品处方以及其他用药风险较高、有其他特殊管理规定的药品处方。
                </div>
                <div className='box-content1'>
                    <p className='ill-history'>患者病史信息</p>
                    <div className='content-patient'>
                        <p className='content-patient-detail'>
                            <p>姓名：{patientName}</p>
                            <p>性别：{patientSex}</p>
                            <p>年龄：{patientAge}岁</p>
                        </p>
                        {vibisable=='block'? <p className='content-patient-all'  >
                            <p><span>居住城市：</span><span>{patientCity}</span></p>
                            <p><span>患者电话：</span><span>{patientPhone}</span></p>
                            {pastHistory==1?<p><span>既往史：</span><span>{pastHistoryNote}</span></p>:<p><span>既往史：</span><span>无</span></p>}
                            {allergicHistory==1?<p><span>过敏史：</span><span>{allergicHistoryNote}</span></p>:<p><span>过敏史：</span><span>无</span></p>}
                            {familyGeneticHistory==1?<p><span>家族遗传史: </span><span>{familyGeneticHistoryNote}</span></p>:<p><span>家族遗传史:</span><span>无</span></p>}
                            {abnormalLiverFunction==0?<p><span>肝功能：</span><span>正常</span></p>:<p><span>肝功能：</span><span>异常</span></p>}
                            {abnormalRenalFunction==0?<p><span>肾功能：</span><span>正常</span></p>:<p><span>肾功能：</span><span>异常</span></p>}
                        </p>:null}
                        {vibisable!='block'? <p className='content-patient-la'  onClick={this.look} >
                            <img src={icon3}/>
                        </p>:<p className='content-patient-la'  onClick={this.look1} >
                            <img src={icon4}/>
                        </p>}
                    </div>
                </div>
                <div className='box-content2'>
                    <p>处方医师：{doctortext.docter_name}</p>
                    <p>科室：{doctortext.docter_department}</p>
                    <p>诊断 <span style={{marginLeft:'5px',color:'#ff0000',fontSize:'16px'}}>*</span></p>
                    {docterDiagnosis?
                        <p className='icd-have'>
                            <p>{docterDiagnosis}</p>
                            <p><img src={icon2} onClick={()=>this.toIcd()}/></p>
                        </p>:
                        <p className='icd-no'>
                            <img src={icon2} onClick={()=>this.toIcd()}/>
                        </p>}
                    {/*<textarea name="" id="" cols={30} rows={10} maxLength={300} placeholder='最多输入300字' value={docterDiagnosis} onChange={(e) => this.changeuserinfo(e, 4)}></textarea>*/}
                    {visible?<div className='add-icd'>
                            {/*// <p className='icd-details-one' onClick={(e)=>this.setIcd(e,item)}>*/}
                            {/*//     <span style={{marginRight:'20px'}}>{item.icdValue}</span>*/}
                            {/*//     <span>{item.icdName}</span>*/}
                            {/*// </p>*/}
                            <div className='icd-top'>
                                <img src={icon1}  onClick={() => {
                                    this.setState({
                                        visible: false,
                                    })
                                }}/>
                                <span>诊断</span>
                                <span></span>
                            </div>
                            <ul className='icd-details icd-details1'>
                                {icdList.length ? icdList.map(item => (
                                    <li className='icd-details-one' onClick={(e) => {
                                        e.stopPropagation()
                                    }}>
                                        <span>{item.icdValue} &nbsp; {item.icdName}</span><input onClick={(e) => this.setIcd(e, item)}
                                                                                                 type='checkbox' checked={item.state==2}
                                                                                                 name='icd'/><br/>
                                    </li>)):null}
                            </ul>
                            <div className='icd-button'>
                                <img src={icon5} onClick={() => this.onSure()}/>
                            </div>
                    </div>:null}
                    <p>医生建议</p>
                    <textarea name="" id="" cols={30} rows={10} maxLength={300} placeholder='最多输入300字' value={docterProposal} onChange={(e) => this.changeuserinfo(e, 5)}></textarea>
                </div>
                <div className='box-content3'>
                    <p className='recipe-type'>
                        <h1>处方类型</h1>
                        <p className='recipe-type-right'>
                            <span className='recipe-type-outer'>
                                <input type="radio" name="recipeType" value="2" checked={recipeType==2?1:0} onChange={e=>this.setvalue(e, "recipeType")} />
                                <span>西药</span>
                            </span>
                            <span className='recipe-type-outer'>
                                <input type="radio" name="recipeType" value="3" checked={recipeType==3?1:0} onChange={e=>this.setvalue(e, "recipeType")} />
                                <span>成药</span>
                            </span>
                            <span className='recipe-type-outer'>
                                <input type="radio" name="recipeType" value="1" checked={recipeType==1?1:0} onChange={e=>this.setvalue(e, "recipeType")} />
                                <span>中药</span>
                            </span>
                        </p>
                    </p>
                    <h1>处方药清单</h1>
                    {somegoods ? somegoods.map(item => {
                        return (
                            <div className='content3-text' key={item.med_id}>
                                <div className='text-name'>
                                    <p>【品牌】{item.med_name}</p>
                                    {this.state.item.order_number?<p>x{item.med_quantity}</p>:item.medicinalQuantity?<p>x{item.medicinalQuantity}</p>:<p>x1</p>}
                                </div>
                                <h2>规格：{item.med_spec}</h2>
                                <h2>口服：{item.med_dosage_form}</h2>
                                <h2>厂家：{item.med_company}</h2>
                                {item.medicinalQuantity?<div className='write-content'>
                                    <p>数量：{item.medicinalQuantity}</p>
                                    <p>用药频率：{item.medicinalFrequency}</p>
                                    <p>单次用量：{item.medicinalUsage}</p>
                                    <p>用药天数：{item.medicinalDay}天</p>
                                    <p className='ff'>用药方法：{item.medicinalMethod}</p>
                                    {item.medicinalSupplementary?<p className='ff'>补充说明：{item.medicinalSupplementary}</p>:null}
                                </div>:null}
                                <div className='text-btn'>
                                    {item.order_number?null:<button onClick={() => this.deleteone(item)}>删除</button>}
                                    <button onClick={() => this.props.history.push({
                                        pathname: "/prescribing/editprescription/editnum",
                                        params: {
                                            item
                                        }
                                    })}>编辑用量</button>
                                </div>
                            </div>
                        )
                    }) : null}
                    {/* <div className='content3-text'>
                        <div className='text-name'>
                            <p>【品牌】阿司匹林胶囊</p>
                            <p>x1</p>
                        </div>
                        <h2>规格：80mg*7粒/盒</h2>
                        <h2>口服：成人一次xxxxx每天xxxx</h2>
                        <div className='text-btn'>
                            <button>删除</button><button onClick={() => this.props.history.push("/prescribing/editprescription/editnum")}>编辑用量</button>
                        </div>
                    </div> */}
                    {item.order_number?null:<>
                    {somegoods ? somegoods.length < 5 ? <div className='content3-bottom'>
                        <button onClick={() => this.props.history.push("/prescribing/addcf/addsearch")}>新增药品</button>
                    </div> : null:<div className='content3-bottom'>
                        <button onClick={() => this.props.history.push("/prescribing/addcf/addsearch")}>新增药品</button>
                    </div>}
                </>}

                </div>
                <div className='box-btn'>
                    {tip?<button onClick={this.submit}>发送处方</button>:
                    <button style={{backgroundColor:'#aaa'}} onClick={this.nosubmit}>发送处方</button>}
                </div>
            </div>
        )
    }
}
