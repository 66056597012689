import { axiosInstance } from '../config'
//查询所有订单及药品
export const allOrders = (token,docterId) => {
    return axiosInstance.get(`/faas/hosp/offline-pharmacy/allorder-meds?token=${token}&docterId=${docterId}`)
}
//查询所有线上订单  unix_timestamp(now())-unix_timestamp([result receptionTime])
export const allReorder = () => {
    return axiosInstance.get(`/api/hosp/retirement/all-reorder`)
}
//医生判断并接诊
export const sureReceive = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/sure-receive`,data)
}
//线上医生判断并接诊	/api/hosp/offline-pharmacy/sure-receive1
export const sureReceive01 = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/sure-receive1`,data)
}
//查询线下药店问诊单选择的药品信息
export const allmedsNews = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/allmeds-news`,data)
}
//查询药品基本信息和用药信息
export const allIllsnews = (data) => {
    return axiosInstance.post(`/faas/hosp/offline-pharmacy/all-illsnews`,data)
}
//统计线下药房医生开方总数和开方成功总数
export const offdocAllnum = (docterId) => {
    return axiosInstance.get(`/api/hosp/offline-pharmacy/offdoc-allnum?docterId=${docterId}`)
}
//保存openid
export const addOpenid = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/add-openid`,data)
}
//聊天信息处方单整合
export const docLine = (data) => {
    return axiosInstance.post(`/faas/hosp/offline-pharmacy/doc-line`,data)
}
//查询药品对应的所有疾病信息
export const getAllills = (data) => {
    return axiosInstance.post(`/faas/hosp/wzpatient/get-all-ills`,data)
}
//查询医生ca认证密码是否保存
export const isCapwd = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/is_capwd`,data)
}
//查询问诊单是否有处方单药品和待提交处方单药品相同
export const getSamemeds = (data) => {
    return axiosInstance.post(`/faas/hosp/offline-pharmacy/get-samemeds`,data)
}
//查询医生对应时间段的处方单价格
export const prescribingPrice = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/prescribing-price`,data)
}
//单量统计
export const statisticsAll = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/statistics-all`,data)
}
//医生取消记住密码
export const removeCapwd = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/remove-ca-pwd`,data)
}
//医生修改电子处方
export const docUpdate = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/doc-update`,data)
}
//处方签章
export const prescriptionSignature = (data) => {
    return axiosInstance.post(`/faas/hosp/doctor-prescription/prescription-signature`,data)
}
//保存视频问诊视频时长
export const savevideoTime = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/save-video-time`,data)
}
//查询视频消息
export const addvideoMessage = (data) => {
    return axiosInstance.post(`/api/hosp/doctor-prescription/add-video-message`,data)
}
//查询医生接诊规则和所有绑定医生信息
export const docBind = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/doc-bind`,data)
}
//医生设置接诊时间段
export const setContime = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/set-contime`,data)
}
//查询当前时间所有可接诊医生
export const nowtimeDoc = () => {
    return axiosInstance.get(`/api/hosp/offline-pharmacy/nowtime-doc`)
}
//医生提交处方功能整合
export const submitPrescribing = (data) => {
    return axiosInstance.post(`/faas/hosp/wzdocter/submit-prescribing`,data)
}
//查询医生是否有待开方的问诊
export const isHavewz = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/is-havewz`,data)
}
//审⽅API
export const prescriptionValidation = (data) => {
    return axiosInstance.post('/faas/hosp/wzdocter/prescription-validation', data)
}
//查询订单最新推送医生id
export const doctorNewid = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/doctor-new-id', data)
}
//当天驳回处方查看
export const allRejectPrescription = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/all-reject-prescription', data)
}

//查询医生的未开方问诊单
export const isSquare = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/is-square`,data)
}

//查询用法用量chineseMethod
export const chineseMethod = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/chinese-method', data)
}


//中药处方签章
export const prescriptionSignatureCha = (data) => {
    return axiosInstance.post(`/faas/hosp/doctor-prescription/prescription-signature-cha`,data)
}


// 查询医生ca认证到期是否给出提示
export const caIsNotify = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/select-cais-notify`,data)
}

// 查询接诊未开方
export const noSquare = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/no-square`,data)
}

// 更新是否点击开方按钮isSquareroot
export const isSquareroot = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/is_squareroot`,data)
}

// 更新到期未认证我已知晓状态dealyKnown
export const becomeDue = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/become-due`,data)
}

// 查询医生是否知晓ca到期未认证selectBecomeDue
export const selectBecomeDue = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/select-become-due`,data)
}

// 修改医生是否知晓ca到期未认证selectBecomeDues
export const selectBecomeDues = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/update-become-due`,data)
}

// 查询店铺所属权限 selectFiltering
export const selectFiltering = (data) => {
    return axiosInstance.post(`/api/hosp/store-permissions/select-filtering`,data)
}


// 查询是否是九十秒被退回的问诊单
export const selectIsConRepush = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/select-is-con-repush`,data)
}

// 更新医生开方按钮状态
export const updateSquareRoot = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/update-is-squareroot`,data)
}

// 查询是否药店药师驳回
export const selectIsPhaReject = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/select-is-pha-reject`,data)
}

// 查询医生详细信息
export const docterDetail = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/select-docter-detail`,data)
}


// 更新问诊单医生对应处方价格 
export const updatePrice = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/update-doc-price`,data)
}

// 记录系统给医生推荐用法用量	/faas/hosp/wzdocter/record-usage
export const recordUsage = (data) => {
    return axiosInstance.post(`/faas/hosp/wzdocter/record-usage`,data)
}

//患者获取问诊信息
export const patientWz = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/patient-wz', data)
}


// 人工开方ai审方
export const docAiReviewer = (data) => {
    return axiosInstance.post('/faas/hosp/ai-reviewer/doc-ai-reviewer', data)
}
