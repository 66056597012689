import React, { Component } from 'react'
import "./style.less"
export default class index extends Component {
    constructor(){
        super()
        this.state={
            hislist:[],
            hotlist:[],
            type:""
        }
    }
    componentDidMount=()=>{
        this.setState({
            hislist:this.props.hislist,
            hotlist:this.props.hotlist,
            type:this.props.type,
        })
    }
    getvalue=(item)=>{
        // console.log(item);
    }
    render() {
        const {hislist,hotlist,type} =this.state
        return (
            <div>
                {type=="history"?<div className='searchhis' onClick={()=>this.getvalue(111)}>
                    {hislist.map((item,index)=>{
                        return(
                            <span key={index} onClick={()=>this.getvalue(item)}>{item}</span>
                        )
                    })}
                </div>:null}
                {type=="hot"?<div className='searchhis'>
                    {hotlist.map((item,index)=>{
                        return(
                            <span key={index} onClick={()=>this.props.onclick(item)}>{item}</span>
                        )
                    })}
                </div>:null}
            </div>
        )
    }
}
