import React, { Component } from 'react'
import {NavLink} from "react-router-dom"
import '../../static/css/iconfont.css'
import { LeftOutline } from 'antd-mobile-icons'
import icon1 from "../../static/img/icon/user.png";
import './style.less'
import Nodata from "../Nodata";
import { Button } from 'antd-mobile';
import { Modal, Space } from 'antd-mobile-v5';
export default function Icdlistsnews(props) {
    const { icdlists,delIcd,onSearch,changeState,visible5,icdList,closev5,setIcd,changeuserinfo,onscroll} = props

    return(
        <div>
            <p className='offaddcf-ill-tipszd'>
                <p className='offaddcf-ill-tipszd-top'>
                    <span className='offaddcf-zd-title'>疾病诊断：</span>
                    <span className='offaddcf-zd-add'
                          onClick={() => onSearch()}>
                            <i className='iconfont icon-jia2'/>
                            <span>添加疾病</span>
                    </span>
                </p>
                <p className='offaddcf-ill-zdills'>
                    {icdlists.length ? icdlists.map(i => (<p className='offaddcf-ill-details'>
                            <input type='checkbox' name='icd' checked={i.state == 1}
                                   onChange={() => {changeState(i)}}/>
                            <select>
                                <option>{i.icd_name ? i.icd_name : i.icdName}</option>
                            </select>
                            <i className='iconfont icon-shanchu1' onClick={(e) => delIcd(e, i)}/>
                        </p>)) :
                        <p className='offaddcf-ill-detailsadd'
                           onClick={() => onSearch()}>请医生添加诊断</p>}
                </p>
            </p>
            {visible5 ? <div className='add-icdff' onClick={(e) =>closev5(e) }>
                <div className='register-headoff' onClick={(e) => {
                    e.stopPropagation()
                }}>
                    <p onClick={(e) =>closev5(e) } className='return-logo'>
                        <LeftOutline style={{ height:'16px' }} />
                        <span className='return-span'>返回</span>
                    </p>
                    <i className='iconfont icon-sousuo'></i>
                    <input type="text" placeholder='搜索icd编码、icd名称' onChange={(e) => changeuserinfo(e, 4)}/>
                    <span className='sousuo-span' onClick={() => onSearch()}>搜索</span>
                </div>
                <ul className='icd-details' onScroll={onscroll}>
                    {icdList.length ? icdList.map(item => (
                        <li className='icd-details-one' onClick={(e) => {
                            e.stopPropagation()
                        }}>
                            <span onClick={(e) => setIcd(e, item)}>{item.icdValue} &nbsp; {item.icdName}</span><br/>
                        </li>
                    )) : <Nodata type='病历'/>}
                </ul>
            </div> : null}
        </div>
    )
}