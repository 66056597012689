import React, { Component } from 'react';
import "./style.less"
import icon1 from "../../static/img/doctor/tips.png"
import icon2 from "../../static/img/doctor/yhcx.png"
import icon3 from "../../static/img/head.png"
import FootNav from '../../component/FootNav'
import { getnewslist,delWznews ,docnewslist} from "../../api/hospitalapi/news"
import {
  caIsNotify
} from "../../api/hospitalapi/offline";
import { Toast,SpinLoading } from 'antd-mobile-v5';
import { Modal,  } from 'antd-mobile';
import {dealParams} from "../../api/config";
import {caAuthentication,caPasstime} from "../../api/hospitalapi/upload";
import { getuserinfo } from "../../api/hospitalapi/login";
import getUrl from "../../component/GetUrlParam";
import moment from "moment";
import Nodata from "../../component/Nodata";
import {caUrl,baseUrl,jumpUrl} from '../../publicurl'
const { alert } = Modal
export default class index extends Component {
  constructor() {
    super()
    this.state = {
      docterId: localStorage.getItem("docterId"),
      newslist: '',//进行中
      newslist1: '',//已结束
      newslist2: '',//驳回
      timer: localStorage.getItem("timer"),
      caPassTime: localStorage.getItem("caPassTime"),
      newsstate: 1,
      page: 1,
      size: 20,
      visible11:false,
    }
  }

  componentDidMount = async () => {
    const {docterId, timer, caPassTime} = this.state
    window.clearTimeout(timer)

    if (getUrl('ca', this.props.location.search) && docterId) {
      let res = await caPasstime(docterId)
      if (res && res.data && res.data[0]) {
        let timeca = moment(res.data[0][0].caPassTime).add(1, 'year').diff(moment(), 'day')
        localStorage.setItem("caPassTime", timeca)
        this.setState({
          caPassTime: timeca
        })
      }
    }
    if (docterId) {
      if (getUrl('newsstate', this.props.location.search)) {
        this.setState({
          newsstate: getUrl('newsstate', this.props.location.search)
        })
      }

      // 查询医生ca认证到期是否给出提示
      let res10 = await caIsNotify({
        docterId:docterId
      });
      if (
        res10.data[0][0].is_notify == 1 &&
        caPassTime &&
        caPassTime <= 0
      ) {
        this.setState({
          visible11:true,
        })  
      }

      // if (caPassTime && caPassTime <= 0) {
      //   alert('身份信息更新', '身份认证有限期已到期，请及时更新', [
      //     {
      //       text: '忘记密码', onPress: () => {
      //         let data0 = {
      //           entityId: docterId,
      //           busiType: '5',
      //           redirectUrl: baseUrl + '/faas/hosp/scca/callback',
      //           redirectPageUrl: jumpUrl + `/index.html#/news`,
      //           province: '四川省',
      //           locality: '德阳市',
      //           orgName: '云益健互联网医院'
      //         }
      //         let {toSign, params} = dealParams(data0)
      //         console.log(toSign, params)
      //         caAuthentication({url: caUrl, toSign, FormData: params}).then(data => {
      //           if (data && data.data && data.data.success == false) {
      //             Toast.info(data.data.result_msg)
      //           } else if (data && data.data) {
      //             window.location.href = (data.data.body)
      //           }
      //         });
      //       }
      //     },
      //     {
      //       text: '确认', onPress: async () => {
      //         let data0 = {
      //           entityId: docterId,
      //           busiType: '3',
      //           redirectUrl: baseUrl + '/faas/hosp/scca/callback',
      //           redirectPageUrl: jumpUrl + `/index.html#/news?ca=${1}`,
      //           province: '四川省',
      //           locality: '德阳市',
      //           orgName: '云益健互联网医院'
      //         }
      //         let {toSign, params} = dealParams(data0)
      //         console.log(toSign, params)
      //         caAuthentication({url: caUrl, toSign, FormData: params}).then(data => {
      //           if (data && data.data && data.data.success == false) {
      //             Toast.info(data.data.result_msg)
      //           } else if (data && data.data) {
      //             window.location.href = (data.data.body)
      //           }
      //         });
      //       }
      //     }
      //   ])
      // }


      this.wzNews()
    } else {
      Toast.show({
        content: "当前未登录，请先登录",
        duration: 1000
      })
      this.props.history.replace("/")
      this.props.history.push("/login")
    }

  }

  wzNews = async () => {
    const {docterId,size} = this.state
    if (docterId) {
      const res = await docnewslist({
        docterId,
        page: 1,
        size: size,
      })
      var arr = [];
      var arr1 = [];
      var arr2 = [];
      var a = [];

      if (res) {
        let temp2 = res.data[0]
        // temp2.map((item, i) => {
        //   // if (item.consultation_type=='1'){
        //   if (!item.order_number && item.consultation_stu != '-2' && item.consultation_stu != '4') {
        //     a.push(item);
        //   }
        //   // }
        // })
        //

        // if (a.length) {
        //   a.map(item => {
        //     if (item.isDelete == 1) {
        //       if (item.consultation_stu == '0') {
        //         arr.push(item)
        //       } else {
        //         arr1.push(item)
        //       }
        //       if (item.consultation_isupload_false) {
        //         arr2.push(item)
        //       }
        //     }
        //   })
        //   console.log(arr);
          this.setState({
            newslist: res.data[0],
            newslist1: res.data[1],
            newslist2: res.data[2],
          })
        // } else {
        //   this.setState({
        //     newslist: [],
        //     newslist1: [],
        //     newslist2: [],
        //   })
        // }

      }
    }
  }

  goinfo(item) {
    const {newsstate} = this.state
    localStorage.setItem("item", JSON.stringify(item))
    if (item.consultation_stu != '0') {
      this.props.history.replace(`/news?newsstate=${newsstate}`)
    } else {
      this.props.history.replace(`/news?newsstate=${newsstate}`)
    }
    if (item.order_form_type == 1 || item.order_form_type == 3) {
      this.props.history.push({
        pathname: "/chatdoctor",
        params: {
          consultationid: item.consultation_id,
          userpic: item.patient_avatar,
          username: item.patient_name,
          userId: item.user_id
        }
      })
    } else {
      this.props.history.push('/offlineDoctorchat')
    }

  }

  //删除消息
  delete = (e, consultationId, consultationStu) => {
    e.stopPropagation()
    const {newslist} = this.state
    if (consultationStu == '0') {
      Toast.show({
        content: "当前问诊单还未结束，不能删除",
        duration: 1000
      })
    } else {
      alert('提示弹窗', '确认删除该消息？', [
        {text: '取消', onPress: () => console.log('cancel'), style: 'default'},
        {
          text: '确定', onPress: () => {
            // 删除问诊消息
            delWznews(consultationId).then(res => {
              let arr = []
              if (res.code == 1) {
                const newTimer = window.setTimeout(() => {
                  this.wzNews()
                  window.clearTimeout(newTimer)
                }, 50);
                newslist.map(item => {
                  if (item.isDelete == 1) {
                    arr.push(item)
                  }
                })
                this.setState({
                  newslist: arr
                })
              }
            })
          }
        },
      ],)
    }
  }
  // 触底更新数据
  onscroll = e => {
    const { flag,} = this.state
    e.preventDefault()
    e.stopPropagation()
    if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <5) {
        this.pageBrowselist()
    }
  }
  // 触底时换页
  pageBrowselist = () => {
    const {size,page,docterId,newslist,newslist1,newslist2} = this.state
    this.setState({
      flag:true
    },()=> {
      const params = {
        size: size,
        page: page + 1,
        docterId
      }
      docnewslist(params).then(res => {
        if (res.code === 1) {
          var arr = newslist;
          var arr1 = newslist1;
          var arr2 = newslist2;
          // let temp2 = res.data[0]
          // temp2.map((item, i) => {
          //   // if (item.consultation_type=='1'){
          //   if (!item.order_number && item.consultation_stu != '-2' && item.consultation_stu != '4') {
          //     a.push(item);
          //   }
          //   // }
          // })
          //
          // if (a.length) {
          //   a.map(item => {
          //     if (item.isDelete == 1) {
          //       if (item.consultation_stu == '0') {
          //         arr.push(item)
          //       } else {
          //         arr1.push(item)
          //       }
          //       if (item.consultation_isupload_false) {
          //         arr2.push(item)
          //       }
          //     }
          //   })
          //   console.log(arr);
            this.setState({
              newslist: [...arr,...res.data[0]],
              newslist1: [...arr1,...res.data[1]],
              newslist2: [...arr2,...res.data[2]],
              page: params.page
            })
          // }
          // this.setState({
          //   page: params.page
          // })
        }
      })
    })
  }

  // 去人脸认证
  sureBtn=async()=>{
    const {docterPhone,docterId,count}=this.state
    const res = await getuserinfo({
      docterPhone,
    });
    this.setState({
      visible11:false,
    })

    let data0 = {
      entityId: docterId,
      busiType: '3',
      redirectUrl: baseUrl + '/faas/hosp/scca/callback',
      redirectPageUrl: jumpUrl + `/index.html#/news?ca=${1}`,
      province: '四川省',
      locality: '德阳市',
      orgName: '云益健互联网医院'
    }
    let {toSign, params} = dealParams(data0)
    caAuthentication({url: caUrl, toSign, FormData: params}).then(data => {
      if (data && data.data && data.data.success == false) {
        Toast.show(data.data.result_msg)
      } else if (data && data.data) {
        window.location.href = (data.data.body)
      }
    });
  }



  render() {
    const {newslist, newsstate, newslist1, newslist2,visible11} = this.state

    return (
        <div className='newsbox'>
          {visible11 && (
            <>
              <div className='tipbox'>
                <div className='alertbox'>
                    <div className='top-box'>身份信息更新提示</div>
                    {/* <p className='tipmsg'>实名认证已到期</p> */}
                    {/* <p className='tipmsg'>请进行人脸认证后使用</p> */}
                    <p className='tipmsg'>您的实名认证已到期，</p>
                    <p className='tipmsg'>请您尽快前往重新进行人脸认证</p>
                    <p onClick={()=> {
                      this.sureBtn()
                    }} className='gourl-two'>去人脸认证</p>
                </div>
              </div>
            </>
          )}

          <div className='box-top'>
            消息
          </div>
          <div className='news-inend'>
            <p className={newsstate == 1 ? 'news-top-left' : 'news-top-left1'}
               onClick={() => this.setState({newsstate: 1},()=>{ this.wzNews()})}>进行中</p>
            <p className={newsstate == 3 ? 'news-top-center' : 'news-top-center1'}
               onClick={() => this.setState({newsstate: 3},()=>{ this.wzNews()})}>被驳回</p>
            <p className={newsstate == 2 ? 'news-top-right' : 'news-top-right1'}
               onClick={() => this.setState({newsstate: 2},()=>{ this.wzNews()})}>已结束</p>
          </div>
          {newslist ? <>
            {newsstate == 1 ? <div className='box-container' onScroll={(e) => this.onscroll(e)}>
                  {newslist.length ? newslist.map((item, index) => {
                    return (
                        <div className='container-item' onClick={() => this.goinfo(item)}>
                          <img src={item.patient_avatar ? item.patient_avatar : icon3} alt=""/>
                          <div className='news-detail'>
                            <p className='user-name'>
                              <span className='user-name-left'>{item.patientName}</span>
                              <span className='user-name-right'>
                                  {item.original_prescribing ? <span
                                          className={!item.consultation_isupload_false && item.consultation_uploadcf ? 'nowchat' : item.consultation_isupload_false ? 'endchat2' : ''}>{!item.consultation_isupload_false && item.consultation_uploadcf ? '已开方' : item.consultation_isupload_false ? '驳回处方' : ''}</span>
                                      : item.consultation_uploadcf == 1 ?
                                          <span className='user-name-right00'>处方未输入密码</span> : null}
                                <span>{item.content_time}</span>
                                </span>
                            </p>
                            <p className='news-onemore'>
                              <p className='news-onemore-left'>
                                {item.detail_type == '3' ? '处方单' :
                                    item.detail_type == '2' ? '[图片]' : item.detail_content}
                              </p>
                              {item.consultation_type == '1' ? <p className='wz-type wz-type1'>图文问诊</p> :
                                  <p className='wz-type wz-type2'>视频问诊</p>}
                              {/*<p className='delete'*/}
                              {/*   onClick={(e) => this.delete(e, item.consultation_id, item.consultation_stu)}>删除</p>*/}
                            </p>
                          </div>
                        </div>
                    )
                  }) : <Nodata type='消息'/>}

                </div> :
                newsstate == 2 ? <div className='box-container' onScroll={(e) => this.onscroll(e)}>
                      {newslist1.length ? newslist1.map((item, index) => {
                        return (
                            <div className='container-item' onClick={() => this.goinfo(item)}>
                              <img src={item.patient_avatar ? item.patient_avatar : icon3} alt=""/>
                              <div className='news-detail'>
                                <p className='user-name'>
                                  <span className='user-name-left'>{item.patientName}</span>
                                  <span className='user-name-right'>
                    {item.original_prescribing ? <span
                            className={!item.consultation_isupload_false && item.consultation_uploadcf ? 'nowchat' : item.consultation_isupload_false ? 'endchat2' : ''}>{!item.consultation_isupload_false && item.consultation_uploadcf ? '已开方' : item.consultation_isupload_false ? '驳回处方' : ''}</span>
                        : item.consultation_uploadcf == 1 ? <span className='user-name-right00'>处方未输入密码</span> : null}
                                    <span>{item.content_time}</span>
                  </span>
                                </p>
                                <p className='news-onemore'>
                                  <p className='news-onemore-left'>
                                    {item.detail_type == '3' ? '处方单' :
                                        item.detail_type == '2' ? '[图片]' : item.detail_content}
                                  </p>
                                  {/*{item.consultation_type=='1'?<p className='wz-type wz-type1'>图文问诊</p>:<p className='wz-type wz-type2'>视频问诊</p>}*/}
                                  <p className='delete'
                                     onClick={(e) => this.delete(e, item.consultation_id, item.consultation_stu)}>删除</p>
                                </p>
                              </div>
                            </div>
                        )
                      }) : <Nodata type='消息'/>}

                    </div> :
                    <div className='box-container' onScroll={(e) => this.onscroll(e)}>
                      {newslist2.length ? newslist2.map((item, index) => {
                        return (
                            <div className='container-item' onClick={() => this.goinfo(item)}>
                              <img src={item.patient_avatar ? item.patient_avatar : icon3} alt=""/>
                              <div className='news-detail'>
                                <p className='user-name'>
                                  <span className='user-name-left'>{item.patientName}</span>
                                  <span className='user-name-right'>

                                    <span className='endchat2'>驳回</span>
                                    <span>{item.content_time}</span>
                                  </span>
                                </p>
                                <p className='news-onemore'>
                                  <p className='news-onemore-left' style={{color: '#FF1D1D'}}>
                                    处方审核失败：{item.reason}
                                  </p>
                                  {item.consultation_type == '1' ? <p className='wz-type wz-type1'>图文问诊</p> :
                                      <p className='wz-type wz-type2'>视频问诊</p>}
                                </p>
                              </div>
                            </div>
                        )
                      }) : <Nodata type='消息'/>}

                    </div>}
          </> : <p className='spin-load'>
            <SpinLoading color='primary' style={{'--size': '48px'}}/>
          </p>}

          <FootNav></FootNav>
        </div>
    )
  }
}
