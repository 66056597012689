import React, { Component } from 'react'
import "./style.less"
import { List, InputItem, Toast } from 'antd-mobile'
import FH from "../../../../static/img/mine/fanhui.png"
import wx from "../../../../static/img/mine/wx.png"
import rmb from "../../../../static/img/mine/RMB.png"

import {addWallet, walletWithdrawal,walletBalance,walletList} from '../../../../api/request/wallet'

export default class index extends Component {
    constructor(props){
        super(props);
        this.state={
            userId: localStorage.type == 1 ? localStorage.userId1 : localStorage.type == 2 ? localStorage.userId2 : localStorage.userId3,
            withdrawalAmount:'',
            balance:''
        }
    }
    componentWillMount() {
        let params={
            userId:this.state.userId
        }
        // console.log(this.state.userId);
        walletBalance(params).then((res)=>{
            this.setState({
                balance:res.data[0].length?res.data[0][0].Balance/100:0
            })
        })
    }
    handleChange=(val)=>{
        this.setState({
			withdrawalAmount:val
		})
    }
    wallet = () => {
        if (this.state.withdrawalAmount>this.state.balance) {
            Toast.info('余额不足')
            return ;
        }
        // if (this.state.withdrawalAmount<20) {
        //     Toast.info('最少提现20')
        //     return ;
        // }
        let params={
            userId:this.state.userId,
            withdrawalAmount:this.state.withdrawalAmount*100,
            Tag:'提现',
            Comments:'提现到微信'
        }
        walletWithdrawal(params).then((res)=>{
            // window.location.reload()
            // console.log(res);
            Toast.info('预计两个小时到账')
            
        })
    }
    goTXMX=()=>{
        this.props.history.push("/mine/storemoney/Withdrawal/txDetail")  
    }
    render() {
        const {withdrawalAmount,balance} = this.state
        return (
            <div style={{width:"100%",height:"100vh",boxSizing:"border-box",paddingLeft:"20px",paddingRight:"20px",backgroundColor:"#FFFFFF"}}>
               <div className="TX-top">
                    <img src={FH} alt="" onClick={()=>window.history.back()}/><h2>开票信息</h2>
                </div>
                <div className="TX-wx">
                    <p>提现至</p><p><img src={wx} alt="" /> ＞</p>
                </div>
                <div className="TX-money">
                    <p>到账金额</p>
                    <div>
                        <img src={rmb} alt="" />
                        <InputItem name={withdrawalAmount} onChange={v=>this.handleChange(v)}/>
                    </div>
                </div>
                <p className="yue">
                    <span>可提现余额 ￥{balance?balance:0}</span>
                    <span onClick={this.goTXMX}>提现明细   ＞ </span>
                    </p>
                <div className="TX-btn">
                    <button onClick={this.wallet}>确定</button>
                </div>
            </div>
        )
    }
}
