import React, { Component } from "react";
import html2canvas from "html2canvas";
import { SpinLoading ,} from "antd-mobile-v5"
// import RNFS from 'react-native-fs';
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/pt.png"
import icon3 from "../../../static/img/yy/zj-zhang.png"
import { Modal, Button,Toast } from "antd-mobile";
import { uploadImg } from '../../../api/request/upload'
import { zipImg,zipPdf } from '../../../component/zipImage/index'
import "./style.less";
import getUrl from '../../../component/GetUrlParam'
import {allMedicinal, prescribingDetail, getConsultationType, addnews,isChineseMeds,chineseMethod} from "../../../api/hospitalapi/news";
import {oldPrescribing} from "../../../api/hospitalapi/mine";
import {getSignature,caWrite} from "../../../api/hospitalapi/home";
import {allOrders, isCapwd, prescriptionSignature,prescriptionSignatureCha,docAiReviewer} from "../../../api/hospitalapi/offline";
import jsPDF from "jspdf";
import {
    imgToCanvas,
    dealParams,
    getBase64,
    dealImageCompression
} from '../../../api/config';
import {
    postCaPdf,postCa
} from '../../../api/ca';
import {caAuthentication} from "../../../api/hospitalapi/upload";
import {caUrl,baseUrl,jumpUrl} from '../../../publicurl'
const { alert } = Modal
export default class HtmlCrop extends Component {
    constructor() {
        super()
        this.state = {
            visible1: false,
            visible2: false,
            list: [],
            detail: {},
            ill: {},
            gqTime: '',
            prescribingId: '',
            year: '',
            month: '',
            day: '',
            docterId: localStorage.getItem("docterId"),
            item: localStorage.getItem("item")?JSON.parse(localStorage.getItem("item")):'',
            docterAutograph: '',
            pdffile: '',
            fileId: '',
            flog: 1,
            refresh: true,
            password: '',
            passwordcode: 1,
            consultationType: '',
            timer: '',
            ca_id:0,
            type:'',
            offcode:'',
            knowcode:false,
            prescription:'',
            ischinese:[],
            chinesemethod:[],//用法用量
            isClickable:true,
        }
    }


    componentDidMount = async () => {
        const {docterId} = this.state
        if (!localStorage.getItem('castate')) {
            localStorage.setItem('castate', 1)
            window.location.reload()
        }
        // localStorage.removeItem('castate')
        if (getUrl('type', this.props.location.search)){
            this.setState({
                type:getUrl('type', this.props.location.search)
            })
        }


        if (getUrl('offcode', this.props.location.search)){
            this.setState({
                offcode:getUrl('offcode', this.props.location.search)
            })
        }
        this.setState({
            prescribingId: getUrl('prescribingId', this.props.location.search)
        })
        let res0=await isCapwd({docterId})
        // console.log(res0)
        if (res0&&res0.data&&res0.data[0]&&res0.data[0].length){
            if (res0.data[0][0].doc_ca_pwd){
                this.setState({
                    password:res0.data[0][0].doc_ca_pwd,
                    passwordcode:2
                })
            }
        }
        let res = await allMedicinal({prescribingId: getUrl('prescribingId', this.props.location.search)})
        // console.log(res);
        if (res && res.code == 1 && res.data[0]) {
            let res0 = await caWrite({docterId})
            // console.log(res0)
            if (res0 && res0.data && res0.data[0]) {
                getBase64(res0.data[0][0].docterAutograph, (value) => {
                    // console.log(value)
                    let data0 = value.split(',')[1]
                    this.setState({
                        docterAutograph: data0
                    })
                })
            }
            let res1 = await prescribingDetail({prescribingId: getUrl('prescribingId', this.props.location.search)})
            // console.log(res1);
            let res2 = await getConsultationType({id: res1.data[0][0].consultation_id})
            // console.log(res2)
            let time1 = new Date(res1.data[0][0].create_time).getTime()
            let res04 = await isChineseMeds({consultation_id: res1.data[0][0].consultation_id})
            let res05 = await chineseMethod({prescribing_id: getUrl('prescribingId', this.props.location.search)})
            this.setState({
                list: res.data[0],
                detail: res1.data[0][0],
                consultationType: res2.data[0][0].consultationType,
                ca_id:res1.data[2]&&res1.data[2].length&&res1.data[2][0].ca_id,
                gqTime: new Date(time1 + 259200000).toLocaleString(),
                year: res1.data[0][0].year,
                month: res1.data[0][0].month,
                day:res1.data[0][0].day,
                ischinese:res04.data[0],
                chinesemethod:res05.data[0]

            })
        }
        this.passwordSure();
    }

    //确定
    // click = () => {
    //     console.log(33333);
    //     const {passwordcode,isClickable}=this.state
    //     console.log('isClickable1',isClickable);
    //     if (passwordcode==1){
    //         this.setState({
    //             visible2: true,
    //         })
    //     }else{
    //         this.passwordSure();
    //         isClick=false
    //     } 
    // };
    click = () => {
        // console.log(33333);
        const { passwordcode, isClickable } = this.state;
        // console.log('isClickable1', isClickable,'passwordcode',passwordcode);
        
        if (isClickable) {
          if (passwordcode === 1) {
            this.setState({
              visible2: true,
              isClickable: false, // 设置为 false，禁止再次触发点击事件
            });
          } else {
            this.passwordSure();
            this.setState({
              isClickable: false, // 设置为 false，禁止再次触发点击事件
            });
          }
        }
      };

    passwordValue = (e) => {
        this.setState({
            password: e.target.value.replace(/\s*/g,"")
        })
    }
    //确认密码
    passwordSure = () => {
        const {docterId, prescribingId, item, password,knowcode,timer,consultationType,type,offcode,ischinese,list} = this.state
        if (!password){
            Toast.show( '请先输入密码')
        }else{
            this.setState({
                visible2: false,
                flog: 2
            }, async () => {
                let params={
                    prescribingId:prescribingId,
                    password:password,
                    consultationId:item.consultation_id,
                    docterId:docterId,
                    knowcode:knowcode?1:0
                }
                // console.log(ischinese.length,'171=====');
                
                    let res=await prescriptionSignature(params)
                    // console.log('处方签章',res.data.data[0].length)
                    // return
                    if (res&&res.data&&res.data.result_msg){
                        if (res.data.result_msg=='云证书获取失败[PIN校验失败]'){
                            this.setState({
                                flog: 1
                            }, () => {
                                this.failPassword()
                            })
                        }else{
                            this.setState({
                                flog: 1
                            }, () => {
                                Toast.show('签章失败，请重新签名后再进行审方')
                            })
                            
                        }
                    }else{

                        // console.log(consultationType,type,offcode)
                        // window.clearTimeout(timer)
                        if (consultationType == '1'||!consultationType) {
                            if (type){
                                this.props.history.replace("/news")
                                this.props.history.push(`/offlineDoctorchat?prescription=${1}`)
                            }else{
                                if (offcode){
                                    this.props.history.replace("/news")
                                    this.props.history.push(`/offlineDoctorchat?prescription=${1}`)
                                }else{
                                    this.props.history.push("/chatdoctor")
                                }
                            }

                        } else if (consultationType == '3') {
                            if (type||offcode){
                                this.props.history.replace("/news")
                                this.props.history.push(`/offlineDoctorchat?prescription=${1}`)
                            }else {
                                this.props.history.push("/videoprescription")
                            }
                        }

                        if(res&& res.data && res.data.data && res.data.data[0].length){
                            // 人工处方签章成功 执行 ai审方
                            let resAiRe = await docAiReviewer({
                                prescribingId:prescribingId,
                                consultationId:item.consultation_id,
                            })
                        }
                    }

            })
        }

    }

    dataURLtoFile = (dataurl, filename, mime) => {
        let arr = dataurl.split('e=generated.pdf;base64,')
        let bstr = atob(arr[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], `${filename}`, {
            type: mime
        })
    };
    /**
     * @description 签章
     * @params 文件签章
     */
    signaturePDF = () => {
        const {docterId, docterAutograph, pdffile, password,ca_id} = this.state
        let signParams = JSON.stringify([
            {
                llx: 70,
                lly: 110,
                urx: 120,
                ury: 140,
                pageList: [1],
                sealImg: docterAutograph
            },
        ]);
        let {toSign: toSign} = dealParams({
            userId: ca_id&&docterId!=ca_id?ca_id:docterId,
            configKey:  ca_id&&docterId!=ca_id?ca_id:docterId,
            signParams,
            cloudCertPass: password,
        });
        // console.log(docterAutograph, pdffile)
        if (docterAutograph && pdffile) {
            getSignature({toSign: toSign}).then((data) => {
                postCaPdf(
                    '/signature-server/api/open/signature/signPdf',
                    {
                        userId:  ca_id&&docterId!=ca_id?ca_id:docterId,
                        configKey:  ca_id&&docterId!=ca_id?ca_id:docterId,
                        signParams,
                        pdfFile: pdffile,
                        cloudCertPass: password,
                    },
                    data.data,
                ).then((value) => {
                    //"1585145531735109633"
                    // console.log(value.data)
                    if (value && value.data && value.data.body && value.data.body[0].fileId) {
                        this.setState({
                            fileId: value.data.body[0].fileId
                        }, () => {
                            this.downPDF(value.data.body[0].fileId)
                        })
                    } else {
                        this.setState({
                            flog:1
                        },()=>{
                            this.failPassword()
                        })
                    }
                });
            });
        }
    };
    //密码错误，ca验证失败
    failPassword = async () => {
        const {isRetail, docterId, prescribingId, phone, allmeds,ca_id} = this.state
        alert('密码错误', '忘记密码可修改密码', [
            {
                text: '忘记密码', onPress: () => {
                    let data0 = {
                        entityId:  ca_id&&docterId!=ca_id?ca_id:docterId,
                        busiType: '5',
                        redirectUrl: baseUrl+'/faas/hosp/scca/callback',
                        redirectPageUrl: jumpUrl+`/index.html#/prescribing/prescription?prescribingId=${prescribingId}&type=${1}`,
                        province: '四川省',
                        locality: '德阳市',
                        orgName: '云益健互联网医院'
                    }

                    let {toSign, params} = dealParams(data0)
                    // console.log(toSign, params)
                    caAuthentication({
                        url: caUrl,
                        toSign,
                        FormData: params
                    }).then(data => {
                        if (data && data.data && data.data.success == false) {
                            Toast.info(data.data.result_msg)
                        } else if (data && data.data) {
                            window.location.href=(data.data.body)
                        }
                    });
                }
            },
            {
                text: '重新输入', onPress: () => {
                    this.setState({
                        visible2: true,
                        flog: 1
                    })
                }
            },
        ])
    }
    /**
     * @description 下载文件
     */
    downPDF = (fileId) => {
        const {docterId, docterAutograph,ca_id} = this.state
        let params = {
            fileId: fileId,
            userId:  ca_id&&docterId!=ca_id?ca_id:docterId,
        };
        let {toSign: toSign, params: FormData} = dealParams(params);
        // console.log(toSign,FormData)
        // return;
        getSignature({toSign: toSign}).then((data) => {
            postCa('/signature-server/api/open/signature/fetchSignedFile', FormData, data.data).then((value) => {
                // let objectUrl = URL.createObjectURL(value.data);
                // let link = document.createElement('a');
                // link.download = '我的pdf文件.pdf';
                // link.href = objectUrl;
                // link.click();
                // link.remove();
                this.getDataPDF(value.data)
                // console.log(value.data)

            });
        });
    }
    getDataPDF = (value) => {
        const {prescribingId, fileId,item, consultationType,docterId, type,timer,offcode,knowcode,password} = this.state
        // console.log(value)

        const blob = new Blob([value], {
            type: 'application/pdf;chartset=UTF-8'
        })
        const fileReader = new FileReader();
        fileReader.readAsDataURL(blob);
        fileReader.onload = (e) => {
            base64toFile(e.target.result);
        };
        // readAsDataURL

        fileReader.onerror = () => {
        };
        let that = this

        function base64toFile(dataurl, filename = 'file') {
            let arr = dataurl.split(',');
            let mime = arr[0].match(/:(.*?);/)[1];
            let suffix = mime.split('/')[1];
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            let file = new File([u8arr], `${filename}.${suffix}`, {
                type: mime,
            });
            // console.log(file);
            if (file) {
                let formData = new FormData()
                formData.append('file', file)
                // console.log(formData)
                // return;
                uploadImg(formData).then(res1 => {
                    // console.log(res1)
                    if (res1 && res1.code === 2) {
                        oldPrescribing({
                            knowcode:knowcode?1:0,
                            docterId,
                            passwordca:password,
                            consultationId:item.consultation_id,
                            prescribingId,
                            fileId,
                            originalPrescribing: res1.data
                        }).then(res2 => {
                            // console.log(res2)
                            if (res2 && res2.code == 1) {
                                // window.clearTimeout(timer)
                                if (consultationType == '1') {
                                    // addnews({
                                    //     consultationId:item.consultation_id,
                                    //     Identity: 2,
                                    //     detailType: 1,
                                    //     detailContent:'医生已开具处方，等待药师审核'
                                    // }).then(res3=>{
                                    //     if (res3&&res3.code==1){
                                            if (type){
                                                that.props.history.replace("/news")
                                                that.props.history.push(`/offlineDoctorchat?prescription=${1}`)
                                            }else{
                                                if (offcode){
                                                    that.props.history.replace("/news")
                                                    that.props.history.push(`/offlineDoctorchat?prescription=${1}`)
                                                }else{
                                                    that.props.history.push("/chatdoctor")
                                                }
                                            }
                                    //     }
                                    // })

                                } else if (consultationType == '3') {
                                    that.props.history.push("/videoprescription")
                                }
                            }
                        })
                    }
                    // Toast.hide()
                }).catch(err => {
                    // Toast.hide()
                })
            }
        }
    };

    render() {
        const {detail, list, flog, year, month, day, visible2,knowcode,password,ischinese,chinesemethod} = this.state
        // console.log(detail)
        return (
            <Modal
                className="aa"
                visible={true}
            >
                {/*<div className='prescription-top'>*/}
                {/*    /!*<img src={icon1}/>*!/*/}
                {/*    <p>原始处方单</p>*/}
                {/*</div>*/}
                <div id='demo'>
                    {/* style={{backgroundColor:detail.patient_age<6?'#A6E499':"#fff"}}*/}
                    <div id="a" >
                        <div className='prescription-outer' >
                            {detail.patient_age<6?<p className='customstyle erke'>儿科</p>:null}
                            <h1 className='customstyle title'>中江云益健互联网医院</h1>
                            <p className='prescription-jian'>
                                <h1>{detail.patient_age<6?'儿科':''}处方笺</h1>
                                <img src={icon2}/>
                            </p>
                            <p className='customstyle prescription-number'>处方编号：{detail.prescribing_number}</p>
                            {/*<p className='prescription-line'></p>*/}
                            <p className='prescription-one'>
                                <p className='prescription-one-left'>
                                    <span className='customstyle'>科室:</span>
                                    <span className='customstyle'>{detail.docter_department}</span>
                                </p>
                                <p className='prescription-one-right'>
                                    <span className='customstyle'>开具日期:</span>
                                    <span className='customstyle linedown'>{year}</span>
                                    <span className='customstyle'>年</span>
                                    <span className='customstyle linedown'>{month}</span>
                                    <span className='customstyle'>月</span>
                                    <span className='customstyle linedown'>{day}</span>
                                    <span className='customstyle'>日</span>
                                </p>
                            </p>
                            <p className='prescription-one'>
                                <p className='prescription-one-left'>
                                    <span className='customstyle'>姓名:</span>
                                    <span className='customstyle'>{detail.patient_name}</span>
                                </p>
                                <p className='prescription-one-right2'>
                                    <span className='customstyle'>性别:</span>
                                    <span className='customstyle linedown2'>{detail.patient_sex=='1'?'男':'女'}</span>
                                    <span className='customstyle'>年龄:</span>
                                    <span className='customstyle linedown2'>{detail.patient_age} 岁</span>
                                </p>
                            </p>
                            <p className='prescription-one2'>
                                <span className='customstyle'>初步诊断：</span>
                                <span className='customstyle'>{detail.docter_diagnosis}</span>
                            </p>
                            <img className='zj-zhang' src={icon3}/>
                            {/*<p className='prescription-line'></p>*/}
                            <p className='prescription-RP'>RP</p>
                            <p className='all-meds'>
                                {ischinese.length ? (
                                    <>
                                        <p className="chinese-list">
                                            {list ? list.map((item, index) => (<p className='prescription-chameds'>
                                                <span className='prescription-chameds-news'>
                                                    <span className='prescription-chameds-left'>
                                                        <span>{item.med_com_name?item.med_com_name:item.medicinal_name}</span>
                                                        <span>{item.medicinal_quantity}g</span>
                                                    </span>
                                                </span> 
                                            </p>)) : null}
                                        </p>
                                        <p className="cha-methods">
                                            <p className="mtthod-tit">用法：</p>
                                            <p className="mtthod-con">
                                                {chinesemethod.filter(i => i.consultation_id == detail.consultation_id).map(i=>(
                                                    <span>
                                                        共{i.co_demand}剂，{i.meiday}{i.daily}剂，每剂分{i.each_dose}次使用，
                                                        每次{i.at_time}{i.unit}，{i.med_method}，
                                                        {i.med_time == 1
                                                        ? "饭前用药"
                                                        : i.med_time == 2
                                                        ? "饭后用药"
                                                        : i.med_time == 3
                                                        ? "睡前用药"
                                                        : i.med_time == 4
                                                        ? "晨起用药"
                                                        : ""}
                                                    </span>
                                                ))} 
                                            </p>
                                        </p>
                                        {list.length < 39 && <p className="kongbailine">(以下空白)</p>}
                                        
                                    </>
                                ) : (
                                    <>
                                        {list ? list.map((item, index) => (<p className='prescription-meds'>
                                            <p className='prescription-meds-news'>
                                                <p className='prescription-meds-left'>
                                                    <span className='customstyle'>{index + 1}、{item.med_com_name?item.med_com_name:item.medicinal_name}</span>
                                                    <span className='customstyle'>{item.medicinal_spec} x{item.medicinal_quantity}{item.packing_unit&&item.packing_unit!="null"?item.packing_unit:''}</span>
                                                </p>
                                            </p>
                                            <p className='customstyle prescription-meds-details'>用法用量：{item.medicinal_frequency},每次{item.medicinal_usage}, {item.medicinal_method}</p>
                                            {item.medicinal_supplementary ?
                                                <p className='customstyle prescription-meds-details'>补充说明：{item.medicinal_supplementary}</p> : null}
                                            {index + 1 == list.length&&index<4 ? <p className='xu-line'></p> : null}
                                            {index + 1 == list.length&&index<4 ? <p className='customstyle kongbai'>(以下空白)</p> : null}
                                        </p>)) : null}
                                    </>
                                )}
                                
                            </p>
                            {/*<p className='prescription-line'></p>*/}
                            <p className='prescription-doctor'>
                                <p className='prescription-doctor1'>
                                    <span className='customstyle'>医师:</span>
                                    <p></p>
                                </p>
                                <p className='prescription-doctor112'>
                                    <span className='customstyle'>医院药师:</span>
                                    <p></p>
                                </p>
                                <p className='prescription-doctor11'>
                                    <span className='customstyle'>药店药师审核:</span>
                                    <p></p>
                                </p>
                            </p>
                            <p className='prescription-doctor2'>
                                <p className='prescription-doctor3'>
                                    <span className='customstyle customstyle'>调配:</span>
                                    <p></p>
                                </p>
                                <p className='prescription-doctor33'>
                                    <span className='customstyle customstyle'>核对/发药:</span>
                                    <p></p>
                                </p>
                                <p className='prescription-doctor4'>
                                    <span className='customstyle customstyle'>金额:</span>
                                    <p></p>
                                </p>
                            </p>
                            <p className='prescription-tips'>
                                <span className='customstyle customstyle'>备注：</span>
                                <span className='customstyle customstyle'>{detail.docter_proposal}</span>
                            </p>
                            <p className='customstyle prescription-tips1'>1、医师处方仅开具当日有效（医生注明除外）。</p>
                            <p className='customstyle prescription-tips2'>2、处方超过7日用量为病情需要。</p>
                        </div>
                    </div>
                </div>

                {password ? null : !visible2 ? <p className='sure' onClick={this.click}>确定{password}</p> : null}

                {/* {!visible2?<p className='sure' onClick={this.click}>确定{password}</p>:null} */}
                {visible2 ?
                    <div className='bohui-outer'>
                        <div className='bohui'>
                            <p className='customstyle title'>请输入密码</p>
                            <p className='content'>
                                <span className='customstyle'>密码：</span>
                                <input className='customstyle' type='text' placeholder='请输入密码' maxLength={20}
                                      defaultValue={password} onChange={(e) => this.passwordValue(e)}/>
                            </p>
                            <p className='content-jizhu'>
                                <input type='radio' checked={knowcode} onClick={()=>this.setState({
                                    knowcode:!knowcode
                                })}/>
                                <span className='customstyle'>记住密码，下次无需输入密码</span>
                            </p>
                            <p className='button'>
                                <span className='customstyle' onClick={() => {
                                    this.setState({
                                        visible2: false,
                                        password: '',
                                        passwordcode:1,
                                        isClickable:true
                                    })
                                }}>取消</span>
                                <span className='customstyle' onClick={() => this.passwordSure()}>确认</span>
                            </p>
                        </div>
                    </div>
                    : null}
                {flog == 2 ? <p className='chat-center-outer'>
                    <SpinLoading/>
                    {/*<p>跳转中...</p>*/}
                </p> : null}
            </Modal>
        );
    }
}