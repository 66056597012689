import { axiosInstance } from '../config'

//消息列表
export const msglistRequest = (userId) => {
    return axiosInstance.get(`/api/hosp/news/list?userId=${userId}`)
}

//标记已读
export const updateMsgRequest = (data) => {
    return axiosInstance.post('/api/hosp/news/read', data)
}