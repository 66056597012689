import React ,{useState,useEffect}from 'react'
import { connect } from 'react-redux'

import FootNav from '../../component/FootNav'
import Wechatlist from '../../application/wechat/wechatitem'

import { healthrecommandRequest } from '../../api/request/health'
import {resetdirect} from '../../redux/user.redux'

import back1 from '../../static/img/health/back1.png'
import icon1 from '../../static/img/health/1.png'
import icon2 from '../../static/img/health/2.png'
import icon3 from '../../static/img/health/3.png'
import icon4 from '../../static/img/health/4.png'

import './style.less'

function Health(props) {
    // 标签页控制
    const [tabindex,settabindex]=useState(1)
    // 存用户id
    const [userId]=useState(localStorage.userId)
    const [topicList, setTopicList] = useState([])
    const [postList, setPostList] = useState([])
    const [sort]=useState(2)
    const [page]=useState(0)
    const [size]=useState(10)

    useEffect(() => {
        getHealth()
    }, [])

    // 获取推荐信息列表
    const getHealth = () => {
        const params = {
            userId: userId?userId:0,
            sort: sort,
            page: page,
            size: size
        }
        healthrecommandRequest(params).then(res => {
            if(res.code === 1 && res.data[0].length > 0) {
                let data = res.data
                data[1].forEach(item => {
                    item.postPicList = item.postPic?item.postPic.split(','):null
                    item.keywordList = item.keyword?item.keyword.split(','):null
                })
                setTopicList(data[0])
                setPostList(data[1])
            }
        })
    }
    // 判断是否有登录，没有跳到登录界面
    const publish = () => {
        if(userId) {
            props.history.push('/health/post/0')
        } else {
            props.history.push('/login')
        }
    }

    return(
        <>
            <div className='health-header flex-apart-row'>
                <p> </p>
                <i className='iconfont-l icon-xiaoxi1'
                onClick={()=>{
                    if(userId) {
                        props.history.push('/tidings')
                    } else {
                        props.redirectDispatch(props.location.pathname)
                        props.history.push('/login')
                    }
                }}></i>
                
                <div className='health-header-classify flex-row'>
                    <div className='flex-row' onClick={()=>settabindex(0)}>关注</div>
                    <div className='flex-row' onClick={()=>settabindex(1)}>推荐</div>
                    <div 
                    className='health-choose-tab'
                    style={{left:`${(tabindex+1)*25}%`}}
                    />
                </div>
            </div>
            <div className='health-screen'>
                <div className='health-edit flex-row' onClick={()=>publish()}>
                    <i className='iconfont-l icon-bianji'></i>
                </div>
                {tabindex===1?
                    <>
                        <div className='health-recommand'>
                            <p>正在热议</p>
                            <img src={back1} alt='' onClick={()=>{props.history.push('/health/recommand')}}/>
                            <ul>
                            {topicList.map((item, index) => (
                                <li className='health-topic' key={index}>
                                    <img src={index===0?icon1:index===1?icon2:icon3} alt=''/>
                                    <span>{item.topicTitle}</span>
                                </li>
                            ))}
                                <li className='health-topic'
                                onClick={()=>{props.history.push('/health/recommand')}}>
                                    <img src={icon4} alt=''/>
                                    <span>查看更多话题</span>
                                </li>
                            </ul>
                        </div>
                        <div className='wechat-news'>
                            <div className='wechat-news-title flex-apart-row'>
                                <span>圈友说</span>
                                <div className='wechat-news-order'>
                                    <span>综合排序</span>
                                    <i className='iconfont-s icon-xiangxia1'></i>
                                </div>
                            </div>
                            {postList.length>0?<Wechatlist postList={postList}/>:null}
                        </div>
                    </>
                :null}
                {tabindex===0?
                    <>
                        <div className='wechat-news'>
                            <div className='wechat-news-title flex-apart-row'>
                                <span>圈友说</span>
                                <div className='wechat-news-order'>
                                    <span>综合排序</span>
                                    <i className='iconfont-s icon-xiangxia1'></i>
                                </div>
                            </div>
                            {postList===null?null:<Wechatlist postList={postList}/>}
                        </div>
                    </>
                :null}
            </div>
            <FootNav/>
        </>
    )
}

// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({
})


// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
return {
    redirectDispatch(data) {
        dispatch(resetdirect(data))
    },
}
}

export default connect(mapStateToProps, mapDispatchToProps)(Health)