import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {Toast} from "antd-mobile-v5"
import {getuserinfo} from "../../api/hospitalapi/login"
import Yyhome from "./yyhome"
import Yyhome2 from "./yyhome2"
import FootNav from '../../component/FootNav'
// 获取url参数的函数
import getUrl from '../../component/GetUrlParam'

import './style.less'
import { storageUser } from './userstorage'
class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            docterExamine:"",
            realNameExamine:""
        }
    }
    componentDidMount=async()=>{
        const docterPhone=localStorage.getItem("docterPhone")
        if (docterPhone) {
            const res=await getuserinfo({
                docterPhone
            })
            this.setState({
                docterExamine:res.data[0][0].docterExamine,
                realNameExamine:res.data[0][0].realNameExamine
            })
        }
        // else{
        //     Toast.show({
        //         content:"当前未登录，请先登录",
        //         duration:1000
        //     })
        // }
    }
    // 获取微信登录状态
    // componentWillMount=async()=> {

    //     //京东获取token测试
    //     //window.location.href="https://oauth.jdl.cn/oauth/authorize?client_id=3126199b82c544f68e348463b04da301&redirect_uri=https%3A%2F%2Fpf-h5.sczyzk.com%2Findex.html%2523%2F%3Fenv%3Dsingle%26pharmacyId%3D25&response_type=code"
    //     this.setState({
    //         wxstate: window.localStorage.getItem("wxlogin") ? true : false,
    //         img: window.localStorage.getItem("wximg"),
    //         userName: window.localStorage.getItem("wxuserName"),
    //         userOpenid: window.localStorage.getItem("wxuserOpenid"),
    //         pharmacyId: window.localStorage.getItem("pharmacyId")
    //     }, async() => {
    //         //批发端判断用资质审核是否通过
    //         if (this.state.pharmacyId == "25"&&this.state.userId) {
    //             const res = await getUserSH({
    //                 userId: this.state.userId
    //             })
    //             console.log(res)
    //             if (res.data[0].length == 0) {
    //                 Toast.fail("未经资质审核")
    //                 this.props.history.push("/qualifications")
    //             } else if (res.data[0][0].entrust_state != 1 || res.data[0][0].card_state != 1 || res.data[0][0].license_state != 1) {
    //                 Toast.fail("资质审核未通过")
    //                 this.props.history.push("/await")
    //             }
    //         }
    //     })
    // }
  
   
    render() {
        const {docterExamine,realNameExamine}=this.state
        return (
            <div>
                <Yyhome props={this.props} />
                {/* {docterExamine=="1"&&realNameExamine=="1"?<Yyhome props={this.props} />:<Yyhome2 docterExamine={docterExamine} realNameExamine={realNameExamine} props={this.props}/>} */}
            </div>
        )
    }
}

export default withRouter(Home)