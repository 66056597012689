import React,{Component} from 'react'
import SwipeableViews from 'react-swipeable-views'
import {autoPlay} from 'react-swipeable-views-utils'
import Pagination from './Pagination'

import './style2.less'
import defaultImg from '../../static/img/default.png'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

class Swiper extends Component{

    constructor(){
        super();
        this.state={index:0}
    }

    handleChangeIndex(index){
        this.setState({
            index:index
        })
    }

    render(){
        const banners=this.props.banners;
        return(
            <div className='swiper2'>
                <AutoPlaySwipeableViews interval={4000} onChangeIndex={this.handleChangeIndex.bind(this)}>
                    {
                        banners.map((v,index)=>{
                            return(
                                <div key={index} className='swiper-view2'>
                                    <img src={v?v:defaultImg} alt=''
                                    onError={(e)=>{e.target.onerror = null;e.target.src=defaultImg}}/>
                                </div>
                            )
                        })
                    }
                </AutoPlaySwipeableViews>
                <Pagination dots={banners.length} index={this.state.index}/>
            </div>
        )
    }
}

export default Swiper