import { axiosInstance,postLogin,postLoginOwn } from '../config'

//获取登录验证码
export const getlocode = (data) => {
    return axiosInstance.post('/api/hosp/doctor_manage/loginver?forward_topic=oplog-v1', data)
}
//获取注册验证码
export const getrecode = (data) => {
    return axiosInstance.post('/api/hosp/doctor_manage/registersms', data)
}
//查看账户是否存在
export const inspectonly = (docterPhone) => {
    return axiosInstance.get(`/api/hosp/doctor_manage/only?docterPhone=${docterPhone}`)
}
//验证码验证
export const verificationcode = (userPhone,smsCode) => {
    return axiosInstance.get(`/api/hosp/doctor_manage/verify?userPhone=${userPhone}&smsCode=${smsCode}`)
}
//注册
export const register = (data) => {
    return axiosInstance.post('/api/hosp/doctor_manage/smsregister', data)
}
//登录
export const userlogin = (data) => {
    return postLogin('/api/hosp/doctor_manage/smslogin', data)
}

//查询登录次数
export const logincishu = (userPhone,smsCode) => {
    return axiosInstance.get(`/api/hosp/doctor_manage/verify?userPhone=${userPhone}&smsCode=${smsCode}`)
}

//获取医生信息
export const getuserinfo = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/get-doctorinfo', data)
}
//获取医生审核信息
export const getdoctorsh = (docterId) => {
    return axiosInstance.get(`/api/hosp/doctor_manage/one-qualifications?id=${docterId}`)
}
// 更新失效验证码状态 
export const updatesxVerify = (userPhone) => {
    return axiosInstance.get(`/api/hosp/wzpatient/updatesx-verify?userPhone=${userPhone}`)
}
// URL转码
export const getJMURL = (data) => {
    return axiosInstance.post(`/faas/hosp/erp-base/geturl`,data)
}
// 短信验证码验证
export const verifyRequest = (phone, code) => {
    return axiosInstance.get(`/api/hosp/doctor_manage/verify?userPhone=${phone}&smsCode=${code}`)
}


// 修改密码
export const changePwdRequest = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/updatepwd`,data)
}


// // 密码登录
// export const loginDuty = (data) => {
//     return postLogin('/api/hosp/wzdocter/login', data)
// }
// export const getLoginInfo = (data) => {
//     return axiosInstance.post(`/api/hosp/wzdocter/get-logininfo`,data)
// }

// 修改h5密码
export const changePwdOwnRequest = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/updatepwd-own`,data)
}

//密码登录
export const loginDuty = (data,isEnablePwd) => {
    // debugger
    if(isEnablePwd===1){
        return postLoginOwn('/api/hosp/wzdocter/login-own', data)
    }else{
        return postLogin('/api/hosp/wzdocter/login', data)
    }   
}

//h5登录
export const getLoginInfo = (data,isEnablePwd) => {
    if(isEnablePwd===1){
        // return axiosInstance.post(`/api/hosp/wzdocter/get-logininfo`,data)
        return axiosInstance.post(`/api/hosp/wzdocter/get-logininfo-own`,data)
    }else{
        return axiosInstance.post(`/api/hosp/wzdocter/get-logininfo`,data)
    } 
}


