import React, { Component } from 'react'
import icon1 from "../../../static/img/yy/fanhuihei.png"
import { Badge, SideBar } from 'antd-mobile-v5'
import { Toast } from 'antd-mobile'
import { getdepartmemntlist } from "../../../api/hospitalapi/department"
import { videoOrder } from "../../../api/hospitalapi/news"
import "./style.less"
import Nodata from "../../../component/Nodata";
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            docterId: localStorage.getItem("docterId"),
            list:[],
            name:''
        }
    }
    componentDidMount = async () => {
        const {docterId}=this.state
        if (docterId){
            const res1=await videoOrder({
                docterId,
                consultationType:'3',
                consultationStu:'0'
            })
            if (res1&&res1.data){
                this.setState({
                    list:res1.data[0]
                })
            }
        }else{
            Toast.info('当前未登录，请先登录')
            this.props.history.push('/login')
        }
    }
    docName=(e)=>{
        this.setState({
            name:e.target.value
        })
    }
    onSearch =async () => {
        const {docterId,name}=this.state
        const res1=await videoOrder({
            docterId,
            consultationType:'3',
            consultationStu:'0',
            name
        })
        console.log(res1)
        if (res1&&res1.data){
            if (res1.data[0].length==0){
                Toast.info('暂未搜索到相关用户的问诊订单')
            }
            this.setState({
                list:res1.data[0]
            })
        }
    }
    toPrescribing=(item)=>{
        console.log(item)
        localStorage.setItem("item",JSON.stringify(item))
        this.props.history.push('/videodiagnostics')
    }
    render() {
        const { list, activeKey } = this.state
        return (
            <div className='typebox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/")} /><h1>视频开方</h1>
                </div>
                <div className='register-head'>
                    <i className='iconfont icon-suosou' onClick={()=>this.onSearch()}></i>
                    <input type="text" placeholder='搜索用药人姓名、手机号等' onChange={(e)=>this.docName(e)}/>
                </div>
                <div className='videoprescription-body'>
                    {list.length?list.map(item=>(
                        <div className='videoprescription-outer' onClick={()=>this.toPrescribing(item)}>
                            <p className='videoprescription-outer-top'>
                                <span>
                                    <img src={item.patient_avatar}/>
                                    <span>{item.patientName}</span>
                                </span>
                                <span>{item.create_time}</span>
                            </p>
                            <p className='videoprescription-outer-bottom'>
                                <span>患者手机号：{item.patient_phone}</span>
                            </p>
                        </div>
                    )):<Nodata type='视频开方'/>}
                </div>
            </div>
        )
    }
}
