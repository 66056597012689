import React, { useState, useEffect, useRef ,useMemo} from 'react'
import { WhiteSpace, Checkbox, Modal, List } from 'antd-mobile'
import { Popup, Stepper, Button, Toast } from 'antd-mobile-v5'
import { connect } from 'react-redux'
import { getUserSH } from "../../api/request/user"
import { wxConfig } from '../../component/scan/index'
import { resetdirect } from '../../redux/user.redux'
import getUrl from '../../component/GetUrlParam'

import FootNav from '../../component/FootNav'
import NoData from '../../component/Nodata'
import RecommmendMed from '../../component/Goods/rmd-med'

import { addcollectRequest } from '../../api/request/fllow'
import { adressListRequest } from "../../api/request/adress"
import {meddetailRequest} from "../../api/request/med"
import { cartlistRequest, updatecartRequest, delCartRequest, changeState, changestoreallState, getstorestartmoney, getstoreTemplate } from '../../api/request/cart'

import rmd from '../../static/img/recommend.png'
import defaultImg from '../../static/img/default.png'
import icon from "../../static/img/home/close.png"
import './style.less'
import { set } from 'lodash'
const { alert } = Modal
const Item = List.Item
const CheckboxItem = Checkbox.CheckboxItem

function Shopcart(props) {

    // let pharmacyId = getUrl('pharmacyId', props.location.search)
    let pharmacyId = '1'
    let pharmacyId2 = localStorage.getItem("pharmacyId")
    //店铺起送价格
    const [visible1, setVisible1] = useState(false)
    const [visible2, setVisible2] = useState(false)
    const [startcode, setStartcode] = useState(0)
    const [nowstoreId, setNowstoreId] = useState(null)
    const [nowstorename, setNowstorename] = useState("")
    const [loading, setLoading] = useState(false)
    const [allmoney, setAllmoney] = useState(0)
    const [tabindex, settabindex] = useState(0)
    const [recommand, setRecommand] = useState([])
    const [cartList, setCartList] = useState([])
    const [pharmacyList, setPharmacyList] = useState([])
    const [cartArr, setCartArr] = useState([])
    const [cartSum, setCartSum] = useState(0)
    const [cartPrice, setCartPrice] = useState(0)
    const [cartPriceNow, setCartPriceNow] = useState(0)
    const [edit, setEdit] = useState(false)
    const [allChecked, setAllChecked] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [useraddress, setuseraddress] = useState("")
    const [freeFreight, setfreeFreight] = useState(0)
    const [orderFreight, setorderFreight] = useState(0)
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const { redirectDispatch } = props
    const type = localStorage.type
    const [deleteStaus, setdeleteStaus] = useState(false)
    let [cref, setcref] = useState(null)
    useEffect(() => {
        if (type == "2") {
            getzz()
        } else {
            getCartList(userId)
        }
        getuseraddress(userId)
        // getCartList(userId)
    }, [userId])
    useEffect(() => {
        if (type == "2") {
            getCartList(userId)
        }

    }, [useraddress])
    useMemo(() => {
        let num=0
        cartList.forEach(item=>{
            if (item.checked) {
                num+=1
            }
        })
        setCartSum(num)
    }, [cartList])
    //获取用户收货地址
    const getuseraddress = async (userId) => {
        const res = await adressListRequest(userId)
        if (res.data[0].length) {
            res.data[0].forEach(item => {
                if (item.addressDefault == "1") {
                    setuseraddress(item.province)
                }
            })
        } else {
            setuseraddress("无")
        }
    }
    //选中变化时计算金额总数
    // useEffect(() => {
    //     if(cartArr[0]){
    //         const arr=cartArr[0].medList
    //         let code=0
    //         arr.forEach((item)=>{
    //             code+=item.medQuantity*(item.medPrice/100)
    //         })
    //         setAllmoney(code)
    //     }

    // }, [cartArr[0]])
    useEffect(() => {
        refresh && setTimeout(() => setRefresh(false))
    }, [refresh])

    useEffect(() => {
        // console.log(cartArr, pharmacyList);
    }, [cartArr, pharmacyList])
    // 判断资质是否通过
    const getzz = async () => {
        if (pharmacyId2 == "25" && userId) {
            // console.log(111);
            const res = await getUserSH({
                userId: userId
            })
            // console.log(res)
            if (res.data[0].length == 0) {
                Toast.show({
                    content:"未经资质审核"
                })
                props.history.push("/qualifications")
            } else if (res.data[0][0].entrust_state != 1 || res.data[0][0].card_state != 1 || res.data[0][0].license_state != 1) {
                Toast.show({
                    content:"未经资质审核"
                })
                props.history.push("/await")
            }
        }
    }
    const cameraScan = () => {
        wxConfig()
    }

    //获取购物车列表
    const getCartList = (userId) => {
        // console.log(useraddress);
        if (!pharmacyId && localStorage.env === 'single') {
            pharmacyId = localStorage.pharmacyId;
        }
        if (type=="2") {
            cartlistRequest(userId, pharmacyId).then(async (res) => {
                //console.log(userId, pharmacyId);
                // console.log(res);
                let pharmacyList = []
                if (res.code === 1) {
                    let data = res.data[0]
                    //console.log(data)
                    //计算总价
    
                    let cartsum = 0
                    let allmoney = 0
                    data.forEach(item => {
                        //setCartSum(cartSum+Number(item.length))
                        //console.log(item);
                        if (item.checkStatus == 1) {
                            allmoney += item.medPrice * item.medQuantity
                            cartsum += item.medQuantity
                        }
    
                    })
                    // console.log(cartsum);
                    setCartSum(cartsum)
                    //console.log(allmoney);
                    setCartPrice(allmoney)
                    setCartPriceNow(allmoney)
                    // if (allmoney / 100 >= 1000 & allmoney / 100 < 2000) {
                    //     setCartPriceNow(allmoney - 20000)
                    // } else if (allmoney >= 200000) {
                    //     //console.log(111);
                    //     setCartPriceNow(allmoney - 50000)
                    // } else {
                    //     setCartPriceNow(allmoney)
                    // }
                    //console.log(cartPriceNow);
                    let data1 = res.data[1] ? res.data[1] : []
                    data.forEach(item => {
                        item.checked = item.checkStatus == 0 ? false : true
                        item.cartPrice = item.medPrice * item.medQuantity
                    })
                    let newCartArr = []
                    // console.log(data);
                    if (data.length == 1) {
                        let arr1 = []
                        let arr2 = []
                        let cityarr = []
                        let freeFreight = 0
                        let orderFreight = 0
                        let code = true
                        let defaultarr = []
                        if (type == "2") {
                            const res = await getstoreTemplate(data[0].pharmacyId, 0)
                            const res2 = await getstoreTemplate(data[0].pharmacyId, 1)
                            //console.log(res2);
    
                            if (res2.data[0].length) {
                                defaultarr = res2.data[0]
                            }
                            // console.log(defaultarr);
                            if (res.data.length) {
                                arr1 = res.data[0]
                                arr2 = res.data[1]
                                // console.log(arr1, arr2);
                                for (let i = 0; i < arr1.length; i++) {
                                    let nowarr = []
                                    for (let j = 0; j < arr2.length; j++) {
                                        if (arr1[i].freightId == arr2[j].freight_id) {
                                            nowarr.push(arr2[j].name)
                                        }
                                    }
                                    cityarr.push({
                                        ctarr: nowarr,
                                        freeFreight: arr1[i].freeFreight,
                                        orderFreight: arr1[i].orderFreight
                                    })
                                }
                                // console.log(cityarr);
                            }
    
    
                            for (let i = 0; i < cityarr.length; i++) {
                                for (let j = 0; j < cityarr[i].ctarr.length; j++) {
                                    if (cityarr[i].ctarr[j] == useraddress) {
                                        setfreeFreight(cityarr[i].freeFreight)
                                        freeFreight = cityarr[i].freeFreight
                                        setorderFreight(cityarr[i].orderFreight)
                                        orderFreight = cityarr[i].orderFreight
                                        code = false
                                    }
                                }
                            }
                            // console.log(freeFreight, orderFreight);
                            //配送模板金额达标判断
                            if (freeFreight && orderFreight && allmoney / 100 < freeFreight) {
                                setCartPriceNow(Number(allmoney) + Number(orderFreight))
                            }
                            // cityarr.forEach((item, index) => {
                            //     item.forEach(code => {
                            //         if (code == useraddress) {
                            //             setfreeFreight(item.freeFreight)
                            //             freeFreight = item.freeFreight
                            //             setorderFreight(item.orderFreight)
                            //             orderFreight = item.orderFreight
                            //             code = false
                            //         }
                            //     })
                            // })
                            // console.log(code);
                            if (code && defaultarr.length > 0) {
                                setfreeFreight(defaultarr[0].freeFreight)
                                freeFreight = defaultarr[0].freeFreight
                                setorderFreight(defaultarr[0].orderFreight)
                                orderFreight = defaultarr[0].orderFreight
                            }
                        }
    
    
    
    
                        getstorestartmoney({
                            companyId: data[0].pharmacyId
                        }).then(res => {
                            // console.log(res);
                            setStartcode(res.data[0].length ? res.data[0][0].minFreight : 0)
                            newCartArr.push({
                                pharmacy: {
                                    pharmacyId: data[0].pharmacyId,
                                    pharmacyName: data[0].pharmacyName,
                                    freight: data[0].freight,
                                    startmoney: res.data[0].length ? res.data[0][0].minFreight : 0,
                                    freeFreight: freeFreight ? freeFreight : null,
                                    orderFreight: orderFreight ? orderFreight : null
                                },
                                medList: [{
                                    cartId: data[0].cartId,
                                    medId: data[0].medId,
                                    medName: data[0].medName,
                                    medPhoto: data[0].medPhoto,
                                    medSpec: data[0].medSpec,
                                    medPacking: data[0].medPacking,
                                    medApproval: data[0].medApproval,
                                    medCompany: data[0].medCompany,
                                    medPrice: data[0].medPrice,
                                    cartPrice: data[0].cartPrice,
                                    medQuantity: data[0].medQuantity,
                                    checkStatus: data[0].checkStatus
                                }]
                            })
                            pharmacyList.push({
                                pharmacyId: data[0].pharmacyId,
                                pharmacyName: data[0].pharmacyName,
                                promoteFull: data[0].promoteFull,
                                promoteCut: data[0].promoteCut,
                                freight: data[0].freight,
                                checked: data[0].checkStatus,
                                startmoney: res.data[0].length ? res.data[0][0].minFreight : 0,
                                freeFreight: freeFreight ? freeFreight : null,
                                orderFreight: orderFreight ? orderFreight : null
                            })
                            // console.log(newCartArr);
                            setNowstoreId(newCartArr[0].pharmacy.pharmacyId)
                            setNowstorename(newCartArr[0].pharmacy.pharmacyName)
                            setCartArr(newCartArr)
                        })
    
                    } else {
                        //console.log(data);
                        let nowdata = JSON.parse(JSON.stringify(data))
                        //console.log(nowdata);
                        // for (var i = 0; i < nowdata.length; i++) {
                        //     for (var j = i + 1; j < nowdata.length; j++) {
                        //         if (data[i].pharmacyId == nowdata[j].pharmacyId) {         //第一个等同于第二个，splice方法删除第二个
                        //             nowdata.splice(j, 1);
                        //             j--;
                        //         }
                        //     }
                        // }
                        // console.log(nowdata);
                        let idlist = []
                        nowdata.forEach(item => {
                            idlist.push(item.pharmacyId)
                        })
                        for(let i=0;i<idlist.length;i++){
                            for(let j=i+1;j<idlist.length;j++){
                                if (idlist[i]==idlist[j]) {
                                 idlist.splice(j,1)
                                 j--   
                                }
                            }
                        }
                       
                        // console.log(idlist);
                        for (let i = 0; i < idlist.length; i++) {
                            let arritem = []
                            let moneydata = []
                            data.forEach((item, index) => {
                                if (item.pharmacyId == idlist[i]) {
                                    arritem.push({
                                        cartId: item.cartId,
                                        medId: item.medId,
                                        medName: item.medName,
                                        medPhoto: item.medPhoto,
                                        medSpec: item.medSpec,
                                        medPacking: item.medPacking,
                                        medApproval: item.medApproval,
                                        medCompany: item.medCompany,
                                        medPrice: item.medPrice,
                                        cartPrice: item.cartPrice,
                                        medQuantity: item.medQuantity,
                                        checkStatus: item.checkStatus,
                                        pharmacyId: item.pharmacyId,
                                        pharmacyName: item.pharmacyName,
                                        promoteFull: item.promoteFull,
                                        promoteCut: item.promoteCut,
                                        freight: item.freight
                                    })
                                }
                            })
                            let defaultarr = []
                            let arr1 = []
                            let arr2 = []
                            let cityarr = []
                            let freeFreight = 0
                            let orderFreight = 0
                            let code2 = true
                            //查询店铺运费模板
                           
                            if (type == "2") {
                                const res0 = await getstoreTemplate(data[0].pharmacyId, 0)
                                const res2 = await getstoreTemplate(data[0].pharmacyId, 1)
                                //console.log(res2);
    
                                if (res2.data[0].length) {
                                    defaultarr = res2.data[0]
                                }
                                // console.log(defaultarr);
    
                                if (res0.data.length) {
                                    arr1 = res0.data[0]
                                    arr2 = res0.data[1]
                                    // console.log(arr1, arr2);
                                    for (let i = 0; i < arr1.length; i++) {
                                        let nowarr = []
                                        for (let j = 0; j < arr2.length; j++) {
                                            if (arr1[i].freightId == arr2[j].freight_id) {
                                                nowarr.push(arr2[j].name)
                                            }
                                        }
                                        cityarr.push({
                                            ctarr: nowarr,
                                            freeFreight: arr1[i].freeFreight,
                                            orderFreight: arr1[i].orderFreight
                                        })
                                    }
                                    // console.log(cityarr);
                                }
                                for (let i = 0; i < cityarr.length; i++) {
                                    for (let j = 0; j < cityarr[i].ctarr.length; j++) {
                                        if (cityarr[i].ctarr[j] == useraddress) {
                                            setfreeFreight(cityarr[i].freeFreight)
                                            freeFreight = cityarr[i].freeFreight
                                            setorderFreight(cityarr[i].orderFreight)
                                            orderFreight = cityarr[i].orderFreight
                                            code2 = false
                                        }
                                    }
                                }
                                // cityarr.forEach((item, index) => {
                                //     item.forEach(code => {
                                //         if (code == useraddress) {
                                //             setfreeFreight(item.freeFreight)
                                //             freeFreight = item.freeFreight
                                //             setorderFreight(item.orderFreight)
                                //             orderFreight = item.orderFreight
                                //             code = false
                                //         }
                                //     })
                                // })
                                if (code2 && defaultarr.length > 0) {
                                    setfreeFreight(defaultarr[0].freeFreight)
                                    freeFreight = defaultarr[0].freeFreight
                                    setorderFreight(defaultarr[0].orderFreight)
                                    orderFreight = defaultarr[0].orderFreight
                                }
                            }
                            
                            let res
                            if (type == "2") {
                                res = await getstorestartmoney({
                                    companyId: arritem[arritem.length - 1].pharmacyId
                                })
                            }
                            
                            newCartArr.push({
                                pharmacy: {
                                    pharmacyId: arritem[0].pharmacyId,
                                    pharmacyName: arritem[0].pharmacyName,
                                    freight: arritem[0].freight,
                                    //startmoney: res.data[0].length ? res.data[0][0].minFreight : 0,
                                    startmoney: res ? res.data[0].length ? res.data[0][0].minFreight : 0 : null,
                                    freeFreight: freeFreight ? freeFreight : null,
                                    orderFreight: orderFreight ? orderFreight : null
                                },
                                medList: arritem
                            })
                            let code = true
                            arritem.forEach(item => {
                                if (item.checkStatus == 0) {
                                    code = false
                                }
                            })
                            pharmacyList.push({
                                pharmacyId: arritem[0].pharmacyId,
                                pharmacyName: arritem[0].pharmacyName,
                                promoteFull: arritem[0].promoteFull,
                                promoteCut: arritem[0].promoteCut,
                                freight: arritem[0].freight,
                                checked: code,
                                startmoney: res ? res.data[0].length ? res.data[0][0].minFreight : 0 : null,
                                freeFreight: freeFreight ? freeFreight : null,
                                orderFreight: orderFreight ? orderFreight : null
                            })
                        }
    
                        newCartArr.map(item => {
    
                            const newitemlist = item.medList.filter(item2 => {
                                return item2.checkStatus == 1
                            })
                            item.medList = newitemlist
                            return item
                        })
                        // console.log(newCartArr);
                        let checkedArr = newCartArr.filter(item => {
                            return item.medList.length > 0
                        })
                        //判断是否有运费
                        if (type == "2"&&checkedArr.length) {
                            if (checkedArr[0].pharmacy.freeFreight && allmoney / 100 < checkedArr[0].pharmacy.freeFreight) {
                                setCartPriceNow(Number(allmoney) + Number(checkedArr[0].pharmacy.orderFreight) * 100)
                            }
                        }
                    //    console.log(checkedArr);
                       if (checkedArr.length) {
                        setNowstoreId(checkedArr[0].pharmacy.pharmacyId)
                       }else{
                        setNowstoreId(1) 
                       }
                       
                        setCartArr(checkedArr)
                    }
                    //console.log(pharmacyList);
    
                    //     let allchecked=true
                    //     data.forEach(item=>{
                    //         if (!item.checked) {
                    //             allchecked=false
                    //         }
                    //     })
                    //    if (allchecked) {
                    //      pharmacyList.checked=true
                    //    }
                    //    console.log(pharmacyList)
                    let allstatus = true
                    pharmacyList.map(item => {
                        if (!item.checked) {
                            allstatus = false
                        }
                    })
                    // console.log(data)
                    setAllChecked(allstatus)
                    setCartList(data)
                    //console.log(pharmacyList);
                    setPharmacyList(pharmacyList)
                    setRecommand(data1)
                } else {
                    setCartList([])
                    setPharmacyList([])
                    setRecommand([])
                }
                setLoading(true)
            }).catch(err => {
                setLoading(true)
            })
        }else if(type=="1"){
            cartlistRequest(userId, pharmacyId).then(async (res) => {
                //console.log(userId, pharmacyId);
                // console.log(res);
                let pharmacyList = []
                if (res.code === 1) {
                    let data = res.data[0]
                    //console.log(data)
                    //计算总价
    
                    let cartsum = 0
                    let allmoney = 0
                    data.forEach(item => {
                        //setCartSum(cartSum+Number(item.length))
                        //console.log(item);
                        if (item.checkStatus == 1) {
                            allmoney += item.medPrice * item.medQuantity
                            cartsum += item.medQuantity
                        }
    
                    })
                    // console.log(cartsum);
                    setCartSum(cartsum)
                    //console.log(allmoney);
                    setCartPrice(allmoney)
                    setCartPriceNow(allmoney)
                    // if (allmoney / 100 >= 1000 & allmoney / 100 < 2000) {
                    //     setCartPriceNow(allmoney - 20000)
                    // } else if (allmoney >= 200000) {
                    //     //console.log(111);
                    //     setCartPriceNow(allmoney - 50000)
                    // } else {
                    //     setCartPriceNow(allmoney)
                    // }
                    //console.log(cartPriceNow);
                    let data1 = res.data[1] ? res.data[1] : []
                    data.forEach(item => {
                        item.checked = item.checkStatus == 0 ? false : true
                        item.cartPrice = item.medPrice * item.medQuantity
                    })
                    let newCartArr = []
                    // console.log(data);
                    if (data.length == 1) {
                        let arr1 = []
                        let arr2 = []
                        let cityarr = []
                        let freeFreight = 0
                        let orderFreight = 0
                        let code = true
                        let defaultarr = []
                        if (type == "2") {
                            const res = await getstoreTemplate(data[0].pharmacyId, 0)
                            const res2 = await getstoreTemplate(data[0].pharmacyId, 1)
                            //console.log(res2);
    
                            if (res2.data[0].length) {
                                defaultarr = res2.data[0]
                            }
                            // console.log(defaultarr);
                            if (res.data.length) {
                                arr1 = res.data[0]
                                arr2 = res.data[1]
                                // console.log(arr1, arr2);
                                for (let i = 0; i < arr1.length; i++) {
                                    let nowarr = []
                                    for (let j = 0; j < arr2.length; j++) {
                                        if (arr1[i].freightId == arr2[j].freight_id) {
                                            nowarr.push(arr2[j].name)
                                        }
                                    }
                                    cityarr.push({
                                        ctarr: nowarr,
                                        freeFreight: arr1[i].freeFreight,
                                        orderFreight: arr1[i].orderFreight
                                    })
                                }
                                // console.log(cityarr);
                            }
    
    
                            for (let i = 0; i < cityarr.length; i++) {
                                for (let j = 0; j < cityarr[i].ctarr.length; j++) {
                                    if (cityarr[i].ctarr[j] == useraddress) {
                                        setfreeFreight(cityarr[i].freeFreight)
                                        freeFreight = cityarr[i].freeFreight
                                        setorderFreight(cityarr[i].orderFreight)
                                        orderFreight = cityarr[i].orderFreight
                                        code = false
                                    }
                                }
                            }
                            // console.log(freeFreight, orderFreight);
                            //配送模板金额达标判断
                            if (freeFreight && orderFreight && allmoney / 100 < freeFreight) {
                                setCartPriceNow(Number(allmoney) + Number(orderFreight))
                            }
                            // cityarr.forEach((item, index) => {
                            //     item.forEach(code => {
                            //         if (code == useraddress) {
                            //             setfreeFreight(item.freeFreight)
                            //             freeFreight = item.freeFreight
                            //             setorderFreight(item.orderFreight)
                            //             orderFreight = item.orderFreight
                            //             code = false
                            //         }
                            //     })
                            // })
                            // console.log(code);
                            if (code && defaultarr.length > 0) {
                                setfreeFreight(defaultarr[0].freeFreight)
                                freeFreight = defaultarr[0].freeFreight
                                setorderFreight(defaultarr[0].orderFreight)
                                orderFreight = defaultarr[0].orderFreight
                            }
                        }
    
    
    
    
                        getstorestartmoney({
                            companyId: data[0].pharmacyId
                        }).then(res => {
                            // console.log(res);
                            setStartcode(res.data[0].length ? res.data[0][0].minFreight : 0)
                            newCartArr.push({
                                pharmacy: {
                                    pharmacyId: data[0].pharmacyId,
                                    pharmacyName: data[0].pharmacyName,
                                    freight: data[0].freight,
                                    startmoney: res.data[0].length ? res.data[0][0].minFreight : 0,
                                    freeFreight: freeFreight ? freeFreight : null,
                                    orderFreight: orderFreight ? orderFreight : null
                                },
                                medList: [{
                                    cartId: data[0].cartId,
                                    medId: data[0].medId,
                                    medName: data[0].medName,
                                    medPhoto: data[0].medPhoto,
                                    medSpec: data[0].medSpec,
                                    medPacking: data[0].medPacking,
                                    medApproval: data[0].medApproval,
                                    medCompany: data[0].medCompany,
                                    medPrice: data[0].medPrice,
                                    cartPrice: data[0].cartPrice,
                                    medQuantity: data[0].medQuantity,
                                    checkStatus: data[0].checkStatus
                                }]
                            })
                            pharmacyList.push({
                                pharmacyId: data[0].pharmacyId,
                                pharmacyName: data[0].pharmacyName,
                                promoteFull: data[0].promoteFull,
                                promoteCut: data[0].promoteCut,
                                freight: data[0].freight,
                                checked: data[0].checkStatus,
                                startmoney: res.data[0].length ? res.data[0][0].minFreight : 0,
                                freeFreight: freeFreight ? freeFreight : null,
                                orderFreight: orderFreight ? orderFreight : null
                            })
                            setCartArr(newCartArr)
                        })
    
                    } else {
                        //console.log(data);
                        let nowdata = JSON.parse(JSON.stringify(data))
                        //console.log(nowdata);
                        // for (var i = 0; i < nowdata.length; i++) {
                        //     for (var j = i + 1; j < nowdata.length; j++) {
                        //         if (data[i].pharmacyId == nowdata[j].pharmacyId) {         //第一个等同于第二个，splice方法删除第二个
                        //             nowdata.splice(j, 1);
                        //             j--;
                        //         }
                        //     }
                        // }
                        // console.log(nowdata);
                        let idlist = []
                        nowdata.forEach(item => {
                            idlist.push(item.pharmacyId)
                        })
                        for(let i=0;i<idlist.length;i++){
                            for(let j=i+1;j<idlist.length;j++){
                                if (idlist[i]==idlist[j]) {
                                 idlist.splice(j,1)
                                 j--   
                                }
                            }
                        }
                       
                        // console.log(idlist);
                        for (let i = 0; i < idlist.length; i++) {
                            let arritem = []
                            let moneydata = []
                            data.forEach((item, index) => {
                                if (item.pharmacyId == idlist[i]) {
                                    arritem.push({
                                        cartId: item.cartId,
                                        medId: item.medId,
                                        medName: item.medName,
                                        medPhoto: item.medPhoto,
                                        medSpec: item.medSpec,
                                        medPacking: item.medPacking,
                                        medApproval: item.medApproval,
                                        medCompany: item.medCompany,
                                        medPrice: item.medPrice,
                                        cartPrice: item.cartPrice,
                                        medQuantity: item.medQuantity,
                                        checkStatus: item.checkStatus,
                                        pharmacyId: item.pharmacyId,
                                        pharmacyName: item.pharmacyName,
                                        promoteFull: item.promoteFull,
                                        promoteCut: item.promoteCut,
                                        freight: item.freight
                                    })
                                }
                            })
                            let defaultarr = []
                            let arr1 = []
                            let arr2 = []
                            let cityarr = []
                            let freeFreight = 0
                            let orderFreight = 0
                            let code2 = true
                            //查询店铺运费模板
                           
                            if (type == "2") {
                                const res0 = await getstoreTemplate(data[0].pharmacyId, 0)
                                const res2 = await getstoreTemplate(data[0].pharmacyId, 1)
                                //console.log(res2);
    
                                if (res2.data[0].length) {
                                    defaultarr = res2.data[0]
                                }
                                // console.log(defaultarr);
    
                                if (res0.data.length) {
                                    arr1 = res0.data[0]
                                    arr2 = res0.data[1]
                                    // console.log(arr1, arr2);
                                    for (let i = 0; i < arr1.length; i++) {
                                        let nowarr = []
                                        for (let j = 0; j < arr2.length; j++) {
                                            if (arr1[i].freightId == arr2[j].freight_id) {
                                                nowarr.push(arr2[j].name)
                                            }
                                        }
                                        cityarr.push({
                                            ctarr: nowarr,
                                            freeFreight: arr1[i].freeFreight,
                                            orderFreight: arr1[i].orderFreight
                                        })
                                    }
                                    // console.log(cityarr);
                                }
                                for (let i = 0; i < cityarr.length; i++) {
                                    for (let j = 0; j < cityarr[i].ctarr.length; j++) {
                                        if (cityarr[i].ctarr[j] == useraddress) {
                                            setfreeFreight(cityarr[i].freeFreight)
                                            freeFreight = cityarr[i].freeFreight
                                            setorderFreight(cityarr[i].orderFreight)
                                            orderFreight = cityarr[i].orderFreight
                                            code2 = false
                                        }
                                    }
                                }
                                // cityarr.forEach((item, index) => {
                                //     item.forEach(code => {
                                //         if (code == useraddress) {
                                //             setfreeFreight(item.freeFreight)
                                //             freeFreight = item.freeFreight
                                //             setorderFreight(item.orderFreight)
                                //             orderFreight = item.orderFreight
                                //             code = false
                                //         }
                                //     })
                                // })
                                if (code2 && defaultarr.length > 0) {
                                    setfreeFreight(defaultarr[0].freeFreight)
                                    freeFreight = defaultarr[0].freeFreight
                                    setorderFreight(defaultarr[0].orderFreight)
                                    orderFreight = defaultarr[0].orderFreight
                                }
                            }
                            
                            let res
                            if (type == "2") {
                                res = await getstorestartmoney({
                                    companyId: arritem[arritem.length - 1].pharmacyId
                                })
                            }
                            
                            newCartArr.push({
                                pharmacy: {
                                    pharmacyId: arritem[0].pharmacyId,
                                    pharmacyName: arritem[0].pharmacyName,
                                    freight: arritem[0].freight,
                                    //startmoney: res.data[0].length ? res.data[0][0].minFreight : 0,
                                    startmoney: res ? res.data[0].length ? res.data[0][0].minFreight : 0 : null,
                                    freeFreight: freeFreight ? freeFreight : null,
                                    orderFreight: orderFreight ? orderFreight : null
                                },
                                medList: arritem
                            })
                            let code = true
                            arritem.forEach(item => {
                                if (item.checkStatus == 0) {
                                    code = false
                                }
                            })
                            pharmacyList.push({
                                pharmacyId: arritem[0].pharmacyId,
                                pharmacyName: arritem[0].pharmacyName,
                                promoteFull: arritem[0].promoteFull,
                                promoteCut: arritem[0].promoteCut,
                                freight: arritem[0].freight,
                                checked: code,
                                startmoney: res ? res.data[0].length ? res.data[0][0].minFreight : 0 : null,
                                freeFreight: freeFreight ? freeFreight : null,
                                orderFreight: orderFreight ? orderFreight : null
                            })
                        }
    
                        newCartArr.map(item => {
    
                            const newitemlist = item.medList.filter(item2 => {
                                return item2.checkStatus == 1
                            })
                            item.medList = newitemlist
                            return item
                        })
                        // console.log(newCartArr);
                        let checkedArr = newCartArr.filter(item => {
                            return item.medList.length > 0
                        })
                        //判断是否有运费
                        if (type == "2"&&checkedArr.length) {
                            if (checkedArr[0].pharmacy.freeFreight && allmoney / 100 < checkedArr[0].pharmacy.freeFreight) {
                                setCartPriceNow(Number(allmoney) + Number(checkedArr[0].pharmacy.orderFreight) * 100)
                            }
                        }
                    //    console.log(checkedArr);
                       if (checkedArr.length) {
                        setNowstoreId(checkedArr[0].pharmacy.pharmacyId)
                       }else{
                        setNowstoreId(1) 
                       }
                       
                        setCartArr(checkedArr)
                    }
                    //console.log(pharmacyList);
    
                    //     let allchecked=true
                    //     data.forEach(item=>{
                    //         if (!item.checked) {
                    //             allchecked=false
                    //         }
                    //     })
                    //    if (allchecked) {
                    //      pharmacyList.checked=true
                    //    }
                    //    console.log(pharmacyList)
                    let allstatus = true
                    pharmacyList.map(item => {
                        if (!item.checked) {
                            allstatus = false
                        }
                    })
                    // console.log(data)
                    setAllChecked(allstatus)
                    setCartList(data)
                    //console.log(pharmacyList);
                    setPharmacyList(pharmacyList)
                    setRecommand(data1)
                } else {
                    setCartList([])
                    setPharmacyList([])
                    setRecommand([])
                }
                setLoading(true)
            }).catch(err => {
                setLoading(true)
            })
        }
      
    }
    //购物车一个店铺所有商品选中
    const checkStore = async (e, shop, index) => {
        //设置某个店铺商品选择状态
        // console.log(e.target.checked);
        const res = await changestoreallState({
            checkStatus: e.target.checked ? 1 : 0,
            userId: userId,
            pharmacyId: shop.pharmacyId
        })
        getCartList(userId)
        //console.log(shop.pharmacyId);
        setNowstoreId(shop.pharmacyId)
        setNowstorename(shop.pharmacyName)
        let count = 0
        let medList = cartList
        //console.log(cartList);
        let storeList = pharmacyList
        let newCartArr = []
        let SUM = cartSum
        let sumPrice = cartPrice
        let medIdArr = []
        //let statusarr=[]
        storeList[index].checked = e.target.checked
        if (e.target.checked) {
            // console.log(medList);
            medList.forEach(item => {
                // statusarr.push({
                //     checkStatus:item.checked?0:1,
                //     cartId:item.cartId
                // })
                if (!item.checked && item.pharmacyId === shop.pharmacyId) {
                    count++
                    item.checked = true
                    medIdArr.push({
                        cartId: item.cartId,
                        medId: item.medId,
                        medName: item.medName,
                        medPhoto: item.medPhoto,
                        medSpec: item.medSpec,
                        medPacking: item.medPacking,
                        medApproval: item.medApproval,
                        medCompany: item.medCompany,
                        medPrice: item.medPrice,
                        medPacking: item.medPacking,
                        cartPrice: item.cartPrice,
                        medQuantity: item.medQuantity
                    })
                    SUM += item.medQuantity
                    sumPrice += item.medQuantity * item.medPrice
                }
            })
            newCartArr.push({
                pharmacy: {
                    pharmacyId: shop.pharmacyId,
                    pharmacyName: shop.pharmacyName,
                    freight: shop.freight
                },
                medList: medIdArr
            })
            // console.log(newCartArr)
            if (count === medList.length) {
                setAllChecked(true)
            }
            setCartArr(newCartArr)
        } else {
            //console.log(medList);
            setCartArr([])
            medList.forEach(item => {
                if (item.checked && item.pharmacyId === shop.pharmacyId) {
                    item.checked = false
                    SUM -= item.medQuantity
                    sumPrice -= item.medQuantity * item.medPrice
                }
            })
            // newCartArr.forEach((item, index) => {
            //     if (item.pharmacyId === shop.pharmacyId) {
            //         newCartArr.splice(index, 1)
            //     }
            // })
            setAllChecked(false)
        }
        // const res=await changeState(statusarr)
        // console.log(res);
        if (sumPrice / 100 >= 1000 & sumPrice / 100 < 2000) {
            setCartPriceNow(sumPrice - 20000)
        } else if (sumPrice >= 200000) {
            // console.log(111);
            setCartPriceNow(sumPrice - 50000)
        } else {
            setCartPriceNow(sumPrice)
        }
        setCartPrice(sumPrice)
        // console.log(SUM);
        setCartSum(SUM)
        //setCartArr(newCartArr)
        setPharmacyList(storeList)
        setCartList(medList)
        setRefresh(true)
    }
    // useEffect(() => {
    //     console.log(cartList);
    // }, [cartList])
    //编辑管理
    const editManage = () => {
        if (deleteStaus) {
            getCartList(userId)
        }
        let medList = cartList
        let storeList = pharmacyList
        medList.forEach(item => {
            item.checked = false
        })
        storeList.forEach(item => {
            item.checked = false
        })
        //console.log(medList);
        setEdit(!edit)
        setCartList(medList)
        setPharmacyList(storeList)
        setCartArr([])
        setCartSum(0)
        setCartPrice(0)
        setAllChecked(false)
        setdeleteStaus(!deleteStaus)
    }

    //小单选按钮事件
    const changebuy = async (e, v, index, i) => {
        // console.log(v);
        if (!deleteStaus) {
            // console.log(v);
            const res = await changeState({
                cartId: v.cartId,
                checkStatus: v.checkStatus == 0 ? 1 : 0
            })
            // console.log(res)
            getCartList(userId)
        }

        setNowstoreId(v.pharmacyId)
        setNowstorename(v.pharmacyName)
        let count = 0
        let storeCount = 0
        let storeSum = 0
        let medList = cartList
        let storeList = pharmacyList
        let newCartArr = []
        let SUM = cartSum
        let sumPrice = cartPrice
        const n = newCartArr.findIndex(item => item.pharmacy.pharmacyId === v.pharmacyId)
        // console.log(n, index, v);
        medList[index].checked = e.target.checked
        if (e.target.checked) {

            SUM += 1
            sumPrice += v.medQuantity * v.medPrice
            if (n === -1) {
                const newarr = medList.filter((item) => {
                    return item.checked
                })
                //console.log(newarr);
                let medIdArr = []
                newarr.forEach(item => {
                    medIdArr.push({
                        cartId: item.cartId,
                        medId: item.medId,
                        medName: item.medName,
                        medPhoto: item.medPhoto,
                        medSpec: item.medSpec,
                        medPacking: item.medPacking,
                        medApproval: item.medApproval,
                        medCompany: item.medCompany,
                        medPrice: item.medPrice,
                        cartPrice: item.cartPrice,
                        medQuantity: item.medQuantity
                    })
                    // newCartArr.push({
                    //     pharmacy: {
                    //         pharmacyId: item.pharmacyId,
                    //         pharmacyName: item.pharmacyName,
                    //         freight: item.freight
                    //     },
                    //     medIdArr: [{
                    //         cartId: item.cartId,
                    //         medId: item.medId,
                    //         medName: item.medName,
                    //         medPhoto: item.medPhoto,
                    //         medSpec: item.medSpec,
                    //         medPacking: item.medPacking,
                    //         medApproval: item.medApproval,
                    //         medCompany: item.medCompany,
                    //         medPrice: item.medPrice,
                    //         cartPrice: item.cartPrice,
                    //         medQuantity: item.medQuantity
                    //     }]
                    // })
                })
                newCartArr.push({
                    pharmacy: {
                        pharmacyId: v.pharmacyId,
                        pharmacyName: v.pharmacyName,
                        freight: v.freight
                    },
                    medList: medIdArr
                })

                // newCartArr.push({
                //     pharmacy: {
                //         pharmacyId: v.pharmacyId,
                //         pharmacyName: v.pharmacyName,
                //         freight: v.freight
                //     },
                //     medList: [{
                //         cartId: v.cartId,
                //         medId: v.medId,
                //         medName: v.medName,
                //         medPhoto: v.medPhoto,
                //         medSpec: v.medSpec,
                //         medPacking: v.medPacking,
                //         medApproval: v.medApproval,
                //         medCompany: v.medCompany,
                //         medPrice: v.medPrice,
                //         cartPrice: v.cartPrice,
                //         medQuantity: v.medQuantity
                //     }]
                // })
                // const newlist= cartList.filter((item,index)=>{
                //     return index!=n
                // })
                // console.log(newlist,n);
            } else {
                // console.log(n);
                newCartArr[n].medList.push({
                    cartId: v.cartId,
                    medId: v.medId,
                    medName: v.medName,
                    medPhoto: v.medPhoto,
                    medSpec: v.medSpec,
                    medPacking: v.medPacking,
                    medPrice: v.medPrice,
                    cartPrice: v.cartPrice,
                    medQuantity: v.medQuantity
                })
            }
            medList.forEach((item, index) => {
                if (item.checked) {
                    count++
                    if (item.pharmacyId === v.pharmacyId) {
                        storeCount++ //购物车中每个店铺选中的商品种类
                    }
                }
                if (item.pharmacyId === v.pharmacyId) {
                    storeSum++  //购物车中每个店铺商品种类
                }
            })
            if (count === medList.length) {
                setAllChecked(true)
            }
            if (storeCount === storeSum) {
                storeList[i].checked = true
            }
            // console.log(storeList[i].checked);


        } else {
            // console.log(v);
            SUM -= 1
            sumPrice -= v.cartPrice
            // console.log(sumPrice);
            // const m = newCartArr[n].medList.findIndex(item => item.medId === v.medId)
            // newCartArr[n].medList.splice(m, 1)
            // if (newCartArr[n].medList.length === 0) {
            //     newCartArr.splice(m, 1)
            // }

            const newarr = medList.filter((item) => {
                return item.checked
            })
            // console.log(newarr);
            newarr.forEach(item => {
                newCartArr.push({
                    pharmacy: {
                        pharmacyId: item.pharmacyId,
                        pharmacyName: item.pharmacyName,
                        freight: item.freight
                    },
                    medList: [{
                        cartId: item.cartId,
                        medId: item.medId,
                        medName: item.medName,
                        medPhoto: item.medPhoto,
                        medSpec: item.medSpec,
                        medPacking: item.medPacking,
                        medApproval: item.medApproval,
                        medCompany: item.medCompany,
                        medPrice: item.medPrice,
                        cartPrice: item.cartPrice,
                        medQuantity: item.medQuantity
                    }]
                })
            })

            storeList[i].checked = false
            setAllChecked(false)
        }
        // if (sumPrice >= 100000 & sumPrice < 200000) {
        //     setCartPriceNow(sumPrice - 20000)
        // } else if (sumPrice >= 200000) {
        //     setCartPriceNow(sumPrice - 50000)
        // } else {
        //     setCartPriceNow(sumPrice)
        // }
        if (type == "2") {
            if (sumPrice > freeFreight) {
                setCartPriceNow(sumPrice)
            } else {
                setCartPriceNow(Number(sumPrice) + orderFreight * 100)
            }
        } else {
            setCartPriceNow(sumPrice)
        }
        // console.log(newCartArr);
        setCartPrice(sumPrice)
        setCartSum(SUM)
        setCartArr(newCartArr)
        setPharmacyList(storeList)
        setCartList(medList)
        setRefresh(true)
    }

    const allCartChecked = (e) => {
        let medList = cartList
        let storeList = pharmacyList
        let newCartArr = cartArr
        let SUM = 0
        let sumPrice = 0
        medList.forEach(item => {
            item.checked = e.target.checked
        })
        storeList.forEach(item => {
            item.checked = e.target.checked
        })
        if (e.target.checked) {
            medList.forEach(item => {
                SUM += item.medQuantity
                sumPrice += item.medQuantity * item.medPrice
                const n = newCartArr.findIndex(v => v.pharmacy.pharmacyId === item.pharmacyId)
                if (n === -1) {
                    newCartArr.push({
                        pharmacy: {
                            pharmacyId: item.pharmacyId,
                            pharmacyName: item.pharmacyName,
                            freight: item.freight
                        },
                        medList: [{
                            cartId: item.cartId,
                            medId: item.medId,
                            medName: item.medName,
                            medPhoto: item.medPhoto,
                            medSpec: item.medSpec,
                            medPacking: item.medPacking,
                            medApproval: item.medApproval,
                            medCompany: item.medCompany,
                            medPrice: item.medPrice,
                            medPacking: item.medPacking,
                            cartPrice: item.cartPrice,
                            medQuantity: item.medQuantity
                        }]
                    })
                } else {
                    newCartArr[n].medList.push({
                        cartId: item.cartId,
                        medId: item.medId,
                        medName: item.medName,
                        medPhoto: item.medPhoto,
                        medSpec: item.medSpec,
                        medPacking: item.medPacking,
                        medApproval: item.medApproval,
                        medCompany: item.medCompany,
                        medPrice: item.medPrice,
                        cartPrice: item.cartPrice,
                        medQuantity: item.medQuantity
                    })
                }
            })
        } else {
            SUM = 0
            sumPrice = 0
            newCartArr = []
        }

        if (type=="2"&&newCartArr.length) {
            if (newCartArr[0].pharmacy.freeFreight && sumPrice / 100 < newCartArr[0].pharmacy.freeFreight) {
                setCartPriceNow(Number(sumPrice) + Number(newCartArr[0].pharmacy.orderFreight) * 100)
            }
        }
        // if (sumPrice / 100 >= 1000 & sumPrice / 100 < 2000) {
        //     setCartPriceNow(sumPrice - 20000)
        // } else if (sumPrice >= 200000) {
        //     console.log(111);
        //     setCartPriceNow(sumPrice - 50000)
        // } else {
        //     setCartPriceNow(sumPrice)
        // }
        setCartPrice(sumPrice)
        setCartSum(SUM)
        setCartArr(newCartArr)
        setAllChecked(e.target.checked)
        setPharmacyList(storeList)
        setCartList(medList)
        setRefresh(true)
    }
    //购物车加减
    const quantity =async (index, val) => {
        //getCartList(userId)
        let storearr = pharmacyList
        let medList = cartList
        const detailres=await meddetailRequest(medList[index].medId)
        if (val>detailres.data[3][0].stockNum) {
            Toast.show({
                content:`该药品库存仅剩${detailres.data[3][0].stockNum}`
            })
            val=detailres.data[3][0].stockNum
        }
        // medList.map((item,code)=>{
        //     if (code==index) {
        //         item.checked=true
        //     }
        //     return item
        // })
        // setCartList(medList)
        let code = 0
        let code2 = 0
        medList.forEach((item, mun) => {
            if (item.checked && mun == index) {
                code++
            }
        })
        storearr.forEach(item => {
            if (item.checked) {
                code2++
            }
        })
        // console.log(cartList);
        let newCartArr = cartArr
        let sumPrice = 0
        medList[index].medQuantity = val
        medList[index].cartPrice = val * medList[index].medPrice
         medList[index].checked = true
         medList[index].checkStatus = 1
         //console.log(medList[index]);
        const stateres=await changeState({
            checkStatus:1,
            cartId:medList[index].cartId
        })
        //console.log(stateres);
        if (newCartArr.length > 0) {
           let arrson=newCartArr[0].medList
           arrson.map(item=>{
               if (item.cartId==medList[index].cartId) {
                   item.medQuantity=val
               }
               return item
           })
           newCartArr[0].medList=arrson
        //    console.log(newCartArr);
        }
        //重新计算被点击商品数量
        // medList.forEach(item => {
        //     if (item.checked) {
        //         SUM += item.medQuantity
        //         sumPrice += item.medQuantity * item.medPrice
        //     }
        // })
        // console.log(medList);
        // console.log(newCartArr[0].medList);
        // // setAllPrice(price)
        // const n = newCartArr[0].medList.findIndex(v => v.cartId === medList[index].cartId)
        // console.log(n);
        // const m = newCartArr[n].medList.findIndex(item => item.medId === medList[index].medId)
        // // console.log(n, m);
        // if (newCartArr[n].medList[m]) {
        //     newCartArr[n].medList[m].medQuantity = val
        // }

        updatecartRequest(val, medList[index].cartId).then(res => {
            // console.log(res);
            if (res.code === 1) {
                setCartArr(newCartArr)
                // if (sumPrice / 100 >= 1000 & sumPrice / 100 < 2000) {
                //     setCartPriceNow(sumPrice - 20000)
                // } else if (sumPrice >= 2000) {
                //     setCartPriceNow(sumPrice - 50000)
                // } else {
                //     setCartPriceNow(sumPrice)
                // }
                // setCartPrice(sumPrice)
            }
        })
        let cartMoney = 0
        let num=0
        // console.log(medList);
        medList.forEach(item => {
            if (item.checked) {
                num+=1
                cartMoney += Number(item.cartPrice)
            }
            //console.log(item.cartPrice);
        })
        // console.log(cartMoney);
        setCartPrice(cartMoney)
        if (code > 0 || code2 > 0) {
            if (type == "2") {
                if (cartMoney / 100 > freeFreight) {
                    setCartPriceNow(cartMoney)
                } else {
                    setCartPriceNow(Number(cartMoney) + orderFreight * 100)
                }
            } else {
                setCartPriceNow(cartMoney)
            }
        }
       

        setCartList(medList)
        let SUM = 0
        medList.forEach(item=>{
            if (item.checked) {
                SUM+=1
            }
        })
        setCartSum(SUM)
        setRefresh(true)
        // getCartList(userId)
    }
    useEffect(() => {
    //   console.log(cartPrice);
    }, [cartPrice])

    //点击结算判断
    const toOrder = () => {
        if (!userId) {
            redirectDispatch('/cart')
            props.history.push('/login')
        }
        if (cartArr.length > 0) {
            // console.log(cartArr)
            if (type == "2") {
                if (useraddress == "无") {
                    props.history.push("/mine/adress")
                    Toast.show({
                        content:"请先设置地址",
                        duration:1000
                    })
                } else {
                    if (cartPrice / 100 < cartArr[0].pharmacy.startmoney) {
                        setVisible1(true)
                    } else {
                        // console.log(cartArr);
                        props.history.push({
                            pathname: '/fillorder',
                            state: cartArr,
                            params: {
                                freeFreight: freeFreight,
                                orderFreight: orderFreight
                            }
                        })

                        // props.history.push({ pathname: '/fillorder', state: cartArr })
                    }
                }

            } else {
                props.history.push({ pathname: '/fillorder', state: cartArr })
            }


        } else {
            Toast.info('你还没有选择药品哦', 1)
        }
    }
    //清除购物车
    const delCart = () => {
        let cartIdArr = []
        cartArr.forEach(item => {
            item.medList.forEach(item1 => {
                cartIdArr.push(item1.cartId)
            })
        })
        // console.log(cartArr);
        alert('是否删除所选商品？', '', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            {
                text: '确认', onPress: () => {
                    setdeleteStaus(false)
                    delCartRequest({ cartId: cartIdArr }).then(res => {
                        if (res.code === 1) {
                            cref.getarrlist()
                            getCartList(userId)
                            editManage()
                            setCartArr([])
                        }
                    })
                }
            },
        ])

        setRefresh(true)
    }

    const addCollection = () => {
        let newCartList = cartList
        let params = {
            array: []
        }
        newCartList.forEach(item => {
            params.array.push({
                userId: userId,
                medId: item.medId
            })
        })
        if (params.array.length > 0) {
            addcollectRequest(params).then(res => {
                Toast.info('已加入收藏', 1)
            })
        }
    }

    const renderShopContent = (tabindex) => {
        return (
            <div className='cart-shop'>
                <div className='cart-nodata'>
                    <NoData type='购物车' />
                </div>
                <div className='recommend-med'>
                    <div className='recommend-med-title flex-apart-row'>
                        <div>推荐商品</div>
                        {/* <div>{env === 'single' ? '推荐商品' : '推荐好药'}</div> */}
                        {/* <div className='vary-line'/>
                        <img src={rmd} alt=''/>
                        <div className='vary-line'/> */}
                    </div>
                    <div className='recommend-med-list'>
                        <RecommmendMed recommand={recommand} />
                    </div>
                </div>
            </div>
        )
    }

    let arr = []
    cartList.forEach(i => {
        if (i.checked) {
            arr.push(i.pharmacyName)
        }
    })
    for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
            if (arr[i] === arr[j]) {
                arr.splice(j, 1);
                j--
            }
        }
    }
    // console.log(arr);
    const renderMedContent = (tabindex) => {
        //console.log(cartPrice)
        return (
            <div>
                {cartList.length > 0 ?
                    <div>
                        <div className='cart-show-part'>
                            {pharmacyList.map((shop, i) => {
                                return (
                                    <div key={i}>
                                        <div className='cart-part flex-col'>
                                            <div className='cart-drugstore flexc'>
                                                <CheckboxItem onChange={(e) => { checkStore(e, shop, i) }} checked={shop.checked}>{shop.pharmacyName}{type == "2" ? <button style={{ backgroundColor: "#FF5555", color: "white", border: "none", float: "right", width: "43px", height: "20px", borderRadius: "10px" }}>领卷</button> : null} </CheckboxItem>
                                                <div className='cart-drugstore-activity '>
                                                    {type == "2" ? <div className='drugstore-manjian flexr'>
                                                        {/* {cartPrice / 100 >= shop.startmoney ? <div className='flex-row'>满减</div> : null} */}
                                                        {shop.startmoney ? <span>满{shop.startmoney}起送</span> : null} {shop.freeFreight ? <span>满{shop.freeFreight}包邮</span> : null}
                                                    </div> : null}
                                                    {/* {type == "2" & cartPrice / 100 < 2000 ? <div className="drugstore-youhui">
                                                        该店铺还差 <span>￥{cartPrice / 100 < 1000 ? (1000 - cartPrice / 100).toFixed(2) : cartPrice / 100 >= 1000 & cartPrice / 100 < 2000 ? (2000 - cartPrice / 100).toFixed(2) : 0}  <span style={{ color: "black" }}>减</span>  ￥{cartPrice / 100 < 1000 ? 200 : cartPrice / 100 <= 2000 & cartPrice / 100 > 1000 ? 500 : null}</span><span onClick={() => props.history.push(`/shop/${shop.pharmacyId}`)}>去凑单 ＞</span>
                                                    </div> : null} */}
                                                    {type == "2" ? null :
                                                        <div onClick={() => props.history.push(`/shop/${shop.pharmacyId}`)}>
                                                            <span>去逛逛</span>
                                                            <i className='iconfont icon-xiangyou'></i>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                            {cartList.map((v, index) => {
                                                return (shop.pharmacyId === v.pharmacyId ?
                                                    <div key={index} className='cart-drugstore flexc'>
                                                        <div className='cart-med'>
                                                            <div className='cart-item-select'>
                                                                <CheckboxItem onChange={(e) => changebuy(e, v, index, i)} checked={v.checked} className='my-radio' />
                                                            </div>
                                                            <div className='cart-med-info'
                                                                onClick={() => props.history.push(`/med/${v.medId}`)}>
                                                                <img src={v.medPhoto ? v.medPhoto : defaultImg} alt=''
                                                                    onError={(e) => { e.target.onerror = null; e.target.src = defaultImg }} />
                                                                <div className='cart-med-detail flexc'>
                                                                    <div className='flexc'>
                                                                        {v.medClassify === '1' ? <p><span className='cfy'>处方药</span>{v.medName}</p> :
                                                                            <p>{v.medName}</p>}
                                                                        <span>{v.medSpec}</span>
                                                                    </div>
                                                                    <p>￥{v.medPrice / 100}</p>
                                                                </div>
                                                            </div>

                                                            <Stepper
                                                                showNumber={true}
                                                                style={{
                                                                    '--border-inner': 'none',
                                                                    '--button-background-color': '#F8F9FA',
                                                                    '--height': '30px',
                                                                    '--input-width': '40px',
                                                                    '--active-border': '1px solid #1677ff',
                                                                    '--button-text-color': type == "2" ? "red" : "blue"
                                                                }}
                                                                // max={10}
                                                                min={1}
                                                                className='changnum'
                                                                value={v.medQuantity}
                                                                onChange={(value) => { quantity(index, value) }}
                                                            />
                                                        </div>
                                                        {index !== cartList.length - 1 ?
                                                            <div style={{
                                                                height: '1px',
                                                                width: '93%',
                                                                marginLeft: '20px',
                                                                backgroundColor: '#DDDFE5'
                                                            }}></div>
                                                            : null}
                                                    </div> : null
                                                )
                                            })}
                                            <WhiteSpace></WhiteSpace>
                                            {/* {orderShipping === '1' || address === null ? null :
                                        <Item className='price-red'
                                            extra={`+ ¥${posProvince === address.province ? cartArr[0].pharmacy.freight / 100 : (cartArr[0].pharmacy.freight + 6) / 100}`}>运费</Item>
                                    } */}
                                            {/* <Item className='price-red' extra={'- ¥0'}>配送费</Item>
                                    <Item className='price-red' extra={'- ¥0'}>优惠</Item>
                                    <Item extra={`¥${ 100}`}>商品金额</Item> */}
                                            <div className='other-tishi'>
                                                {type == "2" ? <p className="other-tishi-p1">
                                                    {/* <span>已满减 </span> <span style={{ color: "#FF5555", marginRight: "20px" }}>￥{cartPrice / 100 >= 1000 & cartPrice / 100 < 2000 ? 200 : cartPrice / 100 >= 2000 ? 500 : 0}.00</span> */}
                                                    合计  <span style={{ fontSize: "18px" }}>￥{cartPriceNow / 100}</span>
                                                </p> : <>
                                                    <p >
                                                        <span>配送费</span>
                                                        <span>- ¥0.00</span>
                                                    </p>
                                                    <p>
                                                        <span>优惠劵</span>
                                                        <span style={{ color: '#F34848' }}>- ¥0.00</span>
                                                    </p>
                                                </>}


                                            </div>
                                        </div>
                                        <WhiteSpace />
                                    </div>
                                )
                            })}
                            <div className='recommend-med'>
                                <div className='recommend-med-title flex-apart-row'>
                                    <div>平台推荐</div>
                                    {/* <div className='vary-line'/>
                                <img src={rmd} alt=''/>
                                <div className='vary-line'/> */}
                                </div>
                                <div className='recommend-med-list cart-med-list'>
                                    {recommand.length === 0 ? null : <RecommmendMed sunthis={cref} getCartList={getCartList} recommand={recommand} />}
                                </div>
                            </div>
                        </div>
                        {arr.length > 1 ? <div>
                            <div className='tishi-jiesuan'>暂时仅支持单店铺下单</div>
                            <div className='xiaosanjiao'></div>
                        </div> : null}
                        <div className='cart-sum flex-apart-row'>
                            <CheckboxItem checked={allChecked}
                                onChange={(e) => { allCartChecked(e) }}
                                className='my-radio' >全选</CheckboxItem>
                            {edit ?
                                <div className='edit-cart flex-apart-row'>
                                    <div onClick={() => delCart()}>删除</div>
                                    {/* <div onClick={()=>addCollection()}>加入收藏</div> */}
                                </div> : type == "2" ?
                                    <div className='cart-to-order flex-apart-row'>
                                        <div style={{ textAlign: "right", width: "40%" }}>
                                            <p style={{ marginBottom: "5px" }}>合计：<span style={{ color: "#FF5555" }}>￥{cartPriceNow / 100}</span></p>
                                            <p style={{ color: "#FF5555" }} onClick={() => setVisible2(true)}>金额明细</p>
                                        </div>

                                        {arr.length <= 1 ? <div onClick={() => toOrder()} className='cart-count flex-row' style={{ backgroundColor: "#FF5555" }}>去结算</div> :
                                            <div className='cart-count flex-row' style={{ backgroundColor: '#CCCCCC' }}>去结算</div>}
                                    </div> :
                                    <div className='cart-to-order flex-apart-row'>
                                        <p>总计：{cartSum}件</p>
                                        <p>￥{cartPriceNow / 100}</p>
                                        {arr.length <= 1 ? <div onClick={() => toOrder()} className='cart-count flex-row'>去结算</div> :
                                            <div className='cart-count flex-row' style={{ backgroundColor: '#CCCCCC' }}>去结算</div>}
                                    </div>
                            }
                        </div>

                    </div> :
                    <div>
                        <div className='cart-nodata'><NoData type='购物车' /></div>
                        {localStorage.singleTob === '1' ? "" :
                            <div className='recommend-med'>
                                <div className='recommend-med-title flex-apart-row'>
                                    <div>平台推荐</div>
                                    {/* <div className='vary-line'/>
                            <img src={rmd} alt=''/>
                            <div className='vary-line'/> */}
                                </div>
                                <div className='recommend-med-list cart-med-list'>
                                    {recommand.length === 0 ? null : <RecommmendMed getCartList={getCartList} sunthis={cref} recommand={recommand} />}
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }

    const onRef = (ref) => {
        //console.log(ref);
        setcref(ref)
    }
    //console.log(cartPriceNow);
    return (
        <div style={{ paddingBottom: "30px" }}>
            <div className='shopcart-header flex-apart-row'>
                <div className='shopcart-scan' onClick={cameraScan}>
                    <i className='iconfont-l icon-saoyisao'></i>
                </div>
                <p onClick={editManage}>编辑管理</p>

                <div className='shopcart-header-classify flex-row'>
                    <div className='flex-row' onClick={() => settabindex(0)}>商品</div>
                    {/* {env === 'single' ? null :
                        <div className='flex-row' onClick={()=>settabindex(1)}>药品</div>
                    }
                    {env === 'single' ? null :
                        <div className='shopcart-choose-tab'
                        style={{left:`${(tabindex+1)*25}%`}} />
                    } */}
                </div>
            </div>
            <WhiteSpace />
            {tabindex === 0 ? renderMedContent(tabindex) : renderShopContent(tabindex)}
            <Popup
                visible={visible1}
                onMaskClick={() => {
                    setVisible1(false)
                }}
                bodyStyle={{ minHeight: '40vh', borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
            >
                <div className="P-body">
                    <div className="P-body-top">
                        <img src={icon} alt="" onClick={() => setVisible1(false)} />
                    </div>
                    <p className="P-body-title">未满起送金额</p>
                    <div className="P-body-text">
                        <div>
                            <p>{nowstorename}</p>
                            {cartArr.length ? <p>满{cartArr[0].pharmacy.startmoney}元起发，还差{(cartArr[0].pharmacy.startmoney - cartPrice / 100).toFixed(2)}元</p> : null}
                        </div>
                        <div>
                            <button onClick={() => props.history.push(`/shop/${nowstoreId}`)}>去凑单</button>
                        </div>
                    </div>
                    <div className="P-body-tishi">
                        未满起送金额，对应商家可选择不接受此笔订单，请联系商家
                    </div>
                    <div className='cart-sum flex-apart-row'>
                        <CheckboxItem checked={allChecked}
                            onChange={(e) => { allCartChecked(e) }}
                            className='my-radio' >全选</CheckboxItem>
                        {edit ?
                            <div className='edit-cart flex-apart-row'>
                                <div onClick={() => delCart()}>删除</div>
                                {/* <div onClick={()=>addCollection()}>加入收藏</div> */}
                            </div> : type == "2" ?
                                <div className='cart-to-order flex-apart-row'>
                                    <div style={{ textAlign: "right", width: "40%" }}>
                                        <p style={{ marginBottom: "5px" }}>合计：<span style={{ color: "#FF5555" }}>￥{cartPriceNow / 100}</span></p>
                                        <p style={{ color: "#FF5555" }} onClick={() => setVisible2(true)}>金额明细</p>
                                    </div>

                                    {arr.length <= 1 ? <div onClick={() => toOrder()} className='cart-count flex-row' style={{ backgroundColor: "#FF5555" }}>去结算</div> :
                                        <div className='cart-count flex-row' style={{ backgroundColor: '#CCCCCC' }}>去结算</div>}
                                </div> :
                                <div className='cart-to-order flex-apart-row'>
                                    <p>总计：{cartSum}件</p>
                                    <p>￥{cartPriceNow / 100}</p>
                                    {arr.length <= 1 ? <div onClick={() => toOrder()} className='cart-count flex-row'>去结算</div> :
                                        <div className='cart-count flex-row' style={{ backgroundColor: '#CCCCCC' }}>去结算</div>}
                                </div>
                        }
                    </div>
                </div>
            </Popup>

            <Popup
                visible={visible2}
                onMaskClick={() => {
                    setVisible2(false)
                }}
                bodyStyle={{ minHeight: '40vh', borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
            >
                <div className="P-body2">
                    <div className="P-body2-top">
                        <img src={icon} alt="" onClick={() => setVisible2(false)} />
                    </div>
                    <div className="P-body2-title">
                        金额明细
                    </div>
                    <div className="P-body2-name">
                        {nowstorename}
                    </div>
                    <div className="P-body2-text">
                        <div>
                            <span className="P-body2-text-left">商品总价</span>
                            <span className="P-body2-text-right">￥{cartPrice / 100}</span>
                        </div>
                        <div>
                            <span className="P-body2-text-left">邮费{cartPriceNow == cartPrice ? null : <span>（还差￥{(freeFreight - cartPrice / 100).toFixed(2)}包邮）</span>}</span>
                            <span className="P-body2-text-right" style={{ color: "#FF5555" }}>{cartPrice == cartPriceNow ? "免邮费" : `￥${orderFreight}`}</span>
                        </div>
                        <div>
                            <span className="P-body2-text-left">小计</span>
                            <span className="P-body2-text-right">￥{cartPriceNow / 100}</span>
                        </div>
                    </div>
                </div>
            </Popup>
            <FootNav onRef={onRef} />
        </div>
    )
}

// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({
    user: state.user.user,
    cart: state.cart
})


// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
    return {
        redirectDispatch(data) {
            dispatch(resetdirect(data))
        },
    }
}

// 将ui组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(Shopcart)