import { axiosInstance } from "../config"

//首页分类活动
export const homeactivityRequest = (pharmacyId, longitude,latitude,type) => {
    return axiosInstance.get(`/api/hosp/home/index1?pharmacyId=${pharmacyId}&longitude=${longitude}&latitude=${latitude}&to_type=${type}`)
}

//限时秒杀
export const limitactivityRequest = (categoryId) => {
    return axiosInstance.get(`/api/hosp/home/limit?categoryId=${categoryId}`)
}

//首页药品推荐
export const homerecommandRequest = (data) => {
    return axiosInstance.post('/api/hosp/home/index2', data)
}

//单店铺首页
export const singleHomeRequest = (pharmacyId) => {
    return axiosInstance.get(`/api/hosp/home/single_index?pharmacyId=${pharmacyId}`)
}

//热销商品
export const hotmedRequest = (data) => {
    return axiosInstance.post('/api/hosp/home/hotmed', data)
}
