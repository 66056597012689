import React, { Component } from 'react';
import "./style.less"
import {Toast} from "antd-mobile"
import icon1 from "../../../../static/img/yy/fanhuihei.png"
import illbox from "../../../../static/img/illbox.png"

import { searchgoods } from "../../../../api/hospitalapi/news"
import Nodata from "../../../../component/Nodata";

export default class index extends Component {
    constructor(props) {
        super()
        // console.log(props);

        this.state = {
            goodslist: [],
            content: "",
            page:1,
            recipeType:localStorage.getItem('recipeType')?localStorage.getItem('recipeType'):2,
            somegoods:JSON.parse(localStorage.getItem("somegoods"))?JSON.parse(localStorage.getItem("somegoods")):[],
            // 食用天数
            medicinaldays:JSON.parse(localStorage.getItem("medicinaldays"))?JSON.parse(localStorage.getItem("medicinaldays")):[],
        }
    }
    componentDidMount = () => {
        const { somegoods ,medicinaldays} = this.state
        // console.log(somegoods);
    }
    gobank = () => {
        window.history.back()
    }
    //点击搜索
    search = async () => {
        const { content,page,recipeType } = this.state
        const res = await searchgoods({
            content,
            supervisionType:recipeType
        })
        // console.log(res)
        if (res&&res.data){
            this.setState({
                goodslist: res.data[0],
                // page:page+1
            })
        }
        //  console.log(res);
    }
    // 获取输入框值
    getvalue = (e) => {
        this.setState({
            content: e.target.value
        })
    }
    // 点击商品计入列表
    pushitem=(item)=>{
        // console.log(item);
        const {somegoods,medicinaldays}=this.state
        if(somegoods.length){
            let code=false
            if(somegoods.length>=5){
                Toast.fail("最多添加5种药品",1)
            }else{
                somegoods.forEach(sun=>{
                    if(sun.med_id==item.med_id){
                        Toast.fail("请勿重复添加",1)
                        code=true
                    }
                })
                if(!code){
                    somegoods.push(item)
                    let arr={
                        med_id:item.med_id,
                        medicinalDay:'',
                        frequency:'',
                        company:''
                    }
                    medicinaldays.push(arr)
                    Toast.success("添加成功",1)
                }
            }
        }else{
            somegoods.push(item)
            let arr1={
                med_id:item.med_id,
                medicinalDay:'',
                frequency:'',
                company:''
            }
            medicinaldays.push(arr1)
            Toast.success("添加成功",1)
        }
        // console.log(somegoods)
        localStorage.setItem("somegoods",JSON.stringify(somegoods))
        localStorage.setItem("medicinaldays",JSON.stringify(medicinaldays))

        this.setState({
            somegoods,
            medicinaldays
        })
    }
    onscroll = e => {
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <=0) {
            this.pageMedlist()
        }
    }
    pageMedlist=async ()=>{
        const { content,page,goodslist,recipeType } = this.state
        const res = await searchgoods({
            content,
            supervisionType:recipeType,
            page:page,
            size:20
        })
        // console.log(res)
        if (res&&res.data){
            this.setState({
                goodslist: [...goodslist,...res.data[0]],
                page:page+1
            })
        }
    }
    render() {
        const { content, goodslist,somegoods } = this.state
        return (
            <div className='addsearchbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>新增药品</h1>
                </div>
                <div className='box-content'>
                    <div className='content-search'>
                        <input type="text" value={content} onChange={(e) => this.getvalue(e)} placeholder='搜药品' /><button onClick={this.search}>搜索</button>
                    </div>
                    {/* <div className='content-text'>
                        <img src="" alt="" />
                        <div>
                            <p>
                                <span>处方药</span>【品牌】阿司匹林胶囊
                            </p>
                            <p>规格：80mg*7粒/盒</p>
                            <p>口服：成人一次xxxxx每天xxxx</p>
                        </div>
                        <button>加入处方</button>
                    </div> */}
                <div style={{ width: '100%', height: '100vh', overflow: 'scroll' }}
                    onScroll={(e)=>this.onscroll(e)}>
                    {goodslist.length?goodslist.map((item, index) => {
                        return (
                            <div>
                                <div className='content-text' key={item.med_id}>
                                    <img src={item.med_photo} alt="" />
                                    <div>
                                        <p>
                                            {item.med_classify=='1'?<span style={{padding:"0 5px"}}>Rx</span>:null}【品牌】{item.med_name}
                                        </p>
                                        <p>规格：{item.med_spec}</p>
                                        <p>口服：{item.med_dosage_form}</p>
                                        <p>厂家：{item.med_company}</p>
                                    </div>
                                    <button onClick={()=>this.pushitem(item)}>加入处方</button>
                                </div>
                                {index!=item.length-1?<div style={{height:'1px',backgroundColor:"#ccc",marginTop:'10px'}}></div>:null}
                            </div>
                        )
                    }):<Nodata type='药品'/>}
                </div>

                </div>
                <div className='box-bottom'>
                    <p className='box-bottom-left' >
                        <img src={illbox} alt="" />
                        <p className='yuan '>{somegoods.length}</p>
                        <span>已选{somegoods.length}种药品</span>
                    </p>
                    <button onClick={this.gobank}>确定</button>
                </div>
            </div>
        )
    }
}
