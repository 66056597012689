import React, { Component } from 'react'
import "./style.less"
import { ImageUploader, Toast, Popup } from "antd-mobile-v5"
import icon1 from "../../static/img/yy/fanhui2.png"
import icon2 from "../../static/img/yy/right.png"
import icon3 from "../../static/img/yy/bz.png"
import icon4 from "../../static/img/yy/dian.png"
import icon5 from "../../static/img/yy/pic.png"
import icon6 from "../../static/img/yy/write.png"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            fileList: [],
            visible1: false
        }
    }
    mockUpload = async (file) => {
        return {
            url: URL.createObjectURL(file),
        }
    }
    setFileList = (list) => {
        this.setState({
            fileList: list
        })
    }
    beforeUpload = (files) => {
        return files.filter(file => {
            if (file.size > 1024 * 1024) {
                Toast.show('请选择小于 1M 的图片')
                return false
            }
            return true
        })
    }
    openbox = () => {
        this.setState({
            visible1:true
        })
    }
    gobank=()=>{
        window.history.back()
    }
    render() {
        const { fileList, visible1 } = this.state
        return (
            <>
                <div className='writebox'>
                    <div className='box-top'>
                        <img src={icon1} alt="" onClick={this.gobank}/>
                    </div>
                    {/* <div className='box-chiose'>
                    <div>
                        <p>选择就诊人</p>
                    </div>
                    <img src={icon2} alt="" />
                </div> */}
                    <div className='box-chiose'>
                        <div>
                            <p>周舸明</p>
                            <p style={{ color: "#999999" }}><span>男</span>   <span> 27岁</span>    <span>138****6418</span></p>
                        </div>
                        <img src={icon2} alt="" onClick={this.openbox} />
                    </div>
                    <div style={{ height: "20px" }}></div>
                    <div className='box-text'>
                        <h1>描述病情</h1>
                        <textarea name="" id="" cols="48" rows="8" placeholder='为了更好的获得医生帮助，请尽可能详细描述您 的病情。'></textarea>
                        <p className='text-title'>上传检查单/患处图片（最多6张）</p>
                        <div className='text-pic'>
                            <ImageUploader
                                value={fileList}
                                maxCount={6}
                                onChange={(list) => this.setFileList(list)}
                                upload={this.mockUpload}
                                beforeUpload={this.beforeUpload}
                            />
                        </div>
                    </div>
                    <div className='box-tips'>
                        <img src={icon3} alt="" className='tips-img' />
                        <div className='tips-item'>
                            <div>
                                <img src={icon4} alt="" /><span>实名医生</span>
                            </div>
                            <div>
                                <img src={icon4} alt="" /><span>及时回复</span>
                            </div>
                            <div>
                                <img src={icon4} alt="" /><span>多次沟通</span>
                            </div>
                            <div>
                                <img src={icon4} alt="" /><span>退款保障</span>
                            </div>
                        </div>
                    </div>
                    <div className='box-money'>
                        <img src={icon5} alt="" />
                        <div>
                            <h1>图文问诊</h1>
                            <p>最长48小时图文多次沟通</p>
                        </div>
                    </div>
                    <div className='box-bottom'>
                        <button>支付</button>
                    </div>
                </div>
                <Popup
                    visible={visible1}
                    className='weitepopup'
                    onMaskClick={() => {
                        this.setState({
                            visible1: false
                        })
                    }}
                    bodyStyle={{ minHeight: '50vh' }}
                >
                    <div className='popup-box'>
                        <h1>选择就诊人</h1>
                        <div className='pbox-item'>
                            <div>
                                <p>周舸明</p>
                                <p>
                                <span>男</span>    <span>27岁</span>     <span>138****6418</span>
                                </p>
                            </div>
                            <div>
                                <span>已选择</span><img src={icon6} alt="" />
                            </div>
                        </div>
                        <div className='pbox-item'>
                            <div>
                                <p>周舸明</p>
                                <p>
                                <span>男</span>    <span>27岁</span>     <span>138****6418</span>
                                </p>
                            </div>
                            <div>
                                <span>已选择</span><img src={icon6} alt="" />
                            </div>
                        </div>
                        <div className='pbox-btn'>
                            <button onClick={()=>{
                                this.props.history.push("/patient")
                            }}>新增就诊人</button>
                        </div>
                    </div>
                </Popup>
            </>
        )
    }
}
