import { axiosInstance } from '../config'

//修改用户信息
export const changedoctor = (data) => {
    return axiosInstance.post('/api/hosp/doctor_manage/doc-update', data)
}
//查询历史电子处方
export const allEprescribing = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/all-eprescribing', data)
}
//医生删除电子处方
export const deletecf = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/deletecf', data)
}
//保存原始处方单
export const oldPrescribing = (data) => {
    return axiosInstance.post('/api/hosp/Consultation/old_prescribing', data)
}
//保存医生签名图片
export const updateDocterAutograph = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/update-docter-autograph', data)
}
//问诊记录
export const allWzrecode = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/all-wzrecode', data)
}


// 获取其他签名
export const getSignature = (data) => {
    return axiosInstance.post('/faas/hosp/scca/get-signature', data)
}

// 更新ca签名值	
export const updateSignature = (data) => {
    return axiosInstance.post('/api/hosp/scca/update-signature-value', data)
}
