import React, { useState, useEffect } from 'react'
import { List, InputItem, Toast } from 'antd-mobile'
import './style.less'
import next from '../../static/img/next.png'
import finish from '../../static/img/finish.png'
import fanhui from '../../static/img/fanhui.png'

import { qualificationsList,getProvince,getCity,getDistrict} from '../../api/request/qualifications'
import Region from '../../application/region'
export default function Qualifications(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const [user, setUser] = useState(null)
    const [area, setArea] = useState(null)
    const [address, setAddress] = useState(null)
    const [person, setPerson] = useState(null)
    const [tel, setTel] = useState(null)
    const [tipsuser, setTipsuser] = useState(null)
    const [tipsarea, setTipsarea] = useState(null)
    const [tipsaddress, setTipsaddress] = useState(null)
    const [tipsperson, setTipsperson] = useState(null)
    const [tipstel, setTipstel] = useState(null)
    const [list, setList] = useState([])
    const [province, setProvince] = useState()
    const [city, setCity] = useState()
    const [county, setCounty] = useState()
    const [provinces, setProvinces] = useState([])
    const [cities, setCities] = useState([])
    const [counties, setCounties] = useState([])
    const [parentId1, setparentId1] = useState(null)
    const [parentId2, setparentId2] = useState(null)

    
    useEffect(() => {
        qualificationsList().then(res => {
            // console.log(res);
            for (let i = 0; i < res.data[0].length; i++) {
                if (res.data[0][i].user_id == userId) {
                    setList(res.data[0][i])
                    setUser(res.data[0][i].company_name)
                    // setArea(res.data[0][i].company_location)
                    setAddress(res.data[0][i].company_address)
                    setPerson(res.data[0][i].contacts)
                    setTel(res.data[0][i].contact_phone)
                    setProvince(res.data[0][i].province)
                    setCity(res.data[0][i].city)
                    setCounty(res.data[0][i].district)
                }

            }
        })
        getProvince().then(res=>{
            // console.log(res);
            setProvinces(res.data[0])
        })
    }, [])
    useEffect(() => {
        let params={parentId:parentId1}
        getCity(params).then(res=>{
            // console.log(res);
            setCities(res.data[0])
        })
    }, [parentId1,province])
    useEffect(() => {
        getDistrict({parentId:parentId2}).then(res=>{
            // console.log(res);
            setCounties(res.data[0])
        })
    }, [parentId2,city])
    // console.log(props);
    const selectAddress = (e, address) => {
        switch (e) {
            case 'province':
                setProvince(address.target.value)
                provinces.map(item=>{
                    if (item.name===address.target.value) {
                        setparentId1(item.id)
                    }
                })
                break;
            case 'city':
                setCity(address.target.value)
                cities.map(item=>{
                    // console.log(item);
                    if (item.name===address.target.value) {
                        setparentId2(item.id)
                    }
                })
                break;
            case 'county':
                setCounty(address.target.value)
                break;
            default:
                break;
        }
    }
    // console.log(parentId2);        
    useEffect(() => {
    }, [province,city,county])
    const handleChange = (key, val) => {
        switch (key) {
            case 'user':
                setUser(val)
                break;
            case 'address':
                setAddress(val)
                break;
            case 'person':
                setPerson(val)
                break;
            case 'tel':
                setTel(val)
                break;
            default:
                break;
        }
    }
    const nextStep = () => {
        const params = {
            userId: userId,
            user: user,
            // area: province+city+county,
            province:province,
            city:city,
            county:county,
            address: address,
            person: person,
            phone: tel
        }
        
        let reg = /^1[3|4|5|8][0-9]\d{4,8}$/;
        if (params.user) {
            if (params.province&&params.city&&params.county) {
                if (params.address) {
                    if (params.person) {
                        if (params.phone) {
                            if (reg.test(params.phone)) {
                                setTipstel(null);
                                setTipsperson(null);
                                setTipsaddress(null);
                                setTipsarea(null);
                                setTipsuser(null)
                                props.history.push({ pathname: '/upload', state: params })
                            } else {
                                setTipstel("请填写正确手机号码");
                                setTipsperson(null);
                                setTipsaddress(null);
                                setTipsarea(null);
                                setTipsuser(null)
                            }
                        } else {
                            setTipstel("未填写手机号码");
                            setTipsperson(null);
                            setTipsaddress(null);
                            setTipsarea(null);
                            setTipsuser(null)
                        }
                    } else {
                        setTipsperson("未填写采购负责人姓名");
                        setTipsaddress(null);
                        setTipsarea(null);
                        setTipsuser(null)
                        setTipstel(null);
                    }
                } else {
                    setTipsaddress("未填写详细地址");
                    setTipsarea(null);
                    setTipsuser(null)
                    setTipstel(null);
                    setTipsperson(null);
                }
            } else {
                setTipsarea("未填写区域或区域没选完整");
                setTipsuser(null)
                setTipstel(null);
                setTipsperson(null);
                setTipsaddress(null);
            }
        } else {
            // Toast.fail("未填写收货人姓名");
            setTipsuser('未填写采购商营业执照名称')
            setTipstel(null);
            setTipsperson(null);
            setTipsaddress(null);
            setTipsarea(null);
        }
    }
    let id1=0
    let id2=0
    let id3=0
    const fanHui=()=>{
        props.history.push('/mine')
    }
    return (
        <>
            <div className='outer'>
                <header>
                    <img src={fanhui} onClick={()=>fanHui()} alt="" />
                    <h2>资质上传</h2>
                    <p className='step'>
                        <span className='cg-news'>采购商信息</span>
                        <span className='xian'></span>
                        <span>相关证件</span>
                        <span className='xian'></span>
                        <span>等待审核</span>
                    </p>
                </header>
                {list ? list.examine === '-1' ? <List className='content' style={{ boxSizing: "border-box" }}>
                    <InputItem placeholder='请填写采购商营业执照名称' value={user}
                        onChange={v => handleChange('user', v)}
                    >采购商</InputItem><span className='tipsuser'>{tipsuser}</span>
                    <InputItem
                    // placeholder='请填写所在区域（省、市、区）' value={area}
                    // onChange={v => handleChange('area', v)}
                    >所在区域</InputItem>
                   <div className='select-area'>
                        <select className="select1" onChange={(e)=>selectAddress("province",e)}>
                            <option value="">选择省</option>
                        {provinces.map(item => (
                            <option value={item.name} key={id1++}>{province?province:item.name}</option>
                        ))}
                        </select>
                        <select className="select1" onChange={(e)=>selectAddress("city",e)}>
                        <option value="">选择市</option>
                            {cities.map(item => (
                                <option value={item.name} key={id2++}>{city?city:item.name}</option>
                            ))}
                        </select>
                        <select className="select1" onChange={(e)=>selectAddress("county",e)}>
                        <option value="">选择区</option>
                            {counties.map(item => (
                                <option value={item.name} key={id3++}>{county?county:item.name}</option>
                            ))}
                        </select>
                    </div>
                    <InputItem
                        placeholder='详细地址，例1层101室' value={address}
                        onChange={v => handleChange('address', v)}
                    >详细地址</InputItem><span className='tipsaddress'>{tipsaddress}</span>
                    <InputItem
                        placeholder='请填写采购负责人姓名' value={person}
                        onChange={v => handleChange('person', v)}
                    >采购负责人</InputItem><span className='tipsperson'>{tipsperson}</span>
                    <InputItem
                        placeholder='请填写负责人手机号码' value={tel}
                        onChange={v => handleChange('tel', v)}
                    >联系方式</InputItem><span className='tipstel'>{tipstel}</span>
                    <p className='xian'></p>
                    <div className='next' onClick={() => nextStep()}>
                        {user && province&&city&&county && address && person && tel ? <img src={finish} alt="" /> : <img src={next} alt="" />}
                        <span>下一步</span>
                    </div>
                </List> : <List className='content' style={{ boxSizing: "border-box" }}>
                    <InputItem placeholder='请填写采购商营业执照名称'
                        onChange={v => handleChange('user', v)}
                    >采购商</InputItem><span className='tipsuser'>{tipsuser}</span>
                    <InputItem
                    // placeholder='请填写所在区域（省、市、区）'
                    // onChange={v => handleChange('area', v)}
                    >所在区域 </InputItem>
                    <div className='select-area'>
                        <select className="select1" onChange={(e)=>selectAddress("province",e)}>
                        <option value="" >选择省</option>
                        {provinces.map(item => (
                            <option value={item.name} key={id1++}>{item.name}</option>
                        ))}
                        </select>
                        <select className="select1" onChange={(e)=>selectAddress("city",e)}>
                        <option value="" >选择市</option>
                            {cities.map(item => (
                                <option value={item.name} key={id2++}>{item.name}</option>
                            ))}
                        </select>
                        <select className="select1" onChange={(e)=>selectAddress("county",e)}>
                        <option value="" >选择区</option>
                            {counties.map(item => (
                                <option value={item.name} key={id3++}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                    {/* <Region selectAddress={selectAddress} province1={province} city1={city} county1={county}></Region> */}
                    {/* <span className='tipsarea'>{tipsarea}</span> */}
                    <InputItem
                        placeholder='详细地址，例1层101室'
                        onChange={v => handleChange('address', v)}
                    >详细地址</InputItem><span className='tipsaddress'>{tipsaddress}</span>
                    <InputItem
                        placeholder='请填写采购负责人姓名'
                        onChange={v => handleChange('person', v)}
                    >采购负责人</InputItem><span className='tipsperson'>{tipsperson}</span>
                    <InputItem
                        placeholder='请填写负责人手机号码'
                        onChange={v => handleChange('tel', v)}
                    >联系方式</InputItem><span className='tipstel'>{tipstel}</span>
                    <p className='xian'></p>
                    <div className='next' onClick={() => nextStep()}>
                        {user && province&&city&&county && address && person && tel ? <img src={finish} alt="" /> : <img src={next} alt="" />}
                        <span>下一步</span>
                    </div>
                </List> : null}
            </div>
        </>
    )
}