import React ,{Component}from 'react'
import {Route,Switch} from 'react-router-dom'

import AuthRoute from '../component/authroute/authroute'
import Introduce from '../container/Introduce/index'
import Home from '../container/Home/index'

import Shopcart from '../application/cart/index'
import Health from '../container/Health/index'
import Healthpost from '../container/Health/Post/index'
import Healthrmd from '../container/Health/Recommand/index'
import Fillorder from '../application/cart/fillorder'
import Mine from '../container/Mine/index'
import Collection from '../container/Mine/collection/index'


import ElectronicInvoice from '../container/Mine/electronicInvoice/index'


import Adressedit from '../container/Mine/adress/adressedit/index'
import Coupon from '../container/Mine/coupon/index'
import Viewrecord from '../container/Mine/viewrecord/index'
import Set from '../container/Mine/set/index'
import Login from '../container/Login/login'
import retrieve from '../container/Login/retrieve'


// import Register from '../container/Register/register'
import Search from '../container/Search/index'
import Tidings from '../container/Tidings/index'
import Med from '../application/medicine/index'
import Medlist from '../application/medicine/medlist'
import Recipel from '../application/medicine/recipel'
import Adduser from '../application/medicine/adduser'
import Userlist from '../application/medicine/userlist'
import Shop from '../application/shop/index'
import Shopinfo from "../application/shop/shopinfo/index"
import Shopmed from '../application/shop/shopmed'
import Inquiry from '../container/Inquiry/index'
import InquiryHospial from '../container/Inquiry/onhospital/index'
import InquiryHospialId from '../container/Inquiry/onhospital/onhospital-id'
import InquiryDisease from '../container/Inquiry/ondisease/index'
import InquiryDoctor from '../container/Inquiry/doctor/index'
import PatientInfo from '../container/Inquiry/patient/patientinfo'
import InquiryListImageText from '../container/Inquiry/patient/inquirylist1'
import InquiryListPhone from '../container/Inquiry/patient/inquirylist2'
import InquiryPay from '../container/Inquiry/patient/pay'
import Notfound from '../container/Notfound/index'
import Drag from '../container/Notfound/drag'
import Seckill from '../container/Home/Activity/seckill'
import electronicsignature from '../container/Home/electronicsignature/index'
import yygActivity from '../container/activity/index'
import Actpage from '../container/activity/actpage'


import getUserInfo from "../container/fanhui/getUserInfo"

import Qualifications from '../application/qualifications/index'
import Upload from '../application/qualifications/upload/index'
import Await from '../application/qualifications/await/index'
import storeinfor from "../application/minepf/storeinfor/index"
import storeticket from "../application/minepf/storeticket/index"
import zhuanedit from "../application/minepf/storeticket/zhuanedit/index"
import moedit from "../application/minepf/storeticket/moedit/index"
import storemoney from "../application/minepf/storemoney/index"
import Withdrawal from "../application/minepf/storemoney/Withdrawal/index"
import txDetail from "../application/minepf/storemoney/Withdrawal/txDetail/index"
import bill from "../application/minepf/bill"
import billinfo from "../application/minepf/bill/billinfo"

import detailed from "../application/minepf/storemoney/detailed/index"
import storeSH from "../application/minepf/storeSH/index"
import storeSHin from "../application/minepf/storeSH/storeSHin/index"
import addpass from "../container/Mine/set/addpass/index"

import Region from "../application/region"

import Departmenttype from "../container/Home/Departmenttype/index"

import doctorinfo from "../application/doctorinfo"
import security from "../container/Mine/set/security"
import chat from "../application/doctorinfo/chat"
import chatinfo from "../application/doctorinfo/chat/chatinfo"
import chiosestore from "../application/chiosestore"
import submit from "../application/submit"

import chatdoctor from "../application/chatdoctor"

import writelist from "../application/writelist"
import patient from "../application/addpatient"
import uploaddoc from "../application/uploaddoc"
import setserver from "../container/Home/setserver"
import videoprescription from "../container/Home/videoprescription"
import videodiagnostics from "../container/Home/videodiagnostics"
import account from "../container/Mine/account"
import record from "../container/Mine/record"
import userinfo from "../container/Mine/userinfo"
import prescribing from "../application/prescribing"
import historycf from "../application/prescribing/historycf"
import addcf from "../application/prescribing/addcf"
import prescription from "../application/prescribing/prescription"
import addsearch from "../application/prescribing/addcf/addsearch"
import editprescription from "../application/prescribing/editprescription"
import editnum from "../application/prescribing/editprescription/editnum"
import docterpic from "../container/Mine/userinfo/docterpic"
import news from "../container/news"
import privacyagreement from "../application/privacyagreement"
import agrenagreement from "../application/agrenagreement"
import downapp from "../container/Home/downapp"
import doctype from "../container/Home/doctype"
import receiveorder from "../container/Mine/receiveorder"
import resign from '../container/Mine/resign/index'
//线下药房聊天页面
import offlineDoctorchat from "../application/offlineDoctorchat"
//线下药房医生新建处方
import offaddcf from "../application/prescribing/offaddcf"
class SubRouter  extends Component{
    render(){
        return(
            <div>
                <AuthRoute></AuthRoute>
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route path='/introduce' component={Introduce}/>
                    <Route path='/setserver' component={setserver}/>
                    <Route path='/downapp' component={downapp}></Route>
                    <Route path='/doctype' component={doctype}></Route>
                    <Route path='/receiveorder' component={receiveorder}></Route>
                    <Route path='/privacyagreement' component={privacyagreement}/>
                    <Route path='/agrenagreement' component={agrenagreement}/>
                    <Route path='/videoprescription' component={videoprescription}></Route>
                    <Route path='/videodiagnostics' component={videodiagnostics}></Route>
                    <Route path='/submit' component={submit}></Route>
                    <Route path='/news' component={news}></Route>
                    <Route exact path='/prescribing' component={prescribing}></Route>
                    <Route exact path='/prescribing/editprescription' component={editprescription}></Route>
                    <Route exact path='/prescribing/historycf' component={historycf}></Route>
                    <Route exact path='/prescribing/addcf' component={addcf}></Route>
                    <Route exact path='/prescribing/prescription' component={prescription}></Route>
                    <Route exact path='/prescribing/addcf/addsearch' component={addsearch}></Route>
                    <Route path='/prescribing/editprescription/editnum' component={editnum}></Route>
                    <Route path='/uploaddoc' component={uploaddoc}></Route>
                    <Route path='/chatdoctor' component={chatdoctor}></Route>

                    <Route path='/patient' component={patient}></Route>
                    <Route path='/writelist' component={writelist}></Route>
                    
                    <Route path='/chiosestore' component={chiosestore}></Route>
                    <Route path='/departmenttype' component={Departmenttype}></Route>
                    
                    <Route exact path='/doctorinfo' component={doctorinfo}></Route>
                    <Route exact path='/doctorinfo/chat' component={chat}></Route>
                    <Route path='/doctorinfo/chat/chatinfo' component={chatinfo}></Route>
                    <Route path='/getUserInfo' component={getUserInfo}></Route>
            
                    <Route path='/shopcart' component={Shopcart}></Route>
                    <Route exact path='/health' component={Health}></Route>
                    <Route path='/health/post/:topicId' component={Healthpost}></Route>
                    <Route path='/health/recommand' component={Healthrmd}></Route>
                    <Route path='/fillorder' component={Fillorder}></Route>
                    <Route exact path='/mine' component={Mine}></Route>
                    <Route exact path='/mine/account' component={account}></Route>
                    <Route exact path='/mine/record' component={record}></Route>
                    <Route exact path='/mine/userinfo' component={userinfo}></Route>
                    <Route exact path='/mine/userinfo/docterpic' component={docterpic}></Route>
                    <Route exact path='/mine/set/security' component={security}></Route>
                    <Route path='/mine/collection' component={Collection}></Route>
                   

                    <Route path='/mine/bill' exact component={bill}></Route>
                    <Route path='/mine/bill/billinfo' component={billinfo}></Route>

                    <Route path='/mine/electronicInvoice' component={ElectronicInvoice}></Route>
                    <Route path='/mine/adress/edit/:type/:addressId' component={Adressedit}></Route>
                    <Route path='/mine/coupon' component={Coupon}></Route>
                    <Route path='/mine/storeinfor' component={storeinfor}></Route>
                    <Route path='/mine/storeticket' exact component={storeticket}></Route>
                    <Route path='/mine/storeticket/zhuanedit' component={zhuanedit}></Route>
                    <Route path='/mine/storeticket/moedit' component={moedit}></Route>
                    <Route path='/mine/storemoney' exact component={storemoney}></Route>
                    <Route path='/mine/storemoney/Withdrawal' exact component={Withdrawal}></Route>
                    <Route path='/mine/storemoney/Withdrawal/txDetail' exact component={txDetail}></Route>
                    <Route path='/mine/storemoney/detailed' exact component={detailed}></Route>
                    <Route path='/mine/storeSH' exact component={storeSH}></Route>
                    <Route path='/mine/storeSH/storeSHin' component={storeSHin}></Route>
                    <Route path='/mine/viewrecord' component={Viewrecord}></Route>
                    {/* <Route path='/mine/set' component={Set}></Route> */}
                    
                    <Route path='/mine/set' exact component={Set}></Route>
                    <Route path='/mine/set/addpass' component={addpass}></Route>
                    <Route path='/login' exact component={Login}></Route>
                    <Route path='/login/retrieve' component={retrieve}></Route>
                    <Route path='/mine/resign' component={resign}></Route>
                  
                    {/* <Route path='/register' component={Register}></Route> */}
                    <Route path='/search' component={Search}></Route>
                    <Route path='/tidings' component={Tidings}></Route>
                    <Route exact path='/med/:medId' component={Med}></Route>
                    <Route exact path='/medlist/:categoryId/:classifyId/:content' component={Medlist}></Route>
                    <Route path='/med/recipel' component={Recipel}></Route>
                    <Route path='/med/adduser' component={Adduser}></Route>
                    <Route path='/med/userlist' component={Userlist}></Route>
                    <Route exact path='/shop/:pharmacyId' component={Shop}></Route>
                    <Route  path='/shopinfo' component={Shopinfo}></Route>
                    <Route path='/shop/shopmed' component={Shopmed}></Route>
                    <Route exact path='/inquiry' component={Inquiry}></Route>
                    <Route exact path='/inquiry/hospital' component={InquiryHospial}></Route>
                    <Route path='/inquiry/hospital/id' component={InquiryHospialId}></Route>
                    <Route path='/inquiry/disease' component={InquiryDisease}></Route>
                    <Route path='/inquiry/doctor' component={InquiryDoctor}></Route>
                    <Route path='/inquiry/patientinfo' component={PatientInfo}></Route>
                    <Route path='/inquiry/list/imagetext' component={InquiryListImageText}></Route>
                    <Route path='/inquiry/list/phone' component={InquiryListPhone}></Route>
                    <Route path='/inquiry/pay' component={InquiryPay}></Route>
                    <Route path='/drag' component={Drag}></Route>
                    <Route path='/seckill' component={Seckill}></Route>
                    <Route path='/electronicsignature' component={electronicsignature}></Route>
                    <Route path='/activity' component={yygActivity}></Route>
                    <Route path='/actpage' component={Actpage}></Route>
                    <Route path='/comment' component={Comment}></Route>

                    <Route path='/qualifications' component={Qualifications}></Route>
                    <Route path='/upload' component={Upload}></Route>
                    <Route path='/await' component={Await}></Route>
                    <Route path='/region' component={Region}></Route>

                    

                    <Route exact path='/offlineDoctorchat' component={offlineDoctorchat}/>
                    <Route exact path='/prescribing/offaddcf' component={offaddcf}></Route>
                    <Route path='*' component={Notfound}></Route>


                </Switch>
            </div>
        )
    }
}

export default SubRouter