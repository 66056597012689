import React, { Component } from 'react'
import "./style.less"
import { Tabs } from "antd-mobile-v5"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/doctor/delete.png"
import Nodata from '../../../component/Nodata'
import {allEprescribing, deletecf,allWzrecode} from '../../../api/hospitalapi/mine'
import {getuserinfo} from "../../../api/hospitalapi/login";
import {Modal,Toast} from "antd-mobile";
import moment from 'moment'
import {browseListRequest} from "../../../api/request/history";
import getUrl from "../../../component/GetUrlParam";
const alert = Modal.alert
export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            yslist: [],
            splist: [],
            phonelist:[],
            docter: '',
            docterId:localStorage.getItem("docterId"),
            size:20,
            page:1,
            nowdaycode:0
        }
    }
    componentDidMount = async () => {
        const { docterId } = this.state
        if (getUrl('nowdaycode', this.props.location.search)) {
            this.setState({
                nowdaycode: getUrl('nowdaycode', this.props.location.search)
            })
        }
        if (docterId) {
            this.allHistorylist()
        }
    }
    allHistorylist=async()=>{
        const { docterId,page,size,nowdaycode } = this.state
        const res = await allWzrecode({
            docterId,
            page,
            size,
            createTime:getUrl('nowdaycode', this.props.location.search)?moment().format('YYYY-MM-DD'):''
        })

        if (res&&res.data&&res.data[0]){
            this.setState({
                yslist: res.data[0]
            })
        }
        if (res&&res.data&&res.data[1]){
            this.setState({
                splist: res.data[1]
            })
        }
    }
    // 触底更新数据
    onscroll = e => {
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10) {
            this.pageBrowselist()
        }
    }
    // 触底时换页
    pageBrowselist = () => {
        const { docterId,page,size,yslist,splist,nowdaycode } = this.state
            const params = {
                docterId: docterId,
                page: page + 1,
                size: size,
                createTime:nowdaycode?moment().format('YYYY-MM-DD'):''
            }
        allWzrecode(params).then(res => {
            if (res.code === 1) {
                if (res.data[0].length > 0) {
                    const data = res.data[0]
                    this.setState({
                        yslist: [...yslist,...res.data[0]],
                        page:params.page
                    })
                }
                if (res.data[1].length > 0) {
                    const data = res.data[1]
                    this.setState({
                        splist: [...splist,...data],
                        page:params.page
                    })
                }
            }
        })
    }
    gobank=()=>{
        window.history.back()
    }

    //查看问诊详情
    goinfo(item){
        localStorage.setItem("item",JSON.stringify(item))
        if (item.order_form_type==1){
            this.props.history.push({
                pathname:"/chatdoctor",
                params:{
                    consultationid:item.consultation_id,
                    userpic:item.patient_avatar,
                    username:item.patient_name,
                    userId:item.user_id
                }
            })
        }else{
            this.props.history.push('/offlineDoctorchat')
        }

    }
    render() {
        const { yslist,phonelist,splist } = this.state

        return (
            <div className='recordbox' onScroll={this.onscroll}>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>接诊记录</h1>
                </div>
                <div className='box-content'>
                    <Tabs>
                        <Tabs.Tab title='图文接诊' key='pic' >
                            {yslist.length?yslist.map((item,index)=>(<div className='content-item' key={index}>
                                <div className='illness-details'>
                                    <span className='nameall'>{item.patientName}<span>（{item.patientSex==1?'男':'女'} {item.patient_age}岁）</span></span>
                                    {(item.prescribingState == 0 || (item.prescribingState == 1 && item.prescringPhaState == 0)) && item.originalPrescribing ? <span className='cfone cfprogress'>
                                       【处方审核中】
                                    </span> :item.prescribingState == 0 && !item.originalPrescribing ? <span className='cfone cfunsuccess'>
                                        【未开方】
                                    </span> :(item.prescribingState ==1 && item.prescringPhaState == 1)  && item.originalPrescribing ? <span className='cfone cfsuccess'>
                                        【处方审核通过】
                                    </span> :(item.prescribingState ==-1 || item.prescringPhaState == 2) && item.originalPrescribing ? <span className='cfone cffalse'>
                                        【处方审核失败】
                                    </span> :<span className='cfone cfunsuccess'>
                                       【未开方】
                                    </span>
                                    }
                                </div>
                                <div className='ill-detail'>
                                    主诉病情：{item.consultation_detail}
                                </div>
                                {/*<div>*/}
                                {/*    <p>病情时间：</p><p><span>1天</span></p>*/}
                                {/*</div>*/}
                                <div className='endTime'>
                                    <p>结束时间：{item.end_time?item.end_time:'问诊进行中'}</p>
                                    <p className='details' onClick={()=>this.goinfo(item)}>查看详情</p>
                                </div>
                                {/*<img src={icon2} alt="" className='item-delete' onClick={()=>this.toDelete(item.prescribingId)} />*/}
                            </div>)):<Nodata type='接诊'/>}
                        </Tabs.Tab>
                        <Tabs.Tab title='视频接诊' key='phone'>
                            {splist.length?splist.map((item,index)=>(<div className='content-item' key={index}>
                                <div className='illness-details'>
                                    <span className='nameall'>{item.patientName}<span>（{item.patientSex==1?'男':'女'} {item.patient_age}岁）</span></span>
                                    {(item.prescribingState == 0 || (item.prescribingState == 1 && item.prescringPhaState == 0)) && item.originalPrescribing ? <span className='cfone cfprogress'>
                                       【处方审核中】
                                    </span> :item.prescribingState == 0 && !item.originalPrescribing ? <span className='cfone cfunsuccess'>
                                        【未开方】
                                    </span> :((item.prescribingState ==1 && item.prescringPhaState != 2) || (item.prescribingState ==1 && item.prescringPhaState == 1)) ? <span className='cfone cfsuccess'>
                                        【处方审核通过】
                                    </span> :(item.prescribingState ==-1 || (item.prescribingState ==1 && item.prescringPhaState == 2)) && item.originalPrescribing ? <span className='cfone cffalse'>
                                        【处方审核失败】
                                    </span> :<span className='cfone cfunsuccess'>
                                       【未开方】
                                    </span>
                                    }
                                </div>
                                <div className='ill-detail'>
                                    主诉病情：{item.consultation_detail}
                                </div>
                                {/*<div>*/}
                                {/*    <p>病情时间：</p><p><span>1天</span></p>*/}
                                {/*</div>*/}
                                <div className='endTime'>
                                    <p>结束时间：{item.end_time?item.end_time:'问诊进行中'}</p>
                                    <p></p>
                                </div>
                            </div>)):<Nodata type='接诊'/>}
                        </Tabs.Tab>
                    </Tabs>
                </div>
            </div>
        )
    }
}
