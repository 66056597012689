import React, { Component } from 'react'
// import icon1 from "../../../static/img/yy/fanhuihei.png"
import { Badge, SideBar } from 'antd-mobile-v5'
import "./style.less"
import SignatureCanvas from 'react-signature-canvas'
import getUrl from '../../../component/GetUrlParam'
import icon1 from '../../../static/img/yy/qianming.png'
import icon2 from '../../../static/img/yy/over.png'
import icon3 from '../../../static/img/yy/del.png'
import {updateCa} from "../../../api/hospitalapi/home";
import {updateDocterAutograph,getSignature,updateSignature} from "../../../api/hospitalapi/mine";
import {imgToCanvas, rotateBase64Img, dealImage, dealParams,getBase64} from '../../../api/config'
import {zipImg} from "../../../component/zipImage";
import {uploadImg,delResign} from "../../../api/request/upload";
import {getuserinfo} from "../../../api/hospitalapi/login";
import {Toast} from "antd-mobile";
import {baseUrl, jumpUrl} from "../../../publicurl";
import {testca,cacallback} from "../../../api/hospitalapi/upload";

export default class index extends Component {
    constructor() {
        super()
        this.state = {
            trimmedDataURL: null,

            docterId:localStorage.getItem("docterId"),
            docterPhone : localStorage.getItem("docterPhone"),
            img:'',
            docter:'',
            caIspass:2
        }
    }
    componentDidMount = async () => {
        const {docterPhone, caPassTime} = this.state
        const res = await getuserinfo({
            docterPhone,
        })
        if (res && res.data&& res.data[0].length) {
            this.setState({
                docter: res.data[0][0],
            })
        }
    }
    trim = () => {
        const {docter, caPassTime} = this.state
        let aaa = this.sigCanvas.isEmpty();
        let img0=''
        let img1=''
        if (aaa){
            Toast.info('请先签名')
        }else {
            this.setState({
                trimmedDataURL: this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")
            }, () => {
                let res0 = this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")

                //顺时针旋转90度
                let res1 = rotateBase64Img(res0, 90, callback)

                function callback(val) {
                    img1 = val
                }

                setTimeout(() => {
                    let a = dealImage(img1, callback1)

                    function callback1(val) {
                        //base64转png图片
                        let res2 = imgToCanvas(val)
                        let file = res2
                        // if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                        zipImg(file).then(res => {
                            let formData = new FormData()
                            formData.append('file', res)

                            uploadImg(formData).then(res1 => {

                                if (res1 && res1.code == 2) {
                                    img0 = res1.data

                                }
                                // Toast.hide()
                            }).catch(err => {
                                // Toast.hide()
                            })
                        })
                    }
                }, 500)
                setTimeout(() => {

                    if (img0) {
                        let docterAutograph0=''
                        getBase64(img0, async (value) => {
                            docterAutograph0 = value.split(',')[1]
                            if (docterAutograph0){
                                updateDocterAutograph({
                                    docterId: this.state.docterId,
                                    docterAutograph: img0
                                }).then( res3 => {

                                    if (res3 && res3.code == 1) {

                                        let data0 = {
                                            userId: docter.ca_id?docter.ca_id:this.state.docterId,
                                            configKey:docter.ca_id?docter.ca_id:this.state.docterId,
                                            // keypairType:3,
                                            // signType:4
                                        }

                                        let data1 = {
                                            userId: docter.ca_id?docter.ca_id:this.state.docterId,
                                            configKey:docter.ca_id?docter.ca_id:this.state.docterId,
                                            keypairType:3,
                                            signType:4,
                                            signParam:JSON.stringify([
                                                {
                                                    llx: 70,
                                                    lly: 110,
                                                    urx: 120,
                                                    ury: 140,
                                                    pageList: [1],
                                                    sealImg: docterAutograph0
                                                }
                                            ]),
                                            sealImg:docterAutograph0,
                                            sealType:1
                                        }

                                        let {toSign, params} = dealParams(data0)
                                        let {toSign:toSign1, params:params1} = dealParams(data1)

                                        let docSignature = ''
                                        getSignature({
                                            toSign:toSign1
                                        }).then((data)=>{
                                            docSignature=data.data
                                        })

                                        delResign({
                                            toSign, //旧签名
                                            FormData: params,
                                            toSign1, //新签名
                                            FormData1: params1,
                                        }).then(res1 => {
                                            if (res1&&res1.data&&res1.data.success==true){
                                                
                                                // 更新医生签名值  docterId: this.state.docterId
                                                updateSignature({
                                                    docter_id: this.state.docterId,
                                                    signature_value:docSignature
                                                }).then((result)=>{
                                                    console.log('修改成功');
                                                })

                                                Toast.info('签名修改成功')
                                                this.props.history.push("/mine")
                                            }
                                            // if (data && data.data && data.data.success == false) {
                                            //     this.props.history.push("/")
                                            // } else if (data && data.data) {
                                            //     window.location.href =data.data.body
                                            // }
                                        });
                                        // this.props.history.push("/")


                                    }
                                })
                            }
                        })


                    }
                }, 1000)


            });
        }
    };
    render() {
        let { trimmedDataURL } = this.state;
        return (
            <div className='electronicsignature'>
                <div className='electronicsignature-top'>
                    手写签名
                </div>
                <div className='electronicsignature-body'>
                    <div className='electronicsignature-body-left'>
                        <img src={icon1}/>
                    </div>
                    <div className='electronicsignature-body-center'>
                        <SignatureCanvas
                            penColor="#000"  // 笔刷颜色
                            minWidth={5} // 笔刷粗细
                            maxWidth={7}
                            ref={(ref) => { this.sigCanvas = ref }} //用于调用插件的API
                            canvasProps={{width: window.outerWidth*.7,
                                height: window.outerHeight*.7, style:{position:'absolute',top:'18vh',left:'15vw',backgroundColor:'#F3F3F3', },
                                className: 'sigCanvas'}} />
                    </div>
                    <div className='electronicsignature-body-right'>
                        <img src={icon2} onClick={this.trim}/>
                        <img src={icon3} onClick={()=>this.sigCanvas.clear()} />
                    </div>
                </div>
                <img id="img" src={trimmedDataURL}/>
            </div>
        )
    }
}
