import React ,{ useState, useEffect }from 'react'

import Head from '../../../component/Head'
import close from '../../../static/img/close1.png'
import './style.less'
import { aftersaleList,aftersaleMedList,stateList} from '../../../api/request/aftersale'
import { oderdetailRequest,oderlistRequest} from '../../../api/request/order'

export default function Viewrecord(props) {
    const userId =localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3
    const [details1, setDetails1] = useState([])
    const [details2, setDetails2] = useState([])
    const [visiable, setVisiable] = useState('none')
    const [invoicepath, setInvoicepath] = useState('')

    
    useEffect(() => {
        if (localStorage.type == "2") {
            stateList().then(res=>{
                setDetails1(res.data[0])
                setDetails2(res.data[1])
            })
        }
    }, [])
    const viewElectronicinvoice=(invoicepath)=>{
        setInvoicepath(invoicepath)
        setVisiable('block')
    }
    const dianji=()=>{
        setVisiable('none')
    }

    return(
        <div>
            <Head title='电子发票'/>
            {details1.map((item,i)=>(
                <div className='ele-outer'>
                    <p className='ele-top'>
                        <h2>电子发票</h2>
                        <span className='ele-s'></span>
                        <span className='ele-time'>{item.aftersale_agreetime.substr(0,10) +' '+(new Date(item.aftersale_agreetime).getHours()<10?'0'+new Date(item.aftersale_agreetime).getHours():new Date(item.aftersale_agreetime).getHours())+':'+ (new Date(item.aftersale_agreetime).getMinutes()<10?'0'+new Date(item.aftersale_agreetime).getMinutes():new Date(item.aftersale_agreetime).getMinutes())+':'+ (new Date(item.aftersale_agreetime).getSeconds()<10?'0'+new Date(item.aftersale_agreetime).getSeconds():new Date(item.aftersale_agreetime).getSeconds())}</span>
                    </p>
                    <p className='ele-pic'>
                    {details2.map(e=>(
                        <>
                            {e.orderNumber==item.order_number?<span className='ele-img'>
                                <img src={e.medPhoto} alt="" />
                                <span>x{e.medQuantity}</span>
                            </span>:null}
                        </>
                        ))}
                    </p>
                    <p className='ele-sj'>商家：{item.pharmacyName}</p>
                    <p className='ele-sj'>订单编号：{item.order_number}</p>
                    <p className='ele-sj'>发票金额：￥{item.order_amount/100}</p>
                    <div className='ele-xian'></div>
                    <div className='ele-bottom' onClick={()=>viewElectronicinvoice(item.aftersale_invoicepath)}>
                        <p className='ele-ck'>查看电子发票</p>
                    </div>
                    {visiable=='block'?<div className='invoice-outer'>
                        <img className='close' src={close} alt=""  onClick={()=>dianji()}/>
                        <img className='pic' src={invoicepath} alt="" />
                    </div>:null}
                </div>
            ))}
        </div>
    )
}