import React, { Component } from 'react'
// import icon1 from "../../../static/img/yy/fanhuihei.png"
import { Badge, SideBar } from 'antd-mobile-v5'
import "./style.less"
import SignatureCanvas from 'react-signature-canvas'
import getUrl from '../../../component/GetUrlParam'
import icon1 from '../../../static/img/yy/qianming.png'
import icon2 from '../../../static/img/yy/over.png'
import icon3 from '../../../static/img/yy/del.png'
import {updateCa} from "../../../api/hospitalapi/home";
import {updateDocterAutograph,getSignature,updateSignature} from "../../../api/hospitalapi/mine";
import {imgToCanvas, rotateBase64Img, dealImage, dealParams} from '../../../api/config'
import {zipImg} from "../../../component/zipImage";
import {uploadImg} from "../../../api/request/upload";
import {getuserinfo} from "../../../api/hospitalapi/login";
import moment, { duration } from 'moment'
import {Toast} from "antd-mobile";
import {baseUrl, jumpUrl} from "../../../publicurl";
import {testca,cacallback} from "../../../api/hospitalapi/upload";
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            trimmedDataURL: null,
            docterId:localStorage.getItem("docterId"),
            img:'',
            caIspass:2
        }
    }
    componentDidMount = async () => {
        // this.setState({
        //     docterId:getUrl('docterId', this.props.location.search)
        // })
        // updateCa({docterId:getUrl('docterId', this.props.location.search)}).then(res=>{
        //     console.log(res)
        // })
        const docterPhone = localStorage.getItem("docterPhone")
        // if (getUrl('docterId', this.props.location.search)&&getUrl('ca', this.props.location.search)){
        //     updateCa({docterId:getUrl('docterId', this.props.location.search)}).then(res=>{
        //         console.log(res)
        //     })
        // }
        if (docterPhone) {
            const res = await getuserinfo({
                docterPhone,
            })
            if (res&&res.data&&res.data[0]&&res.data[0][0].ca_ispass==1&&res.data[0][0].ca_id!=2401){

                // 将字符串日期转换为 Moment 对象
                let caPassTime = moment(res.data[0][0].caPassTime);
                // 获取未来一年后的日期
                let futureDate = caPassTime.add(1, 'year');
                // 获取未来日期的开始时间
                let futureStart = futureDate.startOf('day');
                // 获取今天的开始时间
                let todayStart = moment().startOf('day');
                // 计算未来日期与当天的日期之间的天数差
                let timeca = futureStart.diff(todayStart, 'days');

                localStorage.setItem("caPassTime", timeca)

                this.setState({
                    caIspass:res.data[0][0].ca_ispass,
                    docterId:res.data[0][0].docter_id
                })
            }else if (jumpUrl!="https://doc.yyj-health.com"){
                let data0 = {
                    entityId: res.data[0][0].docter_id,
                    pin:110110,
                }
                // if (docter.docter_examine=='-1'){
                //     data0.busiType='2'
                // }
                let {toSign, params} = dealParams(data0)
                testca({
                    toSign,
                    FormData: params
                }).then(res1 => {
                    if (res1&&res1.data&&res1.data.result_msg=='PIN校验失败'){
                        let params={
                            entityId1: res.data[0][0].docter_id,
                            busiType1: '1'
                        }
                        cacallback(params).then(res2=>{
                            // console.log(res2)
                        })
                    }else{
                        Toast.info('人脸识别认证成功后进行签名，请先认证')
                        this.props.history.push('/')
                    }
                    // if (data && data.data && data.data.success == false) {
                    //     this.props.history.push("/")
                    // } else if (data && data.data) {
                    //     window.location.href =data.data.body
                    // }
                });
            }else{
                Toast.info('人脸识别认证成功后进行签名，请先认证')
                this.props.history.push('/')
            }
            // this.setState({
            //     docter: res.data[0][0],
            //     list: res.data[1][0],
            // })
            // const res1=await getnewslist({
            //     docterId,
            //     consultationType:'3',
            //     consultationStu:'-1'
            // })
            // console.log(res1)
        }
    }
    trim = () => {
        let aaa = this.sigCanvas.isEmpty();
        let img0=''
        let img1=''
        let docSignature = ''

        // console.log( this.sigCanvas.getTrimmedCanvas().toDataURL("image/png"));
        if (aaa){
            Toast.info('请先签名')
        }else{
            this.setState({
                trimmedDataURL: this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")
            },()=>{
                let res0= this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")
                //顺时针旋转90度
                let res1=rotateBase64Img(res0,90,callback)
                function callback(val){
                    img1=val
                }
                setTimeout(()=>{
                    let a=dealImage(img1,callback1)
                    function callback1(val) {
                        //base64转png图片
                        let res2= imgToCanvas(val)
                        let file=res2
                        // if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                        zipImg(file).then(res => {
                            let formData = new FormData()
                            formData.append('file', res)
                            uploadImg(formData).then(res1 => {
                                if(res1&&res1.code == 2) {
                                    img0=res1.data

                                }
                                // Toast.hide()
                            }).catch(err => {
                                // Toast.hide()
                            })
                        })
                    }
                },500)
                setTimeout(()=>{

                    

                    if (img0){
                        updateDocterAutograph({
                            docterId:this.state.docterId,
                            docterAutograph:img0
                        }).then(res3=>{
                            if (res3&&res3.code==1){
                                this.props.history.push("/")
                            }
                        })                      
                    }
                },1000)

            });
        }
    };
    render() {
        let { trimmedDataURL } = this.state;
        return (
            <div className='electronicsignature'>
                <div className='electronicsignature-top'>
                    手写签名
                </div>
                <div className='electronicsignature-body'>
                    <div className='electronicsignature-body-left'>
                        <img src={icon1}/>
                    </div>
                    <div className='electronicsignature-body-center'>
                        <SignatureCanvas
                            penColor="#000"  // 笔刷颜色
                            minWidth={5} // 笔刷粗细
                            maxWidth={7}
                            ref={(ref) => { this.sigCanvas = ref }} //用于调用插件的API
                            canvasProps={{width: window.outerWidth*.7, height: window.outerHeight*.7, style:{position:'absolute',top:'18vh',left:'15vw',backgroundColor:'#F3F3F3', },
                                className: 'sigCanvas'}} />
                    </div>
                    <div className='electronicsignature-body-right'>
                        <img src={icon2} onClick={this.trim}/>
                        <img src={icon3} onClick={()=>this.sigCanvas.clear()} />
                    </div>
                </div>
                <img id="img" src={trimmedDataURL}/>
            </div>
        )
    }
}
