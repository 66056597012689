import axios from "axios";
import {appId} from "../publicurl"
const handleHeader={'content-type' : 'application/json'}
// axios的实例及拦截器配置
const axiosInstanceca = axios.create({
 // /   baseURL: 'http://testmicrosrv.scca.com.cn:9527',
    timeout: 60000,  // 超时，401
});

// response拦截器, 处理response  请求发出后拦截，对状态码code做处理
axiosInstanceca.interceptors.response.use(
    result => {
        return result
    },
    err => {
        console.log(err, "网络错误");
    }
);


//ca对接
export async function postCaPdf(url,params,signature){
    var formData=new FormData()
    Object.keys(params).forEach((key) => {
        const value = params[key];
        if (Array.isArray(value)){
            value.forEach((subValue, i) =>formData.append( key +`[${i}]` , subValue));
        }else{
            formData.append(key, params[key]);
        }
    });
    return await new Promise((resolve, reject) => {
        axiosInstanceca(url, {
            method: 'post',
            data: formData,
            headers: {
                app_id:appId,
                signature:signature
            }

        }).then(res => {
            if (res){
                resolve(res)//成功
            }
        }).catch(err => reject(err));
    })
}
//ca对接
export async function postCa(url,params,signature){
    return await new Promise((resolve, reject) => {
        axiosInstanceca(url, {
            method: 'post',
            data:params,
            responseType: 'blob',
            headers: {
                app_id:appId,
                signature:signature,
                'content-type': 'application/x-www-form-urlencoded',
            }

        }).then(res => {
            if (res){
                resolve(res)//成功
            }
        }).catch(err => reject(err));
    })
}
export { axiosInstanceca };

