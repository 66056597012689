import React, { Component } from "react";
import { throttle } from 'lodash';
import { Button, Space, Steps } from "antd-mobile-v5";
import { Link, withRouter } from "react-router-dom";
import FootNav from "../../component/FootNav";
import {
  getdoctorlist,
  getDocjznum,
  getDayDocjznum,
  updateCa,
} from "../../api/hospitalapi/home";
import { getuserinfo } from "../../api/hospitalapi/login";
import { offdocAllnum, allReorder,chineseMethod,prescribingPrice,updatePrice } from "../../api/hospitalapi/offline";
import "./style1.less";
import "../../static/css/iconfont.css";
import getUrl from "../../component/GetUrlParam";
import icon1 from "../../static/img/home/await-jz.png";
import icon3 from "../../static/img/doctor/u.png";
import icon9 from "../../static/img/doctor/video.png";
import icon4 from "../../static/img/yy/ts.png";
import icon7 from "../../static/img/yy/fanhuihei.png";
import icon5 from "../../static/img/doctor/right.png";
import icon6 from "../../static/img/doctor/home-pic.png";
import icon8 from "../../static/img/home/home-avater.png";
import icon10 from "../../static/img/home/pm.png";
import icon11 from "../../static/img/home/am.png";
import kfcopy from "../../static/img/kfcopy.png";

import icon2 from "../../static/img/icon/renovate.png";
import {
  addnews
} from "../../api/hospitalapi/news";
import {
  allOrders,
  sureReceive,
  sureReceive01,
  addOpenid,
  nowtimeDoc,
  doctorNewid,
  isHavewz,
  allRejectPrescription,
  isSquare,
  caIsNotify,
  noSquare,
  becomeDue,
  selectBecomeDue,
  selectBecomeDues,
  selectIsConRepush,
  updateSquareRoot,
  docterDetail,
  docLine
} from "../../api/hospitalapi/offline";
import {
  CheckCircleFill,
  ClockCircleFill,
  CloseCircleFill,
} from "antd-mobile-icons";
import { dealParams } from "../../api/config";
import {
  caAuthentication,
  cacallback,
  caPasstime,
  testca,
  videoRecording
} from "../../api/hospitalapi/upload";
import { caUrl, baseUrl, jumpUrl } from "../../publicurl";
import { Modal, Toast } from "antd-mobile";
import moment from "moment";
import Nodata from "../../component/Nodata";
import styles from "./style2.less";
import res from "antd-mobile-icons/es/AaOutline";
const { Step } = Steps;
const { alert } = Modal;
class Platform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yslist: [],
      docter: "",
      docterAvatar: localStorage.getItem("docterAvatar"),
      allNum: 0, //总接诊
      dayNum: 0, //当日接诊次数,
      countallNum: 0, //开方总接诊
      countNum: 0, //开方成功次数,
      status: "",
      list: "",
      docterId: localStorage.getItem("docterId"),
      caPassTime: localStorage.getItem("caPassTime"),
      docterPhone: localStorage.getItem("docterPhone"),
      loginTimeDay:localStorage.getItem("loginTimeDay"),
      visible: false,
      visible5: false,
      visible6: false,
      visible2: false,
      reviewercode: true,
      code: 60,
      time: 60, //20240511
      time1: 60,//20240511
      userlists: [],
      userlists1: [],
      userlists2: [],
      newslist: [],
      nouserlist: [], //未开方
      nouserlist1: [], //未开方
      nouserlist2: [],
      userlistcode1: 1, //1、线上医生图文问诊 2 视频
      timer: "",
      timer1: "",
      awaitcode: 1,
      chinesemethod:[],//用法用量
      visible10:false,//开启即将过期
      visible11:false,//开启已过期
      visible12:false,//未开启已过期
      becomeduestate:'',
      displayCount: localStorage.getItem("displayCount") ? localStorage.getItem("displayCount") : 0, //显示次数
      count: localStorage.getItem("count") ? parseInt(localStorage.getItem("count")) : 0,
      time10:'',
      visibleflag:0,
      isSending:false,
      visibledocter:false,//医生执业证书到期提醒窗口
      filingExpirationDate:'',//执业证书备案时间
      FilingDaysTime:'',//执业药师到期天数
      time11:localStorage.getItem("time11")? localStorage.getItem("time11"):'',
      visibledocter2:false,
      docterExamine:'',//医生审核是否通过 2.未上传资料，-1.拒绝，0.审核中，1.通过
    };
  }

  componentWillUnmount = () => {
    const { timer, timer1,timer2,newTimer } = this.state;
    // window.clearInterval(timer2)
    window.clearInterval(timer1);
    // clearInterval(this.state.timer1);
    // window.clearInterval(newTimer)
  };

  componentDidMount = async () => {
    const { docterId, caPassTime, docterPhone,nouserlist1,loginTimeDay,count,time11 } = this.state;
    // console.log('docterId',docterId);
    // console.log(caPassTime,'124');

    if(docterId){
      // 查看医生详细信息
      let res=await docterDetail({docterId:docterId})
      // console.log(res,'res');

      if(res.code==1 && res.data[0] && res.data[0].length){
        if(res.data[0][0].isOurHospital == 2){
          if(res.data[0][0].docterExamine == 1 && res.data[0][0].filingExpirationDate){
            let feTime = new Date(res.data[0][0].filingExpirationDate)
            let nowDate = new Date()
            let timeDiff = feTime.getTime() - nowDate.getTime();
            let dasyDiff = Math.ceil(timeDiff/(1000*60*60*24))
            if(dasyDiff == 30 || (dasyDiff >= 0 && dasyDiff <= 15)){
              // 初次进入时间为null，一定走这里
              if(time11 == ''){
                this.setState({
                  visibledocter:true
                })
              }else{
                // 当time11存在时，则进入这里
                // 获取当前时间
                let currentDate = new Date().toLocaleDateString();
                if(time11 == currentDate){
                  this.setState({
                    visibledocter:false
                  })
                }else{
                  this.setState({
                    visibledocter:true
                  })
                }            
              }       
            }else if(dasyDiff < 0){
              this.setState({
                visibledocter2:true
              })
            }
            this.setState({
              filingExpirationDate:res.data[0][0].filingExpirationDate,
              FilingDaysTime:dasyDiff
            })
          }
        }
        
      }
    }
    

    let visibleflag = 0
    
    this.setState({
      time10 : localStorage.getItem("time10")? localStorage.getItem("time10"):''
    })
    this.setState(prevState => ({
      count: prevState.count + 1
    }), () => {
      localStorage.setItem("count", this.state.count);
    });
    // console.log("组件加载完成，总共加载次数：" + count);
    setInterval(() => {
      const { time10,visible10,visible12 } = this.state;
      // 定时监控 如果身份过期 进入弹窗判断 还要判断是否已经弹窗，未关闭，只要有一个弹窗打开了，就不进入判断
      if(visibleflag&&!visible10&&!visible12){
        // 初始进入 时间为空，一定弹窗
        if(time10==''){
          switch(visibleflag){
            case 10:
              this.setState({
                visible10:true
              })
            break;
            case 12:
              this.setState({
                visible12:true
              })
            break;
          }
          
        }else{
          // 每秒监控当前时间 
          // 获取当前时间
          const currentTime = new Date().getTime();
                  
          // 判断是否到了第二天
          // 将时间戳转换为 Date 对象
          const currentDate = new Date(currentTime);
          const nextDayDate = new Date(Number(time10));

          // 获取日期部分
          const currentDay = currentDate.toISOString().split('T')[0];
          const nextDay = nextDayDate.toISOString().split('T')[0];
          if (currentDay !== nextDay) {
            // 获取当前的时间 记录的时间不相等 进入判断
            // console.log('已经过了一天');
            switch(visibleflag){
              case 10:
                this.setState({
                  visible10:true
                })
              break;
              case 12:
                this.setState({
                  visible12:true
                })
              break;
            }
          }
         
        }
       
      }
      
    }, 1000);



    let resdue = await selectBecomeDue({docterId:docterId})

    if(resdue.data && resdue.data[0]){
      this.setState({
        becomeduestate:resdue.data[0][0].is_become_due
      })
    }

    if (getUrl("ca", this.props.location.search) && docterId) {
      let res = await caPasstime(docterId);
      // console.log(res);
      if (res && res.data && res.data[0]) {
        let timeca = moment(res.data[0][0].caPassTime)
          .add(1, "year")
          .diff(moment(), "day");
        localStorage.setItem("caPassTime", timeca);
        this.setState({
          caPassTime: timeca,
        });
      }
    }
    if(caPassTime>0){
      let res = await selectBecomeDues({docterId:docterId})
    }

    if (getUrl("openid", window.location.search)) {
      let res = await addOpenid({
        openid: getUrl("openid", window.location.search),
        docterId,
      });
      // console.log(res);
    }

    const currentDate = new Date();
    const nowyear = currentDate.getFullYear()
    const nowmonth = String(currentDate.getMonth()+1).padStart(2, "0");
    const nowday = String(currentDate.getDate()).padStart(2, "0");
    const nowDate = `${nowyear}${nowmonth}${nowday}`;
    const dateTime = new Date(loginTimeDay);
    
    

    if (docterPhone) {
      const res = await getuserinfo({
        docterPhone,
      });
      // 查询医生ca认证到期是否给出提示
      let res10 = await caIsNotify({
        docterId:docterId
      });

      this.setState(
        {
          docter: res.data[0][0],
          list: res.data[1][0],
          docterAvatar:res.data[0][0].docter_avatar
        },
        () => {
          if (res.data[0][0].docter_state != 1) {
            Toast.show("账号异常，暂时无法登录，请联系客服：13551897103");
            window.localStorage.clear();
            window.location.reload();
          }
          localStorage.setItem("docuserType", res.data[0][0].docuser_type);
          if (
            res.data[0][0].docuser_type == 2 &&
            res.data[0][0].docter_examine == "1"
          ) {
            this.allLists();
          } else if (
            res.data[0][0].docuser_type == 1 &&
            res.data[0][0].docter_examine == "1"
          ) {
            this.allLists1();
          }
        }
      );

        if (
          res10.data[0][0].is_notify == 1 && caPassTime &&
          caPassTime < 60 &&
          caPassTime > 0 &&
          res.data[0][0].docter_examine == "1"
          
        ) {
          visibleflag = 10
                  
        } else if (
          res10.data[0][0].is_notify == 1 &&
          caPassTime &&
          caPassTime <= 0 &&
          res.data[0][0].docter_examine == "1"
        ) {
          // visibleflag = 11
          this.setState({
            visible11:true,
          })  
        }else if(
          res10.data[0][0].is_notify == 2 &&
          caPassTime &&
          caPassTime <= 0 &&
          res.data[0][0].docter_examine == "1"
        ){
          visibleflag = 12
        }
    }


    // 查询医生当日接诊次数
    getDayDocjznum(localStorage.getItem("docterId")).then((res) => {
      // console.log(res);
      if (res && res.code == 1) {
        this.setState({
          dayNum: res.data[0][0].countNum,
        });
      }
    });
    // 查询医生总接诊次数
    getDocjznum(localStorage.getItem("docterId")).then((res) => {
      // console.log(res);
      if (res && res.code == 1) {
        this.setState({
          allNum: res.data[0][0].countNum,
        });
      }
    });
    //
    offdocAllnum(localStorage.getItem("docterId")).then((res) => {
      // console.log(res);
      if (res && res.code == 1) {
        this.setState({
          countallNum: res.data[0][0].countallNum,
          countNum: res.data[1][0].countNum,
        });
      }
    });

  };

  // 检查是否需要显示
  checkVisible=()=>{

  }

  // 到期去认证
  // 忘记密码
  forgetPwd=async()=>{
    const {docterPhone}=this.state
    const res = await getuserinfo({
      docterPhone,
    });
    let data0 = {
      entityId: res.data[0][0].docter_id,
      busiType: "5",
      redirectUrl: baseUrl + "/faas/hosp/scca/callback",
      redirectPageUrl: jumpUrl + `/index.html#/`,
      province: "四川省",
      locality: "德阳市",
      orgName: "云益健互联网医院",
    };
    // if (docter.docter_examine=='-1'){
    //     data0.busiType='2'
    // }
    let { toSign, params } = dealParams(data0);
    // console.log(toSign, params);
    caAuthentication({
      url: caUrl,
      toSign,
      FormData: params,
    }).then((data) => {
      if (data && data.data && data.data.success == false) {
        Toast.info(data.data.result_msg);
      } else if (data && data.data) {
        window.location.href = data.data.body;
      }
    });
  }
  sureBtn=async()=>{
    const {docterPhone,docterId,count}=this.state
    const res = await getuserinfo({
      docterPhone,
    });
    this.setState({
      visible11:false,
      visible10:false,
      count:count+1,
      time10:new Date().getTime()
    },()=>{
        localStorage.setItem('count',count);
        localStorage.setItem("time10", new Date().getTime());
    })



    let data0 = {
      entityId: res.data[0][0].docter_id,
      busiType: "3",
      redirectUrl: baseUrl + "/faas/hosp/scca/callback",
      // redirectUrl: 'https://h5.sczyzk.com/index.html#/faas/hosp/video-doc/callback',
      redirectPageUrl: jumpUrl + `/index.html#/?ca=${1}`,
      province: "四川省",
      locality: "德阳市",
      orgName: "云益健互联网医院",
    };
    let { toSign, params } = dealParams(data0);
    // console.log(toSign, params);
    caAuthentication({
      url: caUrl,
      toSign,
      FormData: params,
    }).then((data) => {
      // console.log(data,'data====');
      if (data && data.data && data.data.success == false) {
        Toast.info(data.data.result_msg);
        this.toLoadCA()
      } else if (data && data.data) {
        window.location.href = data.data.body;
      }
    });
  }

  toinfo = (item) => {
    this.props.history.push({
      pathname: "/doctorinfo",
      params: {
        item,
      },
    });
  };
  tologin = () => {
    const docterPhone = localStorage.getItem("docterPhone");
    if (!docterPhone) {
      this.props.history.push("/login");
    }
  };
  toVideo = () => {
    const { docterId } = this.state;
    if (!docterId) {
      this.props.history.push("/login");
    } else {
      this.props.history.push("/videoprescription");
    }
  };
  //问诊服务设置
  toSetserver = () => {
    const { docter } = this.state;
    if (localStorage.getItem("docterId")) {
      if (docter.docter_examine == "1") {
        this.props.history.push("/setserver");
      } else {
        Toast.show("资质审核未通过，不能进行问诊服务设置");
      }
    } else {
      Toast.show("当前未登录，请先登录");
      this.props.history.push("/login");
    }
  };
  toLoadagain = () => {
    this.props.history.push("/uploaddoc");
  };
  //人脸实名认证
  toLoadCA = () => {
    const {
      yslist,
      visible,
      code,
      time,
      time1,
      list,
      docter,
      status,
      allNum,
      dayNum,
      caPassTime
    } = this.state;
    // console.log(11111);
    // console.log(docter,'431========',caPassTime);
    if (docter.ca_id) {
      if (docter.ca_ispass == 2 || docter.ca_id == 2401 || caPassTime < 0) {
        if (time == 0 && time1 == 0) {
          this.setState({
            visible2: false,
          });
          if (jumpUrl != "https://doc.yyj-health.com") {
            let data0 = {
              entityId: docter.docter_id,
              pin: 110110,
            };
            // if (docter.docter_examine=='-1'){
            //     data0.busiType='2'
            // }
            let { toSign, params } = dealParams(data0);
            testca({
              toSign,
              FormData: params,
            }).then((res1) => {
              // console.log(res1);
              if (res1 && res1.data && res1.data.result_msg == "PIN校验失败") {
                let params = {
                  entityId1: docter.docter_id,
                  busiType1: "1",
                };
                cacallback(params).then((res2) => {
                  // console.log(res2);
                  if (res2 && res2.code == 1) {
                    this.props.history.push(
                      `/electronicsignature?docterId=${
                        docter.docter_id
                      }&ca=${1}`
                    );
                  }
                });
              } else {
                alert(
                  "人脸识别",
                  "为防止遗忘密码，人脸识别输入建议输入6~18位由字母和数字组合的密码",
                  [
                    {
                      text: "取消",
                      onPress: () => console.log("cancel"),
                      style: "default",
                    },
                    {
                      text: "确认",
                      onPress: async () => {
                        let data0 = {
                          entityId:
                            docter.ca_id == 2401 ? 2401 : docter.docter_id,
                          busiType: "1",
                          redirectUrl:
                            docter.ca_id == 2401
                              ? baseUrl + `/faas/hosp/scca/callback123`
                              : baseUrl + `/faas/hosp/scca/callback`,
                          // redirectUrl: 'https://h5.sczyzk.com/index.html#/faas/hosp/video-doc/callback',
                          redirectPageUrl:
                            jumpUrl +
                            `/index.html#/electronicsignature?docterId=${
                              docter.docter_id
                            }&ca=${1}`,
                          province: "四川省",
                          locality: "德阳市",
                          orgName: "云益健互联网医院",
                        };
                        // if (docter.docter_examine=='-1'){
                        //     data0.busiType='2'
                        // }
                        let { toSign, params } = dealParams(data0);
                        caAuthentication({
                          url: caUrl,
                          toSign,
                          FormData: params,
                        }).then((data) => {
                          if (data && data.data && data.data.success == false) {
                            this.props.history.push("/");
                          } else if (data && data.data) {
                            window.location.href = data.data.body;
                          }
                        });
                      },
                    },
                  ]
                );
              }
            });
          } else {
            alert(
              "人脸识别",
              "为防止遗忘密码，人脸识别输入建议输入6~18位由字母和数字组合的密码",
              [
                {
                  text: "取消",
                  onPress: () => console.log("cancel"),
                  style: "default",
                },
                {
                  text: "确认",
                  onPress: async () => {
                    let data0 = {
                      entityId: docter.ca_id == 2401 ? 2401 : docter.docter_id,
                      busiType: "1",
                      redirectUrl:
                        docter.ca_id == 2401
                          ? baseUrl + `/faas/hosp/scca/callback123`
                          : baseUrl + `/faas/hosp/scca/callback`,
                      // redirectUrl: 'https://h5.sczyzk.com/index.html#/faas/hosp/video-doc/callback',
                      redirectPageUrl:
                        jumpUrl +
                        `/index.html#/electronicsignature?docterId=${
                          docter.docter_id
                        }&ca=${1}`,
                      province: "四川省",
                      locality: "德阳市",
                      orgName: "云益健互联网医院",
                    };
                    // if (docter.docter_examine=='-1'){
                    //     data0.busiType='2'
                    // }
                    let { toSign, params } = dealParams(data0);
                    caAuthentication({
                      url: caUrl,
                      toSign,
                      FormData: params,
                    }).then((data) => {
                      if (data && data.data && data.data.success == false) {
                        this.props.history.push("/");
                      } else if (data && data.data) {
                        window.location.href = data.data.body;
                      }
                    });
                  },
                },
              ]
            );
          }
        } else {
          this.setState({
            visible: true,
          });
          let code = time;
          const timer = setInterval(() => {
            code -= 1;
            this.setState({
              time: code,
              timer: timer,
            });
            if (code == 0) {
              clearInterval(timer);
              // updatesxVerify(number).then(res=>{
              // 	console.log(res);
              // })
              this.setState({
                time: 0,
              });
            }
          }, 1000);
        }
      } else if (!docter.docter_autograph) {
        this.props.history.push(
          `/electronicsignature?docterId=${docter.docter_id}&ca=${1}`
        );
      }
    } else {
      Toast.show("管理员确认后才能进行认证");
    }
  };

  toNext1 = () => {
    const { time1, visible2 } = this.state;
    this.setState(
      {
        visible: false,
        visible2: true,
      },
      () => {
        let code2 = time1;
        const timer = setInterval(() => {
          code2 -= 1;
          this.setState({
            time1: code2,
            timer: timer,
          });
          if (code2 == 0) {
            clearInterval(timer);
            // updatesxVerify(number).then(res=>{
            // 	console.log(res);
            // })
            this.setState({
              time1: 0,
            });
          }
        }, 1000);
      }
    );
  };
  //查询所有药房待接诊订单
  allLists = async () => {
    const {
      timer1,
      userlists,
      newslist,
      docterId,
      docterPhone,
      nouserlist,
      nouserlist1,
      nouserlist2,
      count
    } = this.state;
    // console.log(docterId)
    window.clearInterval(timer1);
   

    // 查询驳回
    let res00 = await allRejectPrescription({ docterId });
    if (res00 && res00.data && res00.data[0]) {
      if (newslist != res00.data[0]) {
        this.setState({
          newslist: res00.data[0],
        });
      }
    }

    // 查询未开方
    let res03 = await isSquare({ docterId });
    if (res03 && res03.data && res03.data[0]) {
      if (nouserlist != res03.data[0]) {
        this.setState({
          nouserlist: res03.data[0],
          nouserlist1: res03.data[1],
          nouserlist2: res03.data[2]
        });

      }
    }

    const res0 = await getuserinfo({
      docterPhone,
    });
    // console.log(res0);
    if (
      res0 &&
      res0.data &&
      res0.data[0] &&
      res0.data[0].length &&
      res0.data[0][0].is_push == 1
    ) {

      let res = await allOrders(localStorage.getItem("jwtTokendoc"), docterId);
      let chinesemethod = []; 

      if (res && res.data) {
        if (userlists != res.data) {
          this.setState({
            userlists: res.data,
          });
        }
        const newTimer = window.setInterval(() => {
          this.setState(
            {
              timer1: newTimer,
            },
            () => {
              this.allLists();
            }
          );
        }, 8000);
      }

    } else {
      const newTimer = window.setInterval(() => {
        this.setState(
          {
            timer1: newTimer,
          },
          () => {
            this.allLists();
          }
        );
      }, 8000);
      this.setState({
        userlists: [],
      });
    }
  };

  //查询所有线上待接诊订单
  allLists1 = async () => {
    const { timer2, userlists1 } = this.state;
    window.clearInterval(timer2);
    let res = await allReorder();

    let array = [];
    let array1 = [];
    
    if (res && res.data && res.data[0]) {
      res.data[0].map((item) => {
        if (item.consultation_type == "1") {
          array.push(item);
        } else {
          array1.push(item);
        }
      });
      this.setState({
        userlists1: array,
        userlists2: array1,
      });
      const newTimer = window.setInterval(() => {
        this.setState(
          {
            timer2: newTimer,
          },
          () => {
            this.allLists1();
          }
        );
      }, 8000);
    }
  };
  //确认接诊
  sureReceive = async (item) => {
    const { docterId, timer1,becomeduestate,caPassTime,FilingDaysTime } = this.state;
    if(FilingDaysTime<0){
      this.setState({
        visibledocter2:true
      })
      return
    }
    if(caPassTime<=0){
      Toast.show('您的身份信息已过期，请联系客服解决后使用')
      return
    }
    this.setState({
      visible5: true,
      item00: item,
    });
  };

  sureReviewer = async () => {
    const { docterId, timer1, item00, reviewercode,docter,isSending } = this.state;
    if(!isSending){
      this.setState({
        isSending:true
      }, async ()=>{
        let item = item00;

        if (reviewercode) {

          let res0 = await isHavewz({ docterId });

          if (res0 && res0.data && res0.data[0]) {
            let count = 0;
            if (res0.data[0].length) {
              res0.data[0].map((item) => {
                if (!item.original_prescribing) {
                  count += 1;
                }
              });
            }

            if (!count) {
              let res = await sureReceive({
                consultationId: item.consultation_id,
                docterId,
              });

              
              if (res && res.data && res.data[2]) {
                window.clearInterval(timer1);
                localStorage.setItem("item", JSON.stringify(res.data[2][0]));
                localStorage.removeItem("cancelArrItem")

                // 查询医生处方单价格
                let resPrice = await prescribingPrice({ docterId });
                if (resPrice && resPrice.data && resPrice.data[0] && resPrice.data[0].length) {
                  resPrice.data[0].forEach((i) => {
                    i.start_time = i.start_time.replace(/:/g, "");
                    i.end_time = i.end_time.replace(/:/g, "");
                  });

                  // 更新问诊单医生对应处方价格
                  let docPrice = resPrice.data[0][0].price ? resPrice.data[0][0].price : 0;
                  if(docPrice){
                    updatePrice({
                      docPrice:docPrice,
                      consultationId:item.consultation_id,
                      docterId:docterId
                    }).then((updateRes)=>{
                      // console.log(updateRes,'updateRes');
                    })
                  }
                }  
    
                const res06 = await updateSquareRoot({consultationId : item.consultation_id})
                const res08 = await selectIsConRepush({consultationId : item.consultation_id})
    
                if(res08.data[0][0].is_con_repush == '2'){
                  // 添加聊天记录，第一次接诊
                  const res09 = await addnews({
                    consultationId : item.consultation_id,
                    Identity: 2,
                    detailType: 1,
                    detailContent:`你好，我是${docter.docter_name}医生为你接诊`,
                  });
                  if(res09.code==1){
                    let randomTime = Math.floor(Math.random() * 2) + 1; //1-3秒随机时间

                    // 查询最后一句对话的时间
                    let resChat = await docLine({
                      consultationId:item.consultation_id,
                      token1: localStorage.getItem("jwtTokendoc"),
                    });
                    let dataChat = resChat.data[2]
                    let lastDataChat = dataChat[dataChat.length - 1].createTime;
                    let lastChatTime =moment(lastDataChat).format("YYYY-MM-DD HH:mm:ss") //最后一个时间
                    let delayTime = moment(lastChatTime).add(randomTime, 'seconds')
                    let chatTime = delayTime.format("YYYY-MM-DD HH:mm:ss")  //最终需传递时间

                    const resChatTwo = await addnews({
                      consultationId : item.consultation_id,
                      Identity: 2,
                      detailType: 1,
                      detailContent:`查看了您的复诊信息，您已明确在线下医院确诊过此疾病并使用过该药品，且病情稳定无相关禁忌和不良反应。`,
                      createTime:chatTime
                    });
                    if(resChatTwo.code=1){
                      // 查询最后一句对话的时间
                      let resChatTime = await docLine({
                        consultationId:item.consultation_id,
                        token1: localStorage.getItem("jwtTokendoc"),
                      });
                      let dataChatTime = resChatTime.data[2]
                      let lastDataChatTime = dataChatTime[dataChatTime.length - 1].createTime;
                      let lastChatTimeTwo =moment(lastDataChatTime).format("YYYY-MM-DD HH:mm:ss") //最后一个时间
                      let delayTimeTwo = moment(lastChatTimeTwo).add(randomTime, 'seconds')
                      let chatTimeTwo = delayTimeTwo.format("YYYY-MM-DD HH:mm:ss")  //最终需传递时间

                      const resChatThree = await addnews({
                        consultationId : item.consultation_id,
                        Identity: 2,
                        detailType: 1,
                        detailContent:`请问您是否还有其它信息需要补充？如无，我将依据您的病历资料开具处方。`,
                        createTime:chatTimeTwo
                      });
                    }
                  }
                  // 发送完成后，重置发送状态为未发送
                }else if(res08.data[0][0].is_con_repush == '1'){
                  // 查询最后一句对话的时间
                  let resChatRe = await docLine({
                    consultationId:item.consultation_id,
                    token1: localStorage.getItem("jwtTokendoc"),
                  });
                  let dataChatRe = resChatRe.data[2]
                  let lastDataChatRe = dataChatRe[dataChatRe.length - 1].createTime;
                  let lastChatTimeRe =moment(lastDataChatRe).format("YYYY-MM-DD HH:mm:ss") //最后一个时间
                  let delayTimeRe = moment(lastChatTimeRe).add( 3 , 'seconds')
                  let chatTimeRe = delayTimeRe.format("YYYY-MM-DD HH:mm:ss")  //最终需传递时间

                  let now = moment();
                  let nowTime = now.format('YYYY-MM-DD HH:mm:ss');
                  // console.log(nowTime,'现在的时间',lastChatTimeRe,chatTimeRe,nowTime > lastChatTimeRe);

                  if(nowTime > lastChatTimeRe){
                    chatTimeRe = nowTime
                  }

                  // 添加聊天记录，被退回的问诊再次被接诊
                  const res15 = await addnews({
                    consultationId : item.consultation_id,
                    Identity: 2,
                    detailType: 1,
                    detailContent:`因医生繁忙，已更换接诊医生，我是${docter.docter_name}医生为你接诊`,
                    createTime:chatTimeRe
                  });
                  if(res15.code==1){
                    let randomTime = Math.floor(Math.random() * 2) + 1; //1-3秒随机时间
                    // 查询最后一句对话的时间
                    let resChat = await docLine({
                      consultationId:item.consultation_id,
                      token1: localStorage.getItem("jwtTokendoc"),
                    });
                    let dataChat = resChat.data[2]
                    let lastDataChat = dataChat[dataChat.length - 1].createTime;
                    let lastChatTime =moment(lastDataChat).format("YYYY-MM-DD HH:mm:ss") //最后一个时间
                    let delayTime = moment(lastChatTime).add(randomTime, 'seconds')
                    let chatTime = delayTime.format("YYYY-MM-DD HH:mm:ss")  //最终需传递时间

                    const resChatFour = await addnews({
                      consultationId : item.consultation_id,
                      Identity: 2,
                      detailType: 1,
                      detailContent:`查看了您的复诊信息，您已明确在线下医院确诊过此疾病并使用过该药品，且病情稳定无相关禁忌和不良反应。`,
                      createTime:chatTime
                    });
                    if(resChatFour.code==1){
                      // 查询最后一句对话的时间
                      let resChatTime = await docLine({
                        consultationId:item.consultation_id,
                        token1: localStorage.getItem("jwtTokendoc"),
                      });
                      let dataChatTime = resChatTime.data[2]
                      let lastDataChatTime = dataChatTime[dataChatTime.length - 1].createTime;
                      let lastChatTimeTwo =moment(lastDataChatTime).format("YYYY-MM-DD HH:mm:ss") //最后一个时间
                      let delayTimeTwo = moment(lastChatTimeTwo).add(randomTime, 'seconds')
                      let chatTimeTwo = delayTimeTwo.format("YYYY-MM-DD HH:mm:ss")  //最终需传递时间

                      const resChatFive = await addnews({
                        consultationId : item.consultation_id,
                        Identity: 2,
                        detailType: 1,
                        detailContent:`请问您是否还有其它信息需要补充？如无，我将依据您的病历资料开具处方。`,
                        createTime:chatTimeTwo
                      });
                    }
                  }
                }
                this.props.history.push("/offlineDoctorchat");
    
              } else {
                this.setState({
                  isSending:false
                })
                this.allLists();
                Toast.info("已被其他医生接诊，下次速度要快点哦");
              }
            } else {
              Toast.info("您有处方未开方，请开方后再接单");
            }
          }
        } else {
          this.setState({
            isSending:false
          })
          Toast.info("请先阅读并勾选医生接诊准则");
        }
      })
    }

    
  };

  //确认接诊
  sureReceive1 = async (item) => {
    const { docterId, timer1 } = this.state;
    alert("提示弹窗", "确认接诊", [
      {
        text: "取消",
        onPress: () => {
          console.log("取消");
        },
      },
      {
        text: "确认",
        onPress: async () => {
          let res = await sureReceive01({
            consultationId: item.consultation_id,
            docterId,
          });
          if (res && res.data && res.data[2]) {
            window.clearInterval(timer1);
            localStorage.setItem("item", JSON.stringify(res.data[2][0]));
            this.props.history.push(
              `/offlineDoctorchat?consultationId=${
                item.consultation_id
              }&retirement=${1}`
            );
          } else {
            this.allLists();
            Toast.info("已被其他医生接诊，下次速度要快点哦");
          }
        },
      },
    ]);
  };

  goinfo(item) {
    const { newsstate,becomeduestate,caPassTime,FilingDaysTime } = this.state;

    if(FilingDaysTime<0){
      this.setState({
        visibledocter2:true
      })
      return
    }
    
    if(caPassTime<=0){
      Toast.show('您的身份信息已过期，请联系客服解决后使用')
      return
    }
    

    localStorage.setItem("item", JSON.stringify(item));
    this.props.history.replace(`/`);
    if (item.order_form_type == 1 || item.order_form_type == 3) {
      this.props.history.push({
        pathname: "/chatdoctor",
        params: {
          consultationid: item.consultation_id,
          userpic: item.patient_avatar,
          username: item.patient_name,
          userId: item.user_id,
        },
      });
    } else {
      this.props.history.push("/offlineDoctorchat");
    }
  }


  nowdayJZ = () => {
    this.props.history.replace(`/`);
    this.props.history.push(`/mine/record?nowdaycode=${1}`);
  };
  allJZ = () => {
    this.props.history.replace(`/`);
    this.props.history.push("/mine/record");
  };
  //开方数量
  toAccount = () => {
    const { docterId, doctor } = this.state;
    this.props.history.replace(`/`);
    this.props.history.push(`/mine/account?homeaccount=${1}`);
  };

  // 复制客服电话
  copykefuhone = () => {
    const spanText = document.getElementById("contactPhone").innerText;
    const oInput = document.createElement("input");
    oInput.value = spanText;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = "oInput";
    oInput.style.display = "none";
    document.body.removeChild(oInput);
    Toast.info("复制成功");
  };

  // 我已知晓
  handleClickKnown=async()=>{
    const {docterId,count} = this.state
    // 更新到期未认证我已知晓状态
    let res = await becomeDue({docterId:docterId}) 
    this.setState({
      visible12:false,
      count:count + 1,
      time10:new Date().getTime()
    },()=>{
      localStorage.setItem("count", count);
      localStorage.setItem("time10", new Date().getTime());
    })
  }
  // 
  handleClickone=()=>{
    const {count} = this.state
    this.setState({
      visible10:false,
      count:count + 1,
      time10:new Date().getTime()
    },()=>{
      localStorage.setItem("count", count);
      localStorage.setItem("time10", new Date().getTime());
    })
  }

  // 执业到期提醒关闭
  handleCloseAlert=()=>{
    // 获取当前时间的年月日
    let currentDate = new Date().toLocaleDateString();

    this.setState({
      visibledocter:false,
      time11:currentDate
    },()=>{
      localStorage.setItem("time11", currentDate);
    })
  }

  // 执业到期提醒关闭
  handleCloseAlert2=()=>{
    // 获取当前时间的年月日
    let currentDate = new Date().toLocaleDateString();
    this.setState({
      visibledocter2:false,
    })
  }

  render() {
    const {
      userlists,
      visible,
      visible2,
      time,
      time1,
      list,
      docter,
      docterAvatar,
      status,
      allNum,
      dayNum,
      awaitcode,
      reviewercode,
      countallNum,
      userlists1,
      userlists2,
      userlistcode1,
      newslist,
      nouserlist, //未开方
      nouserlist1, //未开方
      nouserlist2,
      visible5,
      visible6,
      countNum,
      yslist,
      chinesemethod,
      visible10,
      visible11,
      visible12,
      visibledocter,
      caPassTime,
      becomeduestate,
      displayCount,
      count,
      filingExpirationDate,
      FilingDaysTime,
      visibledocter2
    } = this.state;

    return (
      <div style={{ paddingBottom: "40px" }} className="homebox">

        {/* 医生执业药师到期提醒:30天提醒一次，15天开始每天提醒 */}
        {visibledocter && (
          <>
            <div className='tipbox'>
              <div className='alertbox'>
                  <div className='top-box'>本医院执业到期提醒</div>
                  <p className='tipmsg'>您在本医院执业即将到期，</p>
                  <p className='tipmsg'>截至有效期为：{filingExpirationDate}</p>
                  <p className='tipmsg'>请联系客服：19048012565</p>
                  <div className='knowbtns' onClick={()=>{
                    this.handleCloseAlert()
                  }}>我已知晓</div>
              </div>
            </div>
          </>
        )}
        {/* 执业医生到期之后提醒 */}
        {visibledocter2 && (
          <>
            <div className='tipbox'>
              <div className='alertbox'>
                  <div className='top-box'>本医院执业到期提醒</div>
                  <p className='tipmsg'>您在本医院执业已经到期，</p>
                  <p className='tipmsg'>暂时无法使用</p>
                  <p className='tipmsg'>请联系客服：19048012565</p>
                  <div className='knowbtns' onClick={()=>{
                    this.handleCloseAlert2()
                  }}>我已知晓</div>
              </div>
            </div>
          </>
        )}

        {/* 开启未到期 */}
        {visible10  &&  (
          <>
            <div className='tipbox'>
              <div className='alertbox'>
                  <div className='top-box'>身份信息更新提示</div>
                  <p className='tipmsg'>您的实名认证即将到期，</p>
                  <p className='tipmsg'>请您尽快前往重新进行人脸认证</p>
                  <p onClick={()=> {
                    this.sureBtn()
                  }} className='gourl'>去人脸认证</p>
                  <div className='knowbtn' onClick={()=>{
                    this.handleClickone()
                  }}>我已知晓</div>
              </div>
            </div>
          </>
        )}
        {/* 开启到期 */}
        {visible11 && (
          <>
            <div className='tipbox'>
              <div className='alertbox'>
                  <div className='top-box'>身份信息更新提示</div>
                  <p className='tipmsg'>您的实名认证已到期，</p>
                  <p className='tipmsg'>请您尽快前往重新进行人脸认证</p>
                  <p onClick={()=> {
                    this.sureBtn()
                  }} className='gourl-two'>去人脸认证</p>
              </div>
            </div>
          </>
        )}
        {/* 未开启到期 */}
        {visible12 && (
          <>
            <div className='tipbox'>
              <div className='alertbox'>
                  <div className='top-box'>身份信息更新提示</div>
                  <p className='tipmsg'>实名认证已到期</p>
                  <p className='tipmsg'>请联系客服人员解决</p>
                  <p className='tipmsgs'>客服电话：<span id='contactPhone'>19048012565</span>
                    <img src={kfcopy} onClick={() => this.copykefuhone()} />
                  </p>
                  <div className='knowbtn' onClick={()=>{
                    this.handleClickKnown()
                  }}>我已知晓</div>
              </div>
            </div>
          </>
        )}


        {visible ? (
          <div className="resd-outer">
            <div className="resd-inner">
              <h2>岗位职责</h2>
              <p>
                1、制定目的：为进一步推进互联网医院工作开展，规范在线诊疗行为，保障医疗服务质量和医疗安全,特成立互联网医院领导小组，互联网医院工作小组，互联网医学科，并明确各相关部门在互联网医院工作中的职责。
              </p>
              <p>
                2、制定依据：《医疗机构管理条例》、《医疗机构管理条例实施细则》、《互联网诊疗管理办法（试行）》、《互联网医院管理办法（试行）》、《远程医疗服务管理规范（试行）》等法律法规。
              </p>
              <p>3、适用范围：全体员工。</p>
              <p>4、职责：全体员工对本规则的实施负责。</p>
              <p>5、内容：</p>
              <p>5.1互联网医院领导小组</p>
              <p>
                5.1.1互联网医院领导小组组成：组织并监督员工执行《互联网医院管理办法（试行）》等法律、法规和行政规章，执行医院的各项管理制度。本医院建立以院长为首的互联网医院领导小组，互联网医院领导小组由质量管理专员、信息安全维护部主管、医务行政专员、药剂药学部主管组成。
              </p>
              <p>5.1.2职责：</p>
              <p>5.1.2.1指导并协调组织医院相关部门推进互联网医院工作开展。</p>
              <p>
                5.1.2.2出台互联网医院相关政策、制度，保障互联网医院在线诊疗工作的规范、质量与安全。
              </p>
              <p>
                5.1.2.3掌握国家、省、市互联网医院相关政策、法规，规范互联网医院在线诊疗行为。
              </p>
              <p>5.1.2.4负责医务人员开展互联网医院在线诊疗工作的指导。</p>
              <p>
                5.1.2.5掌握互联网医院相关的政策和要求，负责互联网医院发展思路和方向。
              </p>
              <p>5.1.2.6负责本院质量管理工作的监督。</p>
              <p>5.2临床科室</p>
              <p>
                5.2.1临床科室的组成：本医院临床科室建有内科和外科。外科由1名正高和2名副高，内科由1名正高和1名副高，及多位主治医生组成。
              </p>
              <p>5.2.2职责：</p>
              <p>
                5.2.
                2.1认真贯彻执行《互联网医院管理办法（试行）》等法律、法规和行政规章。
              </p>
              <p>
                5.2.
                2.2医师应按照约定时间出诊，与患者通过图文、电话或者视频等方式进行交流，并及时处理患者就诊需求。
              </p>
              <p>
                5.2.
                2.3负责报告诊断、治疗中存在的问题以及患者病情变化,提出后续治疗意见。
              </p>
              <p>5.2. 2.4坚持复诊负责制，认真对待所复诊的患者，不推诿患者。</p>
              <p>5.2. 2.5尊重患者合法权益，严格执行知情同意管理办法。</p>
              <p>
                5.2.
                2.6医师必须严格遵守各项规章制度，对患者进行诊断、治疗,开具处方。
              </p>
              <p>
                5.2.
                2.7医师应定期接受其所在医院组织的培训，并按要求通过医师定期考核。
              </p>
              <p>5.3互联网医院医生（医师）</p>
              <p>5.3.1医生（医师）具备要求：</p>
              <p>
                5.3.1.1依法取得相应执业资质，在依托的实体医疗机构或其他医疗机构注册。
              </p>
              <p>5.3.1.2取得门诊出诊资质的医师；</p>
              <p>5.3.2岗位职责： </p>
              <p>
                5.3.2.1托互联网等信息技术，按照国家及医院相应法律法规等要求逐步开展健康咨询、院后随访、用药指导、线下诊疗建议或转诊、会诊建议、线上诊疗等工作。包括：普通常见病、慢性病的患者的复诊；医药健康咨询；各类手术后、危重症经规范治疗后，需康复医疗或定期复诊的患者；患者的随访管理;
                卫生计生行政部门规定的其他服务等。
              </p>
              <p>
                5.3.2.2参与互联网诊疗的执业医师，按照以下规定开展互联网医疗服务工作：（1）遵守《执业医师法》规定的医师在执业活动中应履行的义务；应当遵循临床诊疗指南和临床技术操作规范有关要求开展诊疗工作,严格遵守医疗质量安全核心制度，做到合理用药、合理治疗；（2)执行首诊医师负责制，亲自询问病史、阅读检查报告，做出初步判断；（3)
                医生（医师）开展互联网诊疗活动应当按照《医疗机构病历管理规定》和《电子病历基本规范（试行）》等相关文件要求,为患者建立电子病历，并按照规定进行管理.患者可以在线查询检查检验结果和资料、诊断治疗方案、处方和医嘱等病历资料；(4）不隐瞒、误导或夸大病情,不过度医疗；在没有足够信息支撑判断时，不能做出线上诊疗行为；(5）应当严格遵守《处方管理办法》等处方管理规定.在线开具处方前，医师应当掌握患者病历资料，确定患者在实体医疗机构明确诊断为某种或某几种常见病、慢性病后,可以针对相同诊断的疾病在线开具处方。所有在线诊断、处方必须有医师电子签名。处方经药师审核合格后方可生效.不得在互联网上开具麻醉药品、精神类药品处方以及其他用药风险较高、有其他特殊管理规定的药品处方。为低龄儿童（6岁以下）开具互联网儿童用药处方时,应当确定患儿有监护人和相关专业医师陪伴。（6）进一步检查诊断未明确者，应申请线上转诊、会诊,或者建议线下医院就诊；（7）怀疑可能是传染病病例，建议患者立即到就近的实体医疗机构就诊；（8）国家法律、法规、规章规定的其他职责和从业规范。
              </p>
              <p>
                5.3.2.3医生（医师）应当严格执行信息安全和医疗数据保密的有关法律法规，妥善保管患者信息,不得非法买卖、泄露患者信息.发现有患者信息和医疗数据泄露情况时,应及时向医务科及信息科报告，及时采取有效应对措施。
              </p>
              <p>
                5.3.2.4医生（医师）在开展互联网医疗服务过程中,必须遵循《执业医师法》、《医疗机构管理条例》、《医疗事故处理条例》等法律、法规行为的，按照有关法律、法规规定处理。
              </p>

              {time ? (
                <p className="next">仔细阅读{time}秒后可进行下一步</p>
              ) : (
                <p className="next" onClick={() => this.toNext1()}>
                  下一步
                </p>
              )}
            </div>
          </div>
        ) : null}
        {visible2 ? (
          <div className="resd-outer">
            <div className="resd-inner">
              <h2>管理制度</h2>
              <h3>一、互联网医疗服务管理制度</h3>
              <p>
                1、制定目的：为规范本互联网医院管理，提高医疗服务效率，保证医疗质量和医疗安全，特制定本制度。
              </p>
              <p>
                2、制定依据：《医疗机构管理条例》、《医疗机构管理条例实施细则》、《互联网诊疗管理办法（试行）》、《互联网医院管理办法（试行）》、《远程医疗服务管理规范（试行）》等法律法规。
              </p>
              <p>3、适用范围： 在我院从事互联网诊疗活动的全体员工。</p>
              <p>4、职责：全体员工对本规则的实施负责。</p>
              <p>5、内容：</p>
              <p>
                5.1互联网医疗活动是指利用互联网技术为患者和公众提供健康咨询、疾病诊断、治疗方案、处方等服务的行为。
              </p>
              <p>
                5.2我院对互联网诊疗活动实行严格的线上医务人员准入及退出机制，开展严格的线上线下监管。
              </p>
              <p>
                5.3医务行政科、质管科、药剂药学部等职能部门负责我院互联网诊疗活动的日常监督管理。
              </p>
              <p>5.4互联网诊疗活动准入</p>
              <p>
                5.4.1我院开展互联网诊疗服务范围必须与我院执业许可的诊疗科目相一致。
              </p>
              <p>
                5.4.2我院依法完成互联网医院注册前,不能开展就诊活动。依法完成互联网医院注册后逐步开展线上诊疗活动。
              </p>
              <p>
                5.4.3线上医务人员准入条件。完全满足以下条件的医务人员，经医务行政科审核通过后方可开展线上诊疗。
              </p>
              <p>
                5.4.3.1依法取得相应执业资质，在依托的实体医疗机构或其他医疗机构注册。互联网医院提供服务的医师，应当确保完成主要执业机构规定的诊疗工作;
              </p>
              <p>5.4.3.2取得执业医师执业证书并注册在我院的医师；</p>
              <p>5.4.3.3取得门诊出诊资质的医师；</p>
              <p>5.4.3.4经我院互联网医院诊疗相关培训并考核合格的医师。</p>
              <p>
                5.4.4线上医务人员退出机制。医务人员出现以下情形之一者将暂停线上诊疗资质一年，经相关部门培训和考核合格后恢复其资质。
              </p>
              <p>5.4.4.1年度内差评三次及以上;</p>
              <p>5.4.4.2年度内经核实的有效投诉三次及以上;</p>
              <p>
                5.4.4.3年度内发生两次及以上违反我院相关制度或相关卫生管理法律法规者；
              </p>
              <p>5.4.4.4因线上不当诊疗行为导致重大医疗纠纷案例者。</p>
              <p>5.4.5互联网诊疗活动执业规则</p>
              <p>
                5.4.5.1我院开展互联网诊疗活动，应当具备满足互联网技术要求的设备设施、信息化平台、技术人员以及信息安全系统，符合国家信息安全等级要求。
              </p>
              <p>
                5.4.5.2开展线上诊疗前，必须对患者进行风险提示，获得患者的知情同意。
              </p>
              <p>
                5.4.5.3患者在实体医疗机构就诊后复诊的，互联网就诊医师可以出具诊断意见并开具处方；患者未在实体医疗机构就诊，医师不能提供就诊服务。当患者出现病情变化需要医务人员亲自诊查时,医务人员应当立即终止互联网诊疗活动,引导患者到实体医疗机构就诊。
              </p>
              <p>5.4.6互联网医院可以开展以下医疗服务：</p>
              <p>5.4.6.1医药健康咨询；</p>
              <p>5.4.6.2普通常见病、慢性病的患者的复诊；</p>
              <p>
                5.4.6.3各类手术后、危重症经规范治疗后，需康复医疗或定期复诊的患者；
              </p>
              <p>5.4.6.4预约转诊、会诊服务;</p>
              <p>5.4.6.5患者的随访管理;</p>
              <p>5.4.6.6家庭医生的签约服务；</p>
              <p>5.4.6.7卫生计生行政部门规定的其他服务。</p>
              <p>
                5.4.7参与互联网诊疗的执业医师，按照以下规定开展互联网医疗服务工作：
              </p>
              <p>
                5.4.7.1遵守《执业医师法》规定的医师在执业活动中应履行的义务；应当遵循临床诊疗指南和临床技术操作规范有关要求开展诊疗工作，严格遵守医疗质量安全核心制度，做到合理用药、合理治疗；
              </p>
              <p>
                5.4.7.2执行首诊医师负责制，亲自询问病史、阅读检查报告，做出初步判断；
              </p>
              <p>
                5.4.7.3医务人员开展互联网诊疗活动应当按照《医疗机构病历管理规定》和《电子病历基本规范（试行）》等相关文件要求，为患者建立电子病历，并按照规定进行管理。患者可以在线查询检查检验结果和资料、诊断治疗方案、处方和医嘱等病历资料。
              </p>
              <p>
                5.4.7.4不隐瞒、误导或夸大病情,不过度医疗；在没有足够信息支撑判断时，不能做出线上诊疗行为；
              </p>
              <p>
                5.4.7.5应当严格遵守《处方管理办法》等处方管理规定。在线开具处方前，医师应当掌握患者病历资料，确定患者在实体医疗机构明确诊断为某种或某几种常见病、慢性病后,可以针对相同诊断的疾病在线开具处方。
              </p>
              <p>
                5.4.7.6所有在线诊断、处方必须有医师电子签名。处方经药师审核合格后方可生效。不得在互联网上开具麻醉药品、精神类药品处方以及其他用药风险较高、有其他特殊管理规定的药品处方。为低龄儿童（6岁以下）开具互联网儿童用药处方时，应当确定患儿有监护人和相关专业医师陪伴。
              </p>
              <p>
                5.4.7.7进一步检查诊断未明确者,应申请线上转诊、会诊，或者建议线下医院就诊;
              </p>
              <p>
                5.4.7.8怀疑可能是传染病病例，建议患者立即到就近的实体医疗机构就诊；
              </p>
              <p>5.4.7.9国家法律、法规、规章规定的其他职责和从业规范。</p>
              <p>
                5.4.8医务人员及信息管理人员应当严格执行信息安全和医疗数据保密的有关法律法规，妥善保管患者信息，不得非法买卖、泄露患者信息.发现有患者信息和医疗数据泄露情况时，应及时向医务行政科及信息安全维护部报告,及时采取有效应对措施。
              </p>
              <p>
                5.4.9互联网医院发生的医疗服务不良事件和药品不良事件按照国家有关规定上报。
              </p>
              <p>
                5.4.10参与互联网医院工作的药师按照有关规定取得相应的药学专业技术职务任职资格。推行临床药师制,加强临床药学服务能力建设,临床诊断、预防和治疗疾病用药应当遵循安全、有效、经济的合理用药原则，尊重患者对药品使用的知情权。
              </p>
              <p>5.5互联网诊疗活动监督管理</p>
              <p>5.5.1本互联网医院自觉接受上级卫生行政部门的日常监督和管理。</p>
              <p>
                5.5.2医务行政科对线上医务人员资质准入、退出和诊疗行为进行日常管理。
              </p>
              <p>5.5.3医务行政科负责线上医务人员满意度及医疗投诉案例的处理。</p>
              <p>
                5.5.4质管科制定互联网医院线上诊疗质控指标体系并进行日常监督和评价。
              </p>
              <p>
                5.5.5患者各种病历资料、医师意见以及相关资料等数据能实现全程全天候调阅、回溯和特殊需求时的数据备份。
              </p>
              <p>5.6法律责任</p>
              <p>
                5.6.1医务人员在开展互联网医疗服务过程中，有违反《执业医师法》、《医疗机构管理条例》、《医疗事故处理条例》等法律、法规行为的，按照有关法律、法规规定处理。
              </p>
              <p>
                5.6.2医务人员经医院同意开展互联网诊疗活动发生医疗纠纷时,按照《中华人民共和国侵权责任法》、医疗卫生管理法律法规及我院相关规定予以处理。
              </p>
              <p>
                5.6.3医务人员未经医院同意，擅自开展互联网诊疗活动的,承担全部后果和责任。
              </p>
              <p>5.7互联网诊疗活动应规范用语：</p>
              <p>
                5.7.1您好，我是xx科的xx医生，您的咨询问题已收到，接下来我需要再了解一下您现在的整体情况，以便给您合理专业的建议。
              </p>
              <p>5.7.2您好，我是xx科xx医生，请问您这次想咨询的是xx的问题吗？</p>
              <p>5.7.3您好，我是xx科xx医生，请问您目前有哪些不舒服？</p>
              <p>5.7.4您好，我是xx科xx医生，请问您本次具体咨询哪些医药问题？</p>
              <p>
                5.7.5
                请问您之前有过类似的情况，比如xxx等不适吗？线下医院有治疗过吗？
              </p>
              <p>
                5.7.6麻烦发几张不同距离、角度，清晰的图片看看具体情况吧（请发送原图，不要用美颜滤镜功能哦）？或者上传下医院病历吗?以便进一步了解您的病情。谢谢！
              </p>
              <p>
                5.7.7方便上传清晰照片吗？或者方便上传下您的检查报告吗?以便了解您的病情。
              </p>
              <p>
                5.7.8根据目前您的整体情况考虑xxx疾病，但是也不能排除xxx疾病。
              </p>
              <p>
                5.7.9为了您的用药安全，请您回复以下问题：是否有食物/药物过敏史或慢性疾病史，肝肾功能是否正常，是否有长期用药史，如果没有，请回复“无”。{" "}
              </p>
              <p>
                5.7.10为了您的用药安全，请您回复以下问题：是否有食物/药物过敏史或慢性疾病史，肝肾功能是否正常，是否有长期用药史，如果没有，请回复“无”。您是否处在月经期、备孕期、孕期、哺乳期等特殊时期。（育龄期女性）
              </p>
              <p>
                5.7.11建议您服用xx药物xx天/周，如果没有改善或者期间症状加重，请您及时就诊。
              </p>
              <p>
                5.7.12您好，一般这个情况使用XX药物，但是由于线上诊疗条件有限，无法进行必要的查体和检查，不能对疾病进行充分的判断，暂时无法为您提供准确的判断和治疗方案，建议您去当地正规的医院xx科（患者应就诊科室：比如内科）做一下（根据医生自己经验给予检查项目：比如xx检查（胸部CT检查，冠状动脉CTA，心电图）等以判断实际情况。
              </p>
              <h3>二、互联网医院信息系统使用管理制度</h3>
              <p>
                1、制定目的：为确保正确、安全的操作信息处理设施，防止信息遭受未授权泄露、修改、移动或销毁，以及因操作原因造成的业务活动中断，特制定本制度。
              </p>
              <p>
                2、制定依据：《医疗机构管理条例》、《医疗机构管理条例实施细则》、《互联网诊疗管理办法（试行）》、《互联网医院管理办法（试行）》、《远程医疗服务管理规范（试行）》等法律法规。
              </p>
              <p>3、适用范围：全体员工。</p>
              <p>4、职责：全体员工对本规则的实施负责。</p>
              <p>5、内容：</p>
              <p>
                5.1信息系统操作原则：所有员工必须遵守各种信息处理设施的有关使用规定，并按照相关规定操作。未经授权不得操作信息系统，已授权用户必须在授权范围内使用信息系统，不得使用其他员工的权限操作系统，更不得恶意使用系统。
              </p>
              <p>
                5.2用户权限以够用、适用和集中统、分级管理为原则，对医院信息系统用户权限进行规范管理。
              </p>
              <p>
                5.3在线医生的登录权限、执业范围由医务行政科批准后，由信息安全维护部开通权限，用户应保护自己的账号、密码，对自己的账号和密码负责，不得透露给他人只用。离职员工的账号密码应及时取消授权。
              </p>
              <p>
                5.4设备管理：计算机以及计算机的外围设备，不得随意挪换计算机内、外部配件。使用中出现技术性故障，应及时分析，对于人为操作不当、非正常使用软件、致电信息安全维护部由技术人员及时排查或维护维修。
              </p>
              <p>
                5.5使用管理：操作人员在使用计算机时，必须使用ID和口令登录系统，保持桌面清洁和离开锁屏等良好习惯。要严格遵守操作规程，注意爱护设备，保持清洁，使设备处于良好状态，下班时，务必关机切断电源。未经许可，使用者不可增删硬盘上的应用软件和系统软件，严禁使用计算机玩游戏，登录非法网站。
              </p>
              <p>
                5.6安全管理：计算机一些内部应用系统的数据资料列入保密范围，未经许可，严禁非相关人员私自复制，与互联网相连的计算机不得保存与工作有关的机密性文件与资料，涉及工作秘密的文字材料或信息不得在互联网上发布，或以电子邮件的形式发送。
              </p>
              <h3>三、互联网医疗质量控制和评价制度</h3>
              <p>
                1、制定目的：为了进一步加强互联网医院医疗质量管理，防范医疗事故的发生，确保医疗安全，根据相关法律法规的规定，结合本院医疗质量管理的实际情况，特制定本制度。
              </p>
              <p>
                2、制定依据：《医疗机构管理条例》、《医疗机构管理条例实施细则》、《互联网诊疗管理办法（试行）》、《互联网医院管理办法（试行）》、《远程医疗服务管理规范（试行）》等法律法规。
              </p>
              <p>3、适用范围：全体员工。</p>
              <p>4、职责：全体员工对本规则的实施负责。</p>
              <p>5、内容：</p>
              <p>5.1建立健全医疗质量保证体系及质量管理部门。</p>
              <p>
                5.1.1互联网医院领导小组负责监督本院的质量管理工作，质量管理部负责本院质量管理工作，各部门负责本部门质量工作，职责与权限范围清晰。
              </p>
              <p>
                5.1.2院长作为本院医疗质量管理第一责任人，应认真履行质量管理与改进的领导与决策职能；其他部门应切实参与制定、监控质量管理与改进过程。
              </p>
              <p>
                5.1.3各级责任人应明确自己的职权和岗位职责，并应具备相应的质量管理与分析技能。
                定期召开工作会议，为医院质量管理提供决策依据。
              </p>
              <p>
                5.2各部门根据质量管理有关要求和自身医疗工作的实际，建立切实可行的质量管理方案。
              </p>
              <p>
                5.2.1医疗质量管理与持续改进方案是全面、系统的书面计划，能够监督各科室，重点是医疗、护理、医技科室的日常质量管理与质量的危机管理。
              </p>
              <p>
                5.2.2质量管理方案的主要内容包括：建立质量管理目标、指标、计划、措施、效果评价及信息反馈等，加强医疗质量关键环节、重点科室和重点岗位的管理。
              </p>
              <p>
                5.3健全医院管理制度和人员岗位职责制度，严格落实医疗质量和医疗安全的核心制度。
              </p>
              <p>
                5.3.1管理制度包括在线审方管理、在线医疗文书管理、在线复诊患者风险评估遇与突发状况预防处置、信息系统使用等。
              </p>
              <p>
                5.3.2医院拟制互联网医院管理办法，对各项业务进行明确，并将具体的管理办法嵌入实际互联网诊疗操作平台之中，完善如电子病历、电子处方等质量控制为重点的格式和模板，实现闭环管理。参与互联网医院服务运营的医护人员、管理人员，须按互联网医院管理办法规定工作，以确保服务质量。
              </p>
              <p>5.3.3对病历质量管理要重点加强运行病历的实时监控与管理。</p>
              <p>
                5.4加强全员质量和安全教育，牢固树立质量和安全意识，提高全员质量管理与改进的意识与参与能力，严格执行医疗技术操作规范和常规；医务人员“基础理论、基本知识、基本技能”必须人人达标。
              </p>
              <p>5.5医师网上诊疗真实性的技术保障</p>
              <p>
                5.5.1所有进行网络服务人员均为在医院注册执业的医务人员，经操作培训和规章制度考核后，按相关专业向患者开放。医师可根据实际情况，设置远程会诊、图文资讯、视频问诊等业务类型和时段，并利用数据平台共享优势，查看相关检查检验结果，提出处理意见。相关互联网诊疗操作参考临床路径进行设置，确保符合诊疗规范和标准化操作流程，并实时记录，做到有证可查，有据可循。
              </p>
              <p>5.5.2医师在本院网上行医，必须设置其唯一的行医凭证。</p>
              <p>
                5.5.3医师在本院开具处方时，必须准确输入其签章密码才可完成处方提交，以确保该处方由其本人开具。
              </p>
              <p>
                5.5.4医师未进行身份设置及相关权限开通，则不可以使用在线执医相关服务。
              </p>
              <p>5.5.5若医师忘记密码，可通过手机号验证身份后重新设置。</p>
              <p>
                5.6医院对互联网诊疗对象进行严格限制，部分常见病、慢性病复诊时，医师应当掌握患者病历资料，确定患者在实体医疗机构明确诊断为某种或某几种常见病、慢性病后，可以针对相同诊断进行复诊。当患者出现病情变化需要医务人员亲自诊查时，应当立即终止互联网诊疗活动，引导患者到实体医疗机构就诊。
              </p>
              <p>
                5.7为低龄儿童（6岁以下）开具互联网儿童用药处方时，应当确认患儿有监护人和相关专业医师陪伴。
              </p>
              <p>
                5.8质量管理工作应有文字记录，并由质量管理组织形成报告，定期、逐级上报。通过检查、分析、评价、反馈等措施，持续改进医疗质量，将质量与安全的评价结果纳入对医院、科室、员工的绩效评价评估。
              </p>
              <p>
                5.9建立与完善医疗质量管理实行责任追究的制度、形成医疗质量管理可追溯与质量危机预警管理的运行机制。
              </p>
              <p>
                5.10建立不以处罚为目标的，针对医院质量管理系统持续改进为对象的不良事件报告系统，能够把发现的缺陷，用于对医疗质量管理制度、运行机制与程序的改进工作。
              </p>
              <p>
                5.11为强化互联网医院服务质量控制，持续改进互联网诊疗服务，医院基于患者随访系统构建患者评价体系，区分诊疗结束一周后对基层医疗机构或患者进行满意度测评。就
                患者留言提出的意见建议，及时分配相关部门或科室予以解决，并将解决情况第一时间进行反馈。
              </p>
              <p>
                5.12建立与完善质量管理常用的结果性指标体系，逐步形成结果性指标、结构性指标、过程性指标的监控和评价体系。
              </p>
              <h3>四、互联网医院在线处方管理制度</h3>
              <p>
                5.12建立与完善质量管理常用的结果性指标体系，逐步形成结果性指标、结构性指标、过程性指标的监控和评价体系。
              </p>
              <p>
                2、制定依据：《医疗机构管理条例》、《医疗机构管理条例实施细则》、《互联网诊疗管理办法（试行）》、《互联网医院管理办法（试行）》、《远程医疗服务管理规范（试行）》等法律法规。
              </p>
              <p>3、适用范围：在我院从事互联网处方审核活动的，适用本规范</p>
              <p>4、职责：临床科室、药剂药学部、质管部对本规则的实施负责。</p>
              <p>5、内容：</p>
              <p>5.1电子处方管理的一般规定</p>
              <p>
                5.1.1电子处方，是指由注册的医师在互联网线上诊疗活动中为患者开具的、由取得药学专业技术职务任职资格的药师审核、调配、核对，并作为患者用药凭证的医疗文书。
              </p>
              <p>5.1.2电子处方应当严格遵守《处方管理办法》等处方管理规定。</p>
              <p>5.1.3电子处方书写规则</p>
              <p>5.1.3.1电子处方按系统要求完成各项填写；</p>
              <p>
                5.1.3.2患者一般情况、临床诊断填写清晰、完整，并与病历记载相一致；
              </p>
              <p>5.1.3.3每张处方限于一名患者的用药；</p>
              <p>
                5.1.3.4开具处方用药应当使用经药监局批准并公布的药品通用名称、新活性化合物的专利药品名称和复方制剂药品名称；药品用法可用规范的中文、英文、拉丁文或者缩写体书写；药品用法用量应当按照药品说明书规定的常规用法用量使用，特殊情况需要超剂量使用时，应当注明原因并再次签名。
              </p>
              <p>
                5.1.3.5所有在线处方必须有医师电子签名。处方经药师审核合格后方可生效。
              </p>
              <p>5.2处方权的获得</p>
              <p>
                已在互联网医院注册多点执业的执业医师应在院内取得相应处方权，其签名式样和专用签章应在医院药剂药学部门留样备查。
              </p>
              <p>5.3处方的开具</p>
              <p>
                5.3.1在线开具处方前，医师应当掌握患者病历资料，确定患者在实体医疗机构明确诊断为某种或某几种常见病、慢性病后，可以针对相同诊断的疾病在线开具处方。
              </p>
              <p>
                5.3.2医师按照诊疗规范、药品说明书中的药品适应证、药理作用、用法、用量、禁忌、不良反应和注意事项等开具处方。
              </p>
              <p>
                5.3.3处方开具当日有效，特殊情况下需延长有效期的,由开具处方的医师注明有效期限,但有效期最长不得超过3天。
              </p>
              <p>
                5.3.4不得在互联网上开具麻醉药品、精神类药品处方以及其他用药风险较高、有其他特殊管理规定的药品处方。
              </p>
              <p>
                5.3.5为低龄儿童（6岁以下）开具互联网儿童用药处方时，应当确定患儿有监护人和相关专业医师陪伴。
              </p>
              <p>5.4处方的审核</p>
              <p>
                5.4.1具有药师以上专业技术职务任职资格的人员负责处方审核、评估、安全用药指导等工作。
              </p>
              <p>
                5.4.2药师是互联网处方审核工作的第一责任人，药师应按照规范认真审核处方，审核包括
              </p>
              <p>
                5.4.2.1处方前记、正文和后记填写是否清晰、完整，并确认处方的合法性。
              </p>
              <p>5.4.2.2电子处方用药适宜性进行审核，审核内容包括：</p>
              <p>5.4.2.2.1处方用药与临床诊断的相符性；</p>
              <p>5.4.2.2.2剂量、用法的正确性；</p>
              <p>5.4.2.2.3选用剂型与给药途径的合理性；</p>
              <p>5.4.2.2.4是否有重复给药现象；</p>
              <p>5.4.2.2.5是否有潜在临床意义的药物相互作用和配伍禁忌；</p>
              <p>5.4.2.2.6其它用药不适宜情况。</p>
              <p>
                5.4.3药师经处方审核后，认为存在用药不适宜时，要反馈给电子处方医师，请其确认后重新开具处方，并做相应的记录。药师发现严重不合理用药或者用药错误，应及时告知处方医师，请其确认后重新开具处方，并做相应记录。
              </p>
              <p>
                5.4.4电子处方经审核通过后方可进入处方对外流转；未经审核通过的处方不得处方对外流转。
              </p>
              <p>5.5监督管理</p>
              <p>5.5.1质管部进行日常监督和评价。</p>
              <p>
                5.5.2建立电子处方点评制度，填写处方评价表，对处方实施动态监测及超常预警，及时通报不合理处方，对不合理用药及时予以干预。
              </p>
              <p>
                5.5.3对出现超常处方3次以上且无正当理由的医师提出警告，限制其处方权；限制处方权后，仍连续2次以上出现超常处方且无正当理由的，取消其处方权。
              </p>
              <p>5.5.4医师出现下列情形之一的，处方权由医院予以取消：</p>
              <p>5.5.4.1被责令暂停执业；</p>
              <p>5.5.4.2考核不合格离岗培训期间；</p>
              <p>5.5.4.3被注销、吊销执业证书；</p>
              <p>5.5.4.4不按照规定开具处方，造成严重后果的；</p>
              <p>5.5.4.5不按照规定使用药品，造成严重后果的；</p>
              <p>5.5.4.6因开具处方牟取私利。</p>
              <p>
                5.5.5未取得药学专业技术职务任职资格的人员不得从事处方审核工作。
              </p>
              <p>5.5.6电子处方由药剂药学部保管。</p>
              <h3>五、互联网医院在线医疗文书管理制度</h3>
              <p>
                1、制定目的：为规范我院互联网医院在线医疗文书管理，保护医患双方合法权益，制定本制度
              </p>
              <p>
                2、制定依据：《医疗机构管理条例》、《医疗机构管理条例实施细则》、《互联网诊疗管理办法（试行）》、《互联网医院管理办法（试行）》、《远程医疗服务管理规范（试行）》等法律法规。
              </p>
              <p>3、适用范围：全体员工</p>
              <p>4、职责：全体员工对本规则的实施负责。</p>
              <p>5、内容：</p>
              <p>
                5.1在线医疗文书是指医务人员在取得患者线上诊疗知情同意后，在线记录的患者签署的知情同意书、图文资料（患者提供的在实体医疗机构就诊的病例资料）、电子病历、电子处方、检验检查预约申请单及线上会诊/转诊申请单等。
              </p>
              <p>
                5.2在线医疗文书的书写须遵照《病历书写基本规范》《处方管理办法》等规范执行。
              </p>
              <p>
                5.3患者有权利查询自身在线诊疗的所有医疗文书，有义务提供既往实体医疗机构就诊的真实病例资料,客观陈述在线诊疗后的病情变化情况等。
              </p>
              <p>5.4对在线医疗文书实施全流程监管与评价。</p>
              <p>
                5.5信息安全系统实现在线医疗文书的安全保存、严格保密、全流程可追溯，做好患者的隐私保护。
              </p>
              <h3>六、互联网医院患者知情同意与登记制度</h3>
              <p>
                1、制定目的：为规范我院互联网医院诊疗行为，保护医患双方权益，制定本制度。
              </p>
              <p>
                2、制定依据：《医疗机构管理条例》、《医疗机构管理条例实施细则》、《互联网诊疗管理办法（试行）》、《互联网医院管理办法（试行）》、《远程医疗服务管理规范（试行）》等法律法规。
              </p>
              <p>3、适用范围：全体员工</p>
              <p>4、职责：全体员工对本规则的实施负责。</p>
              <p>5、内容：</p>
              <p>
                5.1互联网医疗中的知情同意是指患者对实施互联网医疗的目的、方式、适用疾病范围、线上诊疗流程、使用技术及其诊疗效果预期、潜在风险和风险防范预案等的充分了解及对开展互联网医疗服务的同意。
              </p>
              <p>
                5.2在未取得患者互联网诊疗知情同意前，线上医务人员严禁开始线上诊疗活动。每一次的线上诊疗活动前都须签署知情同意书。
              </p>
              <p>
                5.3患者有义务提供线上医务人员诊疗所需的既往在实体医疗机构的疾病诊疗情况、近期检验检查的复查报告等，患者无法提供的，医务人员有权拒绝开展线上诊疗。
              </p>
              <p>
                5.4《云益健互联网医院患者知情同意书》内容至少包括：互联网医疗的目的、方式、适用疾病范围、线上诊疗流程、使用技术及其诊疗效果预期、线上会诊、线上转线下诊疗、线上诊疗的潜在风险和风险防范预案等，信息部负责知情同意书模板的线上维护、签署痕迹的后台登记、保存和信息安全保护。
              </p>
              <p>
                5.5线上诊疗过程中涉及到特殊检查、特殊治疗等情形，医务人员需告知患者在线或线下签署相关知情同意书后方可继续诊疗。
              </p>
              <p>
                5.6线上诊疗过程中患者出现病情变化或不适合继续线上诊疗的，需在线及时与患者充分沟通,建议其转线下诊疗。沟通情况及时记录在电子病历中。
              </p>
              <h3>
                七、互联网医院医疗安全(不良）事件和患者安全医患报告管理办法
              </h3>
              <p>
                1、制定目的：为了确保互联网医院的医疗安全，提高医务人员风险意识，及时妥善处理医疗不良事件及患者安全隐患，减少或避免医疗差错和事故的发生，促进从不良事件和差错中吸取教训，持续提高医疗服务质量，制定本制度。
              </p>
              <p>
                2、制定依据：《医疗机构管理条例》、《医疗机构管理条例实施细则》、《互联网诊疗管理办法（试行）》、《互联网医院管理办法（试行）》、《远程医疗服务管理规范（试行）》等法律法规。
              </p>
              <p>3、适用范围：全体员工</p>
              <p>4、职责：全体员工对本规则的实施负责。</p>
              <p>5、内容：</p>
              <p>5.1医疗安全（不良）事件和安全隐患的界定及内容</p>
              <p>5.1.1 本规定所称的医疗不良事件是指以下情况：</p>
              <p>
                5.1.1.1在疾病医疗过程中由于诊疗活动而非疾病本身造成的患者机体与功能损害；
              </p>
              <p>
                5.1.1.2虽然发生了错误事实，但未给患者机体与功能造成损害,或有轻微后果可以康复的事件；
              </p>
              <p>5.1.1.3药物不良事件及药品不良反应事件；</p>
              <p>5.1.1.4其他医疗安全（不良）事件等。</p>
              <p>5.1.2 本规定所称的患者安全隐患是指以下情况:</p>
              <p>
                5.1.2.1在线诊疗过程中发现存在缺陷或漏洞，但未形成事实的隐患事件；
              </p>
              <p>
                5.1.2.2在线诊疗过程中不能确定是否存在过失差错，尚未造成明显损伤后果，但存在转化为不良事件可能性的事件；
              </p>
              <p>
                5.1.2.3患者对在线医疗或服务不满意，可能发生纠纷或出现问题的事件；
              </p>
              <p>5.1.2.4其他患者安全隐患等。</p>
              <p>5.2报告要求及流程</p>
              <p>5.2.1医疗安全（不良）事件报告流程</p>
              <p>5.2.1.1医疗安全（不良）事件实行强制报告制度；</p>
              <p>5.2.1.2当事医师有按本规定报告的责任；</p>
              <p>
                5.2.1.3医疗安全（不良）事件发生后,当事医师得知信息后应在48小时内填报《医疗安全（不良）事件报告表》上报医务行政科及院长；
              </p>
              <p>
                5.2.1.4造成死亡、伤残或重要器官功能损伤的严重医疗安全（不良）事件应在事件发生后24小时内填报《医疗安全（不良）事件报告表》上报医务行政科及院长；
              </p>
              <p>
                5.2.1.5药品不良反应事件报告由药品配送企业负责上报药品不良反应监测中心，并同时报告药学药剂科。
              </p>
              <p>5.2.2患者安全隐患报告流程</p>
              <p>5.2.2.1患者安全隐患实行主动报告原则；</p>
              <p>
                5.2.2.2鼓励医务人员主动报告安全隐患。安全隐患当事人和任何发现安全隐患的人员，都有责任向医务行政科报告。医务行政科对于上报的安全隐患信息，只用作工作流程改进，不作为对医疗过失差错当事人处罚的依据;
              </p>
              <p>5.2.2.3上报流程同医疗安全（不良）事件。</p>
              <p>5.3处理流程</p>
              <p>
                接到医疗安全（不良）事件和患者安全隐患报告后，由职能部门会同相关部门制定整改防范措施，并反馈报告人和相关部门，落实持续改进，同时由医务行政科汇集和管理,上报上级卫生行政主管部门。
              </p>
              <h3>八、互联网医院在线复诊患者风险评估与突发状况预防处置制度</h3>
              <p>
                1、制定目的：为规范我院互联网医院诊疗行为，加强在线复诊患者风险管理,保障医疗安全，提升患者满意度,制定本制度。
              </p>
              <p>
                2、制定依据：《医疗机构管理条例》、《医疗机构管理条例实施细则》、《互联网诊疗管理办法（试行）》、《互联网医院管理办法（试行）》、《远程医疗服务管理规范（试行）》等法律法规。
              </p>
              <p>3、适用范围：全体员工</p>
              <p>4、职责：全体员工对本规则的实施负责。</p>
              <p>5、内容：</p>
              <p>
                5.1各临床科室根据疾病特点，制定本专科常见病、多发病在线复诊指征。不适合在线诊疗的，建议患者线下诊疗。
              </p>
              <p>
                5.2医务人员严格遵守《互联网医院医疗安全(不良）事件和患者安全医患报告管理办法》，发现不良事件时按规定流程上报和处置。
              </p>
              <p>
                5.3医务人员每次开展线上诊疗前，需根据患者提供的病例资料，对患者进行完整的风险评估，对于评估后提示病情变化或不适合线上诊疗的,建议患者线下诊疗。
              </p>
              <p>
                5.4风险评估的内容至少包括：心理状况、疾病转归情况、伴发疾病情况、重要脏器功能评估、治疗依从性评估、心脑血管意外风险评估等。
              </p>
              <p>
                5.5复诊患者在线诊疗过程中发生突发状况而无法完成在线诊疗的，接诊医师需及时联系患者及家属，同时向医务行政科报告，医院启动医疗应急预案，开展患者救治；无法联系患者及家属的，医院借助信息化手段，及时完成患者定位，开展救治。
              </p>
              <p>
                5.6复诊患者在线诊疗结束后某个时间发生突发状况的，患者或家属可通过120急救平台实施就近急救。
              </p>
              <p>
                5.7互联网医院在线就诊患者须为病情非紧急状态下。对重症、危急患者，应提醒其及时到线下医疗机构就诊。
              </p>
              <h3>九、互联网医院医务人员培训与考核制度</h3>
              <p>
                1、制定目的：为规范我院互联网医院诊疗行为,增强线上医务人员综合素质，改善互联网医疗质量，保障医疗安全，提升患者满意度，制定本制度。
              </p>
              <p>
                2、制定依据：《医疗机构管理条例》、《医疗机构管理条例实施细则》、《互联网诊疗管理办法（试行）》、《互联网医院管理办法（试行）》、《远程医疗服务管理规范（试行）》等法律法规。
              </p>
              <p>3、适用范围：全体员工</p>
              <p>4、职责：全体员工对本规则的实施负责。</p>
              <p>5、内容：</p>
              <p>
                5.1
                医务人员必须参加医院组织的互联网医疗相关知识培训，考核合格后方可开展线上诊疗。培训的内容至少包括：互联网诊疗平台操作技能、线上诊疗礼仪与医患沟通技能、线上诊疗流程、在线医疗文书书写规范、在线处方管理、线上诊疗风险与风险防范预案等。
              </p>
              <p>
                5.2常态化开展医务人员诊疗技术与操作规范、临床诊疗指南、临床路径以及互联网医疗相关法律法规培训。
              </p>
              <p>
                5.3对线上医务人员实施年度考核，将考核结果应用于线上医务人员的准入、退出与绩效评价。
              </p>
              <p>5.4考核内容包括（见附件):</p>
              <p>5.4.1参加医院组织的互联网诊疗相关培训次数（每年缺勤≤1次）；</p>
              <p>
                5.4.2执业医师在线预约诊疗时间的一致性，即预约在线诊疗患者与执业医师实际履行咨询或诊疗活动时间的偏差情况（预约时间与实际提供咨询或诊疗服务时间偏差小于8小时）；
              </p>
              <p>5.4.3在线咨询问诊回复的准确性(准确性不低于90%）；</p>
              <p>
                5.4.4在线诊疗质量，包括诊断的准确性和合理性，治疗方案的规范性及诊疗效果随访情况(复诊的诊断准确性和合理性不低于90％，规范治疗率不低于90%，随访好转率不低于80%)；
              </p>
              <p>
                5.4.5执业医师开具的电子处方点评情况（每周期的处方点评合格率不低于90%）；
              </p>
              <p>
                5.4.6投诉及医疗纠纷情况,即执业医师因服务态度、服务质量而导致的投诉、医疗纠纷或医疗事故等情况,经认定过错方在执业医师的（根据实际投诉情况扣分）；
              </p>
              <p>
                5.4.7诚信服务方面，即执业医师对就诊患者不存在夸大、虚瞒病情、不通过开药获取药品提成、严格保护患者隐私、亲自接诊患者而不委托他人、模范遵守国家法律法规不散布与国家政策相悖的言论及信息等诚信行为（根据诚信行为情况赋分)；
              </p>
              <p>
                5.4.8在线患者满意度评价情况（根据年度互联网医院统计的在线患者对执业医师满意度的评价情况赋分）。
              </p>
              <p>5.5考核结果应用。</p>
              <p>
                5.5.1出现下列情形之一者暂停线上诊疗资质1年:年度评分＜60分者;年度内差评3次及以上;年度内经核实的有效投诉3次及以上；年度内发生2次及以上违反我院相关制度或相关卫生法律法规者;因线上不当诊疗行为导致重大医疗纠纷案例者。
              </p>
              <p>
                5.5.2出现下列情形之一者暂停线上诊疗资质3～6个月：年度评分60～70分者；年度内差评2次；年度内经核实的有效投诉2次；年度内发生1次违反我院相关制度或相关卫生法律法规者。
              </p>

              {time1 ? (
                <p className="next">仔细阅读{time1}秒后可进行下一步</p>
              ) : (
                <p className="next" onClick={() => this.toLoadCA()}>
                  下一步
                </p>
              )}
            </div>
          </div>
        ) : null}
        {docter.docter_examine == "1" || !docter ? (
          <>
            {localStorage.getItem("docuserType") == 2 ? (
              <p>
                <div className="doclinebox-top">
                  <div className="doclinetop-title">
                    <h1>医生工作台</h1>
                    <div className="doclinetop-info">
                      <img
                        src={docterAvatar != null ? docterAvatar : icon4}
                        alt=""
                      />
                      <h1 onClick={this.tologin}>
                        {docter.docter_phone
                          ? docter.docter_name
                            ? docter.docter_name
                            : docter.docter_phone
                          : "未登录"}
                        <span>
                          {docter.docter_phone
                            ? docter.docter_title
                              ? "/" + docter.docter_title
                              : ""
                            : "/未登录"}
                        </span>
                      </h1>
                    </div>
                  </div>
                  <div className="doclinetop-num">
                    <div onClick={() => this.nowdayJZ()}>
                      <p>{dayNum}</p>
                      <p>今日接诊</p>
                    </div>
                    <div onClick={() => this.allJZ()}>
                      <p>{allNum}</p>
                      <p>总接诊</p>
                    </div>
                    <div onClick={() => this.toAccount()}>
                      <p>
                        <span style={{ color: "#FB9A1C" }}>{countNum}</span>
                        <span>/</span>
                        <span>{countallNum}</span>
                      </p>
                      <p>开方数量</p>
                    </div>
                  </div>
                </div>
                <div className="box-content">
                  <div className="ordergrabbing-box">
                    <p className="ordergrabbing-top">
                      <span
                        className={
                          awaitcode == 1
                            ? "ordergrabbing-await"
                            : "ordergrabbing-await ordergrabbing-await1"
                        }
                        onClick={() => this.setState({ awaitcode: 1 })}
                      >
                        {/*<img src={icon1}/>*/}
                        <span>等待接诊({userlists.length})</span>
                      </span>
                      {/* 未开处方 */}
                      <span
                        className={
                          awaitcode == 3
                            ? "ordergrabbing-await"
                            : "ordergrabbing-await ordergrabbing-await1"
                        }
                        onClick={() => this.setState({ awaitcode: 3 })}
                      >
                        {/*<img src={icon1}/>*/}
                        <span>未开处方({nouserlist.length})</span>
                      </span>

                      <span
                        className={
                          awaitcode == 2
                            ? "ordergrabbing-await"
                            : "ordergrabbing-await ordergrabbing-await1"
                        }
                        onClick={() => this.setState({ awaitcode: 2 })}
                      >
                        {/*<img className='sx' src={icon2} onClick={() => this.allLists()}/>*/}
                        {/*<span className='sx-word'>刷新</span>*/}
                        <span>驳回处方({newslist.length})</span>
                      </span>
                    </p>
                    {awaitcode == 1 ? (
                      <div className="ordergrabbing-body">
                        {userlists.length ? (
                          userlists.map((item) => (
                            <div className="ordergrabbing-one">
                              <p className="ordergrabbing-name">
                                <p className="name">
                                  患者：{item.patientName}&nbsp;&nbsp;&nbsp;
                                  {item.patientSex == 1 ? "男" : "女"}
                                  {/* {item.sex == 1 ? "男" : "女"} */}
                                  &nbsp;&nbsp;&nbsp;{item.patient_age}岁
                                </p>
                                {item.consultation_type == "1" ? (
                                  <p className="type">图文问诊</p>
                                ) : item.consultation_type == "3" ? (
                                  <p className="type type2">视频问诊</p>
                                ) : null}
                              </p>
                              <p className="ordergrabbing-ill">
                                <p>
                                  疾病史：
                                  <span
                                    className={
                                      item.past_history == 0 ? "" : "blue"
                                    }
                                  >
                                    {item.past_history_note}
                                  </span>
                                </p>
                                <p>
                                  过敏史：
                                  <span
                                    className={
                                      item.allergic_history == 0 ? "" : "blue"
                                    }
                                  >
                                    {item.allergic_history_note}
                                  </span>
                                </p>
                              </p>
                              <p className="ordergrabbing-ill">
                                <p>
                                  肝肾功能：
                                  <span
                                    className={
                                      item.abnormal_liver_function == 0
                                        ? ""
                                        : "blue"
                                    }
                                  >
                                    {item.abnormal_liver_function == 0
                                      ? "无异常"
                                      : "有异常"}
                                  </span>
                                </p>
                                <p>
                                  {item.patientSex == 2 ? (
                                    <p>
                                      怀孕情况：
                                      <span className="">
                                      {item.pregnancy_status == 1
                                        ? "未怀孕"
                                        : item.pregnancy_status == 2
                                          ? "备孕中"
                                          : item.pregnancy_status == 3
                                            ? "已怀孕"
                                            : item.pregnancy_status == 4
                                              ? "哺乳期"
                                              : ''}
                                      </span>
                                    </p>
                                  ) : null}
                                </p>
                                {/* <p>肾功能：<span
                                                                className={item.abnormal_renal_function == 0 ? '' : 'blue'}>{item.abnormal_renal_function == 0 ? '无异常' : '有异常'}</span>
                                                            </p> */}
                              </p>

                              <p className="ordergrabbing-ill4">
                                是否医保统筹：
                                <span
                                  className={
                                    item.medical_nsurance_coordination == 1
                                      ? ""
                                      : "blue"
                                  }
                                >
                                  {item.medical_nsurance_coordination == 1
                                    ? "是"
                                    : "否"}
                                </span>
                              </p>
                              {/* {item.patientSex == 2 ? (
                                <p className="ordergrabbing-ill4">
                                  怀孕情况：
                                  <span className="">
                                    {item.pregnancy_status == 1
                                      ? "未怀孕"
                                      : item.pregnancy_status == 2
                                      ? "备孕中"
                                      : item.pregnancy_status == 3
                                      ? "已怀孕"
                                      : "哺乳期"}
                                  </span>
                                </p>
                              ) : null} */}
                              <p className="line"></p>
                              <p className="ordergrabbing-xuqiu">
                                <span>病情描述：</span>
                                <span style={{ color: "#999999" }}>
                                  {item.consultation_detail}
                                </span>
                              </p>

                              {item.is_chinese_meds == 1 ? (
                                <>
                                  <p className="ordergrabbing-xuqiu">    
                                    <span>中药需求：</span>
                                    <span>
                                      {item.array.map((i, index) => (
                                      <span key={index}>
                                        <span>{i.namespace};</span>
                                      </span>
                                    ))}
                                    </span>
                                  </p>
                                  <p className="ordergrabbing-xuqiu">    
                                    <span>用法用量：</span>
                                    <span >
                                      {item.array.slice(0, 1).map((i, index) => (
                                        <span key={index}>
                                          <span>
                                            共{i.co_demand}剂，{i.meiday}{i.daily}剂，每剂分{i.each_dose}次使用，
                                            每次{i.at_time}{i.unit}，{i.med_method}，
                                            {i.med_time == 1
                                              ? "饭前用药"
                                              : i.med_time == 2
                                              ? "饭后用药"
                                              : i.med_time == 3
                                              ? "睡前用药"
                                              : i.med_time == 4
                                              ? "晨起用药"
                                              : ""}
                                          </span>
                                        </span>
                                      ))}

                                    </span>
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="ordergrabbing-xuqiu">    
                                    <span>西药需求：</span>
                                    <span>
                                      {item.array.map((i, index) => (
                                      <span key={index}>
                                        <span className="classfi-tip">{i.supertype}</span>
                                        <span>{i.namespace};</span>
                                      </span>
                                    ))}
                                    </span>
                                  </p>
                                </>
                              )}
                              <p className="ordergrabbing-button">
                                <span>问诊时间：{item.createTime}</span>
                                <button onClick={() => this.sureReceive(item)}>
                                  确认接诊
                                </button>
                              </p>
                            </div>
                          ))
                        ) : docter ? (
                          <>
                            <Nodata type="患者" />
                            <span
                              className="refresh-home"
                              onClick={() => this.allLists()}
                            >
                              刷新
                            </span>
                          </>
                        ) : null}
                      </div>
                    ) : null}
                    {awaitcode == 2 ? (
                      <div className="ordergrabbing-body">
                        {newslist.length ? (
                          newslist.map((item) => (
                            <div className="ordergrabbing-one">
                              <p className="ordergrabbing-name">
                                <p className="name">
                                  患者：{item.patientName}&nbsp;（
                                  {item.patientSex == 1 ? "男" : "女"}/
                                  {item.patient_age}岁）
                                </p>
                                <p className="type00">【处方审核失败】</p>
                              </p>
                              <p className="ordergrabbing-ill4">
                                病情描述：
                                <span>{item.consultation_detail}</span>
                              </p>

                              {item.pha_refu_reson ? (
                                <>
                                  <p className="ordergrabbing-ill4">
                                    驳回理由：
                                    <span style={{ color: "#FF3D3D" }}>
                                    药店药师驳回：{item.pha_refu_reson}
                                    </span>
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="ordergrabbing-ill4">
                                    驳回理由：
                                    <span style={{ color: "#FF3D3D" }}>
                                      {item.reason}
                                    </span>
                                  </p>
                                </>
                              )}
                              
                              <p className="ordergrabbing-button">
                                <span>驳回时间：{item.pha_reviewer_time}</span>
                                <button onClick={() => this.goinfo(item)}>
                                  查看详情
                                </button>
                              </p>
                            </div>
                          ))
                        ) : docter ? (
                          <>
                            <Nodata type="驳回处方" />
                            <span
                              className="refresh-home"
                              onClick={() => this.allLists()}
                            >
                              刷新
                            </span>
                          </>
                        ) : null}
                      </div>
                    ) : null}
                    {awaitcode == 3 ? (
                      <div className="ordergrabbing-body">
                        {nouserlist.length ? (
                          nouserlist.map((item) => (
                            <div className="ordergrabbing-one">
                              <p className="ordergrabbing-name">
                                <p className="name">
                                  患者：{item.patientName}&nbsp;&nbsp;&nbsp;
                                  {item.patientSex == 1 ? "男" : "女"}
                                  &nbsp;&nbsp;&nbsp;{item.patient_age}岁
                                </p>
                                {item.consultation_type == "1" ? (
                                  <p className="type">图文问诊</p>
                                ) : item.consultation_type == "3" ? (
                                  <p className="type type2">视频问诊</p>
                                ) : null}
                              </p>
                              <p className="ordergrabbing-ill">
                                <p>
                                  疾病史：
                                  <span
                                    className={
                                      item.past_history == 0 ? "" : "blue"
                                    }
                                  >
                                    {item.past_history_note}
                                  </span>
                                </p>
                                <p>
                                  过敏史：
                                  <span
                                    className={
                                      item.allergic_history == 0 ? "" : "blue"
                                    }
                                  >
                                    {item.allergic_history_note}
                                  </span>
                                </p>
                              </p>
                              <p className="ordergrabbing-ill">
                                <p>
                                  肝肾功能：
                                  <span
                                    className={
                                      item.abnormal_liver_function == 0
                                        ? ""
                                        : "blue"
                                    }
                                  >
                                    {item.abnormal_liver_function == 0
                                      ? "无异常"
                                      : "有异常"}
                                  </span>
                                </p>
                                <p>
                                  {item.patientSex == 2 ? (
                                    <p>
                                      怀孕情况：
                                      <span className="">
                                      {item.pregnancy_status == 1
                                        ? "未怀孕"
                                        : item.pregnancy_status == 2
                                          ? "备孕中"
                                          : item.pregnancy_status == 3
                                            ? "已怀孕"
                                            : item.pregnancy_status == 4
                                              ? "哺乳期"
                                              : ''}
                                      </span>
                                    </p>
                                  ) : null}
                                </p>
                              </p>
                              <p className="ordergrabbing-ill4">
                                是否医保统筹：
                                <span
                                  className={
                                    item.medical_nsurance_coordination == 1
                                      ? ""
                                      : "blue"
                                  }
                                >
                                  {item.medical_nsurance_coordination == 1
                                    ? "是"
                                    : "否"}
                                </span>
                              </p>
                              <p className="line"></p>
                              <p className="ordergrabbing-xuqiu">
                                <span>病情描述：</span>
                                <span style={{ color: "#999999" }}>
                                  {item.consultation_detail}
                                </span>
                              </p>

                              {item.is_chinese_meds == 1 ? (
                                <>
                                  <p className="ordergrabbing-xuqiu">
                                    <span>中药需求：</span>
                                    <span>
                                      { 
                                        nouserlist1
                                          .filter(i => i.consultationId == item.consultation_id)
                                          .map(i => (
                                            <span key={i.id}>
                                              {i.medComName}
                                              {i.medQuantity}g
                                              {"；"}
                                            </span>
                                          ))
                                      }
                                    </span>
                                  </p>
                                  <p className="ordergrabbing-xuqiu">
                                    <span>用法用量：</span>
                                    <span >
                                      {nouserlist2.filter(i => i.consultation_id == item.consultation_id).map(i=>(
                                        <span>
                                          共{i.co_demand}剂，{i.meiday}{i.daily}剂，每剂分{i.each_dose}次使用，
                                          每次{i.at_time}{i.unit}，{i.med_method}，
                                          {i.med_time == 1
                                            ? "饭前用药"
                                            : i.med_time == 2
                                            ? "饭后用药"
                                            : i.med_time == 3
                                            ? "睡前用药"
                                            : i.med_time == 4
                                            ? "晨起用药"
                                            : ""}
                                        </span>
                                      ))}
                                      
                                    </span>
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="ordergrabbing-xuqiu">
                                    <span>西药需求：</span>
                                    <span>
                                      { 
                                        nouserlist1
                                          .filter(i => i.consultationId == item.consultation_id)
                                          .map(i => (
                                            <span key={i.id}>
                                              {i.supervisionType !== null  && <span className="classfi-tip">({i.supervisionType})</span>}  
                                              {i.medComName}
                                              {i.supervisionType !== null  && <span>{i.medSpec+'X'}</span>}
                                              {/* {i.medSpec+'X'} */}
                                              {i.medQuantity}
                                              {i.supervisionType == null  && <span>g</span>}
                                              {"；"}
                                            </span>
                                          ))
                                      }
                                    </span>
                                  </p>
                                </>
                              )}
                              
                              <p className="ordergrabbing-button">
                                <span>问诊时间：{item.createTime}</span>
                                <button onClick={() => this.goinfo(item)}>
                                {/* <button onClick={() => this.sureReceive()}> */}
                                  去开方
                                </button>
                              </p>
                            </div>
                          ))
                        ) : docter ? (
                          <>
                            <Nodata type="未开处方" />
                            <span
                              className="refresh-home"
                              onClick={() => this.allLists()}
                            >
                              刷新
                            </span>
                          </>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {/*<img src={icon6} alt="" className='content-pic'/>*/}
                </div>
                {visible5 ? (
                  <div className="Reviewer-sure-outer">
                    <div className="Reviewer-sure-inner">
                      <h2>提示弹窗</h2>
                      <p className="sure-Reviewer">确认接诊</p>
                      <div className="read-sure-Reviewer">
                        <input
                          type="radio"
                          checked={reviewercode}
                          // checked={true}
                          name="reviewercode"
                          onClick={
                            () =>
                            this.setState({
                              reviewercode: !reviewercode,
                            })
                          }
                        />
                        <span>
                          已阅读并同意
                          <span
                            style={{ color: "#346FF2" }}
                            onClick={() =>
                              this.setState({
                                visible6: true,
                              })
                            }
                          >
                            《医生接诊准则》
                          </span>
                          ，且承诺是医生本人提供诊诊疗服务。
                        </span>
                      </div>
                      <div className="button-Reviewer">
                        <span
                          onClick={() =>
                            this.setState({
                              visible5: false,
                              reviewercode:true
                            })
                          }
                        >
                          取消
                        </span>
                        <span onClick={() => this.sureReviewer()}>确认</span>
                      </div>
                    </div>
                  </div>
                ) : null}
                {visible6 ? (
                  <div className="Reviewer-sure-outer">
                    <div className="Reviewer-sure-inner Reviewer-sure-inner1">
                      <h2>医生接诊准则</h2>
                      <p className="sure-Reviewer sure-Reviewer1">
                        <span>
                          1.在线开具处方前，医师应当掌握患者病历资料，确定患者在实体医疗机构明确诊断为某种或某几种常见病、慢性病后
                          <span style={{ color: "#346FF2" }}>
                            (确认用户为复诊)
                          </span>
                          ，可以针对相同诊断的疾病在线开具处方。
                        </span>
                      </p>
                      <p className="sure-Reviewer sure-Reviewer1">
                        <span>
                          2.医师接诊前需进行实名认证，确保由
                          <span style={{ color: "#346FF2" }}>
                            本人提供诊疗服务
                          </span>
                          。其他人员、人工智能软件等不得冒用、替代医师本人提供诊疗服务。否则将承担一切法律责任。
                        </span>
                      </p>
                      <p className="sure-Reviewer sure-Reviewer1">
                        <span>
                          3.当患者病情出现变化、本次就诊经医师判断存在其他不适宜互联网诊疗的情况时，接诊医师应当立即终止互联网诊疗活动，并引导患者到实体医疗机构就诊。
                        </span>
                      </p>
                      <p className="sure-Reviewer sure-Reviewer1">
                        <span>
                          4.医生在开药时要遵循国家和地方的相关法规，严格控制药品的使用量。医生应该严格执行药品购进管理和使用管理制度，遵循国家和地方规定的药品使用标准，
                          <span style={{ color: "#346FF2" }}>避免超量开药</span>
                          。
                        </span>
                      </p>
                      <p
                        className="already-Reviewer"
                        onClick={() =>
                          this.setState({
                            visible6: false,
                            reviewercode: true,
                          })
                        }
                      >
                        我已阅读
                      </p>
                    </div>
                  </div>
                ) : null}
              </p>
            ) : (
              <p>
                {/*    <div className='box-top'>*/}
                {/*        <div className='top-title'>*/}
                {/*            <h1>医生工作台</h1>*/}
                {/*            /!* <img src={icon1} alt="" /> *!/*/}
                {/*            /!* <img src={icon2} alt="" className='title-circular' /> *!/*/}
                {/*        </div>*/}
                {/*        <div className='top-info'>*/}
                {/*            /!* <img src={docter?docter.docter_avatar?docter.docter_avatar:icon4:icon4} alt="" /> *!/*/}
                {/*            <img src={docterAvatar != null ? docterAvatar : icon4} alt=""/>*/}
                {/*            <div onClick={this.tologin}>*/}
                {/*                <h1>*/}
                {/*                    {docter.docter_phone ? docter.docter_name ? docter.docter_name : docter.docter_phone : "未登录"}*/}
                {/*                    <span>{docter.docter_phone ? docter.docter_title ? '/' + docter.docter_title : "" : "/未登录"}</span>*/}
                {/*                </h1>*/}
                {/*                <p>{docter.docter_phone ? docter.docter_hospital ? docter.docter_hospital : "资质审核中" : "未登录"}</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className='top-num'>*/}
                {/*            <div>*/}
                {/*                <p>{dayNum}</p>*/}
                {/*                <p>今日接诊</p>*/}
                {/*            </div>*/}
                {/*            <div>*/}
                {/*                <p>{allNum}</p>*/}
                {/*                <p>总接诊</p>*/}
                {/*            </div>*/}
                {/*            <div>*/}
                {/*                <p>*/}
                {/*                    <span style={{color: '#FB9A1C'}}>{countNum}</span>*/}
                {/*                    <span>/</span>*/}
                {/*                    <span>{countallNum}</span>*/}
                {/*                </p>*/}
                {/*                <p>开方数量</p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className='box-content'>*/}
                {/*        /!* <div className='box-tips1'>*/}
                {/*    <img src={icon3} alt="" className='tips1-icon' />*/}
                {/*    <div className='tips1-title'>*/}
                {/*        <h1>在线接诊</h1>*/}
                {/*        <p>查看并回复患者发起的咨询</p>*/}
                {/*    </div>*/}

                {/*    <div className='tips1-right' onClick={() => this.props.history.push("/chatdoctor")}>*/}
                {/*        <span>0</span><img src={icon5} alt="" />*/}
                {/*    </div>*/}
                {/*</div> *!/*/}
                {/*        <div className='box-tips1'>*/}
                {/*            <img src={icon9} alt="" className='tips1-icon'/>*/}
                {/*            <div className='tips1-title'>*/}
                {/*                <h1>视频处方</h1>*/}
                {/*                <p>视频问诊开具处方</p>*/}
                {/*            </div>*/}

                {/*            <div className='tips1-right'*/}
                {/*                 onClick={() => this.toVideo()}>*/}
                {/*                <img src={icon5} alt=""/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className='box-tips2'>*/}
                {/*            <div>问诊服务设置</div>*/}
                {/*            <div onClick={() => this.toSetserver()}>*/}
                {/*                <span>去设置</span>*/}
                {/*                <img src={icon5} alt=""/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className='ordergrabbing-body'>*/}
                {/*            {userlists1.length ? userlists1.map(item => (*/}
                {/*                <div className='ordergrabbing-one'>*/}
                {/*                    <p className='ordergrabbing-name'>*/}
                {/*                        <p className='name'>患者：{item.patientName}&nbsp;&nbsp;&nbsp;{item.patientSex == 1 ? '男' : '女'}&nbsp;&nbsp;&nbsp;{item.patient_age}岁</p>*/}
                {/*                        {item.consultation_type == '1' ? <p className='type'>图文问诊</p> :*/}
                {/*                            item.consultation_type == '3' ?*/}
                {/*                                <p className='type type2'>视频问诊</p> : null}*/}
                {/*                    </p>*/}
                {/*                    <p className='ordergrabbing-ill'>*/}
                {/*                        <p>既往史：<span*/}
                {/*                            className={item.past_history == 0 ? '' : 'blue'}>{item.past_history_note}</span>*/}
                {/*                        </p>*/}
                {/*                        <p>过敏史：<span*/}
                {/*                            className={item.allergic_history == 0 ? '' : 'blue'}>{item.allergic_history_note}</span>*/}
                {/*                        </p>*/}
                {/*                    </p>*/}
                {/*                    <p className='ordergrabbing-ill'>*/}
                {/*                        <p>肝功能：<span*/}
                {/*                            className={item.abnormal_liver_function == 0 ? '' : 'blue'}>{item.abnormal_liver_function == 0 ? '无异常' : '有异常'}</span>*/}
                {/*                        </p>*/}
                {/*                        <p>肾功能：<span*/}
                {/*                            className={item.abnormal_renal_function == 0 ? '' : 'blue'}>{item.abnormal_renal_function == 0 ? '无异常' : '有异常'}</span>*/}
                {/*                        </p>*/}
                {/*                    </p>*/}
                {/*                    <p className='ordergrabbing-ill2'>家族遗传史：<span*/}
                {/*                        className={item.family_genetic_history == 0 ? '' : 'blue'}>{item.family_genetic_history_note}</span>*/}
                {/*                    </p>*/}
                {/*                    <p className='line'></p>*/}
                {/*                    <p className='ordergrabbing-button'>*/}
                {/*                        <span>问诊时间：{item.createTime}</span>*/}
                {/*                        <button onClick={() => this.sureReceive1(item)}>确认接诊</button>*/}
                {/*                    </p>*/}
                {/*                </div>)) : docter ? <Nodata type='患者'/> : null}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                <div className="box-top110">
                  <div className="group_2">
                    <div className="box_2 ">
                      <div className="box_3 ">
                        <img
                          src={docterAvatar != null ? docterAvatar : icon4}
                          alt=""
                        />
                        <span className="text_2">
                          {docter.docter_phone
                            ? docter.docter_name
                              ? docter.docter_name
                              : docter.docter_phone
                            : "未登录"}
                        </span>
                        <span className="text_3">
                          {docter.docter_phone ? docter.docter_title : ""}
                        </span>
                      </div>
                      <div className="box_4">
                        <div className="group_3">
                          <div className="text-group_1 ">
                            <span className="text_4">{dayNum}</span>
                            <span className="text_5">今日接诊</span>
                          </div>
                          <div className="text-group_2 ">
                            <span className="text_6">{allNum}</span>
                            <span className="text_7">累计接诊</span>
                          </div>
                          <div className="text-group_3">
                            <div className="text-wrapper_2">
                              <span className="text_8">{countNum}</span>
                              <span className="text_9">/{countallNum}</span>
                            </div>
                            <span className="text_10">累计处方</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box_5">
                      <div className="image-text_1">
                        <img className="image_3" src={icon8} />
                        <span className="text-group_4">
                          养老专场&nbsp;视频问诊
                        </span>
                      </div>
                      <div className="text-wrapper_3">去接单</div>
                    </div>
                    <div className="box_6">
                      <div className="section_1">
                        <div className="block_1">
                          <span className="text_12">今日预约问诊</span>
                          <span className="text_13">去接诊</span>
                        </div>
                        <div className="text-wrapper_5">
                          <span className="text_14">
                            {moment().format("YYYY-MM-DD")}
                          </span>
                          <span className="text_15">共20位患者预约</span>
                        </div>
                      </div>
                      <div className="list-items_1-0 ">
                        <div className="text-wrapper_22-0">
                          <img className="label_2-0" src={icon10} />
                          <span className="text_16-0">上午预约</span>
                          <span className="text_17-0">9:00-13:00</span>
                        </div>
                        <div className="text-wrapper_6-0">12位</div>
                      </div>
                      <div className="list-items_1-0 list-items_1-1">
                        <div className="text-wrapper_22-0">
                          <img className="label_2-0" src={icon11} />
                          <span className="text_16-0">下午预约</span>
                          <span className="text_17-0">14:00-18:00</span>
                        </div>
                        <div className="text-wrapper_6-0">8位</div>
                      </div>
                    </div>
                    <div className="box_7">
                      <div className="group_4 ">
                        <span className="text_19">我的诊室</span>
                        <div
                          className="image-text_2"
                          onClick={() => this.allLists1()}
                        >
                          <i className="iconfont icon-shuaxin2" />
                          <span className="text-group_5">刷新</span>
                        </div>
                      </div>
                      <div className="text-wrapper_7 ">
                        <span
                          className={userlistcode1 == 1 ? "text_20" : "text_21"}
                          onClick={() => this.setState({ userlistcode1: 1 })}
                        >
                          <span className="text_tw20">
                            图文问诊({userlists1.length}人)
                          </span>
                          <span className="texy_line"></span>
                        </span>
                        <span
                          className={userlistcode1 == 2 ? "text_20" : "text_21"}
                          onClick={() => this.setState({ userlistcode1: 2 })}
                        >
                          <span className="text_tw20">
                            视频问诊({userlists2.length}人)
                          </span>
                          <span className="texy_line"></span>
                        </span>
                      </div>
                    </div>
                    <div className="ordergrabbing-body-outer1">
                      <div className="ordergrabbing-body">
                        {userlistcode1 == 1 ? (
                          <>
                            {userlists1.length ? (
                              userlists1.map((item) => (
                                <div className="ordergrabbing-one">
                                  <p className="ordergrabbing-name">
                                    <p className="name">
                                      患者：{item.patientName}&nbsp;&nbsp;&nbsp;
                                      {item.patientSex == 1 ? "男" : "女"}
                                      &nbsp;&nbsp;&nbsp;{item.patient_age}岁
                                    </p>
                                    {item.consultation_type == "1" ? (
                                      <p className="type">图文问诊</p>
                                    ) : item.consultation_type == "3" ? (
                                      <p className="type type2">视频问诊</p>
                                    ) : null}
                                  </p>
                                  <p className="ordergrabbing-ill">
                                    <p>
                                      疾病史：
                                      <span
                                        className={
                                          item.past_history == 0 ? "" : "blue"
                                        }
                                      >
                                        {item.past_history_note}
                                      </span>
                                    </p>
                                    <p>
                                      过敏史：
                                      <span
                                        className={
                                          item.allergic_history == 0
                                            ? ""
                                            : "blue"
                                        }
                                      >
                                        {item.allergic_history_note}
                                      </span>
                                    </p>
                                  </p>
                                  <p className="ordergrabbing-ill">
                                    <p>
                                      肝功能：
                                      <span
                                        className={
                                          item.abnormal_liver_function == 0
                                            ? ""
                                            : "blue"
                                        }
                                      >
                                        {item.abnormal_liver_function == 0
                                          ? "无异常"
                                          : "有异常"}
                                      </span>
                                    </p>
                                    <p>
                                      肾功能：
                                      <span
                                        className={
                                          item.abnormal_renal_function == 0
                                            ? ""
                                            : "blue"
                                        }
                                      >
                                        {item.abnormal_renal_function == 0
                                          ? "无异常"
                                          : "有异常"}
                                      </span>
                                    </p>
                                  </p>
                                  <p className="ordergrabbing-ill2">
                                    家族遗传史：
                                    <span
                                      className={
                                        item.family_genetic_history == 0
                                          ? ""
                                          : "blue"
                                      }
                                    >
                                      {item.family_genetic_history_note}
                                    </span>
                                  </p>
                                  <p className="line"></p>
                                  <p className="ordergrabbing-button">
                                    <span>
                                      已等待：
                                      <span
                                        style={{
                                          color: "#FF2E24",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {item.timeCha
                                          ? (item.timeCha / 60 >= 0 &&
                                            item.timeCha / 60 < 10
                                              ? "0" +
                                                Math.floor(item.timeCha / 60)
                                              : Math.floor(item.timeCha / 60)) +
                                            ":" +
                                            (item.timeCha % 60 >= 0 &&
                                            item.timeCha % 60 < 10
                                              ? "0" + (item.timeCha % 60)
                                              : (item.timeCha % 60) % 60)
                                          : 0}
                                      </span>
                                    </span>
                                    <button
                                      onClick={() => this.sureReceive1(item)}
                                    >
                                      确认接诊
                                    </button>
                                  </p>
                                </div>
                              ))
                            ) : docter ? (
                              <Nodata type="患者" />
                            ) : null}
                          </>
                        ) : (
                          <>
                            {userlists2.length ? (
                              userlists2.map((item) => (
                                <div className="ordergrabbing-one">
                                  <p className="ordergrabbing-name">
                                    <p className="name">
                                      患者：{item.patientName}&nbsp;&nbsp;&nbsp;
                                      {item.patientSex == 1 ? "男" : "女"}
                                      &nbsp;&nbsp;&nbsp;{item.patient_age}岁
                                    </p>
                                    {item.consultation_type == "1" ? (
                                      <p className="type">图文问诊</p>
                                    ) : item.consultation_type == "3" ? (
                                      <p className="type type2">视频问诊</p>
                                    ) : null}
                                  </p>
                                  <p className="ordergrabbing-ill">
                                    <p>
                                      疾病史：
                                      <span
                                        className={
                                          item.past_history == 0 ? "" : "blue"
                                        }
                                      >
                                        {item.past_history_note}
                                      </span>
                                    </p>
                                    <p>
                                      过敏史：
                                      <span
                                        className={
                                          item.allergic_history == 0
                                            ? ""
                                            : "blue"
                                        }
                                      >
                                        {item.allergic_history_note}
                                      </span>
                                    </p>
                                  </p>
                                  <p className="ordergrabbing-ill">
                                    <p>
                                      肝功能：
                                      <span
                                        className={
                                          item.abnormal_liver_function == 0
                                            ? ""
                                            : "blue"
                                        }
                                      >
                                        {item.abnormal_liver_function == 0
                                          ? "无异常"
                                          : "有异常"}
                                      </span>
                                    </p>
                                    <p>
                                      肾功能：
                                      <span
                                        className={
                                          item.abnormal_renal_function == 0
                                            ? ""
                                            : "blue"
                                        }
                                      >
                                        {item.abnormal_renal_function == 0
                                          ? "无异常"
                                          : "有异常"}
                                      </span>
                                    </p>
                                  </p>
                                  <p className="ordergrabbing-ill2">
                                    家族遗传史：
                                    <span
                                      className={
                                        item.family_genetic_history == 0
                                          ? ""
                                          : "blue"
                                      }
                                    >
                                      {item.family_genetic_history_note}
                                    </span>
                                  </p>
                                  {item.consultation_detail ? (
                                    <p className="ordergrabbing-ill3">
                                      病情描述：
                                      <span className={item == 0 ? "" : "blue"}>
                                        {item.consultation_detail}
                                      </span>
                                    </p>
                                  ) : null}
                                  <p className="line"></p>
                                  <p className="ordergrabbing-button">
                                    <span>
                                      已等待：
                                      <span
                                        style={{
                                          color: "#FF2E24",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {item.timeCha
                                          ? (item.timeCha / 60 >= 0 &&
                                            item.timeCha / 60 < 10
                                              ? "0" +
                                                Math.floor(item.timeCha / 60)
                                              : Math.floor(item.timeCha / 60)) +
                                            ":" +
                                            (item.timeCha % 60 >= 0 &&
                                            item.timeCha % 60 < 10
                                              ? "0" + (item.timeCha % 60)
                                              : (item.timeCha % 60) % 60)
                                          : 0}
                                      </span>
                                    </span>
                                    <button
                                      onClick={() => this.sureReceive1(item)}
                                    >
                                      确认接诊
                                    </button>
                                  </p>
                                </div>
                              ))
                            ) : docter ? (
                              <Nodata type="患者" />
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </p>
            )}

            <FootNav />
          </>
        ) : (
          <div className="homebox2">
            <p className="homebox2-top">
              <h1>入驻流程</h1>
              <p>按以下流程入驻成功后即可接诊</p>
              <p>您提交的信息和资料，将用于身份、医疗资质等核验。</p>
            </p>
            <div className="box-content">
              <Steps
                direction="vertical"
                current={
                  docter.docter_examine == "-2"
                    ? 0
                    : (docter.docter_examine == "1" ||
                        docter.docter_examine == "0") &&
                      (docter.ca_ispass == 2 || !docter.docter_autograph)
                    ? 1
                    : 2
                }
                style={{
                  "--title-font-size": "15px",
                  "--description-font-size": "15px",
                  "--indicator-margin-right": "12px",
                  "--icon-size": "22px",
                }}
              >
                <Step
                  title="1、填写基本信息"
                  // description='这里是一些描述'ClockCircleFill<CloseCircleFill color='#ff0000'/>
                  icon={
                    docter.docter_examine == "0" ||
                    docter.docter_examine == "-1" ? (
                      <i
                        style={{ color: "#346EF4", fontSize: "24px" }}
                        className="iconfont icon-bianjiwenjian"
                      />
                    ) : docter.docter_examine == "-2" ? (
                      <i
                        style={{ color: "#346EF4", fontSize: "24px" }}
                        className="iconfont icon-bianjiwenjian"
                      />
                    ) : (
                      <i
                        style={{ color: "#ACACAC", fontSize: "24px" }}
                        className="iconfont icon-bianjiwenjian"
                      />
                    )
                  }
                  description={
                    <Space block direction="vertical">
                      <div>
                        {" "}
                        {docter.docter_examine == "-2"
                          ? "您需要补充资料才能进一步完成"
                          : ""}
                        {docter.docter_examine == "-2" ? (
                          <span
                            onClick={() => this.toLoadagain()}
                            style={{
                              backgroundColor: "#336FF6",
                              color: "#fff",
                              padding: "3px 8px",
                              borderRadius: "10px",
                              float: "right",
                              marginTop: "-5px",
                            }}
                          >
                            填写信息
                          </span>
                        ) : docter.docter_examine == "0" &&
                          (docter.ca_ispass == 2 ||
                            !docter.docter_autograph) ? (
                          <span
                            onClick={() => this.toLoadagain()}
                            style={{
                              backgroundColor: "#336FF6",
                              color: "#fff",
                              padding: "3px 8px",
                              borderRadius: "10px",
                              float: "right",
                              marginTop: "-5px",
                            }}
                          >
                            修改信息
                          </span>
                        ) : null}
                      </div>
                    </Space>
                  }
                />
                <Step
                  title="2、人脸实名认证和签名"
                  description={
                    <Space block direction="vertical">
                      <div>
                        {" "}
                        {docter.ca_ispass == 1 && docter.ca_id != 2401
                          ? "人脸认证已通过"
                          : docter.ca_id || docter.ca_id == 2401
                          ? "需要完善个人资料信息"
                          : "等待管理员确认"}
                        {((docter.docter_examine == "1" ||
                          docter.docter_examine == "0") &&
                          (docter.ca_ispass == 2 ||
                            !docter.docter_autograph)) ||
                        docter.ca_id == 2401 ? (
                          <span
                            onClick={() => this.toLoadCA()}
                            style={{
                              backgroundColor: docter.ca_id
                                ? "#336FF6"
                                : "#999",
                              color: "#fff",
                              padding: "3px 8px",
                              borderRadius: "10px",
                              float: "right",
                              marginTop: "-5px",
                            }}
                          >
                            去认证
                          </span>
                        ) : null}
                      </div>
                    </Space>
                  }
                  icon={
                    docter.docter_examine != "-2" ? (
                      <i
                        style={{ color: "#346EF4", fontSize: "28px" }}
                        className="iconfont icon-renlianshibie1"
                      />
                    ) : (
                      <i
                        style={{ color: "#ACACAC", fontSize: "28px" }}
                        className="iconfont icon-renlianshibie1"
                      />
                    )
                  }
                />
                <Step
                  title="3、入驻平台审核"
                  description={
                    <Space block direction="vertical">
                      <div>
                        {docter.docter_examine == "0"
                          ? "需要等待工作人员审核"
                          : // docter.docter_examine == "-1" ? "资料审核失败" :
                          docter.docter_examine == "1"
                          ? "审核通过"
                          : null}{" "}
                        {docter.docter_examine == "0" ? (
                          <span
                            style={{
                              color: "#FFA626",
                              position: "absolute",
                              background: "#EFEFEF",
                              padding: "2px 5px 0px 5px",
                              borderRadius: "10px",
                              marginTop: "-24px",
                              marginLeft: "0px",
                            }}
                          >
                            资料审核中
                          </span>
                        ) : docter.docter_examine == "-1" ? (
                          <span
                            style={{
                              color: "#FF5500",
                              display: "inline-block",
                              width: "200px",
                            }}
                          >
                            {list.docter_reason}
                          </span>
                        ) : null}
                        {docter.docter_examine == "-1" ? (
                          <span
                            style={{
                              color: "#FF5500",
                              position: "absolute",
                              padding: "2px 5px 0px 5px",
                              borderRadius: "10px",
                              marginTop: "-24px",
                              marginLeft: "-80px",
                            }}
                          >
                            资料审核失败
                          </span>
                        ) : null}
                        {docter.docter_examine == "-1" ? (
                          <span
                            onClick={() => this.toLoadagain()}
                            style={{
                              backgroundColor: "#336FF6",
                              color: "#fff",
                              padding: "3px 8px",
                              borderRadius: "10px",
                              float: "right",
                              marginTop: "-5px",
                            }}
                          >
                            重新上传
                          </span>
                        ) : null}
                      </div>
                    </Space>
                  }
                  icon={
                    docter.docter_examine == "1" ? (
                      <i
                        style={{ color: "#346EF4", fontSize: "22px" }}
                        className="iconfont icon-jihuashenhe"
                      />
                    ) : docter.docter_examine == "0" &&
                      docter.ca_ispass == 1 ? (
                      <i
                        style={{ color: "#346EF4", fontSize: "22px" }}
                        className="iconfont icon-jihuashenhe"
                      />
                    ) : docter.docter_examine == "-2" ||
                      docter.docter_examine == "-1" ? (
                      <i
                        style={{ color: "#ACACAC", fontSize: "22px" }}
                        className="iconfont icon-jihuashenhe"
                      />
                    ) : (
                      <i
                        style={{ color: "#ACACAC", fontSize: "22px" }}
                        className="iconfont icon-jihuashenhe"
                      />
                    )
                  }
                />
                <Step
                  title="4、入驻成功"
                  description={
                    <Space block direction="vertical">
                      <div>可以开通服务</div>
                    </Space>
                  }
                  icon={
                    status == "1" ? (
                      <i
                        style={{
                          color: "#346EF4",
                          fontSize: "24px",
                          backgroundColor: "none",
                        }}
                        className="iconfont icon-yishengyishengjieshao"
                      />
                    ) : (
                      <i
                        style={{
                          color: "#ACACAC",
                          fontSize: "24px",
                          backgroundColor: "transparent",
                        }}
                        className="iconfont icon-yishengyishengjieshao"
                      />
                    )
                  }
                />
              </Steps>
            </div>
            <FootNav />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Platform);
