import React, { Component } from 'react'
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/rx1.png"
import icon3 from "../../../static/img/yy/rx-add.png"
import { Badge, SideBar } from 'antd-mobile-v5'
import {Modal, Toast} from 'antd-mobile'
import { getdepartmemntlist } from "../../../api/hospitalapi/department"
import {addnews, allIcd, getIcdCode, saveIcd,allEprescribings} from "../../../api/hospitalapi/news"
import "./style.less"
import Nodata from "../../../component/Nodata";
import icon5 from "../../../static/img/sure1.png";
import icon7 from "../../../static/img/sure2.png";
const alert = Modal.alert
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            docterId: localStorage.getItem("docterId"),
            list:[],
            item: JSON.parse(localStorage.getItem("item")),
            visible4: false,
            visible5: false,
            icdList: [],
            icdList1: [],
            icdone: '',
            consultationId:'2',
            icdlists: [],
        }
    }
    componentDidMount = async () => {
        const {item} = this.state

        if (item&&item.electronic_icd){
            this.setState({
                icdlists:JSON.parse(item.electronic_icd)
            })
        }
        if (item){
            this.setState({
                consultationId: item.consultation_id
            })
            let res =await allEprescribings({consultationId: item.consultation_id})
            if (res&&res.data&&res.data[0]){
                this.setState({
                    list:res.data[0]
                })
            }
        }else{
            this.props.history.push("/login")
        }
    }
    // 开电子病历
    prescribingBl = async () => {
        const {page, size, icdone, icdList, icdlists} = this.state
        this.setState({
            visible4: true,
            icdlists1:icdlists
        })
    }
    //输入病历，模糊查询
    changeuserinfo = (e) => {
        this.setState({
            icdone: e.target.value
        })
    }
    //搜索病历
    onSearch = async () => {
        const {page, size, icdone, icdList, icdlists} = this.state
        const params = {
            content: icdone,
            page: 1,
            size: size,
        }
        let res = await getIcdCode(params)
        if (res && res.data && res.data[0]) {
            res.data[0].forEach(i => {
                icdlists.forEach(j => {
                    if (i.id == j.id) {
                        i.state = 1
                    }
                })
            })
            this.setState({
                icdList: res.data[0],
                page: 1,
                visible5: true
            })

        }
    }
    //分页搜索病历
    pageMedlist = async () => {
        const {page, size, icdList, icdone} = this.state
        const params = {
            content: icdone,
            page: page + 1,
            size: size,
        }
        let res = await getIcdCode(params)
        if (res && res.data && res.data[0]) {
            this.setState({
                icdList: [...icdList, ...res.data[0]],
                page: page + 1
            })
        }
    }
    onscroll = e => {
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10) {
            this.pageMedlist()
        }
    }
    //选择或取消某个病历
    setIcd = (e, item) => {
        const {page, size, icdList, icdone, icdlists} = this.state
        e.stopPropagation()
        let arr = []
        let arr1 = []
        let arr2 = []
        if (item.state) {
            item.state = 0
            icdlists.map(j => {
                if (j.id != item.id) {
                    arr2.push(j)
                }
            })
        } else {
            item.state = 1
            arr2 = icdlists
        }
        this.setState({
            icdList: [...icdList],
        }, () => {
            icdList.map(i => {
                if (i.state) {
                    arr.push(i)
                }
            })
            arr2.map(j => {
                if (j.state) {
                    arr1.push(j)
                }
            })
            let nArr = [...arr1, ...arr].filter((currentValue, currentIndex, selfArr) => {
                return selfArr.findIndex(x => x.id === currentValue.id) === currentIndex
            });
            this.setState({
                icdlists: nArr
            })
        })
    }
    //确认病历
    onSure = async () => {
        const {icdlists, consultationId, detailContent} = this.state
        let arr = []
        if (icdlists.length) {
            icdlists.map(item => {
                arr.push(item.icdName)
            })
            let a = arr.join(',')
            alert('确认选择病历', a, [
                {
                    text: '取消', onPress: () => {
                        console.log('取消')
                    }
                },
                {
                    text: '确认', onPress: () => {
                        this.saveIcd()
                        this.setState({
                            visible4: false,
                            visible5: false,
                        })
                    }
                },
            ])
        } else {
            Toast.show('请先选择患者病历')
        }
    }
    //上传保存电子病历
    saveIcd = async () => {
        const {icdlists, consultationId, detailContent} = this.state
        let res0 = await allIcd(consultationId)
        if (res0 && res0.data && res0.data[0] && res0.data[0].length) {
            let res = await saveIcd({consultationId, electronicIcd: JSON.stringify(icdlists), type: 2})
        } else {
            let res = await saveIcd({consultationId, electronicIcd: JSON.stringify(icdlists), type: 1})
        }
    }
    onSure1 = async (e) => {
        e.stopPropagation()
        Toast.show('请先选择患者病历')
    }
    //开方
    toPrescribing=()=>{
        alert('确认开处方', '处方提交后不能修改病历诊断', [
            {
                text: '取消', onPress: () => {
                    console.log('取消')
                }
            },
            {
                text: '确认', onPress: () => {
                    this.props.history.push({
                        pathname: "/prescribing",
                    })
                }
            },
        ])
    }
    toinfo=(prescribingId)=>{
        const {item} = this.state
        this.props.history.push({
            pathname: `/doctorinfo/chat/chatinfo`,
            params: {
                detailContent: prescribingId,
                consultationDetail: item.consultation_detail,  //病情描述
                patientName: item.patientName,  //患者问诊信息
                patientSex: item.patientSex,
                patientBirthday: item.patient_birthday,
                videodiagnostics:1
            }
        })
    }
    render() {
        const { list, visible4,visible5,icdList,icdlists,icdlists1 } = this.state
        return (
            <div className='typebox' style={{backgroundColor:'#fff',height:'100vh'}}>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/videoprescription")} /><h1>新建处方</h1>
                </div>
                <div className='diagnostics-details'>
                    <p className='diagnostics-end'>诊断结果</p>
                    <p className='diagnostics-select' onClick={this.prescribingBl}>已选择{icdlists.length}种诊断疾病</p>
                </div>
                <div className='diagnostics-add'>
                    <p className='diagnostics-add-title'>添加处方</p>
                    <p className='diagnostics-add-details'>
                        {list.length?list.map((item,index)=>(<p key={index} className='diagnostics-one' onClick={()=>this.toinfo(item.prescribing_id)}>
                            <img src={icon2}/>
                            <p>处方{index+1}</p>
                        </p>)):null}
                        <p className='diagnostics-one' onClick={()=>this.toPrescribing()}>
                            <img src={icon3}/>
                            <p>添加</p>
                        </p>
                    </p>
                </div>
                {visible4 ? <div className='add-icd' onScroll={this.onscroll} onClick={(e) => {
                    e.stopPropagation()
                    this.setState({
                        visible5: false
                    })
                }}>
                    <div className='icd-top' onClick={(e) => {
                        e.stopPropagation()
                    }}>
                        <img src={icon1} onClick={() => {
                            this.setState({
                                visible5: false,
                                visible4: false,
                                icdlists: icdlists1
                            })
                        }}/>
                        <span>电子病历单</span>
                        <span></span>
                    </div>
                    <div className='register-head' onClick={(e) => {
                        e.stopPropagation()
                    }}>
                        <i className='iconfont icon-suosou'></i>
                        <input type="text" placeholder='搜索icd编码、icd名称' onChange={(e) => this.changeuserinfo(e, 4)}/>
                        <span onClick={() => this.onSearch()}>搜索</span>
                    </div>
                    {visible5 ? <ul className='icd-details'>
                        {icdList.length ? icdList.map(item => (
                            // <p className={item.state ? 'icd-details-one1' : 'icd-details-one0'}
                            //    style={{height: '26px', lineHeight: '26px'}} onClick={(e) => this.setIcd(e, item)}>
                            //   <span style={{marginRight: '20px'}}>{item.icdValue}</span>
                            //   <span>{item.icdName}</span>
                            // </p>
                            <li className='icd-details-one' onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                <span>{item.icdValue} &nbsp; {item.icdName}</span><input onClick={(e) => this.setIcd(e, item)}
                                                                                         type='checkbox' checked={item.state}
                                                                                         name='icd'/><br/>
                            </li>
                        )) : <Nodata type='病历'/>}
                    </ul> : null}
                    <div className='select-all'>
                        {icdlists.length ? icdlists.map(i => (
                            <p>
                                <span>{i.icdName}</span><span onClick={(e) => this.setIcd(e, i)}>删除</span>
                            </p>)) : <Nodata type='病历'/>}
                    </div>
                    <div className='icd-button'>
                        {icdlists.length ? <img src={icon5} onClick={() => this.onSure()}/> :
                            <img src={icon7} onClick={(e) => this.onSure1(e)}/>}
                    </div>
                </div> : null}
            </div>
        )
    }
}
