import React, { Component } from 'react'
import { Link } from "react-router-dom";
import "./alert.less";
import tiptop from '../../static/img/tiptop.png'
import {jumpUrl} from '../../publicurl'
import localStorage from 'redux-persist/es/storage'
import getUrl from '../../component/GetUrlParam'
import md5 from 'js-md5';

export default class TipAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isAlertShown: true
        };
      }   

    handleKnowBtnClick = () => {
        this.setState({ isAlertShown: false });
        // window.location.reload();
        window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
    }
    forgetment=()=>{
      // debugger
      // const { number, code,loginwaycode,password } = this.state
      const number = this.props.number;
      const password = this.props.password;
      const loginwaycode = this.props.loginwaycode;
      this.setState({ isAlertShown: false });
      this.props.history.replace(`/login?number=${number}&password=${password}&loginwaycode=${loginwaycode}`)
      this.props.history.push(`/login/retrieve?number=${number}&loginwaycode=${loginwaycode}`)
    }


  render() {
    const {isAlertShown} = this.state;
    return (
      <div>
        {isAlertShown && (
            <div className='tipalertbox'>
            <div className='alertbox'>
                <div className='top-box'>提示</div>
                <p className='tipmsg'>根据系统检测您已90天未修改密码了， 为了您的账号安全请及时修改密码！</p>
                <p onClick={()=> {this.forgetment();}} className='gourl'>去修改密码</p>
                <div className='knowbtn' onClick={this.handleKnowBtnClick}>我已知晓</div>
            </div>
        </div>
        )}
      </div>
    )
  }
}

