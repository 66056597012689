import React, { Component } from 'react'
import {NavLink} from "react-router-dom"
import '../../static/css/iconfont.css'
import icon1 from "../../static/img/icon/user.png";
import './style.less'
import chinesestyle from "./chinesestyle.less";
import icon2 from "../../static/img/icon/down.png";
import icon4 from "../../static/img/icon/jian.png";
import icon3 from "../../static/img/icon/add.png";
export default function Offsomegoods(props) {
    const { 
            somegoods,
            visible6,
            index1,
            showall,
            showUseways,
            showV4,
            getvalue,
            changenum2,
            showV1,
            showV01,
            showV2,
            ischinese,
            somegoodssystem
        } = props
        // console.log(somegoodssystem,'somegoodssystem----24',somegoods);
        // console.log(somegoods,'somegoods----25');
    const [showAll, setShowAll] = React.useState(false);

    if(ischinese.length == 0 && somegoodssystem){
        for (let j = 0; j < somegoodssystem.length; j++) {

            for(let i=0;i<somegoods.length;i++){
                if(somegoods[i].medFrequencyLarge){
                    let frequency=somegoods[i].medicinalFrequency.slice(0,2) //几日
                    let frequency2=somegoods[i].medicinalFrequency.slice(2) //几次


                    // console.log(somegoods[i].medFrequencyLarge);
                    let frequencyLargeDay=somegoods[i].medFrequencyLarge.slice(0,2) //几日(最大)
                    let frequencyLarge=somegoods[i].medFrequencyLarge.slice(2) //几次

                    let day=0
                    if (frequency=='一日'||frequency=='一天') {
                        day=1
                    }else if (frequency=='二日'||frequency=='二天') {
                        day=2
                    }else if (frequency=='三日'||frequency=='三天') {
                        day=3
                    }else if (frequency=='四日'||frequency=='四天') {
                        day=4
                    }else if (frequency=='五日'||frequency=='五天') {
                        day=5
                    }else if (frequency=='六日'||frequency=='六天') {
                        day=6
                    }else if (frequency=='七日'||frequency=='七天'||frequency=='一周'||frequency=='每周') {
                        day=7
                    }else if (frequency=='八日'||frequency=='八天') {
                        day=8
                    }else if (frequency=='九日'||frequency=='九天') {
                        day=9
                    }else if (frequency=='十日'||frequency=='十天') {
                        day=10
                    }

                    let dayLarge=0
                    if (frequencyLargeDay=='一日'||frequencyLargeDay=='一天') {
                        dayLarge=1
                    }else if (frequencyLargeDay=='二日'||frequencyLargeDay=='二天') {
                        dayLarge=2
                    }else if (frequencyLargeDay=='三日'||frequencyLargeDay=='三天') {
                        dayLarge=3
                    }else if (frequencyLargeDay=='四日'||frequencyLargeDay=='四天') {
                        dayLarge=4
                    }else if (frequencyLargeDay=='五日'||frequencyLargeDay=='五天') {
                        dayLarge=5
                    }else if (frequencyLargeDay=='六日'||frequencyLargeDay=='六天') {
                        dayLarge=6
                    }else if (frequencyLargeDay=='七日'||frequencyLargeDay=='七天'||frequencyLargeDay=='一周'||frequencyLargeDay=='每周') {
                        dayLarge=7
                    }else if (frequencyLargeDay=='八日'||frequencyLargeDay=='八天') {
                        dayLarge=8
                    }else if (frequencyLargeDay=='九日'||frequencyLargeDay=='九天') {
                        dayLarge=9
                    }else if (frequencyLargeDay=='十日'||frequencyLargeDay=='十天') {
                        dayLarge=10
                    }
        
                    // 最小量
                    let medusage = parseFloat(somegoods[i].medicinalUsage)
                    let medFrequency=parseFloat(frequency2)
                    let smallAllcount=0
        
                    if(day && medFrequency && medusage && somegoods[i].medAllCount){
                        // 用药天数计算：[总量X盒数(用户购买)] / [几次 / 几日 X 每次几片]
                        smallAllcount = Math.ceil((somegoods[i].medAllCount * somegoods[i].medicinalQuantity) / (  medFrequency / day * medusage))
                    }
        
                    // 最大量
                    let medLargeUsage = parseFloat(somegoods[i].medLargeUsage)
                    let medFrequencyLarge=parseFloat(frequencyLarge)
                    let largeAllcount=0
                    if(day && medFrequencyLarge && medLargeUsage && somegoods[i].medAllCount){
                        // 用药天数计算：[总量X盒数(用户购买)] / [几次 / 几日 X 每次几片]
                        largeAllcount = Math.ceil((somegoods[i].medAllCount * somegoods[i].medicinalQuantity) / (  medFrequencyLarge / dayLarge * medLargeUsage))
                    }

                    somegoods[i].smallAllcount=smallAllcount
                    somegoods[i].largeAllcount=largeAllcount
                    
                    // 最多购买多少盒
                    // 最大购买数量计算：每日每次*每次最大几片*最大用药天数/数量
                    let frequencyLargeCount=parseFloat(frequencyLarge)
                    let largeQuantityCount =Math.ceil((frequencyLargeCount * medLargeUsage * somegoods[i].medLargeDay) / somegoods[i].medAllCount)
                    somegoods[i].largeQuantityCount=largeQuantityCount
        
                    // 天数对应的盒数：多少天只能购买多少盒
                    // 计算签署对应盒数：[用药天数*（几次/几日*每次几片）] / 总量
                    let dayQuantity = Math.ceil((smallAllcount * ( medFrequency/day * medusage)) /  somegoods[i].medAllCount)
                    somegoods[i].dayQuantity=dayQuantity

                    // 计算用药天数对应的盒数useDay
                    let useDayCount = Math.ceil((somegoods[i].useDay * ( medFrequency/day * medusage)) /  somegoods[i].medAllCount)
                    somegoods[i].useDayCount=useDayCount


                    // 计算医生修改频率、单次用量之后的用药天数  计算是否和初始推荐不同
                    // console.log('修改：','几天',day,'几次',medFrequency,'单次用量',medusage,'药品总量',somegoods[i].medAllCount);
                    let frequencyinit=somegoodssystem[j].medicinalFrequency.slice(0,2) //初始频率： 几日
                    let frequencyinit2=parseFloat(somegoodssystem[j].medicinalFrequency.slice(2)) //初始频率： 几次  
                    let medusageinit = parseFloat(somegoodssystem[j].medicinalUsage) //单次用量
                    let dayinit=0
                    if (frequencyinit=='一日'||frequencyinit=='一天') {
                        dayinit=1
                    }else if (frequencyinit=='二日'||frequencyinit=='二天') {
                        dayinit=2
                    }else if (frequencyinit=='三日'||frequencyinit=='三天') {
                        dayinit=3
                    }else if (frequencyinit=='四日'||frequencyinit=='四天') {
                        dayinit=4
                    }else if (frequencyinit=='五日'||frequencyinit=='五天') {
                        dayinit=5
                    }else if (frequencyinit=='六日'||frequencyinit=='六天') {
                        dayinit=6
                    }else if (frequencyinit=='七日'||frequencyinit=='七天'||frequencyinit=='一周'||frequencyinit=='每周') {
                        dayinit=7
                    }else if (frequencyinit=='八日'||frequencyinit=='八天') {
                        dayinit=8
                    }else if (frequencyinit=='九日'||frequencyinit=='九天') {
                        dayinit=9
                    }else if (frequencyinit=='十日'||frequencyinit=='十天') {
                        dayinit=10
                    }

                    // console.log('初始：','几天：',dayinit,'几次：',frequencyinit2,'单次用量：',medusageinit,'药品总量：',somegoods[i].medAllCount);
                    // console.log((day != dayinit) || (medFrequency != frequencyinit2) || (medusage != medusageinit),'11111111111111');
// console.log("day:",day,"dayinit:",dayinit,"medFrequency:",medFrequency,"frequencyinit2:",frequencyinit2,'medusage:',medusage,'medusageinit:',medusageinit);
// console.log(somegoods[i].medicinalQuantity == 1 && ((somegoods[i].medLargeDay < somegoods[i].smallAllcount)),'----------');

                    
                    if(somegoods[i].medicinalUsage == '适量'){
                        somegoods[i].useDay= '7'
                    }else if(somegoods[i].medicinalQuantity != 1 && ((day != dayinit) || (medFrequency != frequencyinit2) || (medusage != medusageinit))){
                        somegoods[i].useDay=smallAllcount
                    }else if(somegoods[i].medicinalDay == '84'){
                        somegoods[i].useDay = '84'
                    }
                    // else if((somegoods[i].medLargeDay < somegoods[i].smallAllcount) && (somegoods[i].medLargeDay == somegoods[i].useDay)){
                    //     somegoods[i].useDay=somegoods[i].medLargeDay
                    // }
                    else if(somegoods[i].medicinalQuantity == 1 && ((somegoods[i].medLargeDay < somegoods[i].smallAllcount))){
                        somegoods[i].useDay=somegoods[i].medLargeDay
                    }else{ 
                        somegoods[i].useDay=somegoods[i].smallAllcount
                    }
                    
                }
            }

        }

    }
// console.log(somegoods,'======');

    return(
        <div>
            <div className='chinese-lists'>
                {ischinese.length ? (
                    // 中药材清单
                    <>
                        <div className="all-lists">
                            {somegoods.length ? (somegoods.slice(0, showAll ? somegoods.length : 6).map((item, index) => (
                                <p className='cha-med-list-p'>
                                    <span className='cha-med-list-name'>{item.medComName ? item.medComName : item.medicinalName}</span>
                                    <span className='cha-med-input'>
                                        <input maxLength={4} className='cha-med-keshu1' defaultValue={item.medicinalQuantity + `g`}
                                                onChange={(e) => getvalue(e, index, 'chineseQuantity')}/>
                                    </span>                                                                                                       
                                </p>
                            ))) : null}
                        </div> 
                        {somegoods.length > 6 ? ( showAll ? (
                            <p className="zhankaiall" onClick={() => { setShowAll(false)} }>收起》</p>
                            ) : (
                            <p className="zhankaiall" onClick={() => { setShowAll(true)} }>展开全部》</p>
                            )
                        ) : null}
                    </>
                ) : (
                    // 西药清单
                    <>
                        {somegoods && somegoods.map((item,index)=> (
                            <div className='offaddcf-ill-meds'>
                            <p className='offaddcf-ill-meds-title'>
                                <p className='offaddcf-ill-meds-name'>
                                    <span className='name'>
                                        <span className='classi-tip'>（{item.supervisionType == 1 ? '中药' : item.supervisionType == 2 ? '西药' : item.supervisionType == 3 ? '成药' : '西药'}）</span>
                                        {item.medComName ? item.medComName : item.medicinalName}</span>
                                    <span className='spec'>{item.medicinalSpec}</span>
                                </p>
                                <p className='offaddcf-ill-meds-count'>
                                    X{item.medicinalQuantity}
                                    {/* <span className={(item.useDayCount > item.medicinalQuantity) ? 'color2-count' : 'color1-count' }>
                                        { ' [' + (item.useDayCount ? item.useDayCount : '') + item.packingUnit + ']'}
                                    </span> */}
                                </p>
                            </p>
                            <p className='line'></p>
                            {item.canchange == 1 ? <>
                                {/* 维护了 */}
                                {visible6&&index==index1?<div className={showall==1?'use-ways-outer1':'use-ways-outer'}>
                                    <p className='use-ways-name'>{somegoods.medComName ? item.medComName : item.medicinalName}</p>
                                    <p className='use-ways-body'>{item.medicinalMethod}，每次{item.medicinalUsage ? item.medicinalUsage : ''}，{item.medicinalFrequency ? item.medicinalFrequency : ''}</p>
                                </div>:null}
                                <p className='offaddcf-ill-meds-way'>
                                    <p className='offaddcf-ill-meds-way-left'>
                                        <span onClick={()=>showUseways(index)}>用药方法：<i className='iconfont icon-22222_huaban1'/></span>
                                        <p className='offaddcf-ill-meds-way'>
                                            <span className='offaddcf-ill-meds-way-one'
                                                onClick={() => showV4(item.medId)}>
                                                <span>{item.medicinalMethod}</span>
                                                <img src={icon2}/>
                                            </span>
                                        </p>
                                    </p>
                                    <p className='offaddcf-ill-meds-way-right'>
                                        <p className='ill-one'>单次用量：(例：1ml)</p>
                                        <p className='offaddcf-ill-meds-spec offaddcf-ill-meds-day-bottomoo'>
                                            <input defaultValue={item.medicinalUsage ? item.medicinalUsage : ''}
                                                onChange={(e) => getvalue(e, index, 'quantity')}/>
                                        </p>
                                    </p>
                                </p>
                                <p className='offaddcf-ill-meds-day'>
                                    <p className='offaddcf-ill-meds-day-left'>
                                        <span>
                                            用药天数：
                                        </span>
                                        <p className='offaddcf-ill-meds-day-count'>
                                            {/* <img src={icon4} onClick={() => changenum2("dayreduce", index)}/> */}
                                            <span className={ ((item.useDay > parseInt(item.medLargeDay)) && item.medicinalQuantity !=1) ? 'day-color':'day-color-no'}>{item.useDay}天</span>
                                            {/* <img src={icon3} onClick={() => changenum2("dayadd", index)}/> */}
                                        </p>
                                    </p>
                                    <p className='offaddcf-ill-meds-day-right'>
                                        <p className='pl'>频率：(例：一日3次)</p>
                                        <p className='offaddcf-ill-meds-day-bottom offaddcf-ill-meds-day-bottomoo'>
                                            <input defaultValue={item.medicinalFrequency ? item.medicinalFrequency : ''}
                                                onChange={(e) => getvalue(e, index, 'medfrequency')}/>

                                        </p>
                                    </p>
                                </p>
                            </> : <>
                                {/* 未维护 */}
                                {visible6&&index==index1?<div className={showall==1?'use-ways-outer1':'use-ways-outer'}>
                                    <p className='use-ways-name'>{item.medComName ? item.medComName : item.medicinalName}</p>
                                    <p className='use-ways-body'>{item.useWay}，每次{item.useQuantity}，{item.usePl}{item.useCount}</p>
                                </div>:null}
                                <p className='offaddcf-ill-meds-way'>
                                    <p className='offaddcf-ill-meds-way-left'>
                                        <span>用药方法：<i className='iconfont icon-22222_huaban1'/></span>
                                        <p className='offaddcf-ill-meds-way'>
                                            <span className='offaddcf-ill-meds-way-one'
                                                onClick={() => showV4(item.medId)}>
                                                <span>{item.useWay}</span>
                                                <img src={icon2}/>
                                            </span>
                                        </p>
                                    </p>
                                    <p className='offaddcf-ill-meds-way-right'>
                                        <p className='ill-one'>单次用量：</p>
                                        <p className='offaddcf-ill-meds-spec'>
                                            <img src={icon4} onClick={() => changenum2("quantityreduce", index)}/>
                                            <input value={item.useQuantity}
                                                onChange={(e) => getvalue(e, index, 'quantity')}/>
                                            <img src={icon3} onClick={() => changenum2("quantityadd", index)}/>
                                            <span className='offaddcf-ill-spec'
                                                onClick={() =>showV1(item.medId)}>
                                                <span>{item.useSpec}</span>
                                                <img src={icon2}/>
                                            </span>
                                        </p>
                                    </p>
                                </p>
                                <p className='offaddcf-ill-meds-day'>
                                    <p className='offaddcf-ill-meds-day-left'>
                                        <span>用药天数：</span>
                                        <p className='offaddcf-ill-meds-day-count'>
                                            <img src={icon4} onClick={() => changenum2("dayreduce", index)}/>
                                            <span>{item.useDay}日</span>
                                            <img src={icon3} onClick={() => changenum2("dayadd", index)}/>
                                        </p>
                                    </p>
                                    <p className='offaddcf-ill-meds-day-right'>
                                        <p className='pl'>频率：</p>
                                        <p className='offaddcf-ill-meds-day-bottom'>
                                            <span className='offaddcf-ill-meds-day-one'
                                                onClick={() => showV01(item.medId)}>
                                                <span>{item.usePl}</span>
                                                <img src={icon2}/>
                                            </span>
                                            <span className='offaddcf-ill-meds-day-one'
                                                onClick={() =>showV2(item.medId)}>
                                                <span>{item.useCount}</span>
                                                <img src={icon2}/>
                                            </span>
                                        </p>
                                    </p>
                                </p>
                            </>}

                            <p className='day-count'>
                                {/* 用药天数计算：[总量X盒数(用户购买)] / [几次 / 几日 X 每次几片] */}
                                <span className={ ((item.useDay > item.medLargeDay) && item.medicinalQuantity != 1) ? 'left-day-count-large' : 'left-day-count-nor'}>
                                    用药天数/数量：{item.smallAllcount ? item.smallAllcount : '--'}{'天'} / {item.dayQuantity ? item.dayQuantity : '--'}{item.packingUnit}
                                </span>
                                <span className='right-day-count'>
                                    最大用药数：{item.medLargeDay ? item.medLargeDay : '--'}{'天'} / {item.largeQuantityCount ? item.largeQuantityCount : "--"}{item.packingUnit}
                                </span>
                            </p>
                            <p className='shuoming'>备注说明：</p>
                            <p className='shuoming-all'><input placeholder='输入备注说明' defaultValue={item.medicinalSupplementary}
                                                            onChange={(e) => getvalue(e, index, 'medicinalSupplementary')} maxLength={20}/>
                            </p>
                        </div>
                        ))}
                    </>
                )}               
            </div> 
            
        </div>
    )
}