import React, { Component } from 'react'
import "./style.less"
import { Form, Input, Button, TextArea } from "antd-mobile-v5"
import icon1 from "../../static/img/yy/fanhuihei.png"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            name: "",
            sex: "",
            date: "",
            city: "",
            phone: "",
            pasthistory: "无",
            allergichistory: "无",
            familyhistory: "无",
            liver: "无",
            kidney: "无",
            pasthistorycode: false,
            allergichistorycode: false,
            familyhistorycode: false,
            livercode: false,
            kidneycode: false
        }
    }
    onFinish = (value) => {
        // console.log(value);
    }
    setvalue = (e, type) => {
        // console.log(e,type);
        switch (type) {
            case "pasthistory":
                this.setState({
                    pasthistory: e.target.value
                })
                break;
            case "allergichistory":
                this.setState({
                    allergichistory: e.target.value
                })
                break;
            case "familyhistory":
                this.setState({
                    familyhistory: e.target.value
                })
                break;
            case "liver":
                this.setState({
                    liver: e.target.value
                })
                break;
            case "kidney":
                this.setState({
                    kidney: e.target.value
                })
                break;
            case "name":
                this.setState({
                    name: e.target.value
                })
                break;
            case "sex":
                this.setState({
                    sex: e.target.value
                })
                break;
            case "date":
                this.setState({
                    date: e.target.value
                })
                break;
            case "city":
                this.setState({
                    city: e.target.value
                })
                break;
            case "phone":
                this.setState({
                    phone: e.target.value
                })
                break;
            default:
                return;
        }
    }
    uploadvalue = () => {
        const { name, sex, date, city, phone, pasthistory, allergichistory, familyhistory, liver, kidney } = this.state
    }
    gobank = () => {
        window.history.back()
    }
    render() {
        const { name, sex, date, city, phone, pasthistory, allergichistory, familyhistory, liver,
            kidney, pasthistorycode, allergichistorycode, familyhistorycode, livercode, kidneycode } = this.state
        return (
            <div className='patientbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>就诊人信息</h1>
                </div>
                <div className='box-tips'>
                    为了医生安全的诊疗，请填写真实信息
                </div>
                <div className='box-information'>
                    <h1>基础信息</h1>
                    <div className='information-item'>
                        <div>问诊人</div>
                        <div>
                            <input type="text" placeholder='请填写真实姓名' onChange={e => this.setvalue(e, "name")} />
                        </div>
                    </div>
                    <div className='information-item'>
                        <div>性别</div>
                        <div>
                            <input type="radio" name="sex" value="男" onChange={e => this.setvalue(e, "sex")} /><span>男</span>
                            <input type="radio" name="sex" value="女" onChange={e => this.setvalue(e, "sex")} /><span>女</span>
                        </div>
                    </div>
                    <div className='information-item'>
                        <div>出生日期</div>
                        <div>
                            <input type="date" onChange={e => this.setvalue(e, "date")} />
                        </div>
                    </div>
                    <div className='information-item'>
                        <div>当前城市</div>
                        <div>
                            <input type="text" placeholder='请输入城市' onChange={e => this.setvalue(e, "city")} />
                        </div>
                    </div>
                    <div className='information-item'>
                        <div>手机号码</div>
                        <div>
                            <input type="number" placeholder='请输入手机号码' onChange={e => this.setvalue(e, "phone")} />
                        </div>
                    </div>
                </div>
                <div className='box-information'>
                    <h1>病史信息</h1>
                    <div className='information-item'>
                        <div>既往史</div>
                        <div>
                            <input type="radio" name="pasthistory" defaultChecked onClick={() => this.setState({
                                pasthistorycode: false
                            })} /><span>无</span>
                            <input type="radio" name="pasthistory" onClick={() => this.setState({
                                pasthistorycode: true
                            })} /><span>有</span>
                        </div>
                        {
                            pasthistorycode ? <div className='item-value'>
                                <input type="text" placeholder='请写出你的既往史' value={pasthistory} onChange={e => this.setvalue(e, "pasthistory")} />
                            </div> : null
                        }

                    </div>

                    <div className='information-item'>
                        <div>过敏史</div>
                        <div>
                            <input type="radio" name="allergichistory" defaultChecked onClick={() => this.setState({
                                allergichistorycode: false
                            })} /><span>无</span>
                            <input type="radio" name="allergichistory" onClick={() => this.setState({
                                allergichistorycode: true
                            })} /><span>有</span>

                        </div>
                        {
                            allergichistorycode ? <div className='item-value'>
                                <input type="text" placeholder='请写出你的既往史' value={allergichistory} onChange={e => this.setvalue(e, "allergichistory")} />
                            </div> : null
                        }
                    </div>
                    <div className='information-item'>
                        <div>家族遗传史</div>
                        <div>
                            <input type="radio" name="familyhistory" defaultChecked onClick={() => this.setState({
                                familyhistorycode: false
                            })} /><span>无</span>
                            <input type="radio" name="familyhistory" onClick={() => this.setState({
                                familyhistorycode: true
                            })} /><span>有</span>

                        </div>
                        {
                            familyhistorycode ? <div className='item-value'>
                                <input type="text" placeholder='请写出你的既往史' value={familyhistory} onChange={e => this.setvalue(e, "familyhistory")} />
                            </div> : null
                        }
                    </div>
                    <div className='information-item'>
                        <div>肝功能异常</div>
                        <div>
                            <input type="radio" name="liver" defaultChecked onClick={() => this.setState({
                                livercode: false
                            })} /><span>无</span>
                            <input type="radio" name="liver" onClick={() => this.setState({
                                livercode: true
                            })} /><span>有</span>

                        </div>
                        {
                            livercode ? <div className='item-value'>
                                <input type="text" placeholder='请写出你的既往史' value={liver} onChange={e => this.setvalue(e, "liver")} />
                            </div> : null
                        }
                    </div>
                    <div className='information-item'>
                        <div>肾功能异常</div>
                        <div>
                            <input type="radio" name="kidney" defaultChecked onClick={() => this.setState({
                                kidneycode: false
                            })} /><span>无</span>
                            <input type="radio" name="kidney" onClick={() => this.setState({
                                kidneycode: true
                            })} /><span>有</span>

                        </div>
                        {
                            kidneycode ? <div className='item-value'>
                                <input type="text" placeholder='请写出你的既往史' value={kidney} onChange={e => this.setvalue(e, "kidney")} />
                            </div> : null
                        }
                    </div>
                </div>
                <div className='box-btn'>
                    <button onClick={this.uploadvalue}>保存</button>
                </div>
            </div>
        )
    }
}
