import React, { useState, useEffect } from 'react'

import Head from '../../component/Head'
import Nodata from '../../component/Nodata'

import { msglistRequest, updateMsgRequest } from '../../api/request/msg'

import './style.less'

export default function Tidings(props) {
    const userId = localStorage.type==1?localStorage.userId1:localStorage.type==2?localStorage.userId2:localStorage.userId3
    const [count, setCount] = useState(0)
    const [tidings, setTidings] = useState([])
    const [refresh,setRefresh] = useState(false)

    useEffect(() => {
        msglistRequest(userId).then(res => {
            let newCount = 0
            if(res.code === 1 && res.data[0].length > 0) {
                res.data[0].forEach(item => {
                    if(item.newsRead === '0') {
                        newCount++
                    }
                })
                setCount(newCount)
                setTidings(res.data[0])
            } else {
                setCount(newCount)
            }
        })
    }, [userId])

    useEffect(()=>{
        refresh && setTimeout(() => setRefresh(false))
    },[refresh])

    const clearReaded = () => {
        let newTidings = tidings
        let newsIdArr = []
        tidings.forEach(item => {
            newsIdArr.push(item.newsId)
        })
        updateMsgRequest({newsId:newsIdArr}).then(res => {
            if(res.code === 1) {
                newTidings.forEach(item => {
                    item.newsRead = '1'
                })
                setCount(0)
                setTidings(newTidings)
                setRefresh(true)
            }
        })
    }

    const lookNews = (val) => {
        const params = {
            newsId: [val.newsId]
        }
        updateMsgRequest(params).then(res => {
            if(res.code === 1) {
                window.location.href = val.newsUrl
            }
        })
    }

    return(
        <>
            <Head title={`消息${count===0?'':'('+count+')'}`}
            iconname='icon-shuazi'
            clickmethod={()=>clearReaded()}/>
            <div className='tidings-screen-page' style={{background:'#FFFFFF'}}>
                {tidings.length>0?
                    <>
                        <div style={{
                            height: '1px',
                            width: '100%',
                            backgroundColor: '#DDDFE5'
                        }}></div>
                        {
                            tidings.map((v,index)=>{
                                return(
                                    <div key={index}>
                                        <div className='tidings-item'
                                        onClick={()=>lookNews(v)}>
                                            <div className='item1'>
                                                <div className='item1-img'>
                                                    <img src={v.newsPng} alt=""/>
                                                </div>
                                                {v.newsRead==='1' ? null :
                                                    <div className='hongdian'></div>
                                                }
                                            </div>
                                            <div className='item2'>
                                                <p>{v.newsTitle}</p>
                                                <div>{v.newsBrief}</div>
                                            </div>
                                            <span>{v.createTime}</span>
                                        </div>
                                        <div style={{
                                            height: '1px',
                                            width: '100%',
                                            backgroundColor: '#DDDFE5'
                                        }}></div>
                                    </div>
                                )
                            })
                        }
                    </>
                : <Nodata type='消息'/>
                }
            </div>
        </>
    )
}