import React, { Component } from 'react'
import { ImagePicker, Button, Toast } from "antd-mobile"
import { zipImg } from '../../../../component/zipImage/index'
import { uploadImg } from '../../../../api/request/upload'
import { uploadOne } from "../../../../api/request/user"
import icon from "../../../../static/img/mine/logo.png"
import "./style.less"
import FH from "../../../../static/img/mine/fanhui.png"
const now = new Date()
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            arr: {},
            file: [],
            file2: [],
            evaluatePic: [],
            evaluatePic2: [],
            qualiType: null,
            userId: window.localStorage.getItem("userId2"),
            date1: "",
            date2: "",
            num: "",
            checked: false,
            inputcode: false,
            imgcode: false,
            imgone:"",
            piccode:false
        }
    }


    componentDidMount() {
        this.setState({
            arr: this.props.location.params ? this.props.location.params.item : null,
            qualiType: this.props.location.params ? this.props.location.params.index : null
        }, () => {
            // console.log(this.state.arr)
            if (this.state.arr == null) {

            } else {
                if (this.state.arr.state != 0) {
                    this.setState({
                        inputcode: true
                    })
                }
            }
        })
    }
    //图片上传
    imgChange1 = async (files, type, index) => {
        if (type == "add") {
            let file = files[files.length - 1].file
            // console.log(file)
            let formData = new FormData()
            const res = await zipImg(file)
            formData.append('file', res)
            const res2 = await uploadImg(formData)
            // console.log(res2)
            this.setState({
                evaluatePic: res2.data,
                file: files,
                inputcode: false
            }, () => {
                // console.log(this.state.evaluatePic,this.state.file)
            })
        } else {
            this.setState({
                file: []
            })
        }
    }
    imgChange2 = async (files, type, index) => {
        if (type == "add") {
            let file = files[files.length - 1].file
            let formData = new FormData()
            const res = await zipImg(file)
            formData.append('file', res)
            const res2 = await uploadImg(formData)
            // console.log(res2)
            this.setState({
                evaluatePic2: res2.data,
                file2: files,
                inputcode: false
            }, () => {
                // console.log(this.state.evaluatePic2)
            })
        } else {
            this.setState({
                file2: []
            })
        }
    }
    upload = async () => {
        const { qualiType, evaluatePic, date1, date2, num } = this.state
        //console.log(qualiType)
        if (evaluatePic, date1, date2, num) {
            switch (qualiType) {
                case 0:
                    const res = await uploadOne({
                        userId: this.state.userId,
                        qualiType: qualiType + 1,
                        licenseNumber: num,
                        licenseStarttime: date1,
                        licenseEndtime: date2,
                        licensePic: evaluatePic,
                        isLongterm: 1
                    })
                    if (res.code == 1) {
                        Toast.success("上传成功")
                        this.props.history.push("/mine")
                    }
                    // console.log(res)
                    break;
                case 1:
                    const res2 = await uploadOne({
                        userId: this.state.userId,
                        qualiType: qualiType + 1,
                        businessNumber: num,
                        busStarttime: date1,
                        busEndtime: date2,
                        businessPic: evaluatePic
                    })
                    if (res2.code == 1) {
                        Toast.success("上传成功")
                        this.props.history.push("/mine")
                    }
                    // console.log(res2)
                    break;
                case 2:
                    const res3 = await uploadOne({
                        userId: this.state.userId,
                        qualiType: qualiType + 1,
                        circulationNumber: num,
                        cirStarttime: date1,
                        cirEndtime: date2,
                        circulationPic: evaluatePic
                    })
                    if (res3.code == 1) {
                        Toast.success("上传成功")
                        this.props.history.push("/mine")
                    }
                    // console.log(res3)
                    break;
                case 3:
                    const res4 = await uploadOne({
                        userId: this.state.userId,
                        qualiType: qualiType + 1,
                        medicalDevicenumber: num,
                        medicalStarttime: date1,
                        medicalEndtime: date2,
                        medicalDevicepic: evaluatePic
                    })
                    if (res4.code == 1) {
                        Toast.success("上传成功")
                        this.props.history.push("/mine")
                    }
                    // console.log(res4)
                    break;
                case 4:
                    const res5 = await uploadOne({
                        userId: this.state.userId,
                        qualiType: qualiType + 1,
                        networkMedicalnumber: num,
                        networkStarttime: date1,
                        networkEndtime: date2,
                        networkMedicalpic: evaluatePic
                    })
                    if (res5.code == 1) {
                        Toast.success("上传成功")
                        this.props.history.push("/mine")
                    }
                    // console.log(res5)
                    break;
                case 5:
                    const res6 = await uploadOne({
                        userId: this.state.userId,
                        qualiType: qualiType + 1,
                        medicalIIDevicenumber: num,
                        medicalIIStarttime: date1,
                        medicalIIEndtime: date2,
                        medicalIIDevicepic: evaluatePic
                    })
                    if (res6.code == 1) {
                        Toast.success("上传成功")
                        this.props.history.push("/mine")
                    }
                    // console.log(res6)
                    break;
                case 6:
                    const res7 = await uploadOne({
                        userId: this.state.userId,
                        qualiType: qualiType + 1,
                        cardNumber: num,
                        cardStarttime: date1,
                        cardEndtime: date2,
                        cardReverse: evaluatePic,
                        cardFront: evaluatePic
                    })
                    if (res7.code == 1) {
                        Toast.success("上传成功")
                        this.props.history.push("/mine")
                    }
                    // console.log(res7)
                    break;
                case 7:
                    const res8 = await uploadOne({
                        userId: this.state.userId,
                        qualiType: qualiType + 1,
                        entrustStarttime: date1,
                        entrustEndtime: date2,
                        entrustPic: evaluatePic
                    })
                    if (res8.code == 1) {
                        Toast.success("上传成功")
                        this.props.history.push("/mine")
                    }
                    // console.log(res8)
                    break;
            }
        } else {
            Toast.fail("请将信息填写完整")
        }
    }
    // 日期选择
    chioseone = (e) => {
        // console.log(e.target.value.replaceAll("-", ""))
        this.setState({
            date1: e.target.value
        })
        //console.log(e.target.value);
    }
    chiosetwo = (e) => {
        const { date1 } = this.state
        this.setState({
            date2: e.target.value
        }, () => {
            if (date1) {
                const num = this.state.date2.replaceAll("-", "")
                const num2 = date1.replaceAll("-", "")
                if (num <= num2) {
                    Toast.fail("到期时间需大于初始时间")
                    this.setState({
                        date2: ""
                    })
                }
            } else {
                Toast.fail("初始时间还未选择")
                this.setState({
                    date2: ""
                })
            }
        })
        //console.log(e.target.value);
    }
    getnum = (e) => {

        this.setState({
            num: e.target.value
        })
    }
    getcheck = (e) => {
        this.setState({
            checked: e.target.checked
        })
    }
    // 控制上传组件
    changepic = () => {
        this.setState({
            imgcode: true
        })
    }
    //显示图片
    toimg=(img)=>{
        this.setState({
            imgone:img,
            piccode:true
        })
    }
    //关闭图片
    closepic=()=>{
        this.setState({
            piccode:false
        })
    }
    render() {
        const { arr, file, visible, qualiType, inputcode, imgcode ,piccode,imgone} = this.state
        return (
            <div style={{ width: "100%", height: "100vh", boxSizing: "border-box", backgroundColor: "#FFFFFF" }}>
                <div className="SHintop">

                    <img src={FH} alt="" onClick={() => window.history.back()} /><h2>{arr ? arr.name ? arr.name : null : null}</h2>
                </div>
                <div className="SHinpic">
                    <button className="changepic" onClick={this.changepic}>修改</button>
                    {arr ? arr.pic ? <img src={arr ? arr.pic ? arr.pic : null : null} onClick={()=>this.toimg(arr.pic)} alt="" /> : null : null}
                    {arr ? arr.pic1 ? <img src={arr ? arr.pic1 ? arr.pic1 : null : null} onClick={()=>this.toimg(arr.pic1)} alt="" /> : null : null}
                    {arr ? arr.pic2 ? <img src={arr ? arr.pic2 ? arr.pic2 : null : null} onClick={()=>this.toimg(arr.pic2)} alt="" /> : null : null}
                    {imgcode ? <ImagePicker
                        files={file}
                        length={1}
                        style={arr ? arr.pic1 ? { width: "100px", height: "100px", position: "absolute", margin: "auto", left: "20%", top: 0, bottom: 0 } :
                            { width: "100px", height: "100px", position: "absolute", margin: "auto", left: 0, right: 0, top: 0, bottom: 0 } : null

                        }
                        onChange={this.imgChange1}
                        selectable={file.length < 1}
                        accept='image/gif,image/jpeg,image/jpg,image/png' /> : null}

                    {qualiType == 7 && imgcode ? <ImagePicker
                        files={file}
                        length={1}
                        style={{ width: "100px", height: "100px", position: "absolute", margin: "auto", right: "20%", top: 0, bottom: 0 }}
                        onChange={this.imgChange2}
                        selectable={file.length < 1}
                        accept='image/gif,image/jpeg,image/jpg,image/png' /> : null}
                </div>
                {inputcode ?
                    <div className="inputall">
                        <div>
                            <span>证件编码:</span> <input style={{ border: "none", backgroundColor: "#F2F2F2", borderRadius: "3px" }} type="text" defaultValue={arr.number} />
                        </div>
                        <div>
                            <span>结束时间:</span><input style={{ marginLeft: "3px" }} defaultValue={arr.endtime} />
                        </div>
                        {qualiType == 0 ? <div className="alltime">
                            <span>是否长期有效:</span><span>{arr.isLongterm == 0 ? "否" : "是"}</span>
                        </div> : null}

                    </div> :
                    <div className="inputall">
                        <div>
                            <span>证件编码:</span> <input style={{ border: "none", backgroundColor: "#F2F2F2", borderRadius: "3px" }} type="text" value={this.state.num} onChange={e => this.getnum(e)} />
                        </div>

                        <div>
                            <span>起始时间:</span> <input type="date" value={this.state.date1} onChange={e => this.chioseone(e)} />
                        </div>
                        <div>
                            <span>结束时间:</span><input style={{ marginLeft: "3px" }} type="date" value={this.state.date2} onChange={e => this.chiosetwo(e)} />
                        </div>
                        {qualiType == 0 ? <div className="alltime">
                            <span>是否长期有效:</span>{<input type="checkbox" checked={this.state.checked} onChange={e => this.getcheck(e)} />}
                        </div> : null}

                    </div>}
                {piccode?<div className="card">
                    <div className="card-img">
                        <img src={imgone} alt="" />
                        <div>
                        <button onClick={this.closepic}></button>
                        </div>
                    </div>
                </div>:null}
                <button className="SHbtn" onClick={this.upload}>确定</button>
            </div>
        )
    }
}
