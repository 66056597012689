import React ,{useState}from 'react'

import './style.less'

export default function Table(props) {
    const [isShow,setisShow]=useState(false)
    const [medDetail] = useState(props.medDetail)
    return(
        <>
            <table className='med-table' border='1' cellspacing='0' cellpadding='0'>
                <tbody>
                    {/* <tr>
                        <td>商品编号</td>
                        <td>{medDetail.medNumber}</td>
                    </tr> */}
                    {/* <tr>
                        <th colSpan='2'>主体</th>
                    </tr> */}
                    <tr>
                        <td>通用名称</td>
                        <td>{medDetail.medComName}</td>
                    </tr>
                    <tr>
                        <td>商品名称</td>
                        <td>{medDetail.medName}</td>
                    </tr>
                    <tr>
                        <td>处方类型</td>
                        {medDetail.medClassify==='1'?<td>处方药</td>:
                        <td>非处方药</td>}
                    </tr>
                    <tr>
                        <td>品牌</td>
                        <td>{medDetail.medBrand}</td>
                    </tr>
                    {medDetail.medIf===0?<tr>
                        <td>规格/包装</td>
                        <td>{medDetail.medSpec}</td>
                    </tr>:null}
                    {medDetail.medIf===0?<tr>
                        <td>药品类别</td>
                        <td>{medDetail.medType}</td>
                    </tr>:null}
                    {isShow===true?
                    <>
                    {medDetail.medIf===0?<tr>
                            <td>剂型</td>
                            <td>{medDetail.medDosageForm}</td>
                    </tr>:null}
                    <tr>
                        <td>药品成分</td>
                        <td>{medDetail.medBasis}</td>
                    </tr>
                    <tr>
                        <td>功能主治</td>
                        <td>{medDetail.medExplain}</td>
                    </tr>
                    {medDetail.medIf===0?<tr>
                        <td>适用人群</td>
                        <td>{medDetail.medApply}</td>
                    </tr>:null}
                    {medDetail.medIf===0?<tr>
                        <td>用法用量</td>
                        <td>{medDetail.medUsage}</td>
                    </tr>:null}
                    <tr>
                        <td>注意事项</td>
                        <td>{medDetail.medAttention}</td>
                    </tr>
                    <tr>
                        <td>用药禁忌</td>
                        <td>{medDetail.medTaboo}</td>
                    </tr>
                    <tr>
                        <td>不良反应</td>
                        <td>{medDetail.medAdverse}</td>
                    </tr>
                    {medDetail.medIf===0?<tr>
                        <td>储存条件</td>
                        <td>{medDetail.medStorage}</td>
                    </tr>:null}
                    {medDetail.medValidity?<tr>
                        <td>有效期</td>
                        <td>{medDetail.medValidity}个月</td>
                    </tr>:<tr><td>有效期</td>
                        <td></td></tr>}
                    <tr>
                        <td>{medDetail.medIf===0?'批准文号':'注册证号'}</td>
                        <td>{medDetail.medApproval}</td>
                    </tr>
                    <tr>
                        <td>生产厂家</td>
                        <td>{medDetail.medCompany}</td>
                    </tr>
                    <tr>
                        <td>国产/进口</td>
                        <td>{medDetail.medOrigin===0?'国产':'进口'}</td>
                    </tr>
                    </>:null
                    }
                    {/* {isShow===true?
                    <>
                        {medDetail.medIf===0?<tr>
                            <td>储存条件</td>
                            <td>{medDetail.medStorage}</td>
                        </tr>:null}
                        {medDetail.medIf===0?<tr>
                            <td>药品分类</td>
                            <td>{medDetail.medClassify==='0'?'非处方药':'处方药'}</td>
                        </tr>:null}
                        {medDetail.medIf===0?<tr>
                            <td>适用人群</td>
                            <td>{medDetail.medApply}</td>
                        </tr>:null}
                        {medDetail.medIf===0?<tr>
                            <td>剂型</td>
                            <td>{medDetail.medDosageForm}</td>
                        </tr>:null}
                        {medDetail.medIf===0?<tr>
                            <td>有效期</td>
                            <td>{medDetail.medValidity}</td>
                        </tr>:null}
                        {medDetail.toB===1?<tr>
                            <td>过期时间</td>
                            <td>{medDetail.expiration}</td>
                        </tr>:''}
                        <tr>
                            <th colSpan='2'>参数</th>
                        </tr>
                        <tr>
                            <td>国产/进口</td>
                            <td>{medDetail.medOrigin===0?'国产':'进口'}</td>
                        </tr>
                        {medDetail.medIf===0?<tr>
                            <td>类型</td>
                            <td>{medDetail.medMold}</td>
                        </tr>:null}
                        {medDetail.medIf===0?<tr>
                            <td>适用症状</td>
                            <td>{medDetail.medApplySymptoms}</td>
                        </tr>:null}
                        {medDetail.medIf===0?<tr>
                            <td>使用方法</td>
                            <td>{medDetail.medUsage}</td>
                        </tr>:null}
                    </>:null
                    } */}
                </tbody>
            </table>
            <div 
            className='med-table-collapse' 
            onClick={()=>setisShow(!isShow)}
            style={isShow === true ? { display: "block" } : { display: "none" }} >
                <span>收起</span>
                <i className="icon-xiangshang iconfont"></i>
            </div>
            <div 
            className='med-table-collapse'
            onClick={()=>setisShow(!isShow)}
            style={isShow === false ? { display: "block" } : { display: "none" }}>
                <span>展开</span>
                <i className="icon-xiangxia iconfont"></i>
            </div>
        </>
    )
}