import React, { useState, useEffect  } from 'react'
import "./style.less"
import FH from "../../../../../static/img/mine/fanhui.png"
import { walletRecord} from '../../../../../api/request/wallet'

export default function Shop1(props) {
    const userId =localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3
    const [load, setLoad] = useState(true)
    const [page, setPage] = useState(1)
    const [size] = useState(20)
    const [payList, setpayList] = useState([])
    const [refresh,setRefresh] = useState(false)
    useEffect(() => {
        let params={
            userId: userId,
        }
        walletRecord(params).then((res)=>{
            // console.log(res);
            if(res.code === 1) {
                setpayList(res.data[0])
            }
        })
    }, [])
    useEffect(()=>{
        refresh && setTimeout(() => setRefresh(false))
    },[refresh])
    const onscroll = e => {
        // console.log(11);
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10) {
            pageMedlist()
        }
    }
    const pageMedlist = () => {
        if(load) {
            const params = {
                userId: userId,
                page: page + 1,
                size: size
            }
            walletRecord(params).then((res)=>{
                // console.log(res);
                if(res.code === 1) {
                    let data = res.data
                    if(data[0].length > 0) {
                        setpayList([...payList, ...data[0]])
                        setPage(params.page)
                        if(data[0].length < size) {
                            setLoad(false)
                        }
                    } else {
                        if(data[0].length < size) {
                            setLoad(false)
                        }
                    }
                }
            })
            setRefresh(true)
        }
    }
    return (
        <div onScroll={onscroll} className='MX-outer1'>
            <div className="MX-top">
                <img src={FH} alt="" onClick={() => window.history.back()} /><h2>提现明细</h2>
            </div>
            {payList?payList.map((item)=>{
                return(
                <div className="MX-text1">
                    <div>
                        <p>提现金额</p>
                        <p>{item.withdrawalAmount/100}
                            {item.paymentStu==='1'?<span className='tixian'>(已提现)</span>:
                            <span className='tixian'>(提现中)</span>}
                        </p>
                    </div>
                    <div>
                        <p>提现日期：{new Date(item.createTime).getFullYear()+'-'+(new Date(item.createTime).getMonth()+1)+'-'+new Date(item.createTime).getDate()+'  '
                        +new Date(item.createTime).getHours()+':'+new Date(item.createTime).getMinutes()+':'+new Date(item.createTime).getSeconds()}
                        </p>
                    </div>
                    {item.paymentTime?<div>
                        <p>到账日期：{new Date(item.paymentTime).getFullYear()+'-'+(new Date(item.paymentTime).getMonth()+1)+'-'+new Date(item.paymentTime).getDate()+'  '
                            +new Date(item.paymentTime).getHours()+':'+new Date(item.paymentTime).getMinutes()+':'+new Date(item.paymentTime).getSeconds()}
                        </p>
                    </div>:null}
                </div>
            )}):null
            }
        </div>
    )
}


