import React ,{Component}from 'react'

import './style.less'


class Label extends Component{
    longPressInterval = 600     //longPressInterval长按的毫秒数
    longPressItemTimeOut      //setTimeOut的返回值    为了清掉定时器
    constructor(){
        super()
        this.state={currentDeleteIndex: -1,visible:false}
    }
    componentDidMount=()=>{
        // console.log(this.props);
    }
    onItemTouchStart = currentItem => {
        this.startTime = +new Date()
        this.longPressItemTimeOut = setTimeout(
          () => this.onLongPressItem(currentItem),
          this.longPressInterval
        )
    }
    onLongPressItem(currentItem) {
        this.setState({
          currentDeleteIndex: currentItem
        })
    }
    onItemTouchEnd = (v) => {
        // console.log(v);
        this.endTime = +new Date()
        const interval = this.endTime - this.startTime   //长按时长
        
        if (interval < this.longPressInterval) {      //按键时长<600,默认是点击事件
            // TODO click操作  点击进入对应的Item
            if(!this.state.visible) {
                clearTimeout(this.longPressItemTimeOut)
                this.setState({
                    currentDeleteIndex: -1,
                    visible: false
                })
                this.props.onclick(v)
            }
        } else {        //按键时长>600   长按
          // TODO 长按操作
          this.setState({
            visible: true
          })
        }
    }
    getvalue=(v)=>{
        // console.log(v);
    }
    
    delHistory = (index) => {
        const delHistory = this.props.delHistory
        if(delHistory) {
            delHistory(index)
            this.setState({
                currentDeleteIndex: -1,
                visible: false
            })
        }
    }

    render(){
        const indexlist=this.props.list
        const searchType = this.props.searchType === 'history'

        return(
            <div className='label-part'>
                {
                    indexlist.map((v,index)=>{
                        return(
                            <div key={index}
                            className='label-item flex-row'
                            onMouseDown={() => this.onItemTouchStart(index)}
                            onMouseUp={()=>this.onItemTouchEnd(v)}
                            
                            >
                               <span onClick={()=>this.getvalue(v)}> {v}</span>
                                {searchType&&this.state.visible&&this.state.currentDeleteIndex === index?(
                                    <i className='iconfont icon-guanbi delete-icon'
                                    onClick={()=>this.delHistory(index)}></i>
                                ):null}
                            </div>
                        )
                    
                    })
                }
            </div>
        )
    }
}

export default Label