import { axiosInstance } from '../config'

//图片上传
export const uploadImg = (data) => {
    return axiosInstance.post('/api/hosp/upload/picture', data)
}
//删除签章重新签名
export const delResign = (data) => {
    return axiosInstance.post('/faas/hosp/scca/del', data)
}
