import React ,{ useState, useEffect }from 'react'
import { Tabs } from 'antd-mobile'

import Head from '../../../component/Head'
import Tag from '../../../baseui/tag/index'

import { couponlistRequest } from '../../../api/request/coupon'

import './style.less'
import IMG from '../../../static/img/logo.png'
import noCoupon from '../../../static/img/nodata/coupon.png'

export default function Coupon(props) {
    
    const userId = localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3
    // 优惠卷列表
    const [couponlist, setCouponlist] = useState([])
    // 药店信息
    const [pharmacyList, setPharmacyList] = useState([])
    const tabs=[{ title: '未使用', state: '0' },{ title: '已使用', state: '1' },{ title: '已过期', state: '-1' }]

    useEffect(() => {
        getCoupon(0)
    }, [])
    // 获取优惠卷列表
    const getCoupon = (state) => {
        couponlistRequest(userId, state).then(res => {
            let pharmacyList = []
            if(res.code === 1 && res.data[0].length > 0) {
                let data = res.data[0]
                setCouponlist(data)
                for (let i = 0; i < data.length; i++) {
                    for (let j = i+1; j < data.length; j++) {
                        if(data[i].pharmacyId===data[j].pharmacyId){
                            ++i
                        }
                    }
                    pharmacyList.push({
                        pharmacyId: data[i].pharmacyId,
                        pharmacyName: data[i].pharmacyName,
                        pharmacyPhoto: data[i].pharmacyPhoto
                    })
                }
                setPharmacyList(pharmacyList)
            } else {
                setCouponlist([])
                setPharmacyList([])
            }
        })
    }
    // 切换标签页，获取对应优惠卷信息
    const tabsChange = (tab, index) => {
        getCoupon(tab.state)
    }

    const enterStore = (pharmacyId) => {
        // console.log(pharmacyId)
    }
    // 展示店铺信息
    const showcoupon=(tab)=>{
        return(
            <>
                {tab.map((s,i)=>{
                    return(
                        <div key={i} className='coupon-dragstore flexc'>
                            <div className='flex-apart-row'>
                                <div className='coupon-dragstore-title flex-row'>
                                    <div>
                                        <img src={s.pharmacyPhoto?s.pharmacyPhoto:IMG} alt=''/>
                                    </div>
                                    <p>{s.pharmacyName?s.pharmacyName:'云药购'}</p>
                                </div>
                            {s.pharmacyId === 0 ? null :
                                <div onClick={()=>enterStore(s.pharmacyId)}>
                                    <Tag type='radius'
                                    text='进店'
                                    maincolor='#F34848'
                                    active={false}/>
                                </div>
                            }
                            </div>
                            {showitems(couponlist, s)}
                        </div>
                    )
                })}
            </>
        )
    }
    // 展示优惠卷列表
    const showitems=(tab, s)=>{
        return(
            <div>
                {tab.map((v,index)=>{
                    return(v.pharmacyId === s.pharmacyId ?
                        <div key={index}>
                            <div className='coupon-tiket flexr'>
                                {v.new?
                                <>
                                    <div className='coupon-new'></div>
                                    <span className='coupon-newword'>新</span>
                                </>:null
                                }
                                <div className='coupon-tiket-left flex-col'>
                                    <div className='coupon-num'>
                                        <span>￥</span>
                                        <span>{v.couponMoney/100}</span>
                                    </div>
                                    <p>满{v.couponLimit/100}元可用</p>
                                </div>
                                <div className='coupon-detail flexc'>
                                    <div className='coupon-tiket-name flexr'>
                                        {v.pharmacyId===0?
                                        <Tag type='radius' text='平台券' active={true}/>
                                        :null}
                                        <span>{v.couponName}</span>
                                    </div>
                                    <span>{v.couponExpire}到期</span>
                                    <div className='coupon-divide-line'></div>
                                    <div className='coupon-last-row'>
                                    <Tag type='radius' text='立即使用' maincolor='#F34848' active={true}/>
                                    </div>
                                </div>
                            </div>
                        </div> : null
                    )
                })
                }
            </div>
        )
    }
    // 页面无优惠卷提示/优惠卷渲染
    const renderContent = (tab,index) =>{
        return(
            <div>
            {couponlist.length > 0 ?
                <div className='contain-item'>{showcoupon(pharmacyList)}</div> :
                <div className='no-data coupon-no-data'>
                    <img src={noCoupon} alt=''/>
                    <p>抱歉，暂无优惠券</p>
                </div>
            }
            </div>
        )
    }
    

    return(
        <div>
            <Head title='优惠券'/>
            <Tabs
            tabs={tabs}
            onChange={tabsChange}
            tabBarTextStyle={{fontSize:'14px',padding:'0',height:'29px'}}
            tabBarActiveTextColor={'#333333'}
            tabBarUnderlineStyle={{border: '3px #1F87FF solid',transform:'scaleX(0.25)'}}
            renderTabBar={props => <Tabs.DefaultTabBar {...props} page={3} />}>
                {renderContent}
            </Tabs>
        </div>
    )
}