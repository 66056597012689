import React, { Component } from 'react'
import { Button, Space, Rate } from "antd-mobile-v5"
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/yy/ts.png"
import icon3 from "../../static/img/yy/five.png"
import icon4 from "../../static/img/yy/pic.png"
import icon5 from "../../static/img/yy/phone.png"
import icon6 from "../../static/img/yy/zixun.png"
import "./style.less"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            item: {}
        }
    }
    componentDidMount = () => {
        // console.log(this.props.location.params);
        if (this.props.location.params) {
            this.setState({
                item: this.props.location.params.item
            })
        }
    }
    tochat=()=>{
        const {item}=this.state
        if (item) {
            this.props.history.push({
                pathname:"/doctorinfo/chat",
                params:{
                    item
                }
            })
        }
       
    }
    render() {
        const { item } = this.state
        return (
            <div className='infobox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/")} /><h1>医生简介</h1>
                </div>
                <div className='box-info' style={{ padding: "15px" }}>
                    <div className='info-1'>
                        <div className='box-container'>
                            <div className='container-box'>
                                <img src={icon2} alt="" />
                                <div className='box-text'>
                                    <p className='box-name'>
                                        <span>{item.docterName}</span><span>{item.title}</span><span>{item.department}</span>
                                    </p>
                                    <div className='box-title'>
                                        <span>三甲</span> <span>{item.hospital}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box-evaluate'>
                            <div>
                                <img src={icon3} alt="" /> <span>4.8</span>
                            </div>
                            <div>
                                <span>接诊次数</span> <span>700</span>
                            </div>

                            <div>
                                <span>回复速度:</span> <span>较快</span>
                            </div>
                        </div>
                        <div className='box-introduce'>
                            <span>专业擅长：</span> {item.expert} <span>查看简介 ＞</span>
                        </div>
                    </div>
                    <div className='info-2'>
                        <div className='info2-item'>
                            <img src={icon4} alt="" />
                            <div>
                                <p>图文咨询 <span>￥20/15分钟</span></p>
                                <p>1对1图文多次沟通</p>
                            </div>
                            <img src={icon6} alt="" className='item-img' onClick={this.tochat}/>
                        </div>
                        <div className='info2-item'>
                            <img src={icon5} alt="" />
                            <div>
                                <p>电话咨询 <span>￥40/15分钟</span></p>
                                <p>预约电话，沟通更清楚</p>
                            </div>
                            <img src={icon6} alt="" className='item-img' />
                        </div>
                    </div>
                    <p className='info-3'>评价(365) <p></p></p>
                    <div className='info-4'>
                        <div className='info4-item'>
                            <div className='item-top'>
                                <p>账**名 <Rate readOnly style={{
                                    '--star-size': '20px',
                                    '--active-color': '#1F87FF',
                                }} value={4} /></p><p>2021.12.25</p>
                            </div>
                            <div className='item-btn'>
                                <button>回复很及时</button>  <button>非常热心</button>
                            </div>
                            <p>非常好！（默认）</p>
                        </div>
                        <div className='info4-item'>
                            <div className='item-top'>
                                <p>账**名 <Rate readOnly style={{
                                    '--star-size': '20px',
                                    '--active-color': '#1F87FF',
                                }} value={4} /></p><p>2021.12.25</p>
                            </div>
                            <div className='item-btn'>
                                <button>回复很及时</button>  <button>非常热心</button>
                            </div>
                            <p>非常好！（默认）</p>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
