import React from 'react'
import {withRouter} from 'react-router-dom'

import './style.less'
import '../../static/css/iconfont.css'

import defaultImg from '../../static/img/default.png'

function MedList(props) {
    const userId =localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3
    const userId1=localStorage.getItem("userId1")
    const userId2=localStorage.getItem("userId2")
    const type = localStorage.type
    const { onclick=null } = props
    const recommend = props.recommend
    const medDetails = (medId) => {
        onclick()
        props.history.replace(`/med/${medId}`)
    }

    return(
        <div className='showmed-area flexr'>
        {recommend.map((v,index)=>{
            return(
                <div className='meditem flex-col' key={index} onClick={()=>medDetails(v.medId)}>
                    <div>
                        <img src={v.medPhoto?v.medPhoto:defaultImg} alt=''
                        onError={(e) => {e.target.onerror = null;e.target.src=defaultImg}}/>
                    </div>
                    <p>{v.medName}</p>
                    {/* <p className='meditem-spec'>{v.medSpec}</p> */}
                    {/* {v.priceSingle ? <span>¥{v.medPrice/100}</span> : null} */}
                    {type=="1"?<b>¥{v.priceSingle ? v.priceSingle/100 : v.medPrice/100}</b>:null}

                    {type=="2"?v.toB==1&&!userId2?<b style={{color:"#FC4343"}}>登录可见</b>:<b>¥{v.priceSingle ? v.priceSingle/100 : v.medPrice/100}</b>:null}
                    {/* {v.toB==0&&!userId1&&type=="1"?"请先登录":
                       <b>¥{v.priceSingle ? v.priceSingle/100 : v.medPrice/100}</b>
                     } */}
                </div>
            )
        })}
        </div>
    )
}

export default withRouter(MedList)