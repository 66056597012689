import React, { Component } from 'react';
import "./style.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import { Modal } from "antd-mobile-v5"
export default class index extends Component {
    constructor(){
        super()
        this.state={
            visible:false
        }
    }
    gobank = () => {
        window.history.back()
    }
    openbox=()=>{
        this.setState({
            visible:true
        })
    }
    render() {
        const {visible} =this.state
        return (
            <div className='editbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>编辑处方</h1>
                </div>
                <div className='box-content1'>
                    <div className='content1-one'>
                        <p>处方编号：20220108121325</p>
                        <p>2022-01-08</p>
                    </div>
                    <div>
                        <div>患者姓名：</div>
                        <div>
                            <input type="text" className='content1-input' />
                        </div>
                    </div>
                    <div>
                        <div>年龄：</div>
                        <div>
                            <input type="text" className='content1-input' />岁
                        </div>
                    </div>
                    <div>
                        <div>性别：</div>
                        <div>
                            <input type="radio" value="男" name="sex" /> <span style={{ marginRight: "30px" }}>男</span>  <input type="radio" value="女" name="sex" /> 女
                        </div>
                    </div>
                </div>
                <div className='box-content2'>
                    <p>处方医师：朱心寒</p>
                    <p>科室：内科</p>
                    <p>初步诊断</p>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                    <p>处理意见</p>
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                </div>
                <div className='box-content3'>
                    <div className='content3-text'>
                        <h1>处方药清单</h1>
                        <div className='text-name'>
                            <p>【品牌】阿司匹林胶囊</p>
                            <p>x1</p>
                        </div>
                        <h2>规格：80mg*7粒/盒</h2>
                        <h2>口服：成人一次xxxxx每天xxxx</h2>
                        <div className='text-btn'>
                            <button>删除</button><button onClick={() => this.props.history.push("/prescribing/editprescription/editnum")}>编辑用量</button>
                        </div>
                    </div>
                    <div className='content3-bottom'>
                        <button>新增药品</button>
                    </div>
                </div>
                <div className='box-btn'>
                    <button onClick={this.openbox}>提交审核</button>
                </div>
                <Modal
                    visible={visible}
                    title="提交成功"
                    content='稍后在"我的处方"中可以看到处方审核信息。'
                    closeOnAction
                    onClose={() => {
                        this.setState({
                            visible:false
                        })
                    }}
                    actions={[
                        {
                            key: 'confirm',
                            text: '确定',
                        },
                    ]}
                />
            </div>
        )
    }
}
