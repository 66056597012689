import React, { Component } from 'react'
import Axios from 'axios';
import {getToken,getJMURL} from "../../api/hospitalapi/login"
export default class getUserInfo extends Component {
    constructor(){
        super();
        this.state={
        }
    }
    componentDidMount=async()=>{
        const arr = window.location.href.split("&")
        let url = arr[0].substring(55, arr[0].length)
        const res=await getJMURL({
            url
        })
        const wximg=res.data.headimgurl
        const wxuserName=res.data.nickname
        const wxuserOpenid=res.data.openid
        //console.log(wximg,wxuserName,wxuserOpenid)
        localStorage.setItem("wxuserName",wxuserName)
        localStorage.setItem("wximg",wximg)
        localStorage.setItem("wxuserOpenid",wxuserOpenid)
            window.location.href=" https://doc.yyj-health.com/index.html#/"

    }
    render() {
        return (
            <div>
               <h1>请稍等</h1>
            </div>
        )
    }
}
