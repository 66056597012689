import React, { Component } from 'react'
import "./style.less"
import icon1 from "../../../../static/img/yy/fanhuihei.png"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            docterPhone: localStorage.getItem("docterPhone")
        }
    }
    componentDidMount = () => {
        console.log(localStorage.getItem("docterPhone"));
    }
    clearuser = () => {
        // let attempts=  localStorage.getItem('loginAttempts')
        // let currentTime=  localStorage.getItem('lastFailedTime')

        localStorage.clear()

        // localStorage.setItem('loginAttempts',attempts);
        // localStorage.setItem('lastFailedTime',currentTime);

        this.props.history.push("/login")
    }
    render() {
        const {docterPhone} =this.state
        return (
            <div className='securitybox'>
                 <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/mine/set")} />   账户信息与安全
                    <div className='box-item'>
                    <div>手机号</div> <div>{docterPhone}</div>
                </div>
                {/* <div className='box-item'>
                    <div>微信公众号</div> {patientPhone ?
                        <div>已绑定 <span style={{ color: "rgb(72, 226, 72)" }} onClick={this.clearuser}>点击解绑</span></div> :
                        <div>未绑定 <span style={{ color: "rgb(72, 226, 72)" }} onClick={() => this.props.history.push("/login")}>点击绑定</span></div>}
                </div> */}
                </div>
            </div>
        )
    }
}
