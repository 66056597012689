import React, {Component} from 'react'
import {ImageUploader, Picker, SpinLoading, Toast, CascadePicker, DatePicker} from "antd-mobile-v5"
import {ImagePicker, Modal,Popover,Button } from "antd-mobile"
import "./style.less"
import qs from 'qs';
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/doctor/right.png"
import icon3 from "../../static/img/login/login-bgc.png"
import {uploaddoctor, uploadpic} from "../../api/hospitalapi/upload"
import {zipImg} from '../../component/zipImage'
import {queryDepartment, ejDepartment} from '../../api/hospitalapi/department'
import {dealParams, getBase64, getBase64Image,getBase64Image0} from '../../api/config'
import {caAuthentication} from '../../api/hospitalapi/upload'
import {getuserinfo} from "../../api/hospitalapi/login";
import {baseUrl, caUrl, jumpUrl} from '../../publicurl'
import getUrl from "../../component/GetUrlParam";
import {addOpenid} from "../../api/hospitalapi/offline";

const {alert} = Modal
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            fileList: [],
            fileList2: [],
            fileList3: [],
            fileList4: [],
            fileList5: [],
            fileList6: [],
            evaluatePic: [],
            evaluatePic2: [],
            evaluatePic3: [],
            evaluatePic4: [],
            evaluatePic5: [],
            evaluatePic6: [],
            docter: '',
            name: "",
            id: "",
            hospital: "",
            department1: "",
            department2: "",
            title: "",
            docterId: localStorage.getItem("docterId"),
            visible: false,
            visible1: false,
            visible2: false,
            visible4: false,
            visible5: false,
            value: "",
            basicColumns: [
                [
                    {label: '医生', value: '医生'},
                    {label: '护士', value: '护士'}
                ],
                [
                    {label: '主任医师', value: '主任医师'},
                    {label: '副主任医师', value: '副主任医师'},
                    {label: '主治医师', value: '主治医师'},
                    {label: '执业医师', value: '执业医师'},
                    {label: '住院医师', value: '住院医师'}
                ],
            ],
            basicColumns2: [
                [
                    {label: '医生', value: '医生'},
                    {label: '护士', value: '护士'}
                ],
                [
                    {label: '主任护师', value: '主任护师'},
                    {label: '副主任护师', value: '副主任护师'},
                    {label: '护师', value: '护师'},
                    {label: '护士', value: '护士'}
                ],
            ],
            selectnow: "",
            yijidepartment: [],
            erjidepartment: [],
            depId: '',//二级科室id
            depId1: '',//一级科室id
            typea: 0,
            certificateNum: '',//执业证书编号
            qualificationcertificateNum: '',//资格证书编号
            certificateTime: '',//执业证书发证日期
            qualificationcertificateTime: '',//资格证书发证日期
            yijiname:'',
            erjiname:'',
            pic5:false,
            pic6:false,
            pic4:false,
            pic0:false,
            pic3:false,
            pic2:false,
            pic1:false,
            pic:false,
            invalidFields: [],
            list:''

        }
        this.useRef = React.createRef();
    }

    componentDidMount = async () => {
        const {docterId}=this.state
        const res = await queryDepartment()
        if (res && res.data && res.data[0]) {
            let list = res && res.data[0]
            let arr=[]
            this.setState({
                depId1: res.data[0][0].dep_id
            })
            list.map(item => {
                item.key=item.dep_id
                item.value=item.dep_name
                item.label=item.dep_name

            })
            ejDepartment({depParentId: res.data[0][0].dep_id}).then(res0 => {
                if (res0 && res0.data) {
                    if (res0.data[0].length) {
                        let list2 = res0.data[0]
                        let arr1=[]
                        list2.map(item0 => {
                            let params1={}
                            params1.key=item0.dep_id
                            params1.value=item0.dep_name
                            params1.label=item0.dep_name
                            arr1.push(params1)
                        })
                        res.data[0][0].children=arr1
                    } else {
                        res.data[0][0].children=[]
                    }
                }
            })
            this.setState({
                yijidepartment:res.data[0]
            })
            // console.log(list);
            // this.setState({
            //     yijidepartment: list,
            // })
        }
        if (getUrl('openid',window.location.search)) {
            let res=await addOpenid({openid:getUrl('openid', window.location.search),docterId})
        }
        const docterPhone = localStorage.getItem("docterPhone")
        if (docterPhone) {
            const res = await getuserinfo({
                docterPhone,
            })
            if (res && res.data && res.data[0]) {
                this.setState({
                    docter: res.data[0][0],
                })
            }
            if (res && res.data && res.data[1]&& res.data[1].length) {
                ejDepartment({depParentId: res.data[1][0].depId}).then(res0 => {
                    if (res0 && res0.data) {
                        if (res0.data[0].length) {
                            let list2 = res0.data[0]
                            let arr1=[]
                            list2.map(item0 => {
                                if (item0.dep_id==res.data[1][0].branch){
                                    this.setState({
                                        erjiname:item0.dep_name,
                                        depId:res.data[1][0].branch,
                                        depId1:res.data[1][0].depId,
                                    })
                                }
                            })
                        }
                    }
                })
                this.setState({
                    list: res.data[1][0],
                    name:res.data[1][0].docter_name,
                    id:res.data[1][0].docter_id_number,
                    hospital:res.data[1][0].docter_hospital,
                    department1:res.data[1][0].depName,
                    erjiname:res.data[1][0].erjidepName,
                    certificateNum:res.data[1][0].certificateNum,
                    title:res.data[1][0].docter_title,
                    evaluatePic4:[res.data[1][0].docter_id_front],
                    evaluatePic5:[res.data[1][0].docter_id_back],
                    evaluatePic6:[res.data[1][0].docter_nvq],
                    evaluatePic:[res.data[1][0].docter_license],
                    evaluatePic3:res.data[1][0].docter_other_pic?[res.data[1][0].docter_other_pic] : [],
                    qualificationcertificateNum: res.data[1][0].qualificationcertificateNum,//资格证书编号
                    certificateTime: res.data[1][0].certificateTime1,//执业证书发证日期
                    qualificationcertificateTime:res.data[1][0].qualificationcertificateTime1,
                    selectnow:res.data[1][0].docter_title==('主任护师'||'副主任护师'||'护师'||'护士')?'护士':'医师',
                    depId1:res.data[0][0].depId,
                    depId:res.data[0][0].branch
                })
            }
            // const res1=await getnewslist({
            //     docterId,
            //     consultationType:'3',
            //     consultationStu:'-1'
            // })
            // console.log(res1)
        }
    }

    // 入驻科室
    selectDepartment = async (e, type) => {
        const {yijidepartment, erjidepartment} = this.state
        switch (type) {
            case 'yijidepartment':
                if (!e.target.value) {
                    this.setState({
                        depId: '',//二级科室id
                        depId1: '',//一级科室id
                        erjidepartment: [],
                    })
                }
                yijidepartment.map(item => {
                    if (item.name === e.target.value) {
                        this.setState({
                            depId1: item.key
                        })
                        ejDepartment({depParentId: item.key}).then(res => {
                            if (res && res.data) {
                                if (res.data[0].length) {
                                    let list2 = res.data[0]
                                    list2 = list2.map(item0 => {
                                        return {
                                            key: item0.dep_id,
                                            name: item0.dep_name,
                                        }
                                    })
                                    this.setState({
                                        erjidepartment: list2
                                    }, () => {
                                        // console.log(erjidepartment);
                                    })
                                } else {
                                    this.setState({
                                        erjidepartment: []
                                    })
                                }
                            }
                        })
                    }
                })
                break;
            case 'erjidepartment':
                erjidepartment.map(item => {
                    if (item.name === e.target.value) {
                        this.setState({
                            depId: item.key,
                        }, () => {
                            // console.log(this.state.depId);
                        })
                    }
                })
                break;
            default:
                break;
        }
    }
    validateField = (field) => {
        const { invalidFields } = this.state;
        if (invalidFields.includes(field)) {
            const updatedInvalidFields = invalidFields.filter((item) => item !== field);
            this.setState(
                {
                    invalidFields: updatedInvalidFields
                },
                () => {
                    if (updatedInvalidFields.length === 0) {
                        // 如果已经没有错误字段，可以在这里执行一些操作
                    }
                }
            );
        }
    }

    changevalue = (e, type) => {
        const {validateField} = this.state
        switch (type) {
            case "name":
                this.setState({
                    name: e.target.value
                },() => {
                    if (type === "name") {
                        this.validateField("name");
                    }
                })
                break;
            case "id":
                if (e.target.value.length <= 18) {
                    this.setState({
                        id: e.target.value.replace(/[^a-zA-Z0-9]/g,'')
                    },() => {
                        if (type === "id") {
                            this.validateField("id");
                        }
                    })
                }
                break;
            case "hospital":
                this.setState({
                    hospital: e.target.value.replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, ""),
                },() => {
                    if (type === "hospital") {
                        this.validateField("hospital");
                    }
                })
                break;
            case "certificateNum":
                this.setState({
                    certificateNum: e.target.value.replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, "")
                },() => {
                    if (type === "certificateNum") {
                        this.validateField("certificateNum");
                    }
                })
                break;
            case "qualificationcertificateNum":
                this.setState({
                    qualificationcertificateNum: e.target.value.replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, "")
                },() => {
                    if (type === "qualificationcertificateNum") {
                        this.validateField("qualificationcertificateNum");
                    }
                })
                break;
            default:
                return
        }
    }
    poptop=()=>{
        let timer=setTimeout(()=>{
            this.setState({
                visible2:false
            },()=>{
                window.clearTimeout(timer)
            })
        },5000)
        this.setState({
            visible2:true
        })
    }
    // 点击上传
    uploaddata = async () => {
        const {
            evaluatePic,
            depId1,
            depId,
            docter,list,
            evaluatePic2,
            certificateNum,
            evaluatePic3,
            evaluatePic6,
            evaluatePic4,
            evaluatePic5,
            name,
            id,
            hospital,
            department1,
            qualificationcertificateNum,
            certificateTime,
            qualificationcertificateTime,
            title,
            docterId
        } = this.state
        const requiredFields = ["name", "id","hospital","depId","certificateNum"," title","qualificationcertificateNum","certificateTime","qualificationcertificateTime"];
        const missingFields = requiredFields.filter((field) => !this.state[field]);
        let p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;

        if (depId1 && evaluatePic.length && evaluatePic4.length && evaluatePic5.length&& evaluatePic6.length && name && id && hospital && depId && title && certificateNum&&
        qualificationcertificateNum&&certificateTime&&qualificationcertificateTime) {
            if (p.test(id)) {
                this.setState({
                    typea: 1
                })

                const res = await uploaddoctor({
                    docterId,
                    docterName: name,
                    idNumber: id,
                    docterHospital: hospital,
                    docterDepartment: department1,
                    branch: depId,
                    depId: depId1,
                    updatecode:list?1:'',
                    docterTitle: title,
                    docterNvq: evaluatePic6[0],
                    docterIdFront: evaluatePic4[0],
                    docterIdBack: evaluatePic5[0],
                    docterLicense: evaluatePic[0],
                    docterSpeciality: evaluatePic2[0],
                    docterOtherPic: evaluatePic3.length ? evaluatePic3[0] : "",
                    certificateNum,
                    qualificationcertificateNum,
                    certificateTime,
                    qualificationcertificateTime
                })
                if (res && res.data&&res.data[0]&&res.data[0]&&res.data[0].length) {
                    if (!list&&res.data[0][0].countall==0){
                        Toast.show({
                            content: "基本信息提交成功，等待管理员确认",
                            duration: 1000
                        })
                        this.props.history.replace("/")
                        this.props.history.push('/')
                    }else if(list&&res.data[1]&&res.data[1].length&&res.data[1][0].countall==0){
                        Toast.show({
                            content: "基本信息修改成功",
                            duration: 1000
                        })
                        this.props.history.replace("/")
                        this.props.history.push('/')
                    }else{
                        Toast.show({
                            content: "基本信息已提交，请勿重复提交",
                            duration: 1000
                        })
                        this.props.history.replace("/")
                        this.props.history.push('/')
                    }
                }
            } else {
                Toast.show({
                    content: "请填写正确的身份证号",
                    duration: 1000
                })
            }
        } else {
            if(!evaluatePic4.length){
            this.setState({
                pic4:true,
            
            })
        }
        
            if(!evaluatePic5.length){
            this.setState({
               
                pic0:true
            })
       }

            if(!evaluatePic6.length){
                this.setState({
                    pic2:true,
                })
            }
            if(!evaluatePic.length){
                this.setState({
                    pic3:true,
                })
            }
            if(!qualificationcertificateTime){
                this.setState({
                    pic5:true,
                })
            }
            if(!certificateTime){
                this.setState({
                    pic6:true,
                })
            }

            if(!title){
                this.setState({
                    pic1:true,
                })
            }
            if(!depId){
                this.setState({
                    pic:true,
                })
            }
            if (missingFields.length > 0) {
                this.setState({ invalidFields: missingFields }, () => {
                    this.useRef.current.scrollTop = 0;
                    Toast.show({
                        content: "请将信息填写完整",
                        duration: 1000,
                    });
                });
                return;
            }
        }
        }


    gobank = () => {
        window.history.back()
    }
    imgChange1 = async (files, type, index) => {
        const {fileList4,compressThreshold = 1} = this.state
        let imgList = []
        let pictureQuality = 0.5
        if (type === 'add') {
            let file = files[files.length - 1].file
            let fileSize = file.size / 1024 / 1024;
            if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/jpg") {
                //当开启图片压缩且图片大小大于等于压缩阈值,进行压缩
                if ((fileSize >= compressThreshold) ) {
                    if (fileSize*0.9<=1){
                        pictureQuality=0.9
                    }else if(fileSize*0.8<=1){
                        pictureQuality=0.8
                    }else if(fileSize*0.7<=1){
                        pictureQuality=0.7
                    }else if(fileSize*0.6<=1){
                        pictureQuality=0.6
                    }else if(fileSize*0.5<=1){
                        pictureQuality=0.5
                    }else if(fileSize*0.4<=1){
                        pictureQuality=0.4
                    }else if(fileSize*0.3<=1){
                        pictureQuality=0.3
                    }else if(fileSize*0.2<=1){
                        pictureQuality=0.2
                    }else if(fileSize*0.1<=1){
                        pictureQuality=0.1
                    }else{
                        pictureQuality=0.5
                    }
                    //判断浏览器内核是否支持base64图片压缩
                    if (typeof (FileReader) === 'undefined') {
                        return file;
                    } else {
                        try {
                            this.setState({
                                spinLoading: true
                            });
                            return new Promise(resolve => {
                                //压缩图片再上传
                                let a=getBase64Image(file,pictureQuality,callback1)
                                let that=this
                                function callback1(val) {
                                    //base64转png图片
                                    zipImg(val).then(res1 => {
                                        let formData = new FormData()
                                        formData.append('file', res1)
                                        uploadpic(formData).then(res2 => {
                                            if(res2.code === 2) {
                                                that.setState({
                                                    fileList4: files
                                                })
                                                imgList.push(res2.data)
                                            } else {
                                                Toast.info('上传失败', 1)
                                            }
                                            that.setState({
                                                evaluatePic4: imgList,
                                                pic4:false
                                            },()=>{
                                                // console.log(imgList);
                                            })
                                            // Toast.hide()
                                        }).catch(err => {
                                            // Toast.hide()
                                        })
                                    })
                                }
                            }).then(res => {
                                this.setState({
                                    spinLoading: false
                                });
                                // console.log(res)

                                // return res;
                            }).catch(() => {
                                this.setState({
                                    spinLoading: false
                                });
                                return file;
                            });
                        } catch (e) {
                            this.setState({
                                spinLoading: false
                            });
                            //压缩出错,直接返回原file对象
                            return file;
                        }
                    }
                }else{
                    zipImg(file).then(res => {
                        let formData = new FormData()
                        formData.append('file', res)
                        uploadpic(formData).then(res1 => {
                            if (res1.code === 2) {
                                this.setState({
                                    fileList4: files
                                })
                                imgList.push(res1.data)
                            } else {
                                Toast.info('上传失败', 1)
                            }
                            this.setState({
                                evaluatePic4: imgList,
                                pic4:false
                            })
                            // console.log(imgList);
                            // Toast.hide()
                        }).catch(err => {
                            // Toast.hide()
                        })
                    })
                }
            } else {
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            imgList.splice(index, 1)
            this.setState({
                fileList4: files,
                evaluatePic4: imgList,
                pic4:false
            })
            // console.log(files);
            // Toast.hide()
        }
        // if (type == "add") {
        //     let file = files[files.length - 1].file
        //     console.log(file);
        //     if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
        //         if (file.size < 1024 * 1024) {
        //             let formData = new FormData()
        //             const res = await zipImg(file)
        //             formData.append('file', res)
        //             const res2 = await uploadpic(formData)
        //             console.log(res2);
        //             this.setState({
        //                 fileList4: [...files, res2.data]
        //             })
        //         }else{
        //             Toast.show('请上传小于1M的图片', 1)
        //         }
        //     }else{
        //         Toast.show('请上传png/jpg格式的图片', 1)
        //     }
        // } else {
        //     this.setState({
        //         fileList4: []
        //     })
        // }
    }
    imgChange2 = async (files, type, index) => {
        const {fileList5,compressThreshold = 1} = this.state
        let imgList = []
        let pictureQuality = 0.5
        if(type === 'add') {
            let file = files[files.length-1].file
            let fileSize = file.size / 1024 / 1024;
            // console.log(file);
            if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                if ((fileSize >= compressThreshold) ) {
                    if (fileSize*0.9<=1){
                        pictureQuality=0.9
                    }else if(fileSize*0.8<=1){
                        pictureQuality=0.8
                    }else if(fileSize*0.7<=1){
                        pictureQuality=0.7
                    }else if(fileSize*0.6<=1){
                        pictureQuality=0.6
                    }else if(fileSize*0.5<=1){
                        pictureQuality=0.5
                    }else if(fileSize*0.4<=1){
                        pictureQuality=0.4
                    }else if(fileSize*0.3<=1){
                        pictureQuality=0.3
                    }else if(fileSize*0.2<=1){
                        pictureQuality=0.2
                    }else if(fileSize*0.1<=1){
                        pictureQuality=0.1
                    }else{
                        pictureQuality=0.5
                    }
                    //判断浏览器内核是否支持base64图片压缩
                    if (typeof (FileReader) === 'undefined') {
                        return file;
                    } else {
                        try {
                            this.setState({
                                spinLoading: true
                            });
                            return new Promise(resolve => {
                                //压缩图片再上传
                                let a=getBase64Image(file,pictureQuality,callback1)
                                let that=this
                                function callback1(val) {
                                    //base64转png图片
                                    zipImg(val).then(res1 => {
                                        let formData = new FormData()
                                        formData.append('file', res1)
                                        uploadpic(formData).then(res2 => {
                                            if(res2.code === 2) {
                                                that.setState({
                                                    fileList5: files
                                                })
                                                imgList.push(res2.data)
                                            } else {
                                                Toast.info('上传失败', 1)
                                            }
                                            that.setState({
                                                evaluatePic5: imgList,
                                                pic0:false
                                            },()=>{
                                                // console.log(imgList);
                                            })
                                            // Toast.hide()
                                        }).catch(err => {
                                            // Toast.hide()
                                        })
                                    })
                                }
                            }).then(res => {
                                this.setState({
                                    spinLoading: false
                                });
                                // console.log(res)

                                // return res;
                            }).catch(() => {
                                this.setState({
                                    spinLoading: false
                                });
                                return file;
                            });
                        } catch (e) {
                            this.setState({
                                spinLoading: false
                            });
                            //压缩出错,直接返回原file对象
                            return file;
                        }
                    }
                }else{
                    zipImg(file).then(res => {
                        let formData = new FormData()
                        formData.append('file', res)
                        uploadpic(formData).then(res1 => {
                            if (res1.code === 2) {
                                this.setState({
                                    fileList5: files
                                })
                                imgList.push(res1.data)
                            } else {
                                Toast.info('上传失败', 1)
                            }
                            this.setState({
                                evaluatePic5: imgList,
                                pic0:false
                            })
                            // console.log(imgList);
                            // Toast.hide()
                        }).catch(err => {
                            // Toast.hide()
                        })
                    })
                }
            } else {
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            imgList.splice(index, 1)
            this.setState({
                fileList5: files,
                evaluatePic5: imgList,
                pic0:false
            })
            // console.log(files);
            // Toast.hide()
        }
        // if (type == "add") {
        //     let file = files[files.length - 1].file
        //     if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
        //         if (file.size < 1024 * 1024) {
        //             let formData = new FormData()
        //             const res = await zipImg(file)
        //             formData.append('file', res)
        //             const res2 = await uploadpic(formData)
        //             this.setState({
        //                 fileList5: [...files, res2.data]
        //             })
        //         }else{
        //             Toast.show('请上传小于1M的图片', 1)
        //         }
        //     }else{
        //         Toast.show('请上传png/jpg格式的图片', 1)
        //     }
        // } else {
        //     this.setState({
        //         fileList5: []
        //     })
        // }
    }
    imgChange3 = async (files, type, index) => {
        const {fileList,compressThreshold = 1} = this.state
        let imgList = []
        let pictureQuality = 0.5
        if(type === 'add') {
            let file = files[files.length-1].file
            let fileSize = file.size / 1024 / 1024;
            // console.log(file);
            if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                if ((fileSize >= compressThreshold) ) {
                    if (fileSize*0.9<=1){
                        pictureQuality=0.9
                    }else if(fileSize*0.8<=1){
                        pictureQuality=0.8
                    }else if(fileSize*0.7<=1){
                        pictureQuality=0.7
                    }else if(fileSize*0.6<=1){
                        pictureQuality=0.6
                    }else if(fileSize*0.5<=1){
                        pictureQuality=0.5
                    }else if(fileSize*0.4<=1){
                        pictureQuality=0.4
                    }else if(fileSize*0.3<=1){
                        pictureQuality=0.3
                    }else if(fileSize*0.2<=1){
                        pictureQuality=0.2
                    }else if(fileSize*0.1<=1){
                        pictureQuality=0.1
                    }else{
                        pictureQuality=0.5
                    }
                    // console.log(pictureQuality)
                    //判断浏览器内核是否支持base64图片压缩
                    if (typeof (FileReader) === 'undefined') {
                        return file;
                    } else {
                        try {
                            this.setState({
                                spinLoading: true
                            });
                            return new Promise(resolve => {
                                //压缩图片再上传
                                let a=getBase64Image(file,pictureQuality,callback1)
                                let that=this
                                function callback1(val) {
                                    //base64转png图片
                                    zipImg(val).then(res1 => {
                                        let formData = new FormData()
                                        formData.append('file', res1)
                                        uploadpic(formData).then(res2 => {
                                            if(res2.code === 2) {
                                                that.setState({
                                                    fileList: files
                                                })
                                                imgList.push(res2.data)
                                            } else {
                                                Toast.info('上传失败', 1)
                                            }
                                            that.setState({
                                                evaluatePic: imgList,
                                                pic3:false
                                            },()=>{
                                                // console.log(imgList);
                                            })
                                            // Toast.hide()
                                        }).catch(err => {
                                            // Toast.hide()
                                        })
                                    })
                                }
                            }).then(res => {
                                this.setState({
                                    spinLoading: false
                                });
                            }).catch(() => {
                                this.setState({
                                    spinLoading: false
                                });
                                return file;
                            });
                        } catch (e) {
                            this.setState({
                                spinLoading: false
                            });
                            //压缩出错,直接返回原file对象
                            return file;
                        }
                    }
                }else{
                    zipImg(file).then(res => {
                        let formData = new FormData()
                        formData.append('file', res)
                        uploadpic(formData).then(res1 => {
                            if (res1.code === 2) {
                                this.setState({
                                    fileList: files
                                })
                                imgList.push(res1.data)
                            } else {
                                Toast.info('上传失败', 1)
                            }
                            this.setState({
                                evaluatePic: imgList,
                                pic3:false

                            })
                        }).catch(err => {
                            // Toast.hide()
                        })
                    })
                }
            } else {
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            imgList.splice(index, 1)
            this.setState({
                fileList: files,
                evaluatePic: imgList,
                pic3:false
            })
        }
    }
    imgChange4 = async (files, type, index) => {
        const {fileList5,compressThreshold = 1} = this.state
        let imgList = []
        let pictureQuality = 0.5
        if(type === 'add') {
            let file = files[files.length-1].file
            let fileSize = file.size / 1024 / 1024;
            // console.log(file);
            if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                if ((fileSize >= compressThreshold) ) {
                    if (fileSize*0.9<=1){
                        pictureQuality=0.9
                    }else if(fileSize*0.8<=1){
                        pictureQuality=0.8
                    }else if(fileSize*0.7<=1){
                        pictureQuality=0.7
                    }else if(fileSize*0.6<=1){
                        pictureQuality=0.6
                    }else if(fileSize*0.5<=1){
                        pictureQuality=0.5
                    }else if(fileSize*0.4<=1){
                        pictureQuality=0.4
                    }else if(fileSize*0.3<=1){
                        pictureQuality=0.3
                    }else if(fileSize*0.2<=1){
                        pictureQuality=0.2
                    }else if(fileSize*0.1<=1){
                        pictureQuality=0.1
                    }else{
                        pictureQuality=0.5
                    }
                    //判断浏览器内核是否支持base64图片压缩
                    if (typeof (FileReader) === 'undefined') {
                        return file;
                    } else {
                        try {
                            this.setState({
                                spinLoading: true
                            });
                            return new Promise(resolve => {
                                //压缩图片再上传
                                let a=getBase64Image(file,pictureQuality,callback1)
                                let that=this
                                function callback1(val) {
                                    //base64转png图片
                                    zipImg(val).then(res1 => {
                                        let formData = new FormData()
                                        formData.append('file', res1)
                                        uploadpic(formData).then(res2 => {
                                            if(res2.code === 2) {
                                                that.setState({
                                                    fileList2: files
                                                })
                                                imgList.push(res2.data)
                                            } else {
                                                Toast.info('上传失败', 1)
                                            }
                                            that.setState({
                                                evaluatePic2: imgList
                                            },()=>{
                                                // console.log(imgList);
                                            })
                                            // Toast.hide()
                                        }).catch(err => {
                                            // Toast.hide()
                                        })
                                    })
                                }
                            }).then(res => {
                                this.setState({
                                    spinLoading: false
                                });

                            }).catch(() => {
                                this.setState({
                                    spinLoading: false
                                });
                                return file;
                            });
                        } catch (e) {
                            this.setState({
                                spinLoading: false
                            });
                            //压缩出错,直接返回原file对象
                            return file;
                        }
                    }
                }else{
                    zipImg(file).then(res => {
                        let formData = new FormData()
                        formData.append('file', res)
                        uploadpic(formData).then(res1 => {
                            if (res1.code === 2) {
                                this.setState({
                                    fileList2: files
                                })
                                imgList.push(res1.data)
                            } else {
                                Toast.info('上传失败', 1)
                            }
                            this.setState({
                                evaluatePic2: imgList
                            })
                        }).catch(err => {
                            // Toast.hide()
                        })
                    })
                }
            } else {
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            imgList.splice(index, 1)
            this.setState({
                fileList2: files,
                evaluatePic2: imgList
            })
        }
    }
    imgChange5 = async (files, type, index) => {
        const {fileList3,compressThreshold = 1} = this.state
        let imgList = []
        let pictureQuality = 0.5
        if(type === 'add') {
            let file = files[files.length-1].file
            let fileSize = file.size / 1024 / 1024;
            // console.log(file);
            if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                if ((fileSize >= compressThreshold) ) {
                    if (fileSize*0.9<=1){
                        pictureQuality=0.9
                    }else if(fileSize*0.8<=1){
                        pictureQuality=0.8
                    }else if(fileSize*0.7<=1){
                        pictureQuality=0.7
                    }else if(fileSize*0.6<=1){
                        pictureQuality=0.6
                    }else if(fileSize*0.5<=1){
                        pictureQuality=0.5
                    }else if(fileSize*0.4<=1){
                        pictureQuality=0.4
                    }else if(fileSize*0.3<=1){
                        pictureQuality=0.3
                    }else if(fileSize*0.2<=1){
                        pictureQuality=0.2
                    }else if(fileSize*0.1<=1){
                        pictureQuality=0.1
                    }else{
                        pictureQuality=0.5
                    }
                    //判断浏览器内核是否支持base64图片压缩
                    if (typeof (FileReader) === 'undefined') {
                        return file;
                    } else {
                        try {
                            this.setState({
                                spinLoading: true
                            });
                            return new Promise(resolve => {
                                //压缩图片再上传
                                let a=getBase64Image(file,pictureQuality,callback1)
                                let that=this
                                function callback1(val) {
                                    //base64转png图片
                                    zipImg(val).then(res1 => {
                                        let formData = new FormData()
                                        formData.append('file', res1)
                                        uploadpic(formData).then(res2 => {
                                            if(res2.code === 2) {
                                                that.setState({
                                                    fileList3: files
                                                })
                                                imgList.push(res2.data)
                                            } else {
                                                Toast.info('上传失败', 1)
                                            }
                                            that.setState({
                                                evaluatePic3: imgList
                                            },()=>{
                                                // console.log(imgList);
                                            })
                                            // Toast.hide()
                                        }).catch(err => {
                                            // Toast.hide()
                                        })
                                    })
                                }
                            }).then(res => {
                                this.setState({
                                    spinLoading: false
                                });
                            }).catch(() => {
                                this.setState({
                                    spinLoading: false
                                });
                                return file;
                            });
                        } catch (e) {
                            this.setState({
                                spinLoading: false
                            });
                            //压缩出错,直接返回原file对象
                            return file;
                        }
                    }
                }else{
                    zipImg(file).then(res => {
                        let formData = new FormData()
                        formData.append('file', res)
                        uploadpic(formData).then(res1 => {
                            if (res1.code === 2) {
                                this.setState({
                                    fileList3: files
                                })
                                imgList.push(res1.data)
                            } else {
                                Toast.info('上传失败', 1)
                            }
                            this.setState({
                                evaluatePic3: imgList
                            })
                        }).catch(err => {
                            // Toast.hide()
                        })
                    })
                }
            } else {
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            imgList.splice(index, 1)
            this.setState({
                fileList3: files,
                evaluatePic3: imgList
            })
        }
    }
    //资格证书
    imgChange6 = async (files, type, index) => {
        const {fileList,compressThreshold = 1} = this.state
        let imgList = []
        let pictureQuality = 0.5
        if(type === 'add') {
            let file = files[files.length-1].file
            let fileSize = file.size / 1024 / 1024;
            // console.log(file);
            if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                if ((fileSize >= compressThreshold) ) {
                    if (fileSize*0.9<=1){
                        pictureQuality=0.9
                    }else if(fileSize*0.8<=1){
                        pictureQuality=0.8
                    }else if(fileSize*0.7<=1){
                        pictureQuality=0.7
                    }else if(fileSize*0.6<=1){
                        pictureQuality=0.6
                    }else if(fileSize*0.5<=1){
                        pictureQuality=0.5
                    }else if(fileSize*0.4<=1){
                        pictureQuality=0.4
                    }else if(fileSize*0.3<=1){
                        pictureQuality=0.3
                    }else if(fileSize*0.2<=1){
                        pictureQuality=0.2
                    }else if(fileSize*0.1<=1){
                        pictureQuality=0.1
                    }else{
                        pictureQuality=0.5
                    }
                    // console.log(pictureQuality)
                    //判断浏览器内核是否支持base64图片压缩
                    if (typeof (FileReader) === 'undefined') {
                        return file;
                    } else {
                        try {
                            this.setState({
                                spinLoading: true
                            });
                            return new Promise(resolve => {
                                //压缩图片再上传
                                let a=getBase64Image(file,pictureQuality,callback1)
                                let that=this
                                function callback1(val) {
                                    //base64转png图片
                                    zipImg(val).then(res1 => {
                                        let formData = new FormData()
                                        formData.append('file', res1)
                                        uploadpic(formData).then(res2 => {
                                            if(res2.code === 2) {
                                                that.setState({
                                                    fileList6: files
                                                })
                                                imgList.push(res2.data)
                                            } else {
                                                Toast.info('上传失败', 1)
                                            }
                                            that.setState({
                                                evaluatePic6: imgList,
                                                pic2:false
                                            },()=>{
                                                // console.log(imgList);
                                            })
                                            // Toast.hide()
                                        }).catch(err => {
                                            // Toast.hide()
                                        })
                                    })
                                }
                            }).then(res => {
                                this.setState({
                                    spinLoading: false
                                });
                            }).catch(() => {
                                this.setState({
                                    spinLoading: false
                                });
                                return file;
                            });
                        } catch (e) {
                            this.setState({
                                spinLoading: false
                            });
                            //压缩出错,直接返回原file对象
                            return file;
                        }
                    }
                }else{
                    zipImg(file).then(res => {
                        let formData = new FormData()
                        formData.append('file', res)
                        uploadpic(formData).then(res1 => {
                            if (res1.code === 2) {
                                this.setState({
                                    fileList6: files
                                })
                                imgList.push(res1.data)
                            } else {
                                Toast.info('上传失败', 1)
                            }
                            this.setState({
                                evaluatePic6: imgList,
                                pic2:false
                            })
                        }).catch(err => {
                            // Toast.hide()
                        })
                    })
                }
            } else {
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            imgList.splice(index, 1)
            this.setState({
                fileList6: files,
                evaluatePic6: imgList,
                pic2:false
            })
        }
    }
    changeselect = (val) => {
        this.setState({
            title: val[1]
        })
    }
    chioseone = (val) => {
        this.setState({
            selectnow: val[0]
        })
    }
    onscroll = (e) => {
        const { flag } = this.state;
        e.preventDefault();
        e.stopPropagation();
        this.setState({ flag });
        if (
            e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <
            5
        ) {
        }
    };

    render() {
        const {
            invalidFields,
            fileList,
            typea,
            fileList2,evaluatePic4,
            fileList3,
            fileList4,
            fileList5,
            fileList6,
            name,
            id,
            hospital,
            department1,
            department2,visible2,visible1,list,evaluatePic5,evaluatePic,evaluatePic3,evaluatePic6,
            certificateNum,
            title,
            visible,
            value,
            basicColumns,visible4,visible5,
            selectnow,
            basicColumns2,
            yijidepartment,
            erjidepartment,qualificationcertificateNum,certificateTime,qualificationcertificateTime,
            erjiname,
            pic5,
            pic6,
            pic4,
            pic3,
            pic2,
            pic1,
            pic,
            pic0
        } = this.state
        const isInvalidName = invalidFields.includes("name");
        const isInvalidId = invalidFields.includes("id");
        const isInvalidHospital = invalidFields.includes("hospital");
        const isInvalidQualificationcertificateNum  = invalidFields.includes("qualificationcertificateNum");
        const isInvalidCertificateNum = invalidFields.includes("certificateNum");
        const now = new Date()
        const now1 = new Date('1950-01-01')
        return (
            <div className='uploadbox'
                 ref={this.useRef}
                 style={{ overflowY: 'scroll' }}
                 onScroll={this.onscroll}>
                {/*<div className='box-top'>*/}
                {/*    <img src={icon1} alt="" onClick={() => this.props.history.push('/')}/><h1>身份认证</h1>*/}
                {/*</div>*/}
                <div className='content-tips'>
                    <p>为了保证您能正常使用，请您务必填写真实信息</p>
                </div>
                <div className='upload-content'>
                    <p className='upload-content-title'>
                        <span className='yuan'></span>
                        <span>基本信息</span>
                    </p>
                    <div className={isInvalidName ? "invalid" : "content-item"}>
                        <p><span>*</span>姓名</p>
                        <input type="text" placeholder='请输入真实姓名' value={name}
                               onChange={(e) => this.changevalue(e, "name")}/>
                    </div>
                    <div className={isInvalidId ? "invalid" : "content-item"}>
                        <p><span>*</span>身份证</p>
                        <input type="text" placeholder='请输入身份证号' value={id}
                               onChange={(e) => this.changevalue(e, "id")}/>
                    </div>
                    <div className={isInvalidHospital? "invalid" : "content-item"} onClick={()=>this.poptop()}>
                        <p><span>*</span>第一执业医院</p>
                        <input type="text" placeholder='请输入您所在的第一执业医院' value={hospital} id='hosinput'
                               onChange={(e) => this.changevalue(e, "hospital")}/>
                    </div>
                    {/*<div className='content-item'>*/}
                    {/*    <p><span>*</span>执业资格证号</p>*/}
                    {/*    <input type="text" placeholder='请输入您的执业资格证号' value={certificateNum}*/}
                    {/*           onChange={(e) => this.changevalue(e, "certificateNum")}/>*/}
                    {/*</div>*/}
                    {/*<div className='content-item'>*/}
                    {/*    <p><span>*</span>执业科室</p>*/}
                    {/*    <input type="text" placeholder='请输入您所在的科室' value={department1}*/}
                    {/*           onChange={(e) => this.changevalue(e, "department1")}/>*/}
                    {/*</div>*/}
                    <div className={pic1 ? "invalid" : "content-item"}onClick={() => this.setState({
                        visible: true,
                    })}>
                        <p><span>*</span>职称</p>
                        <h2>
                            <span className={title?'choseks':'nochoseks'}>{title ? title : "请选择您的职称"}</span>
                            <span>＞</span>
                        </h2>

                    </div>

                    <Picker
                        columns={selectnow == "医生" ? basicColumns : basicColumns2}
                        visible={visible}
                        onClose={() => {
                            this.setState({
                                visible: false,
                                pic1:false
                            })
                        }}
                        value={value}
                        onConfirm={(val) => this.changeselect(val)}
                        onSelect={(val) => this.chioseone(val)}
                    ></Picker>

                    <div className={pic ? "invalid" : "content-item"} onClick={() => {
                        this.setState({
                            visible1: true,
                        })
                    }}>
                        <p><span>*</span>入驻科室</p>
                        <h2 className={erjiname?'choseks':'nochoseks'}>
                            <span>{erjiname?erjiname:'请选择入驻科室'}</span>
                            <span>＞</span>
                            {/* <input type="text" placeholder='请输入您在平台入驻的科室' value={department2} onChange={(e) => this.changevalue(e, "department2")} /> */}
                            {/*<select className="select1" onChange={(e) => this.selectDepartment(e, "yijidepartment")}>*/}
                            {/*    <option value="">选择一级科室</option>*/}
                            {/*    {yijidepartment.map(item => (*/}
                            {/*        <option value={item.name}>{item.name}</option>*/}
                            {/*    ))}*/}
                            {/*</select>*/}
                            {/*<select className="select1" onChange={(e) => this.selectDepartment(e, "erjidepartment")}>*/}
                            {/*    <option value="">选择二级科室</option>*/}
                            {/*    {erjidepartment.map(item => (*/}
                            {/*        <option value={item.name}>{item.name}</option>*/}
                            {/*    ))}*/}
                            {/*</select>*/}
                        </h2>
                    </div>
                    <CascadePicker
                        title='科室选择'
                        options={yijidepartment}
                        visible={visible1}
                        onClose={() => {
                            this.setState({
                                visible1: false,
                                pic:false
                            })
                        }}
                        onSelect={(val, extend) => {
                            yijidepartment.map(item => {
                                if (item.value == val[0]) {

                                    this.setState({
                                        depId1: item.key,
                                        department1:item.value
                                    },()=>{
                                        // if (item.children.length){
                                        //     item.children.map(i => {
                                        //         if (i.value == val[1]) {
                                        //             this.setState({
                                        //                 depId: i.key,
                                        //             })
                                        //         }
                                        //     })
                                        // }
                                        ejDepartment({depParentId: item.key}).then(res0 => {
                                            if (res0 && res0.data) {
                                                if (res0.data[0].length) {
                                                    let list2 = res0.data[0]
                                                    let arr1=[]
                                                    list2.map(item0 => {
                                                        if (item0.dep_name == val[1]) {
                                                            this.setState({
                                                                depId: item0.dep_id,
                                                            })
                                                        }
                                                        let params1={}
                                                        params1.key=item0.dep_id
                                                        params1.value=item0.dep_name
                                                        params1.label=item0.dep_name
                                                        arr1.push(params1)
                                                    })
                                                    item.children=arr1
                                                    this.setState({
                                                        yijidepartment: [...yijidepartment]
                                                    }, () => {
                                                        // console.log(erjidepartment);
                                                    })
                                                }
                                            }
                                        })
                                    })

                                }
                            })
                            // this.setState({
                            //     yijiname: val[0],
                            //     erjiname: val[1],
                            // })
                        }}
                        onConfirm={(val, extend) => {
                            this.setState({
                                yijiname: val[0],
                                erjiname: val[1],
                            })
                        }}

                        />

                </div>
                {visible2?<p className='popover-zyhos'>
                    <span>请输入您所在的第一执业医院（例如：华西医院）</span>
                    <i className='iconfont icon-xiala'/>
                </p>:null}
                <div className='box-content'>
                    <p className='upload-content-title'>
                        <span className='yuan'></span>
                        <span>资料信息</span>
                    </p>
                    <div className='upload-pic'>
                        <div className='pic-item'>
                            <p className={pic0||pic4?"pic-a":"pic-b"}><span>*</span>上传身份证正反面</p>
                            <div className='item-img'>
                                <ImagePicker
                                    src={evaluatePic4[0]}
                                    mode='aspectFit'
                                    files={fileList4}
                                    length={1}
                                    style={{
                                        // width: "100px",
                                        // height: "100px",
                                        display: "inline-block",
                                        verticalAlign: "top"
                                    }}

                                    onChange={this.imgChange1}
                                    selectable={fileList4.length < 1}
                                    accept='image/gif,image/jpeg,image/jpg,image/png'/>
                                {list?<img src={evaluatePic4} style={{width:'138px',height:'93px',marginLeft:'-152px',marginTop:'11px'}} alt="" />:null}
                                <ImagePicker
                                    files={fileList5}
                                    length={1}
                                    style={{
                                        // width: "100px",
                                        // height: "100px",
                                        display: "inline-block",
                                        verticalAlign: "top"
                                    }}
                                    onChange={this.imgChange2}
                                    selectable={fileList5.length < 1}
                                    accept='image/gif,image/jpeg,image/jpg,image/png'/>
                                {list?<img src={evaluatePic5} style={{width:'138px',height:'93px',marginLeft:'-152px',marginTop:'11px'}} alt="" />:null}
                            </div>
                        </div>
                        <div className='pic-item'>
                            <p className={isInvalidQualificationcertificateNum? "all-zyzgzs-invalid" : "all-zyzgzs"}>
                                <p><span>*</span>{selectnow == "护士"?'护士':'医师'}资格证书：</p>
                                <p>上传完整资格证书</p>
                            </p>
                            <div className={isInvalidQualificationcertificateNum  ? "certificate-item-inva" : "certificate-item"}>
                                <input type="text" placeholder='请输入资格证书编号' value={qualificationcertificateNum}
                                       onChange={(e) => this.changevalue(e, "qualificationcertificateNum")}/>
                            </div>
                            <div className='qc-time'>
                               <span className={ pic2 ? "qc-time-tips1" : "qc-time-tips"}>资格证书发证日期：</span>
                               <span className={pic5 ? "qc-time-select1" : "qc-time-select"}  onClick={()=>this.setState({
                                   visible4:true
                               })}>
                                   <span className={qualificationcertificateTime?'time time1':'time'}>{qualificationcertificateTime?qualificationcertificateTime:'选择日期'}</span>
                                   <span className='dy'>＞</span>
                               </span>
                            </div>
                            <div className='item-img'>
                                <ImagePicker
                                    files={fileList6}
                                    length={1}
                                    style={{
                                        // width: "100px",
                                        // height: "100px",
                                        display: "inline-block",
                                        verticalAlign: "top"
                                    }}
                                    onChange={this.imgChange6}
                                    selectable={fileList6.length < 1}
                                    accept='image/gif,image/jpeg,image/jpg,image/png'/>
                                {list?<img src={evaluatePic6} style={{width:'138px',height:'93px',marginLeft:'-152px',marginTop:'11px'}} alt="" />:null}
                            </div>
                        </div>
                        <div className='pic-item'>
                            <p className={isInvalidCertificateNum ? "all-zyzgzs-invalid" : "all-zyzgzs"}>
                                <p><span>*</span>{selectnow == "护士"?'护士':'医师'}执业证书：</p>
                                <p>上传完整执业证书</p>
                            </p>
                            <div className={isInvalidCertificateNum ? "certificate-item-inva" : "certificate-item"}>
                                <input type="text" placeholder='请输入执业证书编号' value={certificateNum}
                                       onChange={(e) => this.changevalue(e, "certificateNum")}/>
                            </div>
                            <div className='qc-time'>
                                <span className={ pic3 ? "qc-time-tips1" : "qc-time-tips"}>执业证书发证日期：</span>
                                <span className={pic6 ? "qc-time-select1" : "qc-time-select"} onClick={()=>this.setState({
                                    visible5:true
                                })}>
                                   <span className={certificateTime?'time time1':'time'}>{certificateTime?certificateTime:'选择日期'}</span>
                                   <span className='dy'>＞</span>
                               </span>
                            </div>
                            <div className='item-img'>
                                <ImagePicker
                                    files={fileList}
                                    length={1}
                                    style={{
                                        // width: "100px",
                                        // height: "100px",
                                        display: "inline-block",
                                        verticalAlign: "top"
                                    }}
                                    onChange={this.imgChange3}
                                    selectable={fileList.length < 1}
                                    accept='image/gif,image/jpeg,image/jpg,image/png'/>
                                {list?<img src={evaluatePic} style={{width:'138px',height:'93px',marginLeft:'-152px',marginTop:'11px'}} alt="" />:null}
                            </div>
                        </div>
                        {/*<div className='pic-item'>*/}
                        {/*    /!*<p>职称证明 <span>{title == "主任医师" || title == "副主任医师" || title == "主治医师"?"*":null}</span></p>*!/*/}
                        {/*    <p>职称证明 <span>*</span></p>*/}
                        {/*    <p>专业技术资格证书或医院聘书</p>*/}
                        {/*    <div className='item-img'>*/}
                        {/*        <ImagePicker*/}
                        {/*            files={fileList2}*/}
                        {/*            length={1}*/}
                        {/*            style={{ width: "100px", height: "100px", display: "inline-block", verticalAlign: "top" }}*/}
                        {/*            onChange={this.imgChange4}*/}
                        {/*            selectable={fileList2.length < 1}*/}
                        {/*            accept='image/gif,image/jpeg,image/jpg,image/png' />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className='pic-item'>
                            <p className='all-zyzgzs'>
                                <p>其他证明(非必填)：</p>
                                <p>胸牌/聘用证书/医院公示红头文件等</p>
                            </p>
                            <div className='item-img'>
                                <ImagePicker
                                    files={fileList3}
                                    length={1}
                                    style={{
                                        // width: "100px",
                                        // height: "100px",
                                        display: "inline-block",
                                        verticalAlign: "top"
                                    }}
                                    onChange={this.imgChange5}
                                    selectable={fileList3.length < 1}
                                    accept='image/gif,image/jpeg,image/jpg,image/png'/>
                                {list&&evaluatePic3.length?<img src={evaluatePic3} style={{width:'138px',height:'93px',marginLeft:'-152px',marginTop:'11px'}} alt="" />:null}
                            </div>
                        </div>
                    </div>
                    <div className='content-btn'>
                        <button onClick={this.uploaddata}>提交</button>
                    </div>
                </div>
                {typea ? <div className='chat-center-outer'>
                    <SpinLoading/>
                    {/*<p>loading...</p>*/}
                </div> : null}
                <DatePicker
                    // title='时间选择'
                    visible={visible4}
                    onClose={() => {
                        this.setState({
                            visible4: false,
                        })
                    }}
                    defaultValue={now}
                    max={now}
                    min={now1}
                    precision='day'
                    onConfirm={value => {
                        const num2 = new Date(now).getFullYear()+'-' +((new Date(now).getMonth() + 1) > 9 ? (new Date(now).getMonth() + 1) : '0' + (new Date(now).getMonth() + 1))
                        // if (time20) {
                        const num = new Date(value).getFullYear() +'-'+ ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                        this.setState({
                            qualificationcertificateTime: new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                                + '-' + (new Date(value).getDate()> 9 ? new Date(value).getDate() : '0' + new Date(value).getDate())
                        })
                    }}
                />
                <DatePicker
                    // title='时间选择'
                    visible={visible5}
                    onClose={() => {
                        this.setState({
                            visible5: false,
                        })
                    }}
                    defaultValue={now}
                    max={now}
                    min={now1}
                    precision='day'
                    onConfirm={value => {
                        const num2 = new Date(now).getFullYear()+'-' +((new Date(now).getMonth() + 1) > 9 ? (new Date(now).getMonth() + 1) : '0' + (new Date(now).getMonth() + 1))
                        // if (time20) {
                        const num = new Date(value).getFullYear() +'-'+ ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                        this.setState({
                            certificateTime: new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                                + '-' + (new Date(value).getDate()> 9 ? new Date(value).getDate() : '0' + new Date(value).getDate())
                        })
                    }}
                />
            </div>
        )
    }
}
