import axios from 'axios'
import produce from 'immer'

import { loginRequest, accountsignRequest, codesignRequest } from '../api/request/user'

const AUTH_SUCCESS = 'AUTH_SUCCESS'//账号登录成功并跳转
const ERROR_MSG = 'ERROR_MSG'
const RESET_MSG = 'RESET_MSG'
const LOAD_DATA = 'LOAD_DATA'
const SET_GUIDED = 'SET_GUIDED'//完成引导页
const CODE_SUCCESS = 'CODE_SUCCESS'
const REDIRECTTO = 'REDIRECTTO'//重新定向
const initState={
	redirectTo:'',
	prepath:'',
	msg:'',
	userAcount: '',
	userName:'',
	userId: 0,
	userAvatar: '',
	userIntegral: '',
	userVip: '',
	phone:'',
	address: '',
	token: '',
	isguided:true
}
export const user=produce((draft=initState,action)=> {
    switch(action.type){
		case AUTH_SUCCESS:
			const data = action.payload.data[0][0]
			draft.userAcount=data.userAcount
			draft.userName=data.userName
			draft.userId=data.userId
			draft.userAvatar=data.userAvatar
			draft.userIntegral=data.userIntegral
			draft.userVip=data.userVip
			draft.token=action.payload.token
			draft.msg=''
			draft.redirectTo=draft.prepath
			localStorage.userAcount=data.userAcount
			localStorage.userName=data.userName
			localStorage.userId=data.userId
			localStorage.userAvatar=data.userAvatar
			localStorage.userIntegral=data.userIntegral
			localStorage.userVip=data.userVip
			localStorage.address=data.province+data.city+data.district+data.address
			localStorage.token=action.payload.token
			return
		case LOAD_DATA:
			return{...action.payload}
		case ERROR_MSG:
			draft.msg=action.msg
			draft.isAuth=false
			return
		case RESET_MSG:
			draft.msg=action.msg
			return
		case SET_GUIDED:
			draft.redirectTo=action.path
			draft.isguided=true
			return
		case CODE_SUCCESS:
			draft.msg=''
			draft.redirectTo=draft.prepath
			draft.phone=action.phone
			return
		case REDIRECTTO:
			draft.prepath=action.path
			return
        default:
            return draft;
    }
})


function authSuccess(obj){
	return {type: AUTH_SUCCESS, payload:obj}
}

// function codeSuccess(phone){
// 	return {type: CODE_SUCCESS, phone}
// }

function errorMsg(msg){
	return { msg, type:ERROR_MSG }
}

function guideredirect(path){
	return { path, type:SET_GUIDED }
}

function sendMsg(msg){
	return { msg, type:RESET_MSG }
}

export function resetdirect(path){
	return dispatch=>{
		dispatch({path,type:REDIRECTTO })
	}
}

export function resetmsg(msg){
	return dispatch=>{
		dispatch(sendMsg(msg))
	}
}

export function guideComplete({env, pharmacyId}){
	return dispatch=>{
		if(env === 'single') {
			dispatch(guideredirect(`/?env=${env}&pharmacyId=${pharmacyId}`))
		} else {
			dispatch(guideredirect('/'))
		}
	}
}

export function loadData(userinfo){
	return { type:LOAD_DATA, payload:userinfo}
}

export function update(data){
	return dispatch=>{
		axios.post('/user/update',data)
			.then(res=>{
				if (res.status===200&&res.data.code===0) {
					dispatch(authSuccess(res.data.data))
				}else{
					dispatch(errorMsg(res.data.msg))
				}
			})
	}
}
export function login({user,pwd,acountType}, data=null){
	if (!user||!pwd) {
		return errorMsg('用户名密码不能为空')
	}
	return dispatch=>{
		if(data) {
			dispatch(authSuccess(data))
		} else {
			loginRequest({userAcount: user, userPwd: pwd, acountType:acountType?acountType:1}).then(res => {
				if(res.data[0].length === 0) {
					dispatch(errorMsg('用户名或密码不正确'))
				} else {
					if(acountType&&acountType===2&&res.data[0][0].acountType=='1'){
						dispatch(errorMsg('账号有误，仅接受采购用户登录'))
					}else{
						dispatch(authSuccess(res))
					}
				}
			})
		}
	}
}

export function register({user,pwd}){
	if (!user||!pwd) {
		return errorMsg('用户名或密码不能为空')
	}
	return dispatch=>{
		accountsignRequest({userAcount: user, userPwd: pwd}).then(res => {
			if(res.code === 1) {
				loginRequest({userAcount: user, userPwd: pwd}).then (res1 => {
					dispatch(authSuccess(res1))
				})
			} else {
				return errorMsg('注册失败')
			}
		})
	}

}

export function phonetest({phone}){
	if(!(/^1[3456789]\d{9}$/.test(phone))){
		return errorMsg('手机号码有误，请重填')
	}
	else{
		// console.log(phone)
		return errorMsg('')
	}
}

export function codetest({type,code,phone,pwd}){
	return dispatch=>{
		if(type === 1) {
			codesignRequest({userAcount: phone, smsCode: code}).then(res => {
				if(res.code === 1) {
					if(res.data[0].length === 0) {
						dispatch(errorMsg('验证码不正确'))
					} else {
						dispatch(authSuccess(res))
					}
				}
			})
		} else {
			accountsignRequest({userAcount: phone, userPwd: pwd}).then(res => {
				if(res.code === 1) {
					loginRequest({userAcount: phone, userPwd: pwd}).then (res1 => {
						dispatch(authSuccess(res1))
					})
				} else {
					return errorMsg('注册失败')
				}
			})
		}
	}
}
