
import React, { Component } from 'react'
import { Toast, List, InputItem, Checkbox } from "antd-mobile"

import "./retrieve.less"
import { withRouter } from "react-router-dom";
import back from "../../static/img/login/fanhui.png";
import { encapsulationOperationlog } from "../../api/config";
import { storage } from "./userStorage";
import { getlocode, updatesxVerify, verifyRequest, changePwdRequest, changePwdOwnRequest,loginDuty, getLoginInfo,inspectonly } from "../../api/hospitalapi/login";
// import { List, InputItem } from 'antd-mobile';
import md5 from 'js-md5';
export default class retrieve extends Component {
    constructor() {
        super();
        this.state = {
            phone: '',
            value: "",
            newvalue: "",
            hasError: false,
            hasError2: false,
            pharmacyId: localStorage.getItem("pharmacyId") == "25" ? "0" : localStorage.getItem("pharmacyId"),
            pswd2: '',
            pswd1: '',
            yzm1: '',
            lable: false,
            time: 180,
            fatherId: '',
            eye: 1,
            eye1: 1,
            isFormComplete: false,
            showClearIcon: false,
            showClearIcon2: false,
            valid: true,//获取验证码状态位
            disabled: false,//180s获取验证码一次
        }
    }

    loginwaycode = null;
    phonenumber = null;
    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);
        this.loginwaycode = urlParams.get('loginwaycode');
        this.number = urlParams.get('number');
    }

    // 返回上一个界面
    back = () => {
        window.history.back()
    }
    updateFrom1 = (e, v) => {
        const { pswd1, pswd2 } = this.state
        switch (v) {
            case 'phone':
                this.setState({
                    phone: e.target.value.replace(/\s*/g, "")
                }, () => {
                    this.checkFormComplete();
                });
                break;
            case 'yzm1':
                this.setState({
                    yzm1: e.target.value.replace(/\s*/g, ""),
                    pswd1: pswd2 ? pswd2 : ''
                }, () => {
                    this.checkFormComplete();
                });
                break;
            case 'pswd1':
                // let input = document.getElementById("input1");
                // input.type='password'

                this.setState({
                    pswd1: e.target.value.replace(/\s*/g, ""),
                    showClearIcon: !!e.target.value.replace(/\s*/g, "")
                }, () => {
                    localStorage.setItem('pswd1', pswd1);
                    this.checkFormComplete();
                })
                break;
            case 'pswd2':
                // let input1 = document.getElementById("input2");
                // input1.type='password'
                this.setState({
                    pswd2: e.target.value.replace(/\s*/g, ""),
                    showClearIcon2: e.target.value.replace(/\s*/g, "")
                }, () => {
                    this.checkFormComplete();
                });
                break;
        }
    }

    checkFormComplete() {
        const { phone, yzm1, pswd1, pswd2 } = this.state;
        const isFormComplete = phone.trim() !== "" && yzm1.trim() !== "" && pswd1.trim() !== "" && pswd2.trim() !== "";
        this.setState({ isFormComplete });
    }

    //获取验证码
    getcode = async () => {
        const { phone, lable, time } = this.state
        const reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        if (phone) {
            if (reg_tel.test(phone)) {
                if (lable) {
                    this.setState({
                        type: 1
                    })
                    Toast.show(`请${time}秒后点击获取`)
                } else {
                    // 更新失效验证码状态
                    updatesxVerify(phone).then(res0 => {
                        if (res0.code == 1) {
                            //获取登录验证码
                            getlocode({
                                userPhone: phone
                            }).then(res => {
                                if (res.code == 1) {
                                    this.setState({
                                        lable: true,
                                        time: 180
                                        // time: 1800
                                    }, () => {
                                        let code = this.state.time
                                        const timer = setInterval(() => {
                                            code -= 1
                                            this.setState({
                                                time: code,
                                            })
                                            if (code == 0) {
                                                clearInterval(timer)
                                                this.setState({
                                                    lable: false,
                                                    type: 0
                                                })
                                            }
                                        }, 1000);
                                    })
                                } else {
                                    this.setState({
                                        lable: false,
                                        type: 0
                                    })
                                    Toast.show("您验证码发送太频繁，请稍后再试")
                                }
                            })
                        }
                    })
                }
            } else {
                Toast.show("请输入正确的手机号")
                this.setState({
                    type: 0
                })
            }
            // }

        } else {
            Toast.show("请输入手机号")
            this.setState({
                type: 0
            })
        }
    }

    //获取验证码180s一次
    handleClick = () => {
        if (!this.state.disabled) {
            this.getcode();
            this.setState({ disabled: true });
            setTimeout(() => {
                this.setState({ disabled: false });
            }, 180000);
        }
    }

    //查询医生是否启用h5密码
    // getIsEnablePwd = async (doctorPhone) => {
    //     let res = await getLoginInfo(doctorPhone)
    //     return res.data[0][0].isEnablePwd
    // }
      getIsEnablePwd = async (doctorPhone) => {
        let res = await inspectonly(doctorPhone)
        let result =res.data[0][0].isEnablePwd
        return result
    }
    


    //修改密码
    toNext = async () => {
        const { type, checkcode, passwordcode, pswd1, pswd2, yzm1, phone, lable, time, pharmacyId } = this.state
        const loginwaycode = this.loginwaycode;
        const number = this.number;
        if (time < 180 && time > 0) {
            let res1 = await verifyRequest(phone, yzm1)
            if (res1.code == 1 && res1.data[0].length) {

                if (!(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(phone))) {
                    Toast.info('手机号码有误，请重填')
                    return
                } else {
                    let onepwd = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z0-9\s])[a-zA-Z\d\W]{8,16}$/;
                    if (!pswd1) {
                        Toast.fail("请输入密码")
                    } else {
                        localStorage.setItem('pswd1', pswd1)
                        if (onepwd.test(pswd1)) {
                            if (pswd2) {
                                // if (onepwd.test(pswd1)){
                                if (pswd1.length >= 8 && pswd1.length <= 16) {
                                    if (pswd1 != pswd2) {
                                        Toast.info('两次密码不一致请重新输入')
                                    } else {
                                        // 修改密码的地方
                                        let res
                                        let res1 = await inspectonly(phone)                             
                                        if (res1.data[0][0].isEnablePwd === 2) {
                                            res = await changePwdRequest({ docterPwd: md5(pswd1), docterPhone: phone })
                                        }else{
                                            res = await changePwdOwnRequest({ docterPwd: md5(pswd1), docterPhone: phone })
                                        }

                                        // const res = await changePwdRequest({ docterPwd: md5(pswd1), docterPhone: phone })
                                        Toast.success("设置成功")
                                        // this.props.history.push("/login")
                                        if (loginwaycode == null) {
                                            this.props.history.push("/login")
                                        } else {
                                            this.props.history.replace(`/login?number=${number}`)
                                            this.props.history.push(`/login?loginwaycode=${loginwaycode}&number=${number}`)
                                        }
                                    }
                                } else {
                                    Toast.info('请输入8到16位密码')
                                }
                            } else {
                                Toast.fail("请再次确认密码")
                            }
                        } else {
                            Toast.info('密码由8-16位数字、字母及特殊字符组成')
                        }
                    }
                }
            } else {
                Toast.info('验证码错误')
            }
        } else {
            Toast.info('请先获取验证码')
        }

    }
    setEye = () => {
        const { eye } = this.state
        let input = document.getElementById("input1");
        if (eye == 1) {
            input.type = 'text';
            this.setState({
                eye: 2,
            })
        } else {
            input.type = 'password';
            this.setState({
                eye: 1
            })
        }
    }
    setEye1 = () => {
        const { eye1 } = this.state
        let input = document.getElementById("input2");
        if (eye1 == 1) {
            input.type = 'text';
            this.setState({
                eye1: 2,
            })
        } else {
            input.type = 'password';
            this.setState({
                eye1: 1
            })
        }
    }

    // setEye=()=>{
    // 	const {eye} = this.state
    // 	let input = document.getElementById("inputId");
    // 	if (eye == 1) {
    // 		input.type = 'text';
    // 		this.setState({
    // 			eye: 2,
    // 		})
    // 	} else {
    // 		input.type = 'password';
    // 		this.setState({
    // 			eye: 1
    // 		})
    // 	}
    // }
    // 节流，一秒内只执行一次获取验证码操作
    getcodeagain = () => {
        const { type, number, code, valid } = this.state
        if (!valid) {
            return;
        } else {
            this.setState({
                valid: false//工作期间状态码设置为false
            })
            setTimeout(() => {
                this.codeagain()
                this.setState({
                    valid: true
                })
            }, 500)
        }
    }

    render() {
        const { type, checkcode, eye1, pswd1, pswd2, yzm1, phone, lable, time, eye, showClearIcon, showClearIcon2, isFormComplete } = this.state
        let inputType = 'password'; // 默认为密码输入框
        if (!eye) {
            inputType = 'text';
        }
        if (!eye1) {
            inputType = 'text';
        }
        return (
            <div style={{ width: '100vw', height: '100vh', backgroundColor: '#fff' }}>
                <div className='retrieve-top'>
                    设置密码/忘记密码
                </div>
                <div className="retallbtn-login retallbtn-login1">
                    <p className='retlogin-phone retlogin-phone3'>
                        <input placeholder='请输入手机号'
                            value={phone}
                            maxLength={11}
                            onChange={(e) => this.updateFrom1(e, 'phone')} />
                        <i className='iconfont icon-guanbi2' onClick={() => this.setState({
                            phone: ''
                        })} />
                    </p>
                    <p className='retlogin-phone retlogin-phone1'>
                        <input placeholder='请输入验证码' type="number"
                            maxLength={6} value={yzm1}
                            onChange={(e) => {
                                this.updateFrom1(e, 'yzm1')
                            }} />
                        {/* {lable == false ? <span onClick={this.getcode}>获取验证码</span> : <span>{time}秒后重新发送</span>}  */}
                        {lable == false ? <span disabled={this.state.disabled} onClick={this.handleClick}>获取验证码</span> : <span>{time}秒后重新发送</span>}
                    </p>
                    <p className='retlogin-phone retlogin-phone4'>
                        <input placeholder='请输入8-16位密码'
                            //    type="text" 
                            type={inputType}
                            value={pswd1}
                            maxLength={16}
                            id='input1'
                            autoComplete="off" // 禁用自动填充
                            onChange={(e) => {
                                this.updateFrom1(e, 'pswd1');
                                let onepwd = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z0-9\s])[a-zA-Z\d\W]{8,16}$/;
                                const pswdInput = e.target.value;
                                const filteredInput = pswdInput.replace(/[\u4E00-\u9FA5]/g, ""); // 过滤掉非 ASCII 字符（包括中文）
                                this.setState({ pswd1: filteredInput });
                                const pwdTip = document.querySelector('.pwd-tip');
                                if (!pswdInput) {
                                    pwdTip.style.color = ''; // 将密码提示的文本颜色设置为灰色
                                }
                                else if (!onepwd.test(pswdInput)) {
                                    pwdTip.style.color = 'red'; // 将密码提示的文本颜色设置为红色
                                } else {
                                    pwdTip.style.color = ''; // 清除密码提示的文本颜色
                                }
                            }} />
                        <span className='tipicon'>
                            {/* <i className='iconfont icon-guanbi2' onClick={()=>this.setState({
                                pswd1:''
                            })}/> */}
                            {showClearIcon &&
                                <i className='iconfont icon-guanbi2'
                                    onClick={() => {
                                        const pwdTip = document.querySelector('.pwd-tip');
                                        pwdTip.style.color = '';
                                        this.setState({
                                            pswd1: '',
                                            showClearIcon: false
                                        })
                                    }}
                                />}
                            {eye == 1 ?
                                <i onClick={() => { this.setEye() }} style={{ fontSize: '16px' }} className='iconfont icon-yanjing_bi' /> :
                                <i onClick={() => { this.setEye() }} style={{ fontSize: '16px' }}
                                    className='iconfont icon-yanjing' />}
                        </span>
                    </p>
                    <p className='pwd-tip'>密码由8-16位数字、字母及特殊字符组成</p>
                    {/* <p className={'pwd-tip' + (pswd1 === '' ? ' gray-tip' : '')}>密码由8-16位数字、字母及特殊字符组成</p> */}
                    <p className='retlogin-phone retlogin-phone4'>
                        {/* <i className='iconfont icon-mima'/> */}
                        <input placeholder='请再次输入密码'
                            //    type="text" 
                            type={inputType}
                            id='input2'
                            maxLength={16}
                            value={pswd2}
                            autoComplete="off" // 禁用自动填充
                            onChange={(e) => {
                                this.updateFrom1(e, 'pswd2');
                                const pswdInput = e.target.value;
                                const filteredInput = pswdInput.replace(/[\u4E00-\u9FA5]/g, ""); // 过滤掉非 ASCII 字符（包括中文）
                                this.setState({ pswd2: filteredInput });
                            }} />
                        <span className='tipicon'>
                            {showClearIcon2 && <i className='iconfont icon-guanbi2' onClick={() => this.setState({
                                pswd2: '',
                                showClearIcon2: false
                            })} />}
                            {eye1 == 1 ?
                                <i onClick={() => { this.setEye1() }} style={{ fontSize: '16px' }} className='iconfont icon-yanjing_bi' /> :
                                <i onClick={() => { this.setEye1() }} style={{ fontSize: '16px' }}
                                    className='iconfont icon-yanjing' />}
                        </span>
                    </p>
                </div>
                {/* <p className='retlogin-btn2' onClick={() => this.toNext()}>
                    确定
                </p> */}
                <p className={isFormComplete ? "retlogin-btn2" : "disabled-button"}
                    disabled={!isFormComplete}
                    onClick={this.toNext}>
                    确认
                </p>
            </div>
        )
    }
}

