import React, { Component } from 'react';
import "./style.less"
import { Toast } from "antd-mobile"
import icon1 from "../../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../../static/img/doctor/add.png"
import icon3 from "../../../../static/img/doctor/jian.png"
import down from "../../../../static/img/down.png"

export default class index extends Component {
    constructor() {
        super()
        this.state = {
            item: {},
            num: "1",
            //用药频率
            medicinalFrequency: "1",
            // 单次用量
            medicinalUsage: "0.05",
            // 食用天数
            medicinalDay: "1",
            //用药方法
            medicinalMethod:  "",
            //补充说明
            medicinalSupplementary: "",
            day:['每天','隔日','每周','每月'],
            danwei:['g','mg','ml','片','丸','粒','袋','枚','喷','支','ug','滴','帖','瓶','张','剂'],
            visiable:'none',
            visiable1:'none',
            frequency: '每天',
            company: 'g',
            i:0,
            j:0
        }
    }
    gobank = () => {
        window.history.back()
    }
    componentDidMount = () => {
        // console.log(this.props.location.params.item);
        // console.log((3).toString().length);
        if (this.props.location.params.item.medicinalFrequency) {
            let a= (this.props.location.params.item.medicinalFrequency.split('次')[0])
            // let a= (this.props.location.params.item.medicinalFrequency.substr(2)).substr(0,(this.props.location.params.item.medicinalFrequency.substr(2)).length-1)
            this.setState({
                item: this.props.location.params.item,
                num: this.props.location.params.item.medicinalDay?this.props.location.params.item.medicinalQuantity:"1",
                //用药频率
                medicinalFrequency: this.props.location.params.item.medicinalDay?a:"1",
                // 单次用量
                medicinalUsage: this.props.location.params.item.medicinalDay?parseFloat(this.props.location.params.item.medicinalUsage):"0.05",
                // 食用天数
                medicinalDay:this.props.location.params.item.medicinalDay?this.props.location.params.item.medicinalDay:"1",
                //用药方法
                medicinalMethod: this.props.location.params.item.medicinalDay?this.props.location.params.item.medicinalMethod:"",
                frequency:this.props.location.params.item.medicinalDay?this.props.location.params.item.medicinalFrequency.substr(-2):'每天',
                medicinalSupplementary:this.props.location.params.item.medicinalDay?this.props.location.params.item.medicinalSupplementary:"",
                company:this.props.location.params.item.medicinalDay?this.props.location.params.item.company:'g'
            })
        }
        this.setState({
            item:this.props.location.params.item
        })
        if (this.props.location.params.item.prescription_number) {
            this.setState({
                num:this.props.location.params.item.med_quantity
            })
        }
    }
    // 加减数量
    changenum = (code) => {
        const { num } = this.state
        if (code == "add") {
            this.setState({
                num: Number(num) + 1
            })
        } else {
            if (num > 1) {
                this.setState({
                    num: Number(num) - 1
                })
            }
        }
    }
    // 加减用药频率
    changenum1 = (code) => {
        const { medicinalFrequency } = this.state
        if (code == "add") {
            this.setState({
                medicinalFrequency: Number(medicinalFrequency) + 1
            })
        } else {
            if (medicinalFrequency > 1) {
                this.setState({
                    medicinalFrequency: Number(medicinalFrequency) - 1
                })
            }
        }
    }
    // 加减单次使用量
    changenum2 = (code) => {
        const { medicinalUsage } = this.state
        if (code == "add") {
            this.setState({
                medicinalUsage: (Number(medicinalUsage) + 0.05).toFixed(2)
            })
        } else {
            if (medicinalUsage > 0.05) {
                this.setState({
                    medicinalUsage: (Number(medicinalUsage) - 0.05).toFixed(2)
                })
            }
        }
    }
    // 加减食用天数
    changenum3 = (code) => {
        const { medicinalDay } = this.state
        if (code == "add") {
            this.setState({
                medicinalDay: Number(medicinalDay) + 1
            })
        } else {
            if (medicinalDay > 1) {
                this.setState({
                    medicinalDay: Number(medicinalDay) - 1
                })
            }
        }
    }
    //数量绑定
    changevalue = (e) => {
        // console.log(e.target.value%1)
        if (e.target.value) {
            if (isNaN(e.target.value)||e.target.value.slice(0, 1)==0||e.target.value.slice(0, 1)=='-'||e.target.value.slice(0, 1)=='+'||e.target.value%1>0) {
                Toast.fail("请输入正整数")
                this.setState({
                    num: 1
                })
            } else {
                this.setState({
                    num: e.target.value
                })
            }
        } else {
            this.setState({
                num: ""
            })
        }
    }
    //获取输入框值
    getvalue = (e, text) => {
        // console.log(e.target.value.slice(-1, 1),e.target.value)
        switch (text) {
            case 1:
                if (isNaN(e.target.value)||e.target.value.slice(0, 1)==0||e.target.value.slice(0, 1)=='-'||e.target.value%1>0) {
                    Toast.fail("请输入正整数")
                    this.setState({
                        medicinalFrequency: 1
                    })
                } else {
                    this.setState({
                        medicinalFrequency: e.target.value
                    })
                }
                break;
            case 2:
                if (isNaN(e.target.value)||e.target.value.slice(0, 1)=='-'||e.target.value%1>0) {
                    Toast.fail("请输入大于0的数字")
                    this.setState({
                        medicinalUsage: 0.05
                    })
                } else {
                    this.setState({
                        medicinalUsage: e.target.value
                    })
                }
                break;
            case 3:
                this.setState({
                    medicinalMethod: e.target.value.replace(/\s*/g,"")
                })
                break;
            case 4:
                this.setState({
                    medicinalSupplementary: e.target.value
                })
                break;
            case 5:
                if (isNaN(e.target.value)||e.target.value.slice(0, 1)==0||e.target.value.slice(0, 1)=='-'||e.target.value%1>0) {
                    Toast.fail("请输入正整数")
                    this.setState({
                        medicinalDay: 1
                    })
                } else {
                    this.setState({
                        medicinalDay: e.target.value
                    })
                }
                break;
        }
    }
    // 获取单位
    dianji1 =(index) => {
        const {danwei,i} = this.state
        // console.log(index);
        this.setState({
            i:index
        })
    }
    // 获取频率
    dianji =(index) => {
        const {day} = this.state
        // console.log(index);
        this.setState({
            j:index
        })
    }
    // 显示频率
    showDay =() =>{
        this.setState({
            visiable:'block'
        })
    }
    // 显示单位
    showDanwei =() =>{
        this.setState({
            visiable1:'block'
        })
    }
    // 隐藏频率
    closeDay =() =>{
        const {day,j} = this.state
        this.setState({
            frequency: day[j],
            visiable:'none'
        })
    }
    // 隐藏单位
    closeDanwei =() =>{
        const {danwei,i} = this.state
        this.setState({
            company: danwei[i],
            visiable1:'none'
        })
    }
    preservation=()=>{
        const {frequency,company,num, medicinalFrequency, medicinalUsage, medicinalMethod,medicinalDay, medicinalSupplementary,item}=this.state
        let arr=JSON.parse(localStorage.getItem("somegoods"))
        let arr1=JSON.parse(localStorage.getItem("medicinaldays"))
        // console.log(num,medicinalUsage,medicinalFrequency,medicinalMethod)
        if (num){
            if (medicinalUsage){
                if (medicinalDay){
                    if (medicinalFrequency){
                        // console.log(arr1);
                        if (medicinalMethod) {
                            arr.forEach(sun => {
                                if(sun.med_id==item.med_id){
                                    sun.medicinalFrequency=medicinalFrequency
                                    sun.company=company
                                    sun.medicinalQuantity=num
                                    sun.medicinalUsage=medicinalUsage
                                    sun.medicinalMethod=medicinalMethod
                                    sun.medicinalSupplementary=medicinalSupplementary
                                }
                            });
                            arr1.forEach(sun => {
                                if(sun.med_id==item.med_id){
                                    sun.medicinalDay=medicinalDay
                                    sun.frequency=frequency
                                    sun.company=company
                                }
                            });

                            localStorage.setItem("somegoods",JSON.stringify(arr))
                            localStorage.setItem("medicinaldays",JSON.stringify(arr1))
                            Toast.success("保存成功",1)
                            window.history.back()
                        }else{
                            Toast.info('请填写用药方法')
                        }
                    }else{
                        Toast.info('用药频率不能为空')
                    }
                }else{
                    Toast.info('天数不能为空')
                }
            }else{
                Toast.info('单次用量不能为空且必须是大于0的数字')
            }
        }else{
            Toast.info('药品数量不能为空')
        }


        // this.props.history.replice("/prescribing/addcf")
        // window.location.replace("/prescribing/addcf")
        // console.log(num, medicinalFrequency, medicinalUsage, medicinalMethod, medicinalSupplementary);
    }
    render() {
        const {frequency,company, day,danwei,visiable,visiable1,item,i,j, num, medicinalFrequency, medicinalUsage,medicinalDay, medicinalMethod, medicinalSupplementary } = this.state
        // console.log(item)
        return (
            <div className='editnumbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>用法用量</h1>
                </div>
                <div className='box-text'>
                    <img src={item.med_photo} alt="" />
                    <div>
                        <p>【品牌】{item.med_name}</p>
                        <p>规格：{item.med_spec}</p>
                        <p>口服：{item.med_dosage_form}</p>
                    </div>
                </div>
                <div className='box-content'>
                    <div className='content-item'>
                        <div>数量<span style={{marginLeft:'5px',color:'#ff0000',fontSize:'16px'}}>*</span></div>
                        <div className='changenum'>
                            {item.prescription_number?<span>x{item.med_quantity}</span>:
                            <>
                                <span onClick={() => this.changenum("reduce")}>-</span><input type="text" value={num} onChange={(e) => this.changevalue(e)} /><span onClick={() => this.changenum("add")} >+</span>
                            </>}
                            {/* <img src={icon3} alt="" onClick={() => this.changenum("reduce")} /> <input type="text" value={num} onChange={(e) => this.changevalue(e)} /> <img src={icon2} alt="" onClick={() => this.changenum("add")} /> */}
                        </div>
                    </div>
                    <div className='content-item'>
                        <div>用药频率<span style={{marginLeft:'5px',color:'#ff0000',fontSize:'16px'}}>*</span></div>
                        <div className='pl-content'>
                            <span  className='pl-content-count'>
                                <span onClick={this.showDay}>{frequency}</span>
                                <img src={down} alt="" />
                            </span>
                            <span className='changenum'>
                                <span onClick={() => this.changenum1("reduce")}>-</span><input type="number" value={medicinalFrequency} onChange={(e) => this.getvalue(e, 1)} /><span onClick={() => this.changenum1("add")} >+</span>
                            </span>
                            <span className='pl-content-count1'>次</span>
                            {/* <input type="text" placeholder='请输入' value={medicinalFrequency} onChange={(e) => this.getvalue(e, 1)} /> */}
                        </div>
                    </div>
                    <div className='content-item'>
                        <div>单次用量<span style={{marginLeft:'5px',color:'#ff0000',fontSize:'16px'}}>*</span></div>
                        <div className='pl-content'>
                            <span className='changenum'>
                                <span onClick={() => this.changenum2("reduce")}>-</span><input type="number" value={medicinalUsage} onChange={(e) => this.getvalue(e, 2)} /><span onClick={() => this.changenum2("add")} >+</span>
                            </span>
                            <span  className='pl-content-count'>
                                <span onClick={this.showDanwei}>{company}</span>
                                <img src={down} alt="" />
                            </span>
                            {/* <input type="text" placeholder='请输入' value={medicinalUsage} onChange={(e) => this.getvalue(e, 2)} /> */}
                        </div>
                    </div>
                    <div className='content-item'>
                        <div>天数<span style={{marginLeft:'5px',color:'#ff0000',fontSize:'16px'}}>*</span></div>
                        <div className='pl-content'>
                            <span className='changenum'>
                                <span onClick={() => this.changenum3("reduce")}>-</span><input type="number" value={medicinalDay} onChange={(e) => this.getvalue(e, 5)} /><span onClick={() => this.changenum3("add")} >+</span>
                            </span>
                            <span  className='pl-content-count1'>日</span>
                            {/* <input type="text" placeholder='请输入' value={medicinalUsage} onChange={(e) => this.getvalue(e, 2)} /> */}
                        </div>
                    </div>
                    <div className='content-item' style={{ marginBottom: "35px" }}>
                        <div>用药方法<span style={{marginLeft:'5px',color:'#ff0000',fontSize:'16px'}}>*</span></div>
                        <div>
                            <input type="text" placeholder='请输入最多50字' maxLength={50} value={medicinalMethod} onChange={(e) => this.getvalue(e, 3)} />
                        </div>
                    </div>
                    <h1>补充说明（选填）</h1>
                    <textarea name="" id="" cols={30} rows={9} maxLength={250} placeholder='最多输入250字' value={medicinalSupplementary} onChange={(e) => this.getvalue(e, 4)}></textarea>
                </div>
                <div className='box-btn'>
                    <button onClick={this.preservation}>保存</button>
                </div>
                {visiable=='block'?<div className='up-bottom'>
                    <div>
                        {day.map((item,index)=>(<span className={index==j?'red':''} onClick={()=>this.dianji(index)}>{item}</span>))}
                        <p  onClick={this.closeDay}>确定</p>
                    </div>
                </div>:null}
                {visiable1=='block'?<div className='up-bottom'>
                    <div>
                        {danwei.map((item,index)=>(<span className={index==i?'red':''} onClick={()=>this.dianji1(index)}>{item}</span>))}
                        <p onClick={this.closeDanwei}>确定</p>
                    </div>
                </div>:null}
            </div>
        )
    }
}
