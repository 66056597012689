import React, { Component } from 'react'
import FootNav from '../../component/FootNav'
import "./style.less"
import icon1 from "../../static/img/head.png"
import icon2 from "../../static/img/doctor/minepic.png"
import icon3 from "../../static/img/doctor/right.png"
import icon4 from "../../static/img/doctor/money.png"
import icon5 from "../../static/img/doctor/books.png"
import icon6 from "../../static/img/doctor/jilu.png"
import icon7 from "../../static/img/doctor/set.png"
import icon8 from "../../static/img/doctor/kefu.png"
import icon9 from "../../static/img/doctor/suo.png"
import icon10 from "../../static/img/mine/resign.png"
import icon11 from "../../static/img/mine/resetpwd.png"
import icon12 from "../../static/img/mine/receive.png"
import icona1 from "../../static/img/mine/yywz.png"
import icona2 from "../../static/img/mine/jzjl.png"
import icona3 from "../../static/img/mine/cfjl.png"
import icona4 from "../../static/img/mine/wdzy.png"
import icona5 from "../../static/img/mine/syjl.png"
import icona6 from "../../static/img/mine/sxqm.png"
import icona7 from "../../static/img/mine/wdpj.png"
import icona8 from "../../static/img/mine/kjhf.png"
import filingsicon from "../../static/img/filings-icon.png"
import {getuserinfo} from "../../api/hospitalapi/login"
import {Popup, Rate, Switch, Tabs, Toast,Image} from "antd-mobile-v5"
import {Modal, } from "antd-mobile"
import getUrl from "../../component/GetUrlParam";
import {caAuthentication, caPasstime} from "../../api/hospitalapi/upload";
import {removeCapwd,selectBecomeDue,caIsNotify,docterDetail} from "../../api/hospitalapi/offline";
import moment from "moment";
import {dealParams} from "../../api/config";
import {caUrl,baseUrl,jumpUrl} from '../../publicurl'
const { alert } = Modal

export default class index extends Component {
    constructor() {
        super()
        this.state = {
            docterPhone:localStorage.getItem("docterPhone"),
            docterAvatar:localStorage.getItem("docterAvatar"),
            docterName:localStorage.getItem("docterName"),
            docterId:localStorage.getItem("docterId"),
            doctor:'',
            caPassTime: localStorage.getItem("caPassTime")?localStorage.getItem("caPassTime"):'',
            visible:false,
            code:1,
            docuserType:2,//2、线下医生 1、线上医生
            checked1:0,//线上图文问诊 1开启
            checked2:0,//线上视频问诊 1开启
            checked3:0,//线上预约接诊 1开启
            becomeduestate:'',
            visible11:false,
            visibledocter2:false,
            filingExpirationDate:'',//执业证书备案时间
            FilingDaysTime:'',//执业药师到期天数
        }
    }
    componentDidMount =async () => {
        const {docterId,docterPhone,caPassTime} =this.state
        if(docterId){
            // 查看医生详细信息
            let res=await docterDetail({docterId:docterId})
            if(res.code==1 && res.data[0] && res.data[0].length){
                if(res.data[0][0].isOurHospital == 2){
                    if(res.data[0][0].docterExamine == 1 && res.data[0][0].filingExpirationDate){
                        let feTime = new Date(res.data[0][0].filingExpirationDate)
                        let nowDate = new Date()
                        let timeDiff = feTime.getTime() - nowDate.getTime();
                        let dasyDiff = Math.ceil(timeDiff/(1000*60*60*24))
                        if(dasyDiff < 0){
                          this.setState({
                            visibledocter2:true
                          })
                        }
                        this.setState({
                          filingExpirationDate:res.data[0][0].filingExpirationDate,
                          FilingDaysTime:dasyDiff
                        })
                    }
                }
                
              
            }
        }

        let resdue = await selectBecomeDue({docterId:docterId})
        if(resdue.data && resdue.data[0]){
            this.setState({
                becomeduestate:resdue.data[0][0].is_become_due
            })
        }

        if (docterPhone){
            const res=await getuserinfo({
                docterPhone:docterPhone
            })
            if (res.data[0].length) {
                this.setState({
                    doctor:res.data[0][0],
                    docuserType:res.data[0][0].docuser_type,
                    docterAvatar:res.data[0][0].docter_avatar
                })
            }

            // 查询医生ca认证到期是否给出提示
            let res10 = await caIsNotify({
                docterId:docterId
            });
            if (
                res10.data[0][0].is_notify == 1 &&
                caPassTime &&
                caPassTime <= 0
            ) {
                this.setState({
                  visible11:true,
                })  
            }



        }
        if (getUrl('ca', this.props.location.search)&&docterId){
            let res=await caPasstime(docterId)
            if (res&&res.data&&res.data[0]&&res.data[0][0].caPassTime){
                let timeca=moment(res.data[0][0].caPassTime).add(1, 'year').diff( moment(), 'day')
                localStorage.setItem("caPassTime", timeca)
                this.setState({
                    caPassTime:timeca
                })
            }
        }
        // if (docterId) {
        //     if (caPassTime&&caPassTime <= 0) {
        //         alert('身份信息更新', '身份认证有限期已到期，请及时更新', [
        //             {
        //                 text: '忘记密码', onPress: () => {
        //                     let data0 = {
        //                         entityId: docterId,
        //                         busiType: '5',
        //                         redirectUrl: baseUrl+'/faas/hosp/scca/callback',
        //                         redirectPageUrl: jumpUrl+`/index.html#/mine`,
        //                         province: '四川省',
        //                         locality: '德阳市',
        //                         orgName: '云益健互联网医院'
        //                     }
        //                     let {toSign, params} = dealParams(data0)
        //                     console.log(toSign, params)
        //                     caAuthentication({
        //                         url: caUrl,
        //                         toSign,
        //                         FormData: params
        //                     }) .then(data => {
        //                         if (data && data.data && data.data.success == false) {
        //                             Toast.show({
        //                                 content:data.data.result_msg,
        //                                 duration:1000
        //                             })
        //                         } else if (data && data.data) {
        //                             window.location.href =data.data.body
        //                         }
        //                     });
        //                 }
        //             },
        //             {
        //                 text: '确认', onPress: async () => {
        //                     let data0 = {
        //                         entityId: docterId,
        //                         busiType: '3',
        //                         redirectUrl: baseUrl+'/faas/hosp/scca/callback',
        //                         redirectPageUrl: jumpUrl+`/index.html#/mine?ca=${1}`,
        //                         province: '四川省',
        //                         locality: '德阳市',
        //                         orgName: '云益健互联网医院'
        //                     }
        //                     let {toSign, params} = dealParams(data0)
        //                     console.log(toSign, params)
        //                     caAuthentication({
        //                         url: caUrl,
        //                         toSign,
        //                         FormData: params
        //                     }).then(data => {
        //                         if (data && data.data && data.data.success == false) {
        //                             Toast.show({
        //                                 content:data.data.result_msg,
        //                                 duration:1000
        //                             })
        //                         } else if (data && data.data) {
        //                             window.location.href =data.data.body
        //                         }
        //                     });
        //                 }
        //             }
        //         ])
        //     }
        // }
    }


    //个人信息
    toOwn=()=>{
        const { docterId,doctor,becomeduestate,caPassTime,FilingDaysTime }=this.state
        if(FilingDaysTime<0){
            this.setState({
                visibledocter2:true
            })
            return 
        }

        if(caPassTime<=0){
            Toast.show('您的身份信息已过期，请联系客服解决后使用')
            return
        }

        if (docterId){
            if (doctor.docter_examine=='1'){
                this.props.history.push("/mine/userinfo")
            }else{
                Toast.show({
                    content:"医生尚未入驻成功，无法继续操作",
                    duration:1000
                })
            }
        }else{
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }
    }
    //我的收入
    toAccount=()=>{
        const { docterId,doctor,becomeduestate }=this.state
        if (docterId){
            if (doctor.docter_examine=='1'){
                this.props.history.push("/mine/account")
            }else{
                Toast.show({
                    content:"医生尚未入驻成功，无法继续操作",
                    duration:1000
                })
            }

        }else{
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }
    }
    //证书管理
    toBook=()=>{
        const { docterId }=this.state
        if (docterId){
            // this.props.history.push("/mine/userinfo")
            Toast.show({
                content:"暂无功能页",
                duration:1000
            })
        }else{
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }
    }
    //接诊记录
    toRecord=()=>{
        const { docterId,doctor }=this.state
        if (docterId){
            if (doctor.docter_examine=='1'){
                this.props.history.push("/mine/record")
            }else{
                Toast.show({
                    content:"医生尚未入驻成功，无法继续操作",
                    duration:1000
                })
            }

        }else{
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }
    }
    //联系客服
    toKefu=()=>{
        const { docterId,doctor }=this.state
        if (docterId){
            window.location.href = 'tel://19048012565';

        }else{
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }
    }
    //开方密码管理
    toPwd=()=>{
        const { docterId,doctor,becomeduestate,caPassTime,FilingDaysTime }=this.state
        if(FilingDaysTime<0){
            this.setState({
                visibledocter2:true
            })
            return 
        }
        if(caPassTime<=0){
            Toast.show('您的身份信息已过期，请联系客服解决后使用')
            return
        }
        if (doctor.doc_ca_pwd){
            this.setState({
                visible:true,
                code:1
            })
        }else{
            this.setState({
                visible:true,
                code:2
            })
        }
    }
    //设置接单时间
    toReceiveorder=()=>{
        const { docterId,docterPhone,becomeduestate,caPassTime,FilingDaysTime }=this.state
        if(FilingDaysTime<0){
            this.setState({
                visibledocter2:true
            })
            return 
        }

        if(caPassTime<=0){
            Toast.show('您的身份信息已过期，请联系客服解决后使用')
            return
        }

        if (docterId) {
            this.props.history.push("/receiveorder")
        }else{
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }
    }
    //重置密码
    toagainPwd=async ()=>{
        const { docterId,docterPhone,becomeduestate,caPassTime,FilingDaysTime }=this.state
        if(FilingDaysTime<0){
            this.setState({
                visibledocter2:true
            })
            return 
        }

        if(caPassTime<=0){
            Toast.show('您的身份信息已过期，请联系客服解决后使用')
            return
        }

        if (docterId) {
            alert('提示弹窗', '确认重置密码', [
                { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
                {
                    text: '重置密码', onPress: () => {
                        let data0 = {
                            entityId: docterId,
                            busiType: '5',
                            redirectUrl: baseUrl+'/faas/hosp/scca/callback',
                            redirectPageUrl: jumpUrl+`/index.html#/mine`,
                            province: '四川省',
                            locality: '德阳市',
                            orgName: '云益健互联网医院'
                        }
                        let {toSign, params} = dealParams(data0)
                        caAuthentication({
                            url: caUrl,
                            toSign,
                            FormData: params
                        }) .then(data => {
                            if (data && data.data && data.data.success == false) {
                                Toast.show({
                                    content:data.data.result_msg,
                                    duration:1000
                                })
                            } else if (data && data.data) {
                                window.location.href =data.data.body
                            }
                        });
                    }
                }
            ])
        }else{
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }
    }
    toSure=async ()=>{
        const { docterId,docterPhone }=this.state
        let res0=await removeCapwd({docterId})
        if (res0.code==1){
            const res=await getuserinfo({
                docterPhone:docterPhone
            })
            if (res.data[0].length) {
                this.setState({
                    doctor:res.data[0][0],
                    visible:false,
                })
            }
        }
    }
    //重新签名
    toResign=()=>{
        const { docterId,doctor,becomeduestate,caPassTime,FilingDaysTime }=this.state
        if(FilingDaysTime<0){
            this.setState({
                visibledocter2:true
            })
            return 
        }

        if(caPassTime<=0){
            Toast.show('您的身份信息已过期，请联系客服解决后使用')
            return
        }

        if (docterId){
            if (doctor.docter_examine =='1'){
                this.props.history.push("/mine/resign")
            }else{
                Toast.show({
                    content:"医生尚未入驻成功，无法继续操作",
                    duration:1000
                })
            }

        }else{
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }
    }
    defultPwd=(e)=>{
        // e.preventDefault()
        // e.stopPropagation()
        this.setState({
            visible:false,
        })
    }
    checketuwen=()=>{
        const { checked1 }=this.state
        this.setState({
            checked1:checked1?0:1
        })
    }
    checkevideo=()=>{
        const { checked2 }=this.state
        this.setState({
            checked2:checked2?0:1
        })
    }
    checkeyuyue=()=>{
        const { checked3 }=this.state
        this.setState({
            checked3:checked3?0:1
        })
    }
    // 去人脸认证
    sureBtn=async()=>{
        const {docterPhone,docterId,count}=this.state
        const res = await getuserinfo({
        docterPhone,
        });
        this.setState({
        visible11:false,
        })

        let data0 = {
        entityId: docterId,
        busiType: '3',
        redirectUrl: baseUrl + '/faas/hosp/scca/callback',
        redirectPageUrl: jumpUrl + `/index.html#/news?ca=${1}`,
        province: '四川省',
        locality: '德阳市',
        orgName: '云益健互联网医院'
        }
        let {toSign, params} = dealParams(data0)
        // console.log(toSign, params)
        caAuthentication({url: caUrl, toSign, FormData: params}).then(data => {
        if (data && data.data && data.data.success == false) {
            Toast.show(data.data.result_msg)
        } else if (data && data.data) {
            window.location.href = (data.data.body)
        }
        });
    }

    // 执业到期提醒关闭
  handleCloseAlert2=()=>{
    // 获取当前时间的年月日
    let currentDate = new Date().toLocaleDateString();
    this.setState({
      visibledocter2:false,
    })
  }

    render() {
        const {docuserType,docterAvatar,visible,code,doctor,checked1,checked2,checked3,visible11,visibledocter2}=this.state
        
        return (
            <div className='minebox'>
                {/* 执业医生到期之后提醒 */}
                {visibledocter2 && (
                    <>
                        <div className='tipbox'>
                        <div className='alertbox'>
                            <div className='top-box'>本医院执业到期提醒</div>
                            <p className='tipmsg'>您在本医院执业已经到期，</p>
                            <p className='tipmsg'>暂时无法使用</p>
                            <p className='tipmsg'>请联系客服：19048012565</p>
                            <div className='knowbtns' onClick={()=>{
                                this.handleCloseAlert2()
                            }}>我已知晓</div>
                        </div>
                        </div>
                    </>
                )}

                {visible11 && (
                    <>
                    <div className='tipbox'>
                        <div className='alertbox'>
                            <div className='top-box'>身份信息更新提示</div>
                            {/* <p className='tipmsg'>实名认证已到期</p> */}
                            {/* <p className='tipmsg'>请进行人脸认证后使用</p> */}
                            <p className='tipmsg'>您的实名认证已到期，</p>
                            <p className='tipmsg'>请您尽快前往重新进行人脸认证</p>
                            <p onClick={()=> {
                                this.sureBtn()
                            }} className='gourl-two'>去人脸认证</p>
                        </div>
                    </div>
                    </>
                )}

                {docuserType==2?<>
                    <div className='box-top'>
                        {/* <img src={!doctor?icon1:doctor.docter_avatar?doctor.docter_avatar:icon1} alt="" /> */}
                        <img src={docterAvatar!=null?docterAvatar:icon1} alt="" />
                        {!localStorage.getItem("docterId")?<div onClick={()=>this.props.history.push("/login")}>
                                <h1>
                                    请先登录
                                </h1>
                                <p>请先登录</p>
                            </div>:
                            <div>
                                {doctor.docter_name?<>
                                    <h1>
                                        {doctor.docter_name} <span>/{doctor.docter_title}</span>
                                    </h1>
                                    <p>{doctor.docter_hospital}</p>
                                </>:<h1 style={{marginTop:'20px'}}>{doctor.docter_phone}</h1>}
                            </div>}
                    </div>
                    <div className='box-item' onClick={()=>this.toOwn()}>
                        <img style={{width:"19px",height:"20px"}} src={icon2} alt="" /><p>个人信息</p> <img src={icon3} alt=""  className='item-right'/>
                    </div>
                    <div className='box-item'  onClick={()=>this.toResign()}>
                        <img style={{width:"19px",height:"20px"}} src={icon10} alt="" /><p>重新签名</p> <img src={icon3} alt=""  className='item-right'/>
                    </div>
                    <div className='box-item'  onClick={()=>this.toReceiveorder()}>
                        <img style={{width:"19px",height:"20px"}} src={icon12} alt="" /><p>设置接单时间</p> <img src={icon3} alt=""  className='item-right'/>
                    </div>
                    <div className='box-item' onClick={()=>this.toagainPwd()}>
                        <img style={{width:"19px",height:"20px"}} src={icon11} alt="" /><p>重置密码</p> <img src={icon3} alt=""  className='item-right'/>
                    </div>
                    <div className='box-item' style={{marginBottom:"20px"}} onClick={()=>this.toPwd()}>
                        <img style={{width:"19px",height:"20px"}} src={icon9} alt="" /><p>取消记住密码</p> <img src={icon3} alt=""  className='item-right'/>
                    </div>
                    <div className='box-item' onClick={()=>this.toAccount()}>
                        <img style={{width:"19px",height:"20px"}} src={icon4} alt="" /><p>我的单量统计</p> <img src={icon3} alt=""  className='item-right'/>
                    </div>
                    <div className='box-item' onClick={()=>this.toBook()}>
                        <img style={{width:"19px",height:"20px"}} src={icon5} alt="" /><p>证书管理</p> <img src={icon3} alt=""  className='item-right'/>
                    </div>
                    <div className='box-item' style={{marginBottom:"20px"}} onClick={()=>this.toRecord()}>
                        <img style={{width:"19px",height:"20px"}} src={icon6} alt="" /><p>接诊记录</p> <img src={icon3} alt=""  className='item-right'/>
                    </div>
                    <div className='box-item' onClick={()=>{this.props.history.push("/mine/set")}}>
                        <img style={{width:"19px",height:"20px"}} src={icon7} alt=""/><p>设置</p> <img src={icon3} alt=""  className='item-right'/>
                    </div>
                    <div className='box-item'>
                        <a href={`tel:19048012565`}>
                            <img style={{width:"19px",height:"20px"}} src={icon8} alt="" /><span>联系客服</span> <img src={icon3} alt=""  className='item-right'/>
                        </a>
                    </div>
                </>:<div style={{paddingBottom:'60px'}}>
                    <div className='boxonline-top'>
                        {/* <img src={!doctor?icon1:doctor.docter_avatar?doctor.docter_avatar:icon1} alt="" /> */}
                        <div className='boxonline-top-left'>
                            <img src={docterAvatar!=null?docterAvatar:icon1} alt="" />
                            {!localStorage.getItem("docterId")?<div onClick={()=>this.props.history.push("/login")}>
                                <p className='doc-name'>
                                    <h1>
                                        请先登录
                                    </h1>
                                </p>
                                </div>:
                                <div>
                                    {doctor.docter_name?<p className='doc-name'>
                                        <h1>
                                            {doctor.docter_name}
                                        </h1>
                                        <p className='doc-title'>{doctor.docter_title}</p>
                                    </p>:<h1 style={{marginTop:'20px'}}>{doctor.docter_phone}</h1>}
                                    <p className='bind'>绑定账号：{doctor.docter_phone}</p>
                                </div>}
                        </div>
                        <div className='boxonline-top-right' onClick={()=>this.toOwn()}>
                            <i className='iconfont icon-xiugai1'/>
                            <span>编辑</span>
                        </div>
                    </div>
                    <div className='boxonline-wenzhenset'>
                        <div className='boxonline-wenzhenset-one'>
                            <p className='boxonline-wenzhenset-top'>
                                <span>图文问诊</span>
                                <i className='iconfont icon-shezhi'/>
                            </p>
                            <p className='boxonline-wenzhenset-bottom'>
                                <Switch style={{transform:'scale(0.7)'}}
                                    checked={checked1}
                                    onChange={() => this.checketuwen()}
                                />
                                <span>{checked1?'已开启':'已关闭'}</span>
                            </p>
                        </div>
                        <div className='boxonline-wenzhenset-one'>
                            <p className='boxonline-wenzhenset-top'>
                                <span>视频问诊</span>
                                <i className='iconfont icon-shezhi'/>
                            </p>
                            <p className='boxonline-wenzhenset-bottom'>
                                <Switch style={{transform:'scale(0.7)'}}
                                    checked={checked2}
                                    onChange={() => this.checkevideo()}
                                />
                                <span>{checked2?'已开启':'已关闭'}</span>
                            </p>
                        </div>
                        <div className='boxonline-wenzhenset-one'>
                            <p className='boxonline-wenzhenset-top'>
                                <span>预约接诊</span>
                                <i className='iconfont icon-shezhi'/>
                            </p>
                            <p className='boxonline-wenzhenset-bottom'>
                                <Switch style={{transform:'scale(0.7)'}}
                                    checked={checked3}
                                    onChange={() => this.checkeyuyue()}
                                />
                                <span>{checked3?'已开启':'已关闭'}</span>
                            </p>
                        </div>
                    </div>
                    <div className='service-tools-outer'>
                        <h1>服务工具箱</h1>
                        <div className='service-tools-all'>
                            <p>
                                <span>
                                    <img src={icona1}/>
                                </span>
                                <span>预约问诊</span>
                            </p>
                            <p onClick={()=>this.toRecord()}>
                                <span>
                                    <img src={icona2}/>
                                </span>
                                <span>接诊记录</span>
                            </p>
                            <p onClick={()=>this.toAccount()}>
                                <span>
                                    <img src={icona3}/>
                                </span>
                                <span>处方记录</span>
                            </p>
                            <p>
                                <span>
                                    <img src={icona4}/>
                                </span>
                                <span>我的主页</span>
                            </p>
                            <p>
                                <span>
                                    <img src={icona5}/>
                                </span>
                                <span>收益记录</span>
                            </p>
                            <p onClick={()=>this.toResign()}>
                                <span>
                                    <img src={icona6}/>
                                </span>
                                <span>手写签名</span>
                            </p>
                            <p>
                                <span>
                                    <img src={icona7}/>
                                </span>
                                <span>我的评价</span>
                            </p>
                            <p>
                                <span>
                                    <img src={icona8}/>
                                </span>
                                <span>快捷回复</span>
                            </p>
                        </div>
                    </div>
                    <div className='elderly-care-zone-outer'>
                        <h1>养老专区接诊记录</h1>
                        <div className='elderly-care-zone'>
                            <p>
                                <i className='iconfont icon-qianyuejilu' />
                                <span>接诊记录</span>
                            </p>
                            <p>
                                <i className='iconfont icon-chufang1' />
                                <span>处方记录</span>
                            </p>
                        </div>
                    </div>

                    <div className='box-item' style={{marginTop:"10px"}} onClick={()=>this.toPwd()}>
                        <img style={{width:"19px",height:"20px"}} src={icon9} alt="" /><p>取消记住密码</p> <img src={icon3} alt=""  className='item-right'/>
                    </div>

                    <div className='box-item'>
                        <img style={{width:"19px",height:"20px"}} src={icon5} alt="" /><p>意见反馈</p> <img src={icon3} alt=""  className='item-right'/>
                    </div>
                    <div className='box-item'>
                        <a href={`tel:19048012565`}>
                            <img style={{width:"19px",height:"20px"}} src={icon8} alt="" /><span>联系客服</span> <img src={icon3} alt=""  className='item-right'/>
                        </a>
                    </div>
                    <div className='box-item' onClick={()=>{this.props.history.push("/mine/set")}}>
                        <img style={{width:"19px",height:"20px"}} src={icon7} alt=""/><p>设置</p> <img src={icon3} alt=""  className='item-right'/>
                    </div>

                </div>}

                {visible?<div className='ca-pwd-outer' onClick={(e)=>this.defultPwd(e)}>
                    <div className='ca-pwd-inner' onClick={(e)=>e.stopPropagation()}>
                        <p className='ca-pwd-tips'>提示</p>
                        {code==1?<>
                            <p>是否取消记住密码？</p>
                            <p>
                                取消后，开方时需要输入密码，若需要记住密码，请输入密码时勾选【记住密码】。
                            </p>
                            <p className='ca-pwd-button'>
                                <span onClick={()=>this.setState({visible:false})}>取消</span>
                                <span onClick={()=>this.toSure()}>确定</span>
                            </p>
                        </>:
                        <>
                            <p>暂未记住密码</p>
                            <p>若需要记住密码，请在输入密码时勾选【记住密码】。</p>
                        </>}
                    </div>
                </div>:null}

                <div className='filings-box'>
                    <div className='filings-first-div'><Image src={filingsicon} width={16} style={{ marginRight:'3px'}} />蜀ICP备2022000233号</div>
                    <div>川公网安备51062302000214</div>
                    <div>《医疗机构执业许可证》登记号：PDY50745151062317A1002</div>
                    <div>电信业务经营许可证号：川B2-20220303</div>
                    <div>《互联网药品信息服务资格证书》：（川）-非经营性-2023-0044</div>
                </div>

                <FootNav></FootNav>
            </div>
        )
    }
}
