import React, { Component } from 'react'
import "./style.less"
import { Switch, Popup } from "antd-mobile-v5"
import Nodata from '../../../component/Nodata'
import {docBind,setContime} from "../../../api/hospitalapi/offline";
import {Modal,Toast} from "antd-mobile";
const alert = Modal.alert
export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            docterId:localStorage.getItem("docterId"),
            checked1:0,//1、全天接诊
            cancheckall:1,//1、可以选择全天接诊
            checkedtimelist:[]
        }
    }
    componentDidMount = async () => {
        const { docterId } = this.state
        if (docterId) {
            this.getDocbind()
        }
    }
    //
    getDocbind=async ()=>{
        const { docterId } = this.state
        let res=await docBind({docterId})

        let count=0
        let count1=0
        if (res&&res.data&&res.data[0]){

            res.data[0].map(item=>{
                item.state=2//时间段默认为未选中时间段
                if (item.doctortimeEnd=="00:00"){
                    item.doctortimeEnd="24:00"
                }
                if (res.data[1]&&res.data[1].length){
                    res.data[1].map(j=>{
                        if (item.doctor_access_restrictions_id==j.doctor_access_restrictions_id){
                            item.countnum-=1
                            item.state=1
                            count1+=1
                        }
                    })
                }
                if (item.doctor_time_number<=item.countnum){
                    count=1
                }
            })
            this.setState({
                checkedtimelist:res.data[0],
                cancheckall:count==1?2:1,//2、存在时间段接诊医生已满，无法选择全天接诊
                checked1:count1==res.data[0].length?1:0
            })

        }
    }
    //全天接诊
    checkedallday=async ()=>{
        const { checked1,cancheckall,checkedtimelist } = this.state
        if (cancheckall!=1){
            Toast.info('存在时间段接诊人数已满，无法选择全天接诊')
        }else{
            if (checked1==0){
                checkedtimelist.map(i=>{
                    i.state=1
                })
                this.setState({
                    checkedtimelist,
                    checked1:1
                })
            }else{
                checkedtimelist.map(i=>{
                    i.state=2
                })
                this.setState({
                    checkedtimelist,
                    checked1:0
                })
            }
        }
    }
    settimeState=(item)=>{
        const { checkedtimelist,checked1 } = this.state
        if (item.state==2&&item.doctor_time_number>item.countnum){
            let count0=1
            checkedtimelist.map(i=>{
                if (i.doctor_access_restrictions_id==item.doctor_access_restrictions_id){
                    i.state=1
                }else{
                    if (i.state==2){
                        count0=2
                    }
                }
            })
            this.setState({
                checkedtimelist,
                checked1:count0==1?1:0
            })
        }else if (item.state==1){
            checkedtimelist.map(i=>{
                if (i.doctor_access_restrictions_id==item.doctor_access_restrictions_id){
                    i.state=2
                }
            })
            this.setState({
                checkedtimelist,
                checked1:0
            })
        }else if (item.doctor_time_number==item.countnum){
            Toast.info('此时间段接诊医生已满，无法继续选择')
        }
    }
    toSave=async ()=>{
        const { docterId,checkedtimelist } = this.state
        let array=[]
        let array1=[]
        checkedtimelist.map(item=>{
            let params={}
            if (item.state==1){
                params.doctoraccessrestrictionsId=item.doctor_access_restrictions_id
                params.docterId=docterId
                array.push(params)
            }
        })
            let res=await docBind({docterId})

            let count=0
            if (res&&res.data&&res.data[0]){
                res.data[0].map(item=>{
                    item.state=2//时间段默认为未选中时间段
                    if (res.data[1]&&res.data[1].length){
                        res.data[1].map(j=>{
                            if (item.doctor_access_restrictions_id==j.doctor_access_restrictions_id){
                                item.countnum-=1
                                item.state=1
                            }
                        })
                    }
                    if (item.doctor_time_number<=item.countnum){
                        count=1
                    }
                    if (item.doctortimeEnd=="00:00"){
                        item.doctortimeEnd="24:00"
                    }
                    array.map(j=>{
                        if (item.doctor_time_number>item.countnum&&item.doctor_access_restrictions_id==j.doctoraccessrestrictionsId){
                            item.state=1
                            array1.push(j)
                        }
                    })
                })

                if (array.length&&array.length==array1.length){
                    let res1=await setContime({array:array1,docterId,timeType:1})
                    if (res1.code==1){
                        this.props.history.push('/mine')
                        Toast.info('保存成功')
                    }
                }else if(array.length==0){
                    let res1=await setContime({timeType:2,docterId})
                    if (res1.code==1){
                        this.props.history.push('/mine')
                        Toast.info('保存成功')
                    }
                }else{
                    Toast.info('所选时间段存在接诊医生人数已满，无法继续选择该时间段')
                    this.setState({
                        checked1:0,
                        checkedtimelist:res.data[0],
                        cancheckall:count==1?2:1,//2、存在时间段接诊医生已满，无法选择全天接诊
                    })
                }

            }


    }
    render() {
        const { checked1,checkedtimelist } = this.state
        return (
            <div className='receive-order-outer'>
                <div className='receive-order-timeallday'>
                    <p className='select-time-left'>选择每日接诊时间：</p>
                    <p className='select-time-right'>
                        <span>全天接诊</span>
                        <Switch
                            checked={checked1}
                            onChange={(e) => this.checkedallday(e)}
                        />
                    </p>
                </div>
                <p className='select-time-all'>
                    {checkedtimelist.map((item,index)=>(<span className={item.state==1?'checkedtime':item.doctor_time_number==item.countnum?'uncheckedtime':''}
                                                              onClick={()=>this.settimeState(item)
                    }>{item.doctortimeStart}-{item.doctortimeEnd}</span>))}
                </p>
                <p className='select-time-tips'>选择接诊时间后，医生需要按时间在线，未选时间将不接收问诊消息。灰色表示已被选完。</p>
                <p className='select-time-button'>
                    <button onClick={()=>this.toSave()}>保存</button>
                </p>
            </div>
        )
    }
}
