import React, { Component } from 'react'
import "./style.less"
import { Popup, Rate, Toast, TextArea } from "antd-mobile-v5"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/touxiang.png"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            item: {},
            visible1: false,
            value: ""
        }
    }
    componentDidMount = () => {
        //console.log(this.props.location.params.item);
        if (this.props.location.params) {
            this.setState({
                item: this.props.location.params.item
            })
        }
    }
    totalk = () => {
        this.setState({
            visible1: false
        })
    }
    toinfo = () => {
        this.props.history.push({
            pathname: "/doctorinfo/chat/chatinfo"
        })
    }
    render() {
        const { item, visible1, value } = this.state
        return (
            <>
                <div className='chatbox'>
                    <div className='box-top'>
                        <img src={icon1} alt="" onClick={() => this.props.history.push("/doctorinfo")} />
                        <h1>
                            <span>{item.docterName}</span><span>{item.title}</span><span>{item.department}</span>
                        </h1>
                    </div>
                    <div className='box-tips'>病情已发送,等待医生接诊</div>
                    <div className='box-container'>
                        <div className='container-dan'>
                            <img src={icon2} alt="" />
                            <div className='dan-left'>
                                <div className='left-top'>
                                    <p>图文问诊</p>
                                    <p>￥29</p>
                                </div>
                                <div className='itembox' style={{ backgroundColor: "white" }}>
                                    <div className='left-item'>
                                        <p>患者</p>
                                        <p>的萨芬打赏</p>
                                    </div>
                                    <div className='left-item'>
                                        <p>病情描述</p>
                                        <p>萨凡纳哈克首付款</p>
                                    </div>
                                    <div className='left-title'>互联网医院将保证你的隐私安全,请放心与医生交流</div>
                                </div>

                            </div>
                        </div>
                        <div className='container-left'>
                            <img src={icon2} alt="" />
                            <div className='left-text'>
                                那地方立刻释放你的什么麦当劳少女但是看了你反馈
                            </div>
                        </div>
                        <div className='container-left'>
                            <img src={icon2} alt="" />
                            <div className='left-text'>
                                那地方立刻释放你的什么麦当劳少女但是看了你反馈
                            </div>
                        </div>
                        
                        <div className='container-cfd'>
                            <img src={icon2} alt="" />
                            <div className='dan-left'>
                                <div className='left-top'>
                                    <p>处方单</p>
                                </div>
                                <div className='itembox' style={{ backgroundColor: "white" }}>
                                    <div className='left-item'>
                                        <p>患者</p>
                                        <p>
                                            <span>李</span><span>男</span><span>27</span>
                                        </p>
                                    </div>
                                    <div className='left-item'>
                                        <p>诊断</p>
                                        <p>肺炎</p>
                                    </div>
                                    <div className='left-title'>
                                        <button onClick={this.toinfo}>查看详情</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='container-conclusion'>
                            <img src={icon2} alt="" />
                            <div className='left-text'>
                                <img src={icon2} alt="" />
                                <div>
                                    <p>问诊小结</p>
                                    <p>查看问诊小结</p>
                                </div>
                            </div>
                        </div>
                        <div className='container-evaluate'>
                            <div>
                                <h1>请您对医生的这次服务进行评价</h1>
                                <button onClick={() => this.setState({
                                    visible1: true
                                })}>给评价</button>
                            </div>
                        </div>
                    </div>
                    <div className='box-bottom'>
                        <input type="text" placeholder='病情描述' /> <button>发送</button>
                    </div>
                </div>
                <Popup
                    visible={visible1}
                    className='popup'
                    onMaskClick={() => {
                        this.setState({
                            visible1: false
                        })
                    }}
                    bodyStyle={{ minHeight: '40vh' }}
                >
                    <div className='popup-box'>
                        <div className='box-top'>
                            <img src={icon2} alt="" />
                            <div>
                                <h1>评价医生本次服务</h1>
                                <p>
                                    <span>朱心寒</span><span>副主任医师</span><span>呼吸内科</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='pingfen'>
                        <Rate
                            style={{
                                '--active-color': '#1F87FF'
                            }}
                            onChange={val => Toast.show(val.toString())} />
                    </div>
                    <div className='popup-text'>
                        <textarea name="" id="" cols="50" rows="6" placeholder='谈谈你对本次就医医生的评价吧~'>

                        </textarea>

                    </div>
                    <div className='popup-btn'>
                        <button onClick={this.totalk}>提交评论</button>
                    </div>

                </Popup>
            </>

        )
    }
}
