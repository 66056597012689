import React, { Component } from 'react';
import "./style.less"
import { Tabs } from "antd-mobile-v5"
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/yy/right.png"
import Nodata from '../../component/Nodata'
import {allEprescribing,deletecf} from '../../api/hospitalapi/mine'
import {Modal} from "antd-mobile";
const alert = Modal.alert
export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cylist: [],
            histoylist:[],
            docter: '',
            docterId:localStorage.getItem("docterId"),
            item:localStorage.getItem("item"),
        }
    }
    componentDidMount = async () => {
        const { docterId,item } = this.state
        if (docterId&&item) {
            this.allHistorylist()
        }
    }
    allHistorylist=async()=>{
        const { docterId,item } = this.state
        const res = await allEprescribing({
            docterId,userId:item.user_id
        })
        // console.log(res);
        if (res&&res.data&&res.data[0]){
            this.setState({
                histoylist: res.data[0]
            })
        }
    }
    tonext=()=>{
        this.props.history.push("/prescribing/editprescription")
    }
    gobank=()=>{
        window.history.back()
    }
    toAddcf = () => {
        this.props.history.push({
            pathname: "/prescribing/addcf",
            // params:{
            //     memberId:this.props.location.params.memberId
            // }
        })
    }
    toinfo = (item) => {
        const {consultationDetail,patientName,patientSex,patientBirthday}=this.state
        this.props.history.push({
            pathname: "/doctorinfo/chat/chatinfo",
            params:{
                detailContent:item.prescribingId,
                consultationDetail: item.docterDiagnosis,  //病情描述
                patientName:item.patientName,  //患者问诊信息
                patientSex:item.patientSex,
                patientBirthday:item.patientBirthday
            }
        })
    }
    toDelete=async (prescribingId)=>{
        alert('确认删除吗？', '', [
            { text: '取消', onPress: () => console.log('cancel') },
            {
                text: '确认', onPress: () => {
                    deletecf({prescribingId}).then(res=>{
                        // console.log(res)
                        if (res&&res.code==1){
                            this.allHistorylist()
                        }
                    })
                }
            },
        ])

    }
    render() {
        const { histoylist,cylist } = this.state
        return (
            <div className='prescribingbox'>
                <div className='box-top box-top2'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>开处方</h1> <p onClick={this.toAddcf}>新建处方</p>
                </div>
                <div className='prescribing-outer'>
                    <Tabs >
                        <Tabs.Tab title='常用处方' key='often' style={{ backgroundColor: "white" }}>
                            <div className='box-item-outer'>
                                {cylist.length?cylist.map(item=>(<>
                                    <div className='line'></div>
                                    <div className='box-item'>
                                        <img src={icon2} alt="" className='item-img' onClick={this.tonext}/>
                                        <div>
                                            初步诊断：高血压
                                        </div>
                                        <div>
                                            处理意见：监测血压，xxxxxxx
                                        </div>
                                        <div>
                                            <span>删除</span> <button>使用该处方</button>
                                        </div>
                                    </div>
                                </>)):<Nodata type='处方'/>}
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab title='历史处方' key='history' style={{ backgroundColor: "white" }}>
                            <div className='box-item-outer'>
                            {histoylist.length?histoylist.map(item=>(<>
                                <div className='line'></div>
                                <div className='box-item'>
                                    <img src={icon2} alt="" className='item-img' />
                                    <div>
                                        初步诊断：{item.docterDiagnosis}
                                    </div>
                                    <div>
                                        处理意见：{item.docterProposal}
                                    </div>
                                    <div>
                                        <span onClick={()=>this.toDelete(item.prescribingId)}>删除</span> <button onClick={()=>this.toinfo(item)}>历史处方详情</button>
                                    </div>
                                </div>
                            </>)):<Nodata type='处方'/>}
                            </div>
                        </Tabs.Tab>
                    </Tabs>
                </div>
            </div>
        )
    }
}
