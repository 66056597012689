import React, { Component } from 'react'
// import icon1 from "../../../static/img/yy/fanhuihei.png"
import { Badge, SideBar } from 'antd-mobile-v5'
import "./style.less"
import SignatureCanvas from 'react-signature-canvas'
import getUrl from '../../../component/GetUrlParam'
import icon1 from '../../../static/img/home/avater.png'
import icon2 from '../../../static/img/home/online.png'
import icon3 from '../../../static/img/home/downline.png'
import {jumpUrl} from "../../../publicurl";
import {updateDoctype} from '../../../api/hospitalapi/home'

export default class index extends Component {
    constructor() {
        super()
        this.state = {
            trimmedDataURL: null,
            docterId:localStorage.getItem("docterId"),
            docterPhone : localStorage.getItem("docterPhone"),
            linecode:2,
        }
    }
    componentDidMount = async () => {
        let { docterPhone } = this.state;
    }
    sureType=async ()=>{
        let { linecode,docterId } = this.state;
        let res=await updateDoctype(docterId,linecode)
        if (res.code==1){
            this.props.history.replace("/")
            window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
        }
    }
    render() {
        let { linecode } = this.state;
        return (
            <div className='doctype-outer'>
                <div className='doctype-inner'>
                    <p className='doctype-juese'>
                        <span>请选择医生角色</span>
                        <img src={icon1}/>
                    </p>
                    <p className='doctype-jz'>互联网医院线上接诊/线下药店开方接诊</p>
                    <p className={linecode==2?'doctype-one doctype-one1':'doctype-one'}
                       onClick={()=>this.setState({linecode:2})}>
                        <img className='doctype-one-left' src={icon3}/>
                        <p className='doctype-onep-right'>
                            <span className='doctype-one-right-select'>
                                <span className='line-doc'>药店接诊医生</span>
                                {linecode==2?<span className='select'>选择 √</span>:<span></span>}
                            </span>
                            <span className='doctype-one-right-sm'>患者选药问诊，医生接诊根据药品诊断开方,最快10秒可开一单。</span>
                            <p className='doctype-one-right-way'>
                                <span>图文接诊</span>
                                <span style={{color:'#FF7E00',backgroundColor:"#FFF2E6"}}>视频接诊</span>
                            </p>
                        </p>
                    </p>

                    <p className={linecode==1?'doctype-one doctype-one1':'doctype-one'}
                    //    onClick={()=>this.setState({linecode:1})}
                    >
                        <img className='doctype-one-left' src={icon2}/>
                        <p className='doctype-onep-right'>
                            <span className='doctype-one-right-select'>
                                <span className='line-doc'>互联网线上医生</span>
                                {linecode==1?<span className='select'>选择 √</span>:<span></span>}
                            </span>
                            <span className='doctype-one-right-sm'>互联网医院线上接诊，根据患者情况进行问诊开方，适合时间充裕的医生。</span>
                            <p className='doctype-one-right-way'>
                                <span>图文接诊</span>
                                <span style={{color:'#FF7E00',backgroundColor:"#FFF2E6"}}>视频接诊</span>
                                <span style={{color:'#FF564E',backgroundColor:"#FFE6E6"}}>预约接诊</span>
                            </p>
                        </p>
                    </p>
                    
                    <p className='doctype-tips'>提示：选择后不可修改，请医生根据自己实际情况选择角色</p>
                    <p className='doctype-button'>
                        <button onClick={()=>this.sureType()}>确认</button>
                    </p>
                </div>
            </div>
        )
    }
}
