import React, { Component } from 'react'
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/doctor/right.png"
import { Switch, Popup } from "antd-mobile-v5"
import { changeset } from "../../../api/hospitalapi/home"
import { getuserinfo } from "../../../api/hospitalapi/login"
import "./style.less"
import {Toast} from "antd-mobile";
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            checked1: false,//图文
            checked2: false,//电话
            checked3: false,
            checked4: false,//视频
            visible1: false,
            type: "",
            money: "",//图文问诊输入金额
            money1:"",//图文问诊实际设置金额
            money2: "",//电话问诊输入金额
            money21: "",//电话问诊实际设置金额
            money3: "",//视频问诊输入金额
            money31: "",//视频问诊实际设置金额
            num: "",//输入每日上限
            num1: "",//实际设置每日上限
            starttime: "",
            endtime: ""
        }
    }
    componentDidMount = async () => {
        const res = await getuserinfo({
            docterPhone: localStorage.getItem("docterPhone")
        })
        if (res.data[0].length) {
            let data=res.data[0][0]
            this.setState({
                checked1:data.docter_pic_text=="1"?true:false,
                checked3:data.docter_disturb=="1"?true:false,
                checked2:data.docter_voice=="1"?true:false,
                checked4:data.docter_video_text=="1"?true:false,
                money:data.docter_pic_charge/100,
                money1:data.docter_pic_charge/100,
                money2:data.docter_voice_charge/100,
                money21:data.docter_voice_charge/100,
                money3:data.docter_video_charge/100,
                money31:data.docter_video_charge/100,
                num:data.docter_upcount,
                num1:data.docter_upcount,
                starttime:data.docter_start_disturb,
                endtime:data.docter_end_disturb
           })
        }
    }
    openpopu = (type) => {
        this.setState({
            visible1: true,
            type
        })
    }
    // 修改单数和金额
    uploaddata = async () => {
        const { type, money, money2,money3, num } = this.state
        switch (type) {
            case '1':
                if (money>0){
                    const res = await changeset({
                        docterPicCharge: money*100,
                        docterId: localStorage.getItem("docterId")
                    })
                    if (res&&res.code&&res.code==1){
                        this.setState({
                            money1:money,
                            visible1:false
                        },()=>{
                            Toast.info('保存成功')
                        })
                    }
                }else{
                    Toast.info('金额必须大于0')
                }
                break;
            case '2':
                if (money2>0){
                    const res3 = await changeset({
                        docterVoiceCharge: money2*100,
                        docterId: localStorage.getItem("docterId")
                    })
                    if (res3&&res3.code&&res3.code==1){
                        this.setState({
                            money21:money2,
                            visible1:false
                        },()=>{
                            Toast.info('保存成功')
                        })
                    }
                }else{
                    Toast.info('金额必须大于0')
                }
                break;
            case '4':
                if (money3>0){
                    const res4 = await changeset({
                        docterVideoCharge: money3*100,
                        docterId: localStorage.getItem("docterId")
                    })
                    if (res4&&res4.code&&res4.code==1){
                        this.setState({
                            money31:money3,
                            visible1:false
                        },()=>{
                            Toast.info('保存成功')
                        })
                    }
                }else{
                    Toast.info('金额必须大于0')
                }
                break;
            case '3':
                if (num>=0){
                    const res2 = await changeset({
                        docterUpcount: num,
                        docterId: localStorage.getItem("docterId")
                    })
                    if (res2&&res2.code&&res2.code==1){
                        this.setState({
                            num1:num,
                            visible1:false
                        },()=>{
                            Toast.info('保存成功')
                        })
                    }
                }else{
                    Toast.info('订单上限必须大于等于0')
                }
                // console.log(res2);
                break
        }
    }
    changevalue = async (e, type) => {
        let reg=/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
        switch (type) {
            case "1":
                // if (reg.test(e.target.value)){
                    // 设置图文问诊价格
                    this.setState({
                        money: e.target.value
                    })
                // }else{
                //     // this.setState({
                //     //     money: e.target.value.toFixed(2)
                //     // })
                //     Toast.info('问诊金额最多两位小数')
                // }
                break;
            case "2":
                this.setState({
                    money2: e.target.value
                })
                break;
            case "4":
                this.setState({
                    money3: e.target.value
                })
                break;
            case "3":
                this.setState({
                    num: e.target.value
                })
                break;
        }
    }
    gobank = () => {
        window.history.back()
    }
    //开启和关闭设置
    checkedone = async (checked, code) => {
        switch (code) {
            case 1://图文问诊
                this.setState({
                    checked1: checked
                })
                const res = await changeset({
                    docterPicText: checked==true ? '1' : '0',
                    docterId: localStorage.getItem("docterId")
                })

                break;
            case 2://电话问诊
                this.setState({
                    checked2: checked
                })
                const res2 = await changeset({
                    docterVoice: checked==true ? '1' : '0',
                    docterId: localStorage.getItem("docterId")
                })

                break;
            case 4://视频问诊
                this.setState({
                    checked4: checked
                })
                const res4 = await changeset({
                    docterVideoText: checked==true ? '1' : '0',
                    docterId: localStorage.getItem("docterId")
                })

                break;
            case 3://免打扰
                this.setState({
                    checked3: checked
                })
                const res3 = await changeset({
                    docterDisturb: checked ? 1 : '0',
                    docterId: localStorage.getItem("docterId")
                })

                break;
        }
    }
    // 修改时间
    changetime = async (e, code) => {
        if (code == 1) {
            this.setState({
                starttime: e.target.value
            })
            const res = await changeset({
                docterStartDisturb: e.target.value,
                docterId: localStorage.getItem("docterId")
            })
        } else {
            this.setState({
                endtime: e.target.value
            })
            const res2 = await changeset({
                docterEndDisturb: e.target.value,
                docterId: localStorage.getItem("docterId")
            })

        }
    }
    render() {
        const { checked1, checked2, checked3,checked4,money21,money31, visible1, type, money,money1, money2,money3, num,num1, starttime, endtime } = this.state
        return (
            <>
                <div className='setserverbox'>
                    <div className='box-top'>
                        <img src={icon1} alt="" onClick={this.gobank} /><h1>问诊服务设置</h1>
                    </div>
                    <div className='box-item'>
                        <div>
                            <h1>图文问诊</h1>
                            <p>与患者文字、图片沟通，进行问诊开方</p>
                        </div>
                        <div>
                            <Switch
                                checked={checked1}
                                onChange={(checked) => this.checkedone(checked, 1)}
                            />
                        </div>
                    </div>
                    <div className='box-item'>
                        <div>
                            <h1>图文问诊价格（次/48小时）</h1>
                        </div>
                        <div onClick={() => this.openpopu("1")}>
                            <span>￥{money1?money1:"暂未设置"}</span><img src={icon2} alt="" />
                        </div>
                    </div>
                    <div className='box-item' style={{ marginBottom: "10px" }}>
                        <div>
                            <h1>每日订单上限</h1>
                        </div>
                        <div onClick={() => this.openpopu("3")}>
                            <span>{num1?num1+'单':"暂未设置"}</span><img src={icon2} alt="" />
                        </div>
                    </div>

                    {/*<div className='box-item'>*/}
                    {/*    <div>*/}
                    {/*        <h1>电话问诊</h1>*/}
                    {/*        <p>与患者15分钟隐私电话、语音沟通，进行问诊开方</p>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <Switch*/}
                    {/*            checked={checked2}*/}
                    {/*            onChange={checked => this.checkedone(checked, 2)}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className='box-item' style={{ marginBottom: "10px" }}>*/}
                    {/*    <div>*/}
                    {/*        <h1>电话问诊价格（次/15分钟）</h1>*/}
                    {/*    </div>*/}
                    {/*    <div onClick={() => this.openpopu("2")}>*/}
                    {/*        <span>￥{money21?money21:"暂未设置"}</span><img src={icon2} alt="" />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className='box-item'>
                        <div>
                            <h1>视频问诊</h1>
                            <p>与患者15分钟视频对话，进行问诊开方</p>
                        </div>
                        <div>
                            <Switch
                                checked={checked4}
                                onChange={checked => this.checkedone(checked, 4)}
                            />
                        </div>
                    </div>
                    <div className='box-item' style={{ marginBottom: "10px" }}>
                        <div>
                            <h1>视频问诊价格（次/15分钟）</h1>
                        </div>
                        <div onClick={() => this.openpopu("4")}>
                            <span>￥{money31?money31:"暂未设置"}</span><img src={icon2} alt="" />
                        </div>
                    </div>

                    <div className='box-item'>
                        <div>
                            <h1>免打扰时段</h1>
                        </div>
                        <div>
                            <Switch
                                checked={checked3}
                                onChange={(checked) => this.checkedone(checked, 3)}
                            />
                        </div>
                    </div>
                    <div className='box-item'>
                        <div>
                            <h1>起始时段</h1>
                        </div>
                        <div>
                            <span><input type="time" value={starttime} onChange={(e) => this.changetime(e, 1)} /></span><img src={icon2} alt="" />
                        </div>
                    </div>
                    <div className='box-item'>
                        <div>
                            <h1>结束时段</h1>
                        </div>
                        <div>
                            <span><input type="time" value={endtime} onChange={(e) => this.changetime(e, 2)} /></span><img src={icon2} alt="" />
                        </div>
                    </div>
                </div>
                <Popup
                    visible={visible1}
                    className='setserverbox-popup'
                    onMaskClick={() => {
                        this.setState({
                            visible1: false
                        })
                    }}
                    bodyStyle={{
                        minHeight: '20vh',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                    }}
                >
                    {type == "1" ?
                        <div className='popup-box'>
                            <h1>图文问诊价格</h1>
                            <div className='box-item'>
                                <div>自定义价格</div>
                                <div>￥ <input type="number" className='item-money' value={money} onChange={(e) => this.changevalue(e, "1")} /> </div>
                                <div><button className='item-btn' onClick={this.uploaddata}>确定</button></div>
                            </div>
                        </div>
                        : null
                    }
                    {type == "2" ?
                        <div className='popup-box'>
                            <h1>电话问诊价格</h1>
                            <div className='box-item'>
                                <div>自定义价格</div>
                                <div>￥ <input type="number" className='item-money' value={money2} onChange={(e) => this.changevalue(e, "2")} /> </div>
                                <div><button className='item-btn' onClick={this.uploaddata}>确定</button></div>
                            </div>
                        </div>
                        : null
                    }
                    {type == "4" ?
                        <div className='popup-box'>
                            <h1>视频问诊价格</h1>
                            <div className='box-item'>
                                <div>自定义价格</div>
                                <div>￥ <input type="number" className='item-money' value={money3} onChange={(e) => this.changevalue(e, "4")} /> </div>
                                <div><button className='item-btn' onClick={this.uploaddata}>确定</button></div>
                            </div>
                        </div>
                        : null
                    }
                    {type == "3" ?
                        <div className='popup-box'>
                            <h1>每日订单上限</h1>
                            <div className='box-item'>
                                <div>每日订单上限量</div>
                                <div><input type="number" className='item-money' value={num} onChange={(e) => this.changevalue(e, "3")} />单 </div>
                                <div><button className='item-btn' onClick={this.uploaddata}>确定</button></div>
                            </div>
                        </div>
                        : null
                    }

                </Popup>
            </>

        )
    }
}
