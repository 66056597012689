import React ,{ useState, useEffect } from 'react'
import { List, InputItem } from 'antd-mobile'
import './style.less'
import finish from '../../../static/img/finish.png'
import { qualificationsList } from '../../../api/request/qualifications'

export default function Await(props){
    const cartArr=props.location.state
    const userId =localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3
    const [list,setList]=useState([])
    useEffect(() => {
        qualificationsList().then(res=>{
            // console.log(res);
            for (let i = 0; i < res.data[0].length; i++) {
                if (res.data[0][i].user_id==userId) {
                    setList(res.data[0][i])
                }
                
            }
        })
    }, [])
    const nextStep=()=>{
        // props.history.push("/mine/storeSH")
        props.history.push("/qualifications")
    }
    // console.log(list);
    // let user;  //先定义一个存储参数的变量
    // let area;
    // let person;
    // let phone;

    // let licensePic;
    // let entrustPic;
    // let cardFront;
    // let cardReverse;

    // if(cartArr){  //判断是否有参数
    //     user=cartArr.companyName;//采购商
    //     area=cartArr.companyLocation+cartArr.companyAddress;//所在区域
    //     person=cartArr.Contacts;//采购负责人
    //     phone=cartArr.contactPhone;//联系方式
    //     licensePic=cartArr.licensePic
    //     entrustPic=cartArr.entrustPic
    //     cardFront=cartArr.cardFront
    //     cardReverse=cartArr.cardReverse
    //     localStorage.setItem('qualificationsUser', user);
    //     localStorage.setItem('qualificationsArea', area);
    //     localStorage.setItem('qualificationsPerson', person);
    //     localStorage.setItem('qualificationsPhone', phone);
    //     localStorage.setItem('qualificationslicensePic', licensePic);
    //     localStorage.setItem('qualificationsentrustPic', entrustPic);
    //     localStorage.setItem('qualificationscardFront', cardFront);
    //     localStorage.setItem('qualificationscardReverse', cardReverse);

    // }else{  //这种情况是说的刷新B页面时没有传递来的参数
    //     user = localStorage.getItem('qualificationsUser');
    //     area = localStorage.getItem('qualificationsArea');
    //     person = localStorage.getItem('qualificationsPerson');
    //     phone = localStorage.getItem('qualificationsPhone');//当state没有参数时，取sessionStorage中的参数
        
    //     licensePic = localStorage.getItem('qualificationslicensePic');
    //     entrustPic = localStorage.getItem('qualificationsentrustPic');
    //     cardFront = localStorage.getItem('qualificationscardFront');
    //     cardReverse = localStorage.getItem('qualificationscardReverse');
    // }
    return(
        <>
            <div className='outer2'>
                {list.examine==='0'?<header>
                    <h2>资质上传</h2>
                    <p className='step'>
                        <span>采购商信息</span>
                        <span className='xian'></span>
                        <span>相关证件</span>
                        <span className='xian'></span>
                        <span className='cg-news'>等待审核</span>
                    </p>
                </header>:null}
                {list.examine==='-1'?<header style={{height:'60px'}}>
                    <h2>资质申请</h2>
                </header>:null}
                {list.examine==='0'?<div className='await-zz'>
                    <h2>你的资质正在加急审核中，请耐心等待</h2>
                    <p className='qualifications-await'>审核通过后，可在"我的资质"中申请修改和增加资质</p>
                </div>:null}
                {list.examine==='-1'?<div className='await-zz1'>
                    <h3>很抱歉,您的采购资质未通过审核。</h3>
                    <p className='qualifications-await1'>为保证我们能够给您提供更优质的采购服务，请按照标记的不合格原因重新上传符合要求的证件。</p>
                </div>:null}
                <div className='jc-news'>
                    <p className='jc-news-title'><span>|</span>基础信息</p>
                    <p className='cgs-name'>采购商名称：<span>{list.company_name}</span></p>
                    <p className='address'>注册地址：<span>{list.company_location+list.company_address}</span></p>
                    <p className='fz-person'>采购负责人：<span>{list.contacts}</span></p>
                    <p className='phone'>联系方式：<span>{list.contact_phone}</span></p>
                </div>
                <div className='zz-news'>
                    <p className='zz-news-title'><span>|</span>资质信息</p>
                    {list.examine==='0'?<div className='zz-pic'>
                        <span className='pic'>
                            <img src={list.license_pic}></img>
                            <span className='pic-word'><span>*</span>营业执照</span>
                        </span>
                        <span className='pic'>
                            <img src={list.business_pic}></img>
                            <span className='pic-word'><span>*</span>药品经营许可证</span>
                        </span>
                        <span className='pic'>
                            <img src={list.entrust_pic}></img>
                            <span className='pic-word'><span>*</span>委托书</span>
                        </span>
                        <span className='pic'>
                            <img src={list.card_reverse}></img>
                            <span className='pic-word'><span>*</span>身份证正面照</span>
                        </span>
                        <span className='pic'>
                            <img src={list.card_front}></img>
                            <span className='pic-word'><span>*</span>身份证反面照</span>
                        </span>
                    </div>:null}
                    {list.examine==='-1'?<div className='zz-pic'>
                        {list.license_state===2?<span className='pic'>
                            <img src={list.license_pic}></img>
                            <span className='pic-word'><span>*</span>营业执照</span>
                        </span>:null}
                        {list.business_state===2?<span className='pic'>
                            <img src={list.business_pic}></img>
                            <span className='pic-word'><span>*</span>药品经营许可证</span>
                        </span>:null}
                        {list.entrust_state===2?<span className='pic'>
                            <img src={list.entrust_pic}></img>
                            <span className='pic-word'><span>*</span>委托书</span>
                        </span>:null}
                        {list.card_state===2?<span className='pic'>
                            <img src={list.card_reverse}></img>
                            <span className='pic-word'><span>*</span>身份证正面照</span>
                        </span>:null}
                        {list.card_state===2?<span className='pic'>
                            <img src={list.card_front}></img>
                            <span className='pic-word'><span>*</span>身份证反面照</span>
                        </span>:null}
                    </div>:null}
                </div>
                {list.examine==='-1'?<div className='reason'>
                    <h2>审核不通过原因：</h2>
                    <p>{list.examine_reasons}</p>
                </div>:null}
                <p className='kefu'>如有疑问，可咨询平台客服：</p>
                <p className='kefu-phone'>028-859642</p>
                <p className="outer2-btn">
                    <button onClick={()=>props.history.push("/login/Login1")}>注销</button><button onClick={()=>props.history.push("/mine")}>个人中心</button>
                </p>
                {list.examine==='-1'?<div className='next' onClick={()=>nextStep()}>
                    <img src={finish} alt="" />
                    <span>返回重新上传</span>
                </div>:null}
            </div>
        </>
    )
}